import { configureStore } from '@reduxjs/toolkit';

import authReducer from './auth';
import timestampsReducer from './timestamps';
import vesselsReducer from './vessels';

export const store = configureStore({
  reducer: {
    vessels: vesselsReducer,
    timestamps: timestampsReducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat([]);
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
