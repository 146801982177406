import { PropsWithChildren } from 'react';
import classNames from 'classnames';

export function Page({
  children,
  className,
}: PropsWithChildren<{
  className?: string;
}>) {
  return (
    <div className={classNames('w-full h-full', 'flex flex-col', className)}>
      {children}
    </div>
  );
}
