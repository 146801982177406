export type User = {
  name: string;
  username: string;
  password: string;
};

export const Users: User[] = [
  {
    name: 'admin',
    username: 'admin',
    password: 'admin',
  },
];
