import { AppDispatch, RootState } from '@/store';
import { selectEndTime, selectStartTime } from '@/store/timestamps';
import {
  retrieveHistoricalVessels,
  retrieveVessels,
  retrieveVesselTrack,
} from '@/utils/transfer';

import { setLoadingData, setVesselCollection } from './slice';

export function fetchVessels() {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoadingData({ isLoading: true }));
    const vessels = await retrieveVessels();
    dispatch(setVesselCollection(vessels));
    dispatch(
      setLoadingData({
        isLoading: false,
        updatedAt: new Date().toISOString(),
      }),
    );
  };
}

export function fetchHistoricalVessels() {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const startTime = selectStartTime(state);
    const endTime = selectEndTime(state);

    if (startTime && endTime) {
      dispatch(setLoadingData({ isLoading: true }));
      const vessels = await retrieveHistoricalVessels(startTime, endTime);
      dispatch(setVesselCollection(vessels));
      dispatch(
        setLoadingData({
          isLoading: false,
          updatedAt: new Date().toISOString(),
        }),
      );
    }
  };
}

export function fetchVesselTrack() {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoadingData({ isLoading: true }));
    await dispatch(retrieveVesselTrack);
    dispatch(
      setLoadingData({ isLoading: false, updatedAt: new Date().toISOString() }),
    );
  };
}
