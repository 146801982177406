import React from 'react';
import { Layer, Source } from 'react-map-gl';

import { PointsOfInterest } from '@/constants';
import { poiAsFeatureCollection } from '@/utils';

export const MapPointsOfInterestLayer = () => {
  const poiFeatures = poiAsFeatureCollection(PointsOfInterest);

  return (
    <Source data={poiFeatures} id={'poi'} type={'geojson'}>
      <Layer
        layout={{
          'icon-image': 'harbor-15',
          'text-field': ['get', 'name'],
          'text-offset': [0, 1],
          'text-anchor': 'top',
        }}
        type={'symbol'}
      />
    </Source>
  );
};
