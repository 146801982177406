import { AppDispatch } from '@/store';

import { clearAuthState, setAuthState } from './slice';
import {
  AccessToken,
  AuthenticatedState,
  AuthState,
  Credentials,
  UnauthenticatedState,
} from './types';
import { Users } from '@/constants';

// TODO: proper authentication
async function loginRequest({
  username,
  password,
}: Credentials): Promise<AuthState> {
  return new Promise((resolve) => {
    setTimeout(() => {
      const user = Users.find(
        (user) => username === user.username && password === user.password,
      );
      if (user) {
        return resolve(
          AuthenticatedState({
            access_token: 'access_token' as AccessToken,
            user,
          }),
        );
      }

      return resolve(UnauthenticatedState('Invalid username or password'));
    }, 200);
  });
}

export function login(credentials: Credentials) {
  return async (dispatch: AppDispatch) => {
    dispatch(setAuthState(await loginRequest(credentials)));
  };
}

export function logout() {
  return async (dispatch: AppDispatch) => {
    dispatch(clearAuthState());
  };
}
