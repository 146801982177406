import React from 'react';

type Props = {
  className?: string;
  children?: React.ReactNode;
};

export const Paragraph = ({ className, children }: Props) => {
  return <p className={`text-md text-gray-400 ${className}`}>{children}</p>;
};
