import { Anchor, PointLike } from 'react-map-gl';
import { TemporalVessel, Vessel } from '@/store/vessels';
import { LngLatBoundsLike } from 'mapbox-gl';

type LngLat = [number, number];
type LngLatBounds = [LngLat, LngLat];

export function symmetricTooltipOffset(
  offset: number,
): Record<Anchor, PointLike> {
  return {
    center: [0, 0],
    top: [0, offset],
    bottom: [0, -offset],
    left: [offset, 0],
    right: [-offset, 0],
    'top-right': [-offset, offset],
    'top-left': [offset, offset],
    'bottom-right': [-offset, -offset],
    'bottom-left': [offset, -offset],
  };
}

export const getBoundsForVessels: (
  vessels: Vessel[],
) => LngLatBoundsLike | null = (vessels: Vessel[]) => {
  if (vessels.length === 0) {
    return null;
  }

  return vessels.reduce(
    (acc, vessel) =>
      extendBounds(acc, vessel.location.value.coordinates as LngLat),
    [
      [170, 80],
      [-170, -80],
    ],
  );
};

export const getBoundsForVesselTrack: (
  vessel: TemporalVessel,
) => LngLatBoundsLike | null = (vessel: TemporalVessel) => {
  if (!vessel.location || !vessel.location.values) {
    return null;
  }

  return vessel.location.values.reduce(
    (acc, location) => extendBounds(acc, location[0].coordinates as LngLat),
    [
      [80, 170],
      [-80, -170],
    ],
  );
};

/**
 * Returns a new Bounds object extended by the given coordinates [lng, lat] if they fall outside the given bounds.
 * @param bounds
 * @param lng
 * @param lat
 */
function extendBounds(bounds: LngLatBounds, [lng, lat]: LngLat): LngLatBounds {
  return [
    [Math.min(bounds[0][0], lng), Math.min(bounds[0][1], lat)],
    [Math.max(bounds[1][0], lng), Math.max(bounds[1][1], lat)],
  ];
}
