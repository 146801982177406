import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingData, TemporalVessel, Vessel } from './types';
import { filterValidVessels } from '@/utils/vessels';

export type VesselsState = {
  loadingData: LoadingData;
  vesselCollection: Vessel[];
  selectedVessel: Vessel | null;
  selectedVesselTrack: TemporalVessel | null;
  historicMode: boolean;
};

const initialState: VesselsState = {
  loadingData: { isLoading: false, updatedAt: undefined },
  vesselCollection: [],
  selectedVessel: null,
  selectedVesselTrack: null,
  historicMode: false,
};

export const vesselsSlice = createSlice({
  name: 'vessels',
  initialState,
  reducers: {
    setLoadingData: (state, action: PayloadAction<LoadingData>) => {
      state.loadingData = {
        ...state.loadingData,
        ...action.payload,
      };
    },
    setVesselCollection: (state, action: PayloadAction<Vessel[]>) => {
      state.vesselCollection = filterValidVessels(action.payload);
    },
    setSelectedVessel: (state, action: PayloadAction<Vessel | null>) => {
      state.selectedVessel = action.payload;
      if (action.payload === null) {
        state.selectedVesselTrack = null;
      }
    },
    setSelectedVesselTrack: (
      state,
      action: PayloadAction<TemporalVessel | null>,
    ) => {
      state.selectedVesselTrack = action.payload;
    },
    setHistoricMode: (state, action: PayloadAction<boolean>) => {
      state.historicMode = action.payload;
    },
  },
});

export default vesselsSlice.reducer;

export const {
  setVesselCollection,
  setLoadingData,
  setSelectedVessel,
  setSelectedVesselTrack,
  setHistoricMode,
} = vesselsSlice.actions;
