import classNames from 'classnames';
import React from 'react';

import { Container } from '@/components';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectVesselCollection,
  setSelectedVessel,
  VesselProperty,
} from '@/store/vessels';
import { sortVesselsAlpha } from '@/utils/vessels';

export function MapVesselCollection() {
  const dispatch = useAppDispatch();
  const vesselCollection = sortVesselsAlpha(
    useAppSelector(selectVesselCollection),
  );

  return (
    <>
      <Container
        className={classNames(
          'absolute top-4 right-4',
          'max-h-96',
          'join join-vertical',
          'btn-group',
          'border border-gray-600',
          'divide-y divide-gray-300',
          'rounded-br-none',
        )}
      >
        <div className={classNames('text-center', 'font-bold text-lg', 'p-3')}>
          Vessels
        </div>
        {vesselCollection.length ? (
          <div
            className={classNames(
              'overflow-y-scroll', // fixes scrollbar clipping
              'max-w-[12rem]',
              'rounded-none',
              'mb-2',
            )}
          >
            <div className={classNames('join join-vertical')}>
              {vesselCollection.map((vessel) => (
                <button
                  className={classNames(
                    'join-item',
                    'px-4 py-1',
                    'hover:bg-neutral-200',
                  )}
                  key={vessel.id}
                  name={'consumer'}
                  onClick={() => dispatch(setSelectedVessel(vessel))}
                >
                  {vessel[VesselProperty.Name].value}
                </button>
              ))}
            </div>
          </div>
        ) : null}
      </Container>
    </>
  );
}
