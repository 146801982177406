import { FeatureCollection } from 'geojson';
import { Layer, Source } from 'react-map-gl';
import { CORRIDORS_OF_INTEREST } from 'src/constants/corridors';

function mergeFeatureCollections(
  featureCollections: FeatureCollection[],
): FeatureCollection {
  return {
    type: 'FeatureCollection',
    features: featureCollections.flatMap(
      (featureCollection) => featureCollection.features,
    ),
  };
}

export const MapCorridorsOfInterestLayer = () => {
  const corridors = mergeFeatureCollections(CORRIDORS_OF_INTEREST);

  return (
    <Source data={corridors} id={'corridors-of-interest'} type={'geojson'}>
      <Layer
        paint={{
          'line-color': 'green',
          'line-width': 10,
          'line-opacity': 0.5,
        }}
        type={'line'}
      />
    </Source>
  );
};
