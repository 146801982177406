import { VesselETA, VesselProperty } from '@/store/vessels';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';

const TD_STYLE = classNames(
  'border-2',
  'px-2',
  'whitespace-nowrap',
  'select-all',
);

export function ETADataRow({ vessel }: { vessel: VesselETA }) {
  return (
    <tr
      className={classNames(
        'border-2',
        'px-2',
        'hover:bg-primary hover:text-white',
      )}
    >
      <td className={TD_STYLE}>{vessel[VesselProperty.Name]?.value || ''}</td>
      <td className={TD_STYLE}>{vessel[VesselProperty.MMSI]?.value || ''}</td>
      <td className={TD_STYLE}>
        {moment(
          vessel[VesselProperty.EstimatedTimeOfArrival]?.value || '',
        ).toISOString()}
      </td>
      <td className={TD_STYLE}>
        {moment(vessel['calculated_eta']?.value || '').toISOString()}
      </td>
      <td className={TD_STYLE}>
        {vessel[VesselProperty.DestinationPort]?.value || ''}
      </td>
    </tr>
  );
}
