import { User } from '@/constants';
import { Opaque } from '@/utils/opaque';

export type AccessToken = Opaque<string, 'AccessToken'>;

export type Credentials = {
  username: string;
  password: string;
};

export type AuthState = AuthenticatedState | UnauthenticatedState;

export type AuthenticatedState = {
  authenticated: true;
} & AuthData;

export type AuthData = {
  user: User;
  access_token: AccessToken;
};

export type UnauthenticatedState = {
  authenticated: false;
  error?: string;
};

export function AuthenticatedState(authData: AuthData): AuthenticatedState {
  return {
    authenticated: true,
    ...authData,
  };
}

export function UnauthenticatedState(error?: string): UnauthenticatedState {
  return {
    authenticated: false,
    error,
  };
}
