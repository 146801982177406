import { RootState } from '@/store';

export const selectVesselCollection = (state: RootState) => [
  ...state.vessels.vesselCollection,
];

export const selectLoadingData = (state: RootState) =>
  state.vessels.loadingData;

export const selectSelectedVessel = (state: RootState) =>
  state.vessels.selectedVessel;

export const selectSelectedVesselTrack = (state: RootState) =>
  state.vessels.selectedVesselTrack;

export const selectHistoricMode = (state: RootState) =>
  state.vessels.historicMode;
