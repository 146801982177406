import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { NavBar } from '@/components/navigation';
import { ETATable } from '@/components/eta-table/ETATable';
import { ETA_BROKER_TENANT, ETA_BROKER_URL } from '@/constants/env';
import { VesselEntityType, VesselETA } from '@/store/vessels';
import { Page } from '@/pages/Page';

export function ETAPage() {
  const [vesselETAs, setVesselETAs] = useState<VesselETA[]>([]);

  useEffect(() => {
    async function fetchVesselsETA() {
      try {
        const headers: Record<string, string> = {};
        if (ETA_BROKER_TENANT) {
          headers['Ngsild-tenant'] = ETA_BROKER_TENANT;
        }
        const response = await fetch(
          `${ETA_BROKER_URL}/ngsi-ld/v1/entities?type=${VesselEntityType}`,
          {
            headers,
          },
        );
        const responseBody = (await response.json()) as VesselETA[];
        if (Array.isArray(responseBody) && responseBody.length) {
          setVesselETAs(responseBody);
        }
      } catch (err) {
        console.error('Error fetching ETAs', err);
      }
    }

    fetchVesselsETA();
  }, []);

  return (
    <Page className={'bg-primary'}>
      <NavBar />
      <div className={classNames('flex justify-center items-center', 'p-24')}>
        <div
          className={classNames(
            'bg-white',
            'p-12',
            'flex flex-col items-center',
          )}
        >
          <h2 className={'font-bold text-xl mb-4'}>Vessel ETAs</h2>
          <ETATable vessels={vesselETAs} />
        </div>
      </div>
    </Page>
  );
}
