import { THEME } from '@/constants/env';
import { useAuth } from '@/hooks';
import { LoginPage, VesselMapPage } from '@/pages';
import { ETAPage } from '@/pages/ETAPage';
import classNames from 'classnames';

export function App() {
  const { authState } = useAuth();

  const SHOW_ETA_PAGE = THEME === 'dacia';

  const page = authState.authenticated ? (
    SHOW_ETA_PAGE ? (
      <ETAPage />
    ) : (
      <VesselMapPage />
    )
  ) : (
    <LoginPage />
  );

  return (
    <div
      className={classNames(
        'h-screen w-screen',
        'selection:bg-accent selection:text-accent-content',
      )}
    >
      {page}
    </div>
  );
}
