import 'react-datepicker/dist/react-datepicker.css';

import DatePicker from 'react-datepicker';

import { useAppDispatch } from '@/hooks';

type Props = {
  timestamp: Date | null | undefined;
  onChange: (arg0: Date) => void;
};

export const DateTimePicker = ({ timestamp, onChange }: Props) => {
  const dispatch = useAppDispatch();

  // The custom style is a hack to get the datepicker to display correctly with timeSelect enabled
  // See https://github.com/Hacker0x01/react-datepicker/issues/1116
  return (
    <>
      <style>
        {`.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
                     padding-left: unset;
                     padding-right: unset;
                     width: 100px;
        }
        .react-datepicker__input-container, .react-datepicker-wrapper {
                             width:100%;
        }
        .react-datepicker {
                             width: 328px;
        }
        .react-datepicker .react-datepicker__day {
                       line-height: 1.4rem;
                       margin: .1rem 0.166rem;
        }
        .react-datepicker .react-datepicker__day-name {
                       line-height: 1rem;
        }`}
      </style>
      <DatePicker
        calendarStartDay={1} // Start on Monday
        dateFormat={'yyyy-MM-dd HH:mm'}
        onChange={onChange}
        popperPlacement={'top-end'}
        selected={timestamp}
        showTimeSelect={true} // Enable time selection
        timeFormat={'HH:mm'}
        timeIntervals={60} // Only allow 1-hour intervals
      />
    </>
  );
};
