import classNames from 'classnames';
import React from 'react';

import { TableHeader } from './TableHeader';
import { VesselETA } from '@/store/vessels';
import { ETADataRow } from '@/components/eta-table/ETADataRow';

export interface ETATableProps {
  vessels: VesselETA[];
}

export function ETATable({ vessels }: ETATableProps) {
  return (
    <table className={classNames('table-auto', 'cursor-default')}>
      <thead>
        <TableHeader
          headers={[
            'Vessel Name',
            'MMSI',
            'Original ETA',
            'AI-Generated ETA',
            'Destination',
          ]}
        />
      </thead>
      <tbody>
        {vessels.map((vesselData, index) => (
          <ETADataRow key={index} vessel={vesselData} />
        ))}
      </tbody>
    </table>
  );
}
