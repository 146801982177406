import { FeatureCollection, LineString } from 'geojson';

import { CORRIDOR_AWT_BONNEUIL } from './awt_boneuil';
import { CORRIDOR_AWT_DUISBURG } from './awt_duisburg';
import { CORRIDOR_DUISBURG_REGENSBURG } from './duisburg_regensburg';
import { CORRIDOR_REGENSBURG_GIURGIU } from './regensburg_giurgiu';

export const CORRIDORS_OF_INTEREST: FeatureCollection<LineString>[] = [
  CORRIDOR_AWT_BONNEUIL,
  CORRIDOR_AWT_DUISBURG,
  CORRIDOR_DUISBURG_REGENSBURG,
  CORRIDOR_REGENSBURG_GIURGIU,
];
