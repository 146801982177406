import { FeatureCollection, LineString } from 'geojson';

export const CORRIDOR_DUISBURG_REGENSBURG: FeatureCollection<LineString> = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        name: 'duisburg_regensburg',
      },
      geometry: {
        type: 'LineString',
        coordinates: [
          [6.722019, 51.451542],
          [6.719445, 51.451066],
          [6.716709, 51.448995],
          [6.714171, 51.446476],
          [6.712506, 51.444298],
          [6.71157, 51.441886],
          [6.711426, 51.43951],
          [6.711822, 51.437098],
          [6.711921, 51.43671],
          [6.712983, 51.434371],
          [6.715926, 51.431239],
          [6.718338, 51.429466],
          [6.723135, 51.426657],
          [6.727194, 51.424417],
          [6.733395, 51.420196],
          [6.73902, 51.415399],
          [6.739263, 51.415129],
          [6.742701, 51.411502],
          [6.74451, 51.407947],
          [6.744627, 51.40774],
          [6.745095, 51.406299],
          [6.745671, 51.40179],
          [6.745455, 51.399766],
          [6.744789, 51.397534],
          [6.7437, 51.395716],
          [6.742107, 51.393277],
          [6.739794, 51.390837],
          [6.734511, 51.385762],
          [6.72651, 51.380398],
          [6.721515, 51.377734],
          [6.717285, 51.375997],
          [6.713271, 51.374646],
          [6.70392, 51.372847],
          [6.695163, 51.372361],
          [6.68781, 51.372028],
          [6.67908, 51.370912],
          [6.671331, 51.369282],
          [6.664509, 51.367231],
          [6.658785, 51.3649],
          [6.655968, 51.362793],
          [6.65406, 51.36004],
          [6.65352, 51.358069],
          [6.653655, 51.355585],
          [6.654492, 51.352984],
          [6.655887, 51.35149],
          [6.658425, 51.349546],
          [6.658731, 51.349329],
          [6.661917, 51.347899],
          [6.666102, 51.346819],
          [6.674247, 51.345028],
          [6.679971, 51.343804],
          [6.685704, 51.342589],
          [6.692427, 51.341248],
          [6.702687, 51.338485],
          [6.711849, 51.335083],
          [6.719661, 51.331528],
          [6.725214, 51.328531],
          [6.730281, 51.325047],
          [6.73173, 51.323553],
          [6.733125, 51.321906],
          [6.734934, 51.318559],
          [6.735555, 51.314319],
          [6.734979, 51.309946],
          [6.733197, 51.305419],
          [6.730605, 51.301162],
          [6.727203, 51.297957],
          [6.726348, 51.297211],
          [6.71931, 51.291514],
          [6.707097, 51.281667],
          [6.704262, 51.278544],
          [6.704019, 51.278175],
          [6.702768, 51.276106],
          [6.701931, 51.27373],
          [6.701868, 51.271038],
          [6.703092, 51.268347],
          [6.705477, 51.265459],
          [6.708483, 51.26311],
          [6.712128, 51.261202],
          [6.716268, 51.259429],
          [6.72129, 51.257773],
          [6.727203, 51.256414],
          [6.731865, 51.255361],
          [6.73893, 51.253867],
          [6.741963, 51.253282],
          [6.748776, 51.251779],
          [6.751818, 51.250816],
          [6.755814, 51.249187],
          [6.759117, 51.247206],
          [6.759612, 51.246936],
          [6.762852, 51.244623],
          [6.764643, 51.242725],
          [6.766425, 51.240826],
          [6.768045, 51.238],
          [6.769071, 51.234697],
          [6.769368, 51.231835],
          [6.769368, 51.231393],
          [6.768981, 51.2272],
          [6.767856, 51.224788],
          [6.766065, 51.222466],
          [6.763941, 51.221143],
          [6.763365, 51.220909],
          [6.762015, 51.220468],
          [6.759531, 51.219936],
          [6.756867, 51.219972],
          [6.754266, 51.220395],
          [6.751152, 51.221745],
          [6.746364, 51.225184],
          [6.740874, 51.227667],
          [6.73515, 51.229198],
          [6.730038, 51.228991],
          [6.726411, 51.227695],
          [6.723468, 51.225525],
          [6.7221, 51.222862],
          [6.722955, 51.219811],
          [6.727833, 51.214158],
          [6.731172, 51.208894],
          [6.731352, 51.208479],
          [6.732099, 51.20578],
          [6.732009, 51.201631],
          [6.73128, 51.198985],
          [6.731154, 51.198544],
          [6.729822, 51.193647],
          [6.729804, 51.19101],
          [6.730551, 51.188392],
          [6.732486, 51.185854],
          [6.733989, 51.184774],
          [6.735123, 51.183937],
          [6.739155, 51.181965],
          [6.744339, 51.180814],
          [6.752061, 51.180507],
          [6.757812, 51.181489],
          [6.766461, 51.183685],
          [6.774723, 51.1857],
          [6.775605, 51.185854],
          [6.780411, 51.186034],
          [6.784704, 51.18535],
          [6.78852, 51.184252],
          [6.792471, 51.182074],
          [6.795297, 51.179896],
          [6.79797, 51.175791],
          [6.798654, 51.171597],
          [6.797862, 51.167196],
          [6.79752, 51.16618],
          [6.796899, 51.164956],
          [6.793137, 51.158151],
          [6.79203, 51.154804],
          [6.792327, 51.151474],
          [6.793524, 51.148512],
          [6.79563, 51.146173],
          [6.798546, 51.144445],
          [6.802119, 51.143122],
          [6.80544, 51.142545],
          [6.811128, 51.142573],
          [6.815079, 51.143643],
          [6.816708, 51.144436],
          [6.819201, 51.145669],
          [6.822486, 51.149007],
          [6.826122, 51.153895],
          [6.830001, 51.157413],
          [6.833781, 51.159889],
          [6.837624, 51.161598],
          [6.840837, 51.162561],
          [6.844401, 51.162886],
          [6.847947, 51.162642],
          [6.851655, 51.161868],
          [6.854706, 51.160573],
          [6.856677, 51.158556],
          [6.857685, 51.156352],
          [6.85719, 51.154246],
          [6.85548, 51.151708],
          [6.85107, 51.147019],
          [6.84882, 51.143077],
          [6.847704, 51.139576],
          [6.847749, 51.13594],
          [6.849072, 51.132565],
          [6.850683, 51.130432],
          [6.851772, 51.129208],
          [6.852456, 51.128533],
          [6.85458, 51.126858],
          [6.857775, 51.124959],
          [6.867585, 51.120369],
          [6.871797, 51.118371],
          [6.877242, 51.115708],
          [6.880707, 51.112809],
          [6.882768, 51.110029],
          [6.883965, 51.10704],
          [6.884028, 51.10444],
          [6.883191, 51.101641],
          [6.881868, 51.100002],
          [6.879987, 51.098554],
          [6.878322, 51.097618],
          [6.875829, 51.096681],
          [6.866649, 51.093486],
          [6.86088, 51.091345],
          [6.856497, 51.089158],
          [6.854418, 51.087222],
          [6.852636, 51.084658],
          [6.852294, 51.082111],
          [6.852933, 51.079159],
          [6.855012, 51.076459],
          [6.858315, 51.074506],
          [6.860754, 51.073722],
          [6.865182, 51.072813],
          [6.873102, 51.07167],
          [6.88212, 51.07024],
          [6.889068, 51.068467],
          [6.893379, 51.066964],
          [6.895845, 51.065946],
          [6.90057, 51.063714],
          [6.905502, 51.060925],
          [6.911667, 51.057351],
          [6.915744, 51.055543],
          [6.91776, 51.054643],
          [6.932862, 51.047551],
          [6.935742, 51.046138],
          [6.939981, 51.044076],
          [6.942501, 51.042871],
          [6.951609, 51.038568],
          [6.960717, 51.034257],
          [6.961176, 51.033997],
          [6.966171, 51.031198],
          [6.969879, 51.028327],
          [6.972174, 51.025951],
          [6.973722, 51.023503],
          [6.97437, 51.020758],
          [6.974235, 51.017922],
          [6.97293, 51.015519],
          [6.967719, 51.009741],
          [6.963696, 51.005629],
          [6.962022, 51.002658],
          [6.961617, 50.999896],
          [6.961698, 50.997673],
          [6.962661, 50.995027],
          [6.964209, 50.992435],
          [6.966144, 50.99023],
          [6.969276, 50.988214],
          [6.976026, 50.985811],
          [6.985467, 50.983101],
          [6.991362, 50.981167],
          [6.9948, 50.979304],
          [6.996996, 50.977432],
          [6.998355, 50.975794],
          [6.999237, 50.973912],
          [6.999525, 50.971005],
          [6.998517, 50.967811],
          [6.996078, 50.964265],
          [6.995826, 50.963977],
          [6.994035, 50.962303],
          [6.991578, 50.960845],
          [6.986907, 50.959153],
          [6.980148, 50.957181],
          [6.975423, 50.954868],
          [6.974919, 50.954589],
          [6.971175, 50.952375],
          [6.968538, 50.949558],
          [6.966756, 50.946436],
          [6.96618, 50.944024],
          [6.965793, 50.941693],
          [6.96573, 50.941198],
          [6.966297, 50.936608],
          [6.966351, 50.93622],
          [6.966459, 50.935663],
          [6.967125, 50.932738],
          [6.967512, 50.93091],
          [6.967683, 50.93029],
          [6.970149, 50.923126],
          [6.972966, 50.917168],
          [6.97311, 50.916934],
          [6.975234, 50.913306],
          [6.979635, 50.907394],
          [6.984513, 50.902687],
          [6.989769, 50.899536],
          [6.990453, 50.899195],
          [6.99408, 50.897071],
          [7.000776, 50.895289],
          [7.006221, 50.894461],
          [7.014024, 50.894155],
          [7.025742, 50.894659],
          [7.033293, 50.894757],
          [7.03926, 50.894137],
          [7.043634, 50.892804],
          [7.046703, 50.891158],
          [7.048917, 50.889556],
          [7.05042, 50.887809],
          [7.051599, 50.886109],
          [7.052157, 50.883931],
          [7.051986, 50.881924],
          [7.050798, 50.879349],
          [7.04799, 50.876343],
          [7.043589, 50.874139],
          [7.034922, 50.871753],
          [7.027362, 50.869189],
          [7.022448, 50.866156],
          [7.018992, 50.863105],
          [7.016643, 50.861062],
          [7.013286, 50.858056],
          [7.006671, 50.85379],
          [7.003368, 50.852484],
          [7.001946, 50.851954],
          [6.990579, 50.848318],
          [6.98535, 50.84623],
          [6.982425, 50.844241],
          [6.980085, 50.84173],
          [6.978825, 50.838885],
          [6.978735, 50.835348],
          [6.980112, 50.832271],
          [6.98292, 50.829319],
          [6.987186, 50.827051],
          [6.992577, 50.825647],
          [6.997662, 50.825196],
          [7.004376, 50.825016],
          [7.006725, 50.824864],
          [7.013475, 50.82391],
          [7.018515, 50.822461],
          [7.022403, 50.820696],
          [7.026111, 50.817888],
          [7.027722, 50.815926],
          [7.029117, 50.813326],
          [7.030107, 50.810238],
          [7.030314, 50.806881],
          [7.028919, 50.798908],
          [7.029009, 50.795308],
          [7.03017, 50.791771],
          [7.03179, 50.789277],
          [7.033824, 50.787028],
          [7.03728, 50.784274],
          [7.044399, 50.77999],
          [7.052535, 50.776641],
          [7.058763, 50.774158],
          [7.068411, 50.770342],
          [7.069428, 50.769954],
          [7.078545, 50.765833],
          [7.085952, 50.762007],
          [7.092621, 50.759361],
          [7.09947, 50.756878],
          [7.10028, 50.756356],
          [7.102962, 50.75442],
          [7.105086, 50.751414],
          [7.107624, 50.746275],
          [7.110243, 50.738437],
          [7.110369, 50.737978],
          [7.111458, 50.734494],
          [7.112835, 50.730481],
          [7.115886, 50.726566],
          [7.119549, 50.723983],
          [7.124823, 50.721678],
          [7.132743, 50.71978],
          [7.143039, 50.717673],
          [7.143795, 50.717493],
          [7.148214, 50.716485],
          [7.153272, 50.714938],
          [7.157718, 50.713048],
          [7.161876, 50.710536],
          [7.165287, 50.70771],
          [7.167933, 50.704587],
          [7.169193, 50.70214],
          [7.170417, 50.698944],
          [7.172451, 50.693419],
          [7.172856, 50.692231],
          [7.174035, 50.689423],
          [7.176186, 50.686282],
          [7.181469, 50.681872],
          [7.187571, 50.675329],
          [7.192377, 50.669596],
          [7.200054, 50.660802],
          [7.204878, 50.656267],
          [7.206354, 50.653414],
          [7.207173, 50.65174],
          [7.207731, 50.650444],
          [7.208019, 50.649103],
          [7.208127, 50.647546],
          [7.208199, 50.645557],
          [7.20792, 50.643513],
          [7.207605, 50.641552],
          [7.207398, 50.640561],
          [7.207299, 50.639824],
          [7.207119, 50.638168],
          [7.207227, 50.636989],
          [7.207596, 50.635566],
          [7.208118, 50.63401],
          [7.208721, 50.633317],
          [7.210431, 50.631544],
          [7.212258, 50.627962],
          [7.212726, 50.620878],
          [7.213671, 50.615694],
          [7.213779, 50.610969],
          [7.214121, 50.606514],
          [7.214175, 50.60512],
          [7.213761, 50.601943],
          [7.211313, 50.598865],
          [7.2099, 50.596273],
          [7.210215, 50.593771],
          [7.21116, 50.591449],
          [7.21395, 50.588308],
          [7.217991, 50.585418],
          [7.22133, 50.583375],
          [7.226451, 50.581872],
          [7.23465, 50.580414],
          [7.236405, 50.580172],
          [7.244505, 50.579479],
          [7.24572, 50.579398],
          [7.252218, 50.578579],
          [7.258374, 50.577256],
          [7.263891, 50.575393],
          [7.268274, 50.572756],
          [7.273107, 50.567806],
          [7.275294, 50.565321],
          [7.276104, 50.564539],
          [7.27848, 50.561659],
          [7.280217, 50.557311],
          [7.281783, 50.550787],
          [7.283655, 50.546377],
          [7.284105, 50.545413],
          [7.289856, 50.534668],
          [7.290918, 50.532408],
          [7.294212, 50.525802],
          [7.297785, 50.517982],
          [7.301853, 50.51224],
          [7.310691, 50.504095],
          [7.312419, 50.502717],
          [7.324587, 50.49334],
          [7.334676, 50.485077],
          [7.340067, 50.480245],
          [7.344639, 50.476105],
          [7.347141, 50.472792],
          [7.348041, 50.471397],
          [7.350957, 50.468104],
          [7.362936, 50.460021],
          [7.377012, 50.45184],
          [7.38432, 50.448114],
          [7.394256, 50.444109],
          [7.400997, 50.443318],
          [7.412058, 50.443569],
          [7.418952, 50.444137],
          [7.424298, 50.444542],
          [7.429113, 50.444604],
          [7.436196, 50.443101],
          [7.441695, 50.440366],
          [7.445808, 50.436918],
          [7.451064, 50.429745],
          [7.45209, 50.427586],
          [7.454034, 50.425723],
          [7.455942, 50.424417],
          [7.459038, 50.422087],
          [7.459542, 50.42178],
          [7.460145, 50.421457],
          [7.466283, 50.419072],
          [7.467705, 50.418333],
          [7.469244, 50.417334],
          [7.471503, 50.415544],
          [7.47324, 50.414553],
          [7.476309, 50.413321],
          [7.47963, 50.412349],
          [7.483167, 50.411386],
          [7.491168, 50.411494],
          [7.500726, 50.412214],
          [7.509591, 50.413753],
          [7.514928, 50.415814],
          [7.521066, 50.418927],
          [7.526709, 50.420863],
          [7.52715, 50.420953],
          [7.532046, 50.421817],
          [7.539102, 50.421817],
          [7.546158, 50.4208],
          [7.553511, 50.418568],
          [7.561278, 50.416039],
          [7.566588, 50.413213],
          [7.57215, 50.408749],
          [7.572546, 50.408398],
          [7.57791, 50.404249],
          [7.584309, 50.400721],
          [7.589925, 50.398408],
          [7.591482, 50.397741],
          [7.597971, 50.395177],
          [7.604604, 50.390992],
          [7.608465, 50.387409],
          [7.611426, 50.383486],
          [7.613082, 50.380236],
          [7.613487, 50.378536],
          [7.613271, 50.375916],
          [7.609851, 50.368033],
          [7.608861, 50.364667],
          [7.607556, 50.361093],
          [7.606548, 50.357521],
          [7.603578, 50.353579],
          [7.603425, 50.353372],
          [7.601013, 50.348223],
          [7.599321, 50.345946],
          [7.597647, 50.34231],
          [7.596531, 50.338359],
          [7.593462, 50.33341],
          [7.593372, 50.33323],
          [7.593273, 50.332941],
          [7.593129, 50.332671],
          [7.590357, 50.325742],
          [7.589547, 50.323834],
          [7.588989, 50.32071],
          [7.58916, 50.317975],
          [7.589502, 50.315653],
          [7.591842, 50.310604],
          [7.593192, 50.308291],
          [7.594551, 50.305977],
          [7.599114, 50.300101],
          [7.603731, 50.295178],
          [7.606989, 50.29255],
          [7.614972, 50.287473],
          [7.625358, 50.280949],
          [7.636338, 50.273407],
          [7.641603, 50.269428],
          [7.645635, 50.266549],
          [7.651062, 50.263011],
          [7.652232, 50.260653],
          [7.652808, 50.25727],
          [7.652313, 50.254552],
          [7.650342, 50.251563],
          [7.64712, 50.248926],
          [7.643727, 50.247541],
          [7.640172, 50.246452],
          [7.635618, 50.245732],
          [7.630425, 50.245849],
          [7.625277, 50.246596],
          [7.611138, 50.249629],
          [7.601778, 50.250583],
          [7.594911, 50.250339],
          [7.588692, 50.248936],
          [7.584615, 50.247127],
          [7.580628, 50.245129],
          [7.578774, 50.242932],
          [7.57809, 50.240485],
          [7.578864, 50.238073],
          [7.581438, 50.235876],
          [7.584957, 50.2345],
          [7.59015, 50.234041],
          [7.591896, 50.234067],
          [7.593174, 50.234086],
          [7.598502, 50.233923],
          [7.604334, 50.233033],
          [7.608375, 50.231655],
          [7.610967, 50.229901],
          [7.613775, 50.227444],
          [7.615692, 50.224779],
          [7.617051, 50.221143],
          [7.618554, 50.218092],
          [7.620525, 50.216103],
          [7.623441, 50.214276],
          [7.626879, 50.212765],
          [7.630911, 50.211127],
          [7.634493, 50.209291],
          [7.639218, 50.206024],
          [7.642566, 50.203593],
          [7.644285, 50.20137],
          [7.645356, 50.199012],
          [7.646904, 50.190985],
          [7.646427, 50.18904],
          [7.644717, 50.18599],
          [7.644069, 50.183929],
          [7.644024, 50.18176],
          [7.64523, 50.179446],
          [7.647354, 50.177161],
          [7.652655, 50.175225],
          [7.661709, 50.173372],
          [7.666947, 50.172337],
          [7.672356, 50.171194],
          [7.67916, 50.170618],
          [7.685055, 50.170519],
          [7.690032, 50.16988],
          [7.693812, 50.169034],
          [7.697889, 50.167314],
          [7.701129, 50.16502],
          [7.703613, 50.162023],
          [7.706466, 50.158404],
          [7.708014, 50.15683],
          [7.710732, 50.154957],
          [7.712748, 50.154148],
          [7.714773, 50.153338],
          [7.716987, 50.152366],
          [7.718346, 50.151816],
          [7.720713, 50.150817],
          [7.723287, 50.149666],
          [7.724574, 50.148235],
          [7.724979, 50.146912],
          [7.724448, 50.145139],
          [7.723908, 50.142942],
          [7.724493, 50.140873],
          [7.725996, 50.138884],
          [7.727913, 50.137606],
          [7.731783, 50.136967],
          [7.735392, 50.136148],
          [7.736742, 50.13514],
          [7.738587, 50.132619],
          [7.739253, 50.130495],
          [7.738461, 50.129217],
          [7.733826, 50.125735],
          [7.728219, 50.121027],
          [7.724277, 50.11866],
          [7.722882, 50.116932],
          [7.722495, 50.115303],
          [7.722801, 50.113675],
          [7.724151, 50.112091],
          [7.727202, 50.110291],
          [7.729488, 50.108959],
          [7.732845, 50.10724],
          [7.740117, 50.103082],
          [7.744446, 50.099985],
          [7.745823, 50.098681],
          [7.751187, 50.093973],
          [7.75593, 50.089878],
          [7.761834, 50.084703],
          [7.763274, 50.083462],
          [7.765884, 50.081121],
          [7.770195, 50.077351],
          [7.773435, 50.073679],
          [7.774425, 50.071653],
          [7.775046, 50.06908],
          [7.774767, 50.066641],
          [7.773435, 50.061798],
          [7.7733, 50.059981],
          [7.774164, 50.058063],
          [7.775766, 50.056453],
          [7.784118, 50.049792],
          [7.789122, 50.046741],
          [7.793838, 50.044348],
          [7.799355, 50.041684],
          [7.802964, 50.039659],
          [7.807041, 50.036626],
          [7.809534, 50.034699],
          [7.812036, 50.032765],
          [7.815969, 50.030119],
          [7.823979, 50.0257],
          [7.829685, 50.021992],
          [7.833834, 50.020056],
          [7.837911, 50.018608],
          [7.842762, 50.016997],
          [7.848423, 50.015052],
          [7.852248, 50.013037],
          [7.855659, 50.010391],
          [7.857549, 50.008213],
          [7.858467, 50.006305],
          [7.858638, 50.004694],
          [7.858125, 50.001652],
          [7.858341, 49.99915],
          [7.859907, 49.995909],
          [7.862112, 49.990429],
          [7.86366, 49.985155],
          [7.865145, 49.982653],
          [7.866648, 49.980762],
          [7.869087, 49.978558],
          [7.872435, 49.976614],
          [7.876215, 49.974787],
          [7.879941, 49.973823],
          [7.884666, 49.973229],
          [7.889706, 49.972896],
          [7.89597, 49.9725],
          [7.899579, 49.972159],
          [7.905969, 49.972626],
          [7.91208, 49.974714],
          [7.916958, 49.975939],
          [7.921809, 49.976515],
          [7.926984, 49.97665],
          [7.934274, 49.976119],
          [7.938018, 49.976164],
          [7.941753, 49.976217],
          [7.950672, 49.976713],
          [7.960788, 49.977622],
          [7.968789, 49.978944],
          [7.975269, 49.980709],
          [7.98075, 49.982572],
          [7.985484, 49.984272],
          [7.994412, 49.987359],
          [8.003034, 49.989214],
          [8.00883, 49.990672],
          [8.0172, 49.993705],
          [8.023419, 49.997161],
          [8.03016, 50.001282],
          [8.038952, 50.006178],
          [8.045954, 50.008519],
          [8.053164, 50.010427],
          [8.059941, 50.011002],
          [8.065224, 50.011255],
          [8.070804, 50.012083],
          [8.080974, 50.014557],
          [8.089055, 50.016574],
          [8.103132, 50.018778],
          [8.116353, 50.021271],
          [8.125299, 50.022729],
          [8.135793, 50.024331],
          [8.143776, 50.025907],
          [8.151669, 50.028138],
          [8.163306, 50.031712],
          [8.172684, 50.033835],
          [8.1837, 50.035762],
          [8.187867, 50.035447],
          [8.196561, 50.035284],
          [8.199603, 50.035149],
          [8.205507, 50.034637],
          [8.211825, 50.033502],
          [8.21223, 50.033421],
          [8.21844, 50.032405],
          [8.229276, 50.031613],
          [8.234694, 50.030938],
          [8.239284, 50.029804],
          [8.242884, 50.028265],
          [8.243802, 50.027967],
          [8.24868, 50.025835],
          [8.253873, 50.022721],
          [8.254044, 50.022594],
          [8.260047, 50.018922],
          [8.26326, 50.016979],
          [8.266779, 50.014531],
          [8.271738, 50.01076],
          [8.275446, 50.00617],
          [8.275599, 50.005953],
          [8.280981, 50.001976],
          [8.284014, 49.998951],
          [8.287056, 49.995919],
          [8.289675, 49.994623],
          [8.293752, 49.994254],
          [8.296155, 49.994308],
          [8.298558, 49.994631],
          [8.30133, 49.995649],
          [8.304741, 49.997097],
          [8.309808, 49.999609],
          [8.310213, 49.999798],
          [8.312823, 50.001022],
          [8.315018, 50.001597],
          [8.316819, 50.00167],
          [8.325837, 50.000383],
          [8.332146, 49.999744],
          [8.337825, 49.998979],
          [8.33877, 49.998816],
          [8.338959, 49.998799],
          [8.345321, 49.998447],
          [8.345753, 49.998456],
          [8.34813, 49.99878],
          [8.357688, 50.001175],
          [8.363007, 50.002075],
          [8.368155, 50.002309],
          [8.372168, 50.002056],
          [8.378064, 50.001292],
          [8.38358, 50.000104],
          [8.396712, 49.997007],
          [8.401212, 49.996539],
          [8.403282, 49.996539],
          [8.406684, 49.996801],
          [8.409888, 49.997422],
          [8.412093, 49.998151],
          [8.413209, 49.998726],
          [8.414325, 49.999311],
          [8.421597, 50.004883],
          [8.421966, 50.005198],
          [8.424747, 50.007484],
          [8.428545, 50.009553],
          [8.432334, 50.011165],
          [8.43516, 50.012083],
          [8.437977, 50.012992],
          [8.449956, 50.017537],
          [8.459784, 50.02191],
          [8.463906, 50.023908],
          [8.465004, 50.024772],
          [8.466894, 50.026374],
          [8.468028, 50.028031],
          [8.468397, 50.028687],
          [8.468622, 50.029092],
          [8.471277, 50.033206],
          [8.472591, 50.034924],
          [8.473725, 50.035987],
          [8.475462, 50.037184],
          [8.478954, 50.039829],
          [8.480799, 50.041323],
          [8.483274, 50.042683],
          [8.487342, 50.044914],
          [8.490051, 50.046057],
          [8.493399, 50.04712],
          [8.499357, 50.049298],
          [8.502012, 50.05018],
          [8.503182, 50.050639],
          [8.50482, 50.051269],
          [8.507376, 50.052699],
          [8.509797, 50.054697],
          [8.511471, 50.056363],
          [8.513325, 50.057703],
          [8.517051, 50.059675],
          [8.523837, 50.063005],
          [8.525637, 50.064166],
          [8.526834, 50.065624],
          [8.527311, 50.067235],
          [8.527221, 50.068476],
          [8.523108, 50.075199],
          [8.522901, 50.075686],
          [8.522604, 50.077603],
          [8.522622, 50.079034],
          [8.523117, 50.080357],
          [8.52327, 50.080717],
          [8.524872, 50.083219],
          [8.527833, 50.085559],
          [8.531739, 50.087692],
          [8.534961, 50.08924],
          [8.535402, 50.089447],
          [8.540964, 50.09202],
          [8.543088, 50.093343],
          [8.545032, 50.09482],
          [8.545293, 50.095026],
          [8.548488, 50.097412],
          [8.550045, 50.098203],
          [8.550801, 50.098527],
          [8.552754, 50.099095],
          [8.554455, 50.099275],
          [8.556723, 50.09923],
          [8.560242, 50.098627],
          [8.564148, 50.097294],
          [8.566695, 50.095926],
          [8.573697, 50.092129],
          [8.574192, 50.091894],
          [8.57736, 50.090509],
          [8.58159, 50.089222],
          [8.585811, 50.088466],
          [8.590059, 50.088258],
          [8.592624, 50.088268],
          [8.596395, 50.088412],
          [8.60139, 50.088429],
          [8.605593, 50.088403],
          [8.609157, 50.088573],
          [8.611722, 50.088988],
          [8.614116, 50.089248],
          [8.615655, 50.089321],
          [8.617194, 50.089402],
          [8.617941, 50.089347],
          [8.622306, 50.088763],
          [8.626446, 50.088691],
          [8.629389, 50.088916],
          [8.630802, 50.089123],
          [8.631099, 50.089195],
          [8.631414, 50.089257],
          [8.636175, 50.090644],
          [8.637642, 50.0913],
          [8.639118, 50.091958],
          [8.640504, 50.092534],
          [8.641899, 50.093101],
          [8.644104, 50.093866],
          [8.64648, 50.094496],
          [8.653599, 50.095836],
          [8.653977, 50.095918],
          [8.655894, 50.096359],
          [8.657811, 50.096809],
          [8.659773, 50.097393],
          [8.662311, 50.098501],
          [8.666568, 50.100922],
          [8.666955, 50.101164],
          [8.671941, 50.103846],
          [8.672139, 50.103973],
          [8.67681, 50.106142],
          [8.677179, 50.106303],
          [8.67996, 50.107491],
          [8.682057, 50.108104],
          [8.682183, 50.108131],
          [8.684172, 50.1085],
          [8.68752, 50.10868],
          [8.687916, 50.10868],
          [8.690247, 50.108455],
          [8.692569, 50.108076],
          [8.692947, 50.108014],
          [8.694603, 50.10778],
          [8.695026, 50.107726],
          [8.701038, 50.107032],
          [8.70525, 50.106817],
          [8.705493, 50.106817],
          [8.708157, 50.106673],
          [8.711082, 50.106502],
          [8.711496, 50.106493],
          [8.715798, 50.106277],
          [8.71875, 50.106384],
          [8.721423, 50.106618],
          [8.725734, 50.107681],
          [8.729073, 50.108599],
          [8.732223, 50.109409],
          [8.735508, 50.110713],
          [8.736039, 50.110957],
          [8.738136, 50.112145],
          [8.740179, 50.113305],
          [8.741844, 50.114098],
          [8.743743, 50.114791],
          [8.746056, 50.11543],
          [8.747604, 50.115655],
          [8.750079, 50.115691],
          [8.752734, 50.115331],
          [8.755353, 50.114503],
          [8.757171, 50.113611],
          [8.758521, 50.112676],
          [8.75871, 50.112559],
          [8.762049, 50.110282],
          [8.76411, 50.109354],
          [8.765334, 50.108931],
          [8.766873, 50.108518],
          [8.770203, 50.108014],
          [8.772714, 50.108157],
          [8.775396, 50.108734],
          [8.777367, 50.109579],
          [8.779203, 50.110687],
          [8.780364, 50.111937],
          [8.780895, 50.113],
          [8.780967, 50.114467],
          [8.780481, 50.115852],
          [8.779761, 50.117086],
          [8.778951, 50.118013],
          [8.777349, 50.119344],
          [8.773956, 50.122234],
          [8.770977, 50.125159],
          [8.770887, 50.125249],
          [8.770104, 50.126275],
          [8.769303, 50.127615],
          [8.768943, 50.129262],
          [8.768862, 50.130765],
          [8.76924, 50.132107],
          [8.770059, 50.133303],
          [8.77122, 50.134456],
          [8.773074, 50.135617],
          [8.775261, 50.136489],
          [8.777484, 50.136993],
          [8.780931, 50.137398],
          [8.783739, 50.137435],
          [8.786223, 50.137291],
          [8.788284, 50.137029],
          [8.792694, 50.136013],
          [8.795214, 50.135436],
          [8.797725, 50.13486],
          [8.80128, 50.134347],
          [8.802801, 50.134204],
          [8.808039, 50.134006],
          [8.815014, 50.133852],
          [8.818497, 50.133412],
          [8.825058, 50.132178],
          [8.833383, 50.130388],
          [8.83557, 50.130081],
          [8.836479, 50.129982],
          [8.837163, 50.12992],
          [8.842077, 50.129586],
          [8.845767, 50.129118],
          [8.848746, 50.128479],
          [8.858574, 50.126095],
          [8.86158, 50.125645],
          [8.866125, 50.125276],
          [8.869878, 50.125015],
          [8.874342, 50.124736],
          [8.876898, 50.124573],
          [8.881335, 50.124357],
          [8.885259, 50.124187],
          [8.888094, 50.124268],
          [8.890974, 50.124807],
          [8.89317, 50.125527],
          [8.896365, 50.127049],
          [8.898894, 50.128147],
          [8.90118, 50.128767],
          [8.903214, 50.128831],
          [8.904582, 50.128507],
          [8.905572, 50.128038],
          [8.90622, 50.127517],
          [8.906868, 50.126994],
          [8.908146, 50.125771],
          [8.910594, 50.123431],
          [8.910828, 50.123214],
          [8.911008, 50.123025],
          [8.91261, 50.121846],
          [8.914239, 50.11993],
          [8.915085, 50.118489],
          [8.915976, 50.116581],
          [8.917344, 50.113251],
          [8.917776, 50.112072],
          [8.918757, 50.110867],
          [8.920224, 50.109607],
          [8.921655, 50.108841],
          [8.922744, 50.108356],
          [8.923221, 50.108211],
          [8.924562, 50.107771],
          [8.927478, 50.107491],
          [8.931339, 50.107491],
          [8.934057, 50.107203],
          [8.935731, 50.106564],
          [8.935947, 50.106502],
          [8.937873, 50.105917],
          [8.9397, 50.104701],
          [8.940879, 50.103415],
          [8.941662, 50.102118],
          [8.941977, 50.100787],
          [8.941995, 50.100265],
          [8.942418, 50.097376],
          [8.942778, 50.0941],
          [8.942805, 50.09392],
          [8.943255, 50.089222],
          [8.943345, 50.08752],
          [8.943354, 50.086665],
          [8.943417, 50.084875],
          [8.943849, 50.083605],
          [8.944731, 50.082165],
          [8.94645, 50.080897],
          [8.947944, 50.080177],
          [8.949735, 50.079628],
          [8.951427, 50.079384],
          [8.954262, 50.079466],
          [8.956971, 50.079519],
          [8.960427, 50.079502],
          [8.964909, 50.079618],
          [8.965755, 50.079628],
          [8.97048, 50.079853],
          [8.972442, 50.079781],
          [8.974593, 50.079483],
          [8.976807, 50.079007],
          [8.984898, 50.076451],
          [8.986248, 50.075776],
          [8.987733, 50.074884],
          [8.989191, 50.073634],
          [8.990109, 50.072517],
          [8.990757, 50.071465],
          [8.991189, 50.070204],
          [8.991297, 50.068918],
          [8.990973, 50.067262],
          [8.990586, 50.066542],
          [8.989875, 50.065425],
          [8.989479, 50.064913],
          [8.988804, 50.064031],
          [8.985933, 50.06044],
          [8.983611, 50.057379],
          [8.982432, 50.056264],
          [8.980578, 50.055066],
          [8.978607, 50.053771],
          [8.97723, 50.052754],
          [8.976546, 50.051773],
          [8.976285, 50.050675],
          [8.976222, 50.049505],
          [8.976519, 50.048227],
          [8.977266, 50.046471],
          [8.97804, 50.045527],
          [8.979219, 50.044329],
          [8.979705, 50.044024],
          [8.981658, 50.042916],
          [8.983773, 50.042089],
          [8.98614, 50.041675],
          [8.988678, 50.041567],
          [8.990775, 50.041782],
          [8.994906, 50.042835],
          [8.996328, 50.043231],
          [9.001035, 50.044492],
          [9.00387, 50.045131],
          [9.00576, 50.045446],
          [9.008631, 50.045643],
          [9.01125, 50.045581],
          [9.014679, 50.045275],
          [9.017361, 50.044861],
          [9.021159, 50.044194],
          [9.023598, 50.043547],
          [9.025695, 50.042781],
          [9.027612, 50.041926],
          [9.029376, 50.040847],
          [9.030834, 50.039614],
          [9.03195, 50.038281],
          [9.032139, 50.037282],
          [9.032004, 50.035878],
          [9.031977, 50.035788],
          [9.030906, 50.031748],
          [9.030501, 50.029182],
          [9.029988, 50.026123],
          [9.029952, 50.02453],
          [9.030222, 50.022676],
          [9.030591, 50.021218],
          [9.031545, 50.019067],
          [9.032481, 50.017537],
          [9.033795, 50.015845],
          [9.035514, 50.014072],
          [9.03582, 50.013748],
          [9.039357, 50.010147],
          [9.040788, 50.008852],
          [9.042642, 50.008483],
          [9.044163, 50.007591],
          [9.044811, 50.007025],
          [9.045243, 50.006764],
          [9.045657, 50.006584],
          [9.045936, 50.006467],
          [9.04626, 50.006332],
          [9.048771, 50.005486],
          [9.051741, 50.004307],
          [9.054936, 50.002615],
          [9.056331, 50.001247],
          [9.058392, 49.999717],
          [9.060732, 49.997719],
          [9.061929, 49.996449],
          [9.062622, 49.995495],
          [9.063261, 49.994308],
          [9.06399, 49.992472],
          [9.064359, 49.990969],
          [9.064467, 49.990446],
          [9.064917, 49.989034],
          [9.06498, 49.987621],
          [9.064953, 49.984624],
          [9.065196, 49.983543],
          [9.065853, 49.982364],
          [9.066933, 49.981213],
          [9.06858, 49.97998],
          [9.06876, 49.979853],
          [9.071046, 49.978683],
          [9.074736, 49.977487],
          [9.083961, 49.975182],
          [9.090954, 49.973464],
          [9.09288, 49.973149],
          [9.095139, 49.973104],
          [9.102303, 49.973617],
          [9.104832, 49.973868],
          [9.107559, 49.974489],
          [9.115866, 49.976775],
          [9.117774, 49.97719],
          [9.122013, 49.977703],
          [9.125145, 49.977757],
          [9.127422, 49.977622],
          [9.127917, 49.977594],
          [9.131958, 49.97727],
          [9.134343, 49.976875],
          [9.136584, 49.975956],
          [9.137709, 49.975246],
          [9.13878, 49.974318],
          [9.139437, 49.973113],
          [9.139689, 49.971961],
          [9.139707, 49.971781],
          [9.139482, 49.970628],
          [9.138438, 49.968855],
          [9.137124, 49.967181],
          [9.136917, 49.966911],
          [9.133119, 49.96243],
          [9.132543, 49.961637],
          [9.130986, 49.959469],
          [9.130077, 49.958416],
          [9.128529, 49.957111],
          [9.126243, 49.955797],
          [9.123471, 49.954474],
          [9.11988, 49.953025],
          [9.119781, 49.952971],
          [9.113265, 49.950334],
          [9.11115, 49.9492],
          [9.109638, 49.947922],
          [9.108729, 49.9465],
          [9.108306, 49.945186],
          [9.108495, 49.943664],
          [9.109152, 49.942288],
          [9.11052, 49.940856],
          [9.112059, 49.939822],
          [9.113976, 49.938823],
          [9.116424, 49.937932],
          [9.119934, 49.937185],
          [9.122238, 49.93669],
          [9.124011, 49.936096],
          [9.125415, 49.935466],
          [9.129132, 49.933882],
          [9.132093, 49.932685],
          [9.134244, 49.931506],
          [9.13662, 49.930012],
          [9.138987, 49.928374],
          [9.140706, 49.926843],
          [9.14184, 49.925566],
          [9.142848, 49.924108],
          [9.143613, 49.922641],
          [9.144063, 49.921057],
          [9.144315, 49.91958],
          [9.144261, 49.917618],
          [9.14391, 49.915729],
          [9.142794, 49.910616],
          [9.14238, 49.908294],
          [9.142344, 49.906791],
          [9.142659, 49.904802],
          [9.143487, 49.90258],
          [9.144414, 49.901112],
          [9.145728, 49.899502],
          [9.147465, 49.8978],
          [9.149274, 49.896604],
          [9.1512, 49.895533],
          [9.151425, 49.895388],
          [9.154584, 49.893922],
          [9.157059, 49.892787],
          [9.159012, 49.891743],
          [9.160677, 49.890637],
          [9.162099, 49.889224],
          [9.163224, 49.88773],
          [9.163989, 49.885938],
          [9.164196, 49.884238],
          [9.16407, 49.882303],
          [9.163683, 49.880556],
          [9.162936, 49.878487],
          [9.162216, 49.876965],
          [9.1611, 49.874905],
          [9.160524, 49.873186],
          [9.160092, 49.871638],
          [9.159291, 49.868784],
          [9.158454, 49.866085],
          [9.157572, 49.860963],
          [9.156582, 49.856373],
          [9.155259, 49.850784],
          [9.154305, 49.847167],
          [9.153207, 49.844187],
          [9.151803, 49.841091],
          [9.151758, 49.841001],
          [9.149679, 49.8375],
          [9.149598, 49.837357],
          [9.147951, 49.83499],
          [9.144558, 49.830507],
          [9.141831, 49.827051],
          [9.140157, 49.824963],
          [9.13932, 49.823857],
          [9.138591, 49.822551],
          [9.13779, 49.820743],
          [9.137403, 49.819519],
          [9.137088, 49.818034],
          [9.136746, 49.816675],
          [9.135621, 49.810942],
          [9.135738, 49.809411],
          [9.136431, 49.807908],
          [9.137646, 49.806819],
          [9.138807, 49.806082],
          [9.140292, 49.805533],
          [9.143028, 49.804641],
          [9.147321, 49.803238],
          [9.153297, 49.801275],
          [9.153477, 49.801221],
          [9.155682, 49.800439],
          [9.157383, 49.799611],
          [9.160389, 49.798053],
          [9.162963, 49.796398],
          [9.168273, 49.792897],
          [9.173097, 49.789756],
          [9.174231, 49.788703],
          [9.175221, 49.787416],
          [9.176148, 49.785489],
          [9.17748, 49.783365],
          [9.178335, 49.781692],
          [9.178425, 49.781521],
          [9.179001, 49.780567],
          [9.180441, 49.777947],
          [9.180972, 49.776382],
          [9.181728, 49.774212],
          [9.181809, 49.77307],
          [9.181701, 49.771792],
          [9.180882, 49.769056],
          [9.180729, 49.768615],
          [9.178992, 49.763403],
          [9.17883, 49.762882],
          [9.178182, 49.760946],
          [9.177345, 49.758264],
          [9.176994, 49.756446],
          [9.17685, 49.754188],
          [9.177084, 49.751757],
          [9.177363, 49.750029],
          [9.178074, 49.747915],
          [9.17928, 49.745169],
          [9.180243, 49.743136],
          [9.181062, 49.741777],
          [9.182286, 49.740292],
          [9.183915, 49.73878],
          [9.187236, 49.736314],
          [9.189045, 49.73517],
          [9.19278, 49.733343],
          [9.197181, 49.731804],
          [9.200592, 49.73094],
          [9.205362, 49.729941],
          [9.210294, 49.728888],
          [9.21186, 49.728448],
          [9.213885, 49.727629],
          [9.215406, 49.726783],
          [9.216756, 49.725766],
          [9.219105, 49.723786],
          [9.2196, 49.723273],
          [9.220212, 49.722562],
          [9.221193, 49.721157],
          [9.222282, 49.719232],
          [9.223272, 49.718035],
          [9.224217, 49.717243],
          [9.224424, 49.71709],
          [9.229527, 49.713778],
          [9.232632, 49.711762],
          [9.235656, 49.709664],
          [9.237438, 49.708377],
          [9.239247, 49.706173],
          [9.240075, 49.705048],
          [9.240138, 49.704967],
          [9.240768, 49.704274],
          [9.242361, 49.702842],
          [9.244224, 49.701573],
          [9.245637, 49.700979],
          [9.247689, 49.700494],
          [9.249507, 49.700404],
          [9.251019, 49.700602],
          [9.252297, 49.700979],
          [9.253278, 49.701528],
          [9.254475, 49.702276],
          [9.254583, 49.702375],
          [9.255924, 49.703832],
          [9.256221, 49.704166],
          [9.256635, 49.704598],
          [9.257958, 49.706154],
          [9.258588, 49.707162],
          [9.259623, 49.709529],
          [9.259704, 49.709799],
          [9.26046, 49.712059],
          [9.261072, 49.713516],
          [9.261756, 49.714749],
          [9.262548, 49.715928],
          [9.264159, 49.718602],
          [9.264933, 49.720546],
          [9.265995, 49.724343],
          [9.266625, 49.726684],
          [9.267345, 49.728151],
          [9.268461, 49.729842],
          [9.269712, 49.731624],
          [9.271467, 49.733911],
          [9.272781, 49.735296],
          [9.275166, 49.737745],
          [9.277371, 49.739824],
          [9.27909, 49.740724],
          [9.280593, 49.741299],
          [9.282663, 49.741696],
          [9.285111, 49.741767],
          [9.287343, 49.741498],
          [9.291204, 49.740904],
          [9.293886, 49.740525],
          [9.297216, 49.740598],
          [9.301077, 49.740733],
          [9.308034, 49.740472],
          [9.314748, 49.740238],
          [9.316323, 49.740373],
          [9.31806, 49.740624],
          [9.319482, 49.741156],
          [9.320886, 49.741885],
          [9.322002, 49.742577],
          [9.322164, 49.742731],
          [9.323001, 49.743658],
          [9.323568, 49.744837],
          [9.323811, 49.745934],
          [9.323685, 49.747131],
          [9.323136, 49.748625],
          [9.322515, 49.749534],
          [9.321894, 49.750012],
          [9.320328, 49.751569],
          [9.31788, 49.753944],
          [9.315342, 49.756321],
          [9.31428, 49.757779],
          [9.313569, 49.759237],
          [9.313263, 49.760596],
          [9.313362, 49.764916],
          [9.313677, 49.766023],
          [9.31455, 49.76712],
          [9.31572, 49.767948],
          [9.316971, 49.768552],
          [9.318465, 49.76893],
          [9.320706, 49.769209],
          [9.324, 49.769406],
          [9.329868, 49.76947],
          [9.332532, 49.769352],
          [9.337572, 49.768758],
          [9.340155, 49.768452],
          [9.342783, 49.768372],
          [9.348426, 49.768578],
          [9.350784, 49.768921],
          [9.353259, 49.769524],
          [9.3555, 49.770351],
          [9.358533, 49.771801],
          [9.361089, 49.773052],
          [9.362655, 49.774204],
          [9.364653, 49.775985],
          [9.36684, 49.777767],
          [9.368226, 49.778496],
          [9.369828, 49.778901],
          [9.371817, 49.779036],
          [9.373266, 49.77883],
          [9.375642, 49.778091],
          [9.377343, 49.7773],
          [9.37854, 49.776534],
          [9.380808, 49.774384],
          [9.382482, 49.773042],
          [9.384102, 49.772034],
          [9.386667, 49.770765],
          [9.388134, 49.77037],
          [9.389376, 49.770235],
          [9.391095, 49.770388],
          [9.392643, 49.77091],
          [9.393948, 49.771792],
          [9.395964, 49.773286],
          [9.397575, 49.774086],
          [9.399969, 49.774815],
          [9.402417, 49.775689],
          [9.404028, 49.776472],
          [9.405558, 49.777542],
          [9.40716, 49.778865],
          [9.41184, 49.782736],
          [9.414639, 49.784887],
          [9.415206, 49.785346],
          [9.417231, 49.786911],
          [9.418725, 49.787991],
          [9.420615, 49.7889],
          [9.422667, 49.789396],
          [9.424935, 49.789531],
          [9.428769, 49.789233],
          [9.431586, 49.788667],
          [9.434241, 49.787766],
          [9.435843, 49.786911],
          [9.437166, 49.785912],
          [9.43821, 49.784869],
          [9.439461, 49.783213],
          [9.439911, 49.782511],
          [9.441414, 49.779838],
          [9.442998, 49.777201],
          [9.443511, 49.775959],
          [9.444159, 49.774671],
          [9.445554, 49.773286],
          [9.447615, 49.772133],
          [9.449073, 49.771629],
          [9.451026, 49.77108],
          [9.452934, 49.770334],
          [9.454689, 49.769875],
          [9.456534, 49.769524],
          [9.458316, 49.769551],
          [9.459567, 49.769731],
          [9.461322, 49.770235],
          [9.463338, 49.771035],
          [9.467784, 49.773312],
          [9.468945, 49.774276],
          [9.469737, 49.775113],
          [9.470295, 49.776427],
          [9.471078, 49.779306],
          [9.471429, 49.780125],
          [9.472149, 49.781017],
          [9.472977, 49.781862],
          [9.47664, 49.785084],
          [9.478746, 49.786857],
          [9.480267, 49.787505],
          [9.482067, 49.788],
          [9.484092, 49.78818],
          [9.491148, 49.78846],
          [9.491436, 49.78846],
          [9.498168, 49.788757],
          [9.500544, 49.788765],
          [9.502119, 49.788568],
          [9.503694, 49.788145],
          [9.506133, 49.786902],
          [9.507249, 49.78621],
          [9.508365, 49.785517],
          [9.510354, 49.784284],
          [9.511488, 49.78351],
          [9.512235, 49.782366],
          [9.512658, 49.781377],
          [9.512586, 49.780179],
          [9.512271, 49.779225],
          [9.511299, 49.777156],
          [9.510219, 49.77487],
          [9.509436, 49.772512],
          [9.509229, 49.771324],
          [9.509292, 49.76991],
          [9.509724, 49.768812],
          [9.510363, 49.767787],
          [9.512712, 49.765311],
          [9.515142, 49.763026],
          [9.517104, 49.761955],
          [9.519462, 49.761235],
          [9.52191, 49.760937],
          [9.524268, 49.761199],
          [9.524475, 49.761225],
          [9.526968, 49.76172],
          [9.528165, 49.762414],
          [9.533115, 49.765671],
          [9.535068, 49.766932],
          [9.536661, 49.767508],
          [9.5391, 49.767778],
          [9.541278, 49.767697],
          [9.543429, 49.767346],
          [9.545121, 49.766769],
          [9.546975, 49.765663],
          [9.548523, 49.76452],
          [9.549927, 49.763088],
          [9.550998, 49.761495],
          [9.551322, 49.760299],
          [9.551385, 49.759435],
          [9.551601, 49.758084],
          [9.552042, 49.755241],
          [9.552159, 49.754133],
          [9.552537, 49.75219],
          [9.553005, 49.749759],
          [9.553689, 49.74805],
          [9.55485, 49.746618],
          [9.556398, 49.745493],
          [9.558243, 49.74471],
          [9.560322, 49.744305],
          [9.561996, 49.744125],
          [9.563751, 49.744234],
          [9.565623, 49.744819],
          [9.566973, 49.745521],
          [9.567765, 49.746385],
          [9.568494, 49.747752],
          [9.568971, 49.74921],
          [9.569016, 49.750363],
          [9.568737, 49.751515],
          [9.5679, 49.752739],
          [9.565731, 49.754934],
          [9.561159, 49.759552],
          [9.56007, 49.761036],
          [9.558864, 49.762837],
          [9.558099, 49.76461],
          [9.557433, 49.766662],
          [9.557019, 49.76965],
          [9.556542, 49.774041],
          [9.556551, 49.775535],
          [9.557145, 49.776832],
          [9.558072, 49.778425],
          [9.559575, 49.779568],
          [9.561789, 49.780846],
          [9.564597, 49.781997],
          [9.565704, 49.782366],
          [9.567108, 49.782672],
          [9.569862, 49.783221],
          [9.571482, 49.783393],
          [9.572184, 49.783474],
          [9.577737, 49.78395],
          [9.581085, 49.78404],
          [9.583371, 49.784257],
          [9.587475, 49.784734],
          [9.590427, 49.784949],
          [9.594009, 49.785013],
          [9.596277, 49.785112],
          [9.599355, 49.785481],
          [9.602496, 49.786102],
          [9.60588, 49.786911],
          [9.609075, 49.78791],
          [9.612036, 49.789072],
          [9.614223, 49.790169],
          [9.615726, 49.791096],
          [9.617058, 49.79241],
          [9.619227, 49.795075],
          [9.620217, 49.796631],
          [9.620838, 49.798261],
          [9.620964, 49.800006],
          [9.62064, 49.801545],
          [9.619605, 49.802913],
          [9.618453, 49.803912],
          [9.616815, 49.804714],
          [9.614493, 49.805443],
          [9.61182, 49.805946],
          [9.608742, 49.806397],
          [9.605079, 49.807494],
          [9.604917, 49.807558],
          [9.60201, 49.808755],
          [9.599751, 49.809754],
          [9.596916, 49.810618],
          [9.592983, 49.811823],
          [9.588834, 49.813137],
          [9.586845, 49.813705],
          [9.584703, 49.814578],
          [9.582759, 49.815856],
          [9.58167, 49.816782],
          [9.580581, 49.818006],
          [9.580032, 49.819005],
          [9.57942, 49.820544],
          [9.579105, 49.822272],
          [9.578826, 49.824442],
          [9.578853, 49.826071],
          [9.579222, 49.827825],
          [9.579834, 49.82977],
          [9.580752, 49.832649],
          [9.581418, 49.833802],
          [9.582435, 49.834872],
          [9.583578, 49.835701],
          [9.584973, 49.836366],
          [9.586674, 49.836907],
          [9.588357, 49.837213],
          [9.590607, 49.837599],
          [9.592245, 49.838041],
          [9.593964, 49.838787],
          [9.595143, 49.839535],
          [9.595971, 49.840326],
          [9.596637, 49.841227],
          [9.597204, 49.842541],
          [9.597672, 49.84408],
          [9.597789, 49.84533],
          [9.597537, 49.84741],
          [9.59751, 49.847545],
          [9.597024, 49.849804],
          [9.597231, 49.851631],
          [9.597681, 49.853269],
          [9.598446, 49.855059],
          [9.599481, 49.857229],
          [9.599589, 49.857409],
          [9.600372, 49.858885],
          [9.601452, 49.860369],
          [9.603621, 49.862466],
          [9.60525, 49.864203],
          [9.605736, 49.86568],
          [9.605763, 49.867129],
          [9.60561, 49.868911],
          [9.605583, 49.870999],
          [9.60525, 49.872069],
          [9.604656, 49.873366],
          [9.602811, 49.876273],
          [9.601425, 49.879206],
          [9.600075, 49.881826],
          [9.598419, 49.884769],
          [9.596916, 49.887829],
          [9.596313, 49.888791],
          [9.595062, 49.890646],
          [9.593415, 49.892581],
          [9.591975, 49.894462],
          [9.591165, 49.895965],
          [9.59031, 49.897603],
          [9.58923, 49.900276],
          [9.589005, 49.901247],
          [9.588366, 49.903623],
          [9.588258, 49.904956],
          [9.588474, 49.907773],
          [9.588123, 49.909672],
          [9.587322, 49.91131],
          [9.586188, 49.912561],
          [9.584667, 49.91383],
          [9.582633, 49.915288],
          [9.579024, 49.917843],
          [9.575235, 49.920795],
          [9.573975, 49.922101],
          [9.573255, 49.923162],
          [9.572715, 49.92445],
          [9.572301, 49.925872],
          [9.57222, 49.927482],
          [9.572436, 49.92841],
          [9.572751, 49.930696],
          [9.573147, 49.931974],
          [9.573741, 49.933116],
          [9.574749, 49.934602],
          [9.575955, 49.935862],
          [9.577773, 49.93785],
          [9.579384, 49.939291],
          [9.582759, 49.941829],
          [9.584244, 49.942764],
          [9.58599, 49.943619],
          [9.588249, 49.944511],
          [9.591615, 49.945474],
          [9.593532, 49.946095],
          [9.595458, 49.947022],
          [9.596799, 49.948003],
          [9.597735, 49.948957],
          [9.598599, 49.950154],
          [9.599274, 49.951684],
          [9.599652, 49.952808],
          [9.599958, 49.95442],
          [9.600129, 49.955644],
          [9.600003, 49.956939],
          [9.599634, 49.958101],
          [9.599076, 49.959315],
          [9.598194, 49.960612],
          [9.596853, 49.962672],
          [9.596295, 49.96377],
          [9.595629, 49.965247],
          [9.595215, 49.966615],
          [9.594936, 49.968118],
          [9.594711, 49.971897],
          [9.594486, 49.9734],
          [9.593964, 49.974588],
          [9.593172, 49.975587],
          [9.591921, 49.976659],
          [9.590454, 49.977577],
          [9.588519, 49.978378],
          [9.586269, 49.979386],
          [9.584901, 49.980241],
          [9.584244, 49.980781],
          [9.583155, 49.982004],
          [9.582507, 49.983013],
          [9.582066, 49.984083],
          [9.581553, 49.985631],
          [9.581337, 49.987396],
          [9.58131, 49.989051],
          [9.581805, 49.992768],
          [9.581814, 49.992895],
          [9.581913, 49.994901],
          [9.581958, 49.995118],
          [9.582318, 49.995999],
          [9.582741, 49.996638],
          [9.583353, 49.997358],
          [9.584244, 49.998079],
          [9.585297, 49.99878],
          [9.58707, 49.999869],
          [9.589635, 50.001282],
          [9.594072, 50.003631],
          [9.596223, 50.004883],
          [9.59859, 50.006502],
          [9.601128, 50.008168],
          [9.602514, 50.008888],
          [9.604035, 50.009913],
          [9.607014, 50.01201],
          [9.608292, 50.01291],
          [9.610695, 50.014792],
          [9.611793, 50.015916],
          [9.613791, 50.017365],
          [9.616482, 50.0194],
          [9.619011, 50.020731],
          [9.621396, 50.02182],
          [9.624366, 50.022928],
          [9.627777, 50.024142],
          [9.630567, 50.02488],
          [9.633852, 50.025501],
          [9.637587, 50.025943],
          [9.640701, 50.026294],
          [9.643392, 50.026663],
          [9.645507, 50.027148],
          [9.647532, 50.027851],
          [9.649332, 50.028732],
          [9.650826, 50.029534],
          [9.651951, 50.030452],
          [9.652104, 50.030649],
          [9.652968, 50.031622],
          [9.653661, 50.0329],
          [9.653787, 50.033755],
          [9.653823, 50.034619],
          [9.653508, 50.037409],
          [9.653076, 50.038903],
          [9.652446, 50.040433],
          [9.651807, 50.041594],
          [9.650853, 50.04271],
          [9.649566, 50.044042],
          [9.647685, 50.045607],
          [9.646344, 50.046975],
          [9.645255, 50.048334],
          [9.644481, 50.049622],
          [9.643932, 50.050945],
          [9.643185, 50.053186],
          [9.643104, 50.054508],
          [9.643212, 50.055274],
          [9.643635, 50.05621],
          [9.644598, 50.057263],
          [9.645534, 50.057964],
          [9.646947, 50.058747],
          [9.649548, 50.059756],
          [9.651042, 50.06016],
          [9.652743, 50.060314],
          [9.654669, 50.060286],
          [9.656388, 50.05999],
          [9.658332, 50.059441],
          [9.659385, 50.059126],
          [9.663939, 50.057866],
          [9.666396, 50.057227],
          [9.668673, 50.05683],
          [9.671796, 50.056579],
          [9.673956, 50.056444],
          [9.674244, 50.056425],
          [9.676944, 50.05639],
          [9.679005, 50.056354],
          [9.681048, 50.056174],
          [9.683424, 50.05585],
          [9.68481, 50.055589],
          [9.684981, 50.055552],
          [9.687663, 50.055076],
          [9.689643, 50.054536],
          [9.691929, 50.053644],
          [9.693468, 50.052853],
          [9.695358, 50.051511],
          [9.69939, 50.048002],
          [9.703548, 50.044473],
          [9.70614, 50.042286],
          [9.708201, 50.041017],
          [9.710163, 50.040018],
          [9.712476, 50.039145],
          [9.714942, 50.038416],
          [9.717498, 50.037859],
          [9.720684, 50.037076],
          [9.72315, 50.036266],
          [9.725022, 50.035393],
          [9.726183, 50.03434],
          [9.726606, 50.033412],
          [9.72684, 50.031963],
          [9.726795, 50.030749],
          [9.726498, 50.029525],
          [9.725904, 50.028391],
          [9.72504, 50.027158],
          [9.723852, 50.026087],
          [9.72369, 50.025988],
          [9.722781, 50.025321],
          [9.722178, 50.024953],
          [9.721692, 50.024701],
          [9.720693, 50.024206],
          [9.720288, 50.024026],
          [9.716283, 50.02218],
          [9.714069, 50.020911],
          [9.712944, 50.019931],
          [9.711819, 50.018634],
          [9.710964, 50.017204],
          [9.709182, 50.013144],
          [9.708327, 50.010859],
          [9.70812, 50.009643],
          [9.708147, 50.008402],
          [9.708723, 50.006457],
          [9.709407, 50.005207],
          [9.710847, 50.003623],
          [9.712197, 50.002399],
          [9.713169, 50.001822],
          [9.714951, 50.000968],
          [9.718425, 49.999213],
          [9.720522, 49.998196],
          [9.721728, 49.997656],
          [9.72324, 49.997242],
          [9.725643, 49.996773],
          [9.730188, 49.996314],
          [9.735714, 49.995774],
          [9.742212, 49.995208],
          [9.745569, 49.99492],
          [9.7488, 49.994443],
          [9.752193, 49.993641],
          [9.754416, 49.99285],
          [9.756423, 49.991707],
          [9.757647, 49.99068],
          [9.759042, 49.989267],
          [9.760428, 49.987612],
          [9.761661, 49.985865],
          [9.762453, 49.984317],
          [9.76284, 49.982986],
          [9.763236, 49.981168],
          [9.763506, 49.979071],
          [9.763938, 49.974354],
          [9.763965, 49.973068],
          [9.763965, 49.972905],
          [9.763812, 49.970782],
          [9.763488, 49.968945],
          [9.762543, 49.965292],
          [9.761958, 49.962925],
          [9.761355, 49.959568],
          [9.761337, 49.959424],
          [9.761391, 49.956922],
          [9.761499, 49.955977],
          [9.761796, 49.955166],
          [9.762552, 49.954024],
          [9.763227, 49.953213],
          [9.763767, 49.952727],
          [9.76383, 49.952664],
          [9.765378, 49.951575],
          [9.767808, 49.950064],
          [9.770292, 49.948587],
          [9.771786, 49.947922],
          [9.773487, 49.947264],
          [9.775503, 49.946824],
          [9.777087, 49.946526],
          [9.780255, 49.945969],
          [9.784989, 49.94515],
          [9.788607, 49.94443],
          [9.792369, 49.943583],
          [9.796284, 49.942665],
          [9.798165, 49.942206],
          [9.799605, 49.941585],
          [9.801135, 49.940757],
          [9.802548, 49.939803],
          [9.804078, 49.938508],
          [9.804942, 49.937428],
          [9.805545, 49.936311],
          [9.805851, 49.935213],
          [9.805896, 49.934107],
          [9.805887, 49.932945],
          [9.805608, 49.931802],
          [9.805185, 49.930704],
          [9.804411, 49.92912],
          [9.803727, 49.927546],
          [9.802269, 49.92382],
          [9.802215, 49.923702],
          [9.802152, 49.923567],
          [9.801036, 49.920814],
          [9.80046, 49.919481],
          [9.800154, 49.918492],
          [9.799974, 49.917286],
          [9.800073, 49.915927],
          [9.800397, 49.912138],
          [9.800937, 49.91032],
          [9.801711, 49.909068],
          [9.802683, 49.907925],
          [9.804024, 49.906828],
          [9.806094, 49.905604],
          [9.807858, 49.904703],
          [9.80937, 49.904056],
          [9.810954, 49.903462],
          [9.811161, 49.903399],
          [9.814005, 49.902651],
          [9.820089, 49.901095],
          [9.820188, 49.901067],
          [9.823248, 49.900105],
          [9.82503, 49.899169],
          [9.8271, 49.897945],
          [9.828567, 49.896657],
          [9.829602, 49.895083],
          [9.831078, 49.891257],
          [9.832536, 49.887586],
          [9.835416, 49.880934],
          [9.836712, 49.878532],
          [9.838296, 49.87575],
          [9.840402, 49.87117],
          [9.841158, 49.869208],
          [9.841698, 49.867461],
          [9.842211, 49.865221],
          [9.842571, 49.864267],
          [9.843093, 49.863339],
          [9.843669, 49.862386],
          [9.844758, 49.861396],
          [9.846117, 49.860622],
          [9.849294, 49.859235],
          [9.851472, 49.858309],
          [9.852777, 49.857499],
          [9.856071, 49.855591],
          [9.857484, 49.854754],
          [9.858933, 49.853818],
          [9.859869, 49.853061],
          [9.862146, 49.851091],
          [9.863973, 49.849174],
          [9.8658, 49.847311],
          [9.867042, 49.845456],
          [9.867915, 49.843773],
          [9.868275, 49.842189],
          [9.868293, 49.840795],
          [9.868167, 49.839031],
          [9.867816, 49.837185],
          [9.867762, 49.836349],
          [9.867762, 49.836231],
          [9.867789, 49.834945],
          [9.868104, 49.832857],
          [9.868743, 49.829238],
          [9.868995, 49.82761],
          [9.869346, 49.826665],
          [9.869454, 49.826367],
          [9.869994, 49.825188],
          [9.871362, 49.823524],
          [9.872154, 49.822291],
          [9.872532, 49.821445],
          [9.87282, 49.820373],
          [9.872946, 49.819275],
          [9.872865, 49.81744],
          [9.872919, 49.816261],
          [9.873009, 49.815019],
          [9.873801, 49.812157],
          [9.87498, 49.808637],
          [9.87561, 49.807233],
          [9.876492, 49.805991],
          [9.87813, 49.804291],
          [9.878229, 49.804201],
          [9.879804, 49.802887],
          [9.881703, 49.80052],
          [9.883026, 49.798818],
          [9.883935, 49.797964],
          [9.884781, 49.797433],
          [9.886068, 49.796884],
          [9.887418, 49.79655],
          [9.888921, 49.796415],
          [9.890748, 49.796569],
          [9.892008, 49.796929],
          [9.893907, 49.797622],
          [9.897813, 49.79926],
          [9.900567, 49.800357],
          [9.905382, 49.802338],
          [9.907263, 49.803003],
          [9.908982, 49.803445],
          [9.910647, 49.803822],
          [9.912258, 49.803895],
          [9.914013, 49.803886],
          [9.915786, 49.803588],
          [9.917208, 49.803157],
          [9.918333, 49.802581],
          [9.919566, 49.801753],
          [9.919845, 49.801581],
          [9.921654, 49.80016],
          [9.922887, 49.798891],
          [9.923076, 49.798683],
          [9.924273, 49.797469],
          [9.924903, 49.796146],
          [9.925155, 49.794751],
          [9.92511, 49.793761],
          [9.925218, 49.793004],
          [9.925218, 49.792942],
          [9.925254, 49.792573],
          [9.925623, 49.789863],
          [9.925785, 49.788639],
          [9.92592, 49.787983],
          [9.926253, 49.786417],
          [9.92628, 49.786291],
          [9.927, 49.784724],
          [9.927441, 49.783735],
          [9.928188, 49.782556],
          [9.929835, 49.779757],
          [9.932139, 49.776472],
          [9.932202, 49.776391],
          [9.933138, 49.775194],
          [9.934659, 49.773942],
          [9.936387, 49.772907],
          [9.937935, 49.772169],
          [9.939897, 49.771449],
          [9.940194, 49.771342],
          [9.941094, 49.771035],
          [9.941292, 49.770955],
          [9.943434, 49.770216],
          [9.945036, 49.769622],
          [9.947052, 49.76875],
          [9.950976, 49.766779],
          [9.952578, 49.76614],
          [9.955638, 49.765015],
          [9.958041, 49.764232],
          [9.960642, 49.763628],
          [9.962604, 49.763278],
          [9.964908, 49.763034],
          [9.967077, 49.762863],
          [9.968922, 49.762702],
          [9.970803, 49.762333],
          [9.972261, 49.761865],
          [9.973908, 49.761216],
          [9.975141, 49.760479],
          [9.976212, 49.759533],
          [9.977328, 49.758463],
          [9.980064, 49.755736],
          [9.981108, 49.754503],
          [9.983133, 49.752001],
          [9.983979, 49.750957],
          [9.985041, 49.7493],
          [9.985464, 49.747915],
          [9.985941, 49.746033],
          [9.986346, 49.744764],
          [9.986454, 49.742487],
          [9.986229, 49.740219],
          [9.985896, 49.738924],
          [9.985734, 49.738501],
          [9.985113, 49.736044],
          [9.98496, 49.734631],
          [9.98505, 49.733056],
          [9.985266, 49.732291],
          [9.985482, 49.731868],
          [9.985968, 49.731021],
          [9.987363, 49.729501],
          [9.990621, 49.726153],
          [9.99297, 49.723858],
          [9.994644, 49.722517],
          [9.996597, 49.721194],
          [9.998505, 49.720293],
          [10.00062, 49.719519],
          [10.004121, 49.718394],
          [10.006569, 49.717774],
          [10.008801, 49.717071],
          [10.010808, 49.716198],
          [10.01259, 49.715281],
          [10.01502, 49.713975],
          [10.017243, 49.712581],
          [10.018386, 49.711447],
          [10.01907, 49.710429],
          [10.019466, 49.708918],
          [10.019511, 49.708765],
          [10.0197, 49.707442],
          [10.019997, 49.706398],
          [10.021212, 49.703986],
          [10.021644, 49.702762],
          [10.021815, 49.701538],
          [10.021887, 49.698325],
          [10.021599, 49.69531],
          [10.021464, 49.692663],
          [10.021662, 49.691503],
          [10.022148, 49.690521],
          [10.023057, 49.68937],
          [10.024425, 49.688236],
          [10.02753, 49.685941],
          [10.030806, 49.68378],
          [10.03302, 49.682295],
          [10.035747, 49.680316],
          [10.037574, 49.67883],
          [10.037718, 49.678714],
          [10.037943, 49.678543],
          [10.040922, 49.676455],
          [10.042362, 49.675618],
          [10.044198, 49.67451],
          [10.04733, 49.672585],
          [10.050237, 49.670497],
          [10.051029, 49.670064],
          [10.052838, 49.669408],
          [10.05444, 49.66885],
          [10.056609, 49.668327],
          [10.057878, 49.668066],
          [10.058346, 49.667958],
          [10.059588, 49.667688],
          [10.062324, 49.667248],
          [10.06344, 49.66714],
          [10.063557, 49.667122],
          [10.065096, 49.667023],
          [10.066896, 49.666959],
          [10.067067, 49.666951],
          [10.06911, 49.666968],
          [10.071027, 49.667113],
          [10.073115, 49.667365],
          [10.076184, 49.667887],
          [10.078794, 49.668417],
          [10.081602, 49.669138],
          [10.08333, 49.669498],
          [10.085004, 49.669659],
          [10.086669, 49.66965],
          [10.088235, 49.669525],
          [10.09188, 49.669281],
          [10.093725, 49.669236],
          [10.095786, 49.6693],
          [10.098207, 49.669579],
          [10.100745, 49.669794],
          [10.102878, 49.669813],
          [10.104759, 49.66974],
          [10.108323, 49.669506],
          [10.110897, 49.669273],
          [10.113219, 49.668949],
          [10.11618, 49.66849],
          [10.120041, 49.668066],
          [10.122435, 49.66786],
          [10.124982, 49.667725],
          [10.126845, 49.667626],
          [10.127907, 49.667607],
          [10.128699, 49.667607],
          [10.130715, 49.667643],
          [10.133073, 49.667832],
          [10.136043, 49.66813],
          [10.138446, 49.668805],
          [10.140417, 49.669129],
          [10.142334, 49.6693],
          [10.143909, 49.669525],
          [10.144926, 49.669794],
          [10.146726, 49.67064],
          [10.146888, 49.670749],
          [10.147851, 49.67155],
          [10.148625, 49.672701],
          [10.149021, 49.673854],
          [10.149129, 49.675293],
          [10.149039, 49.676365],
          [10.14867, 49.677372],
          [10.148112, 49.678524],
          [10.147473, 49.679334],
          [10.146654, 49.680288],
          [10.145745, 49.681081],
          [10.144629, 49.681782],
          [10.143468, 49.682413],
          [10.141956, 49.683088],
          [10.137681, 49.684753],
          [10.135062, 49.685787],
          [10.133946, 49.686336],
          [10.13283, 49.687056],
          [10.13202, 49.687777],
          [10.131138, 49.688748],
          [10.130607, 49.689766],
          [10.130193, 49.690765],
          [10.130031, 49.691899],
          [10.12995, 49.693464],
          [10.130013, 49.694742],
          [10.130238, 49.695868],
          [10.131003, 49.699171],
          [10.131498, 49.701294],
          [10.131912, 49.702312],
          [10.132407, 49.703292],
          [10.132938, 49.704211],
          [10.134522, 49.706371],
          [10.13598, 49.70835],
          [10.137537, 49.710204],
          [10.138599, 49.711321],
          [10.13976, 49.71232],
          [10.140867, 49.71312],
          [10.142226, 49.713922],
          [10.143693, 49.714651],
          [10.145763, 49.715622],
          [10.149354, 49.717225],
          [10.151064, 49.718044],
          [10.154682, 49.719708],
          [10.156257, 49.720383],
          [10.157679, 49.721067],
          [10.159443, 49.722021],
          [10.161315, 49.723444],
          [10.162521, 49.724362],
          [10.16397, 49.72537],
          [10.164249, 49.725766],
          [10.16469, 49.727233],
          [10.16496, 49.728114],
          [10.165311, 49.729699],
          [10.165932, 49.732327],
          [10.166265, 49.733793],
          [10.166445, 49.735099],
          [10.166445, 49.735251],
          [10.166391, 49.736359],
          [10.166112, 49.737573],
          [10.165734, 49.738581],
          [10.165293, 49.7394],
          [10.164861, 49.740058],
          [10.164708, 49.740255],
          [10.163772, 49.741344],
          [10.160739, 49.744386],
          [10.160595, 49.74454],
          [10.160082, 49.745179],
          [10.159407, 49.746367],
          [10.158831, 49.747825],
          [10.158363, 49.749255],
          [10.158183, 49.750443],
          [10.158201, 49.751605],
          [10.158462, 49.752928],
          [10.158372, 49.754223],
          [10.157958, 49.75543],
          [10.157508, 49.756195],
          [10.156671, 49.757104],
          [10.15533, 49.758319],
          [10.15398, 49.759578],
          [10.153359, 49.76047],
          [10.15308, 49.761289],
          [10.152945, 49.762108],
          [10.153035, 49.763079],
          [10.153278, 49.763872],
          [10.153872, 49.764852],
          [10.155681, 49.767148],
          [10.156347, 49.768272],
          [10.156635, 49.769191],
          [10.156788, 49.77019],
          [10.156608, 49.771549],
          [10.156266, 49.773402],
          [10.155699, 49.776778],
          [10.15488, 49.78153],
          [10.154331, 49.783077],
          [10.1538, 49.784428],
          [10.153593, 49.785498],
          [10.153557, 49.785877],
          [10.153512, 49.786822],
          [10.153647, 49.787955],
          [10.154304, 49.791916],
          [10.154529, 49.792995],
          [10.154844, 49.793941],
          [10.155438, 49.795002],
          [10.156194, 49.795885],
          [10.157274, 49.796901],
          [10.158624, 49.797819],
          [10.160262, 49.798657],
          [10.162098, 49.799287],
          [10.163997, 49.799737],
          [10.165536, 49.799935],
          [10.167669, 49.800042],
          [10.169361, 49.799926],
          [10.170936, 49.799746],
          [10.172547, 49.799386],
          [10.173843, 49.798981],
          [10.176048, 49.798153],
          [10.177938, 49.797315],
          [10.179612, 49.79656],
          [10.181835, 49.795741],
          [10.183401, 49.795147],
          [10.18503, 49.794633],
          [10.188306, 49.793716],
          [10.193058, 49.792465],
          [10.194993, 49.792015],
          [10.196595, 49.791799],
          [10.198269, 49.791745],
          [10.199754, 49.79179],
          [10.20096, 49.791852],
          [10.202949, 49.792213],
          [10.205037, 49.792725],
          [10.20681, 49.793454],
          [10.208952, 49.794453],
          [10.210626, 49.795516],
          [10.212174, 49.796758],
          [10.215873, 49.799737],
          [10.21599, 49.799845],
          [10.217583, 49.801204],
          [10.218843, 49.802014],
          [10.221408, 49.803625],
          [10.222578, 49.804596],
          [10.223217, 49.805488],
          [10.223631, 49.806369],
          [10.223802, 49.807441],
          [10.223685, 49.808377],
          [10.223208, 49.810537],
          [10.222893, 49.811527],
          [10.222587, 49.812903],
          [10.221858, 49.815298],
          [10.221768, 49.815847],
          [10.221759, 49.815963],
          [10.221741, 49.816044],
          [10.221489, 49.81771],
          [10.221282, 49.81887],
          [10.221147, 49.81986],
          [10.220823, 49.822371],
          [10.220823, 49.824019],
          [10.22094, 49.825441],
          [10.221174, 49.826592],
          [10.22121, 49.826719],
          [10.221624, 49.828059],
          [10.222119, 49.829257],
          [10.223145, 49.83094],
          [10.224522, 49.832929],
          [10.226538, 49.835809],
          [10.22661, 49.835944],
          [10.227663, 49.837618],
          [10.228221, 49.838941],
          [10.228626, 49.840309],
          [10.228923, 49.841533],
          [10.229058, 49.84327],
          [10.228887, 49.84516],
          [10.228509, 49.846653],
          [10.228068, 49.847868],
          [10.227231, 49.849686],
          [10.227168, 49.849813],
          [10.226043, 49.85236],
          [10.225962, 49.85254],
          [10.224126, 49.856536],
          [10.222344, 49.860369],
          [10.221687, 49.861819],
          [10.221444, 49.862286],
          [10.22058, 49.86387],
          [10.219986, 49.864582],
          [10.219824, 49.864734],
          [10.218798, 49.865652],
          [10.21752, 49.866445],
          [10.216098, 49.867093],
          [10.213677, 49.868272],
          [10.212435, 49.869009],
          [10.210896, 49.870036],
          [10.209771, 49.870926],
          [10.208277, 49.872439],
          [10.207197, 49.873123],
          [10.205595, 49.873788],
          [10.203795, 49.874122],
          [10.20195, 49.874275],
          [10.199529, 49.874176],
          [10.197657, 49.873942],
          [10.195281, 49.873419],
          [10.191204, 49.872294],
          [10.186101, 49.870891],
          [10.181403, 49.869558],
          [10.178631, 49.868983],
          [10.17648, 49.868649],
          [10.172664, 49.868379],
          [10.170297, 49.868424],
          [10.168533, 49.868713],
          [10.166769, 49.869307],
          [10.165437, 49.870045],
          [10.164357, 49.870936],
          [10.163745, 49.871674],
          [10.163295, 49.872709],
          [10.162899, 49.873735],
          [10.162827, 49.875147],
          [10.162935, 49.876146],
          [10.163223, 49.877253],
          [10.163718, 49.878297],
          [10.164645, 49.879566],
          [10.166427, 49.881763],
          [10.167201, 49.882572],
          [10.168272, 49.883365],
          [10.169721, 49.884057],
          [10.171215, 49.88458],
          [10.17342, 49.884895],
          [10.175184, 49.885228],
          [10.17684, 49.885794],
          [10.178316, 49.886568],
          [10.179594, 49.887477],
          [10.180665, 49.888648],
          [10.181709, 49.890142],
          [10.182267, 49.890916],
          [10.182996, 49.891833],
          [10.18323, 49.892077],
          [10.184049, 49.89294],
          [10.185732, 49.894489],
          [10.187208, 49.895982],
          [10.188018, 49.896946],
          [10.188639, 49.89789],
          [10.189035, 49.899043],
          [10.189314, 49.900492],
          [10.189332, 49.903192],
          [10.189332, 49.905252],
          [10.188927, 49.906575],
          [10.188387, 49.907638],
          [10.187226, 49.909402],
          [10.185543, 49.911544],
          [10.184706, 49.912543],
          [10.182753, 49.91509],
          [10.182177, 49.915899],
          [10.181133, 49.917582],
          [10.180665, 49.918428],
          [10.180458, 49.91896],
          [10.180089, 49.92022],
          [10.179765, 49.921758],
          [10.179576, 49.92265],
          [10.179207, 49.925233],
          [10.178775, 49.927851],
          [10.178739, 49.928824],
          [10.178892, 49.929913],
          [10.179711, 49.933755],
          [10.180215, 49.935807],
          [10.180737, 49.937355],
          [10.181943, 49.940631],
          [10.183266, 49.943889],
          [10.183653, 49.945096],
          [10.183833, 49.946076],
          [10.183878, 49.947337],
          [10.183734, 49.948488],
          [10.183347, 49.949631],
          [10.182915, 49.950559],
          [10.181214, 49.953016],
          [10.179504, 49.956058],
          [10.176966, 49.960665],
          [10.174761, 49.964482],
          [10.174437, 49.965571],
          [10.174275, 49.966488],
          [10.174284, 49.967371],
          [10.174527, 49.968748],
          [10.175175, 49.970737],
          [10.175436, 49.971906],
          [10.175508, 49.973319],
          [10.175436, 49.975263],
          [10.175076, 49.978108],
          [10.174869, 49.978882],
          [10.173924, 49.98249],
          [10.172952, 49.985856],
          [10.172844, 49.987396],
          [10.172898, 49.988358],
          [10.173141, 49.989267],
          [10.173645, 49.990221],
          [10.175823, 49.99285],
          [10.177938, 49.995369],
          [10.18098, 49.998906],
          [10.184499, 50.003155],
          [10.186119, 50.005044],
          [10.186281, 50.005233],
          [10.187874, 50.007096],
          [10.189071, 50.00824],
          [10.190862, 50.009589],
          [10.194714, 50.012353],
          [10.197306, 50.014053],
          [10.19979, 50.01544],
          [10.204452, 50.017852],
          [10.20798, 50.019715],
          [10.211805, 50.021649],
          [10.212822, 50.022298],
          [10.213587, 50.022909],
          [10.213911, 50.023188],
          [10.214631, 50.023927],
          [10.217106, 50.026843],
          [10.217889, 50.027688],
          [10.218969, 50.029101],
          [10.220175, 50.031091],
          [10.221435, 50.03344],
          [10.221975, 50.034349],
          [10.222371, 50.034808],
          [10.222596, 50.035014],
          [10.22355, 50.035807],
          [10.22481, 50.03659],
          [10.224972, 50.03668],
          [10.226736, 50.037526],
          [10.229643, 50.03865],
          [10.231794, 50.039614],
          [10.233279, 50.040288],
          [10.236564, 50.041971],
          [10.237923, 50.042665],
          [10.238076, 50.042764],
          [10.240542, 50.044258],
          [10.242252, 50.045086],
          [10.244862, 50.046256],
          [10.2474, 50.047354],
          [10.251639, 50.049198],
          [10.256463, 50.051331],
          [10.260387, 50.053068],
          [10.263096, 50.054022],
          [10.265796, 50.054886],
          [10.268613, 50.055625],
          [10.271097, 50.05612],
          [10.273887, 50.056524],
          [10.276299, 50.056633],
          [10.279782, 50.056678],
          [10.281762, 50.056588],
          [10.284552, 50.056191],
          [10.287279, 50.055661],
          [10.290123, 50.054941],
          [10.293192, 50.054013],
          [10.294974, 50.053303],
          [10.296729, 50.052465],
          [10.298403, 50.051331],
          [10.301445, 50.049046],
          [10.304928, 50.0464],
          [10.308096, 50.043943],
          [10.310121, 50.042116],
          [10.311912, 50.040298],
          [10.313694, 50.03847],
          [10.315719, 50.036275],
          [10.317258, 50.0346],
          [10.318203, 50.033269],
          [10.318824, 50.032377],
          [10.319967, 50.031082],
          [10.321245, 50.030055],
          [10.32264, 50.029039],
          [10.323999, 50.027823],
          [10.32516, 50.026509],
          [10.326825, 50.024502],
          [10.327086, 50.024206],
          [10.328013, 50.023152],
          [10.329309, 50.02209],
          [10.330821, 50.021271],
          [10.332576, 50.020714],
          [10.334385, 50.020326],
          [10.337148, 50.019904],
          [10.34073, 50.019355],
          [10.344555, 50.018733],
          [10.347543, 50.018004],
          [10.350207, 50.017329],
          [10.352862, 50.016591],
          [10.354599, 50.016276],
          [10.356606, 50.01615],
          [10.358487, 50.016214],
          [10.358658, 50.01624],
          [10.360467, 50.01652],
          [10.363167, 50.017078],
          [10.366137, 50.017213],
          [10.371249, 50.016997],
          [10.374084, 50.016771],
          [10.377387, 50.016555],
          [10.378647, 50.016673],
          [10.379313, 50.01669],
          [10.379664, 50.016645],
          [10.379925, 50.016628],
          [10.380078, 50.01661],
          [10.38096, 50.016411],
          [10.383129, 50.016034],
          [10.38798, 50.015674],
          [10.395477, 50.015727],
          [10.399158, 50.01571],
          [10.401534, 50.015719],
          [10.403487, 50.015584],
          [10.40607, 50.01517],
          [10.410525, 50.014486],
          [10.412721, 50.014171],
          [10.415169, 50.013963],
          [10.417752, 50.013883],
          [10.420425, 50.013991],
          [10.423566, 50.014198],
          [10.429911, 50.014702],
          [10.433754, 50.014837],
          [10.433988, 50.014846],
          [10.438794, 50.014872],
          [10.441053, 50.014962],
          [10.442934, 50.01517],
          [10.446093, 50.015665],
          [10.449, 50.016285],
          [10.449792, 50.016456],
          [10.451403, 50.01679],
          [10.45314, 50.017078],
          [10.455093, 50.017104],
          [10.457046, 50.016898],
          [10.459053, 50.016628],
          [10.46097, 50.016493],
          [10.462545, 50.016555],
          [10.464273, 50.016816],
          [10.465776, 50.017204],
          [10.467036, 50.017725],
          [10.468359, 50.018428],
          [10.469538, 50.01931],
          [10.47033, 50.020191],
          [10.471869, 50.022289],
          [10.472985, 50.023872],
          [10.473912, 50.02489],
          [10.474974, 50.025853],
          [10.476459, 50.026968],
          [10.478322, 50.028147],
          [10.479582, 50.028921],
          [10.481121, 50.029641],
          [10.483047, 50.030199],
          [10.48518, 50.03055],
          [10.487142, 50.030685],
          [10.489284, 50.030767],
          [10.491498, 50.030614],
          [10.497006, 50.030046],
          [10.498959, 50.029903],
          [10.502775, 50.029885],
          [10.505214, 50.029813],
          [10.506843, 50.029669],
          [10.507032, 50.02965],
          [10.509093, 50.02938],
          [10.510632, 50.028966],
          [10.511775, 50.028526],
          [10.512612, 50.028048],
          [10.513647, 50.027319],
          [10.51488, 50.026384],
          [10.517076, 50.024646],
          [10.517814, 50.023837],
          [10.518282, 50.022901],
          [10.518705, 50.021874],
          [10.519992, 50.018077],
          [10.520496, 50.017042],
          [10.521225, 50.016088],
          [10.52226, 50.015215],
          [10.523358, 50.014495],
          [10.52478, 50.013819],
          [10.527606, 50.012722],
          [10.528983, 50.011993],
          [10.530135, 50.01121],
          [10.531431, 50.010022],
          [10.532619, 50.008393],
          [10.533753, 50.006952],
          [10.534833, 50.00599],
          [10.536048, 50.005216],
          [10.537614, 50.004549],
          [10.539333, 50.004045],
          [10.541295, 50.00373],
          [10.543347, 50.003451],
          [10.546686, 50.002903],
          [10.54917, 50.002516],
          [10.550988, 50.002246],
          [10.553409, 50.001931],
          [10.556442, 50.001498],
          [10.558809, 50.001318],
          [10.561995, 50.001318],
          [10.562265, 50.001346],
          [10.565253, 50.001535],
          [10.568691, 50.001886],
          [10.571517, 50.002236],
          [10.575108, 50.00266],
          [10.577808, 50.002831],
          [10.579446, 50.002894],
          [10.582227, 50.002903],
          [10.584441, 50.002821],
          [10.58553, 50.002786],
          [10.588428, 50.002516],
          [10.589976, 50.002317],
          [10.591749, 50.002039],
          [10.592055, 50.001994],
          [10.594512, 50.001571],
          [10.597329, 50.001031],
          [10.598904, 50.000626],
          [10.603521, 49.999069],
          [10.606464, 49.998051],
          [10.609533, 49.997142],
          [10.612692, 49.996278],
          [10.615896, 49.995622],
          [10.61766, 49.995217],
          [10.619505, 49.99456],
          [10.620747, 49.993939],
          [10.621737, 49.993147],
          [10.622412, 49.992264],
          [10.62342, 49.990564],
          [10.624176, 49.989304],
          [10.625085, 49.988125],
          [10.626147, 49.987063],
          [10.627092, 49.986334],
          [10.627947, 49.985775],
          [10.629747, 49.984831],
          [10.631115, 49.984201],
          [10.633734, 49.983112],
          [10.636551, 49.982014],
          [10.636776, 49.981933],
          [10.636929, 49.98186],
          [10.640637, 49.980321],
          [10.641996, 49.979755],
          [10.646613, 49.977604],
          [10.647342, 49.977325],
          [10.649088, 49.976524],
          [10.652589, 49.975038],
          [10.654443, 49.974373],
          [10.656117, 49.973958],
          [10.657881, 49.973689],
          [10.659546, 49.973625],
          [10.662057, 49.973814],
          [10.663578, 49.973923],
          [10.665315, 49.973887],
          [10.666737, 49.973769],
          [10.668105, 49.973554],
          [10.668303, 49.973509],
          [10.669554, 49.973229],
          [10.67049, 49.973095],
          [10.672137, 49.972933],
          [10.673766, 49.972905],
          [10.675989, 49.973085],
          [10.67913, 49.973724],
          [10.681155, 49.974138],
          [10.683207, 49.974346],
          [10.685421, 49.974363],
          [10.688193, 49.974193],
          [10.689984, 49.974031],
          [10.692072, 49.973634],
          [10.693683, 49.973032],
          [10.6947, 49.9725],
          [10.695708, 49.971771],
          [10.698336, 49.969584],
          [10.699461, 49.968757],
          [10.70046, 49.96809],
          [10.701585, 49.967451],
          [10.703043, 49.966866],
          [10.704555, 49.966435],
          [10.706481, 49.966128],
          [10.708785, 49.965751],
          [10.710432, 49.965346],
          [10.711818, 49.964887],
          [10.713222, 49.964212],
          [10.714815, 49.963086],
          [10.715688, 49.962286],
          [10.716345, 49.961484],
          [10.71693, 49.960468],
          [10.717974, 49.957642],
          [10.718658, 49.956346],
          [10.719288, 49.955473],
          [10.720395, 49.954347],
          [10.721286, 49.953582],
          [10.722483, 49.952782],
          [10.724049, 49.951936],
          [10.72548, 49.951342],
          [10.727073, 49.950801],
          [10.72845, 49.95046],
          [10.730115, 49.950171],
          [10.732077, 49.949956],
          [10.735461, 49.949892],
          [10.736883, 49.949776],
          [10.738602, 49.94947],
          [10.739952, 49.949037],
          [10.7415, 49.948363],
          [10.742526, 49.947697],
          [10.743507, 49.946932],
          [10.744596, 49.94568],
          [10.746801, 49.943116],
          [10.748754, 49.940839],
          [10.750311, 49.939057],
          [10.751076, 49.938148],
          [10.751742, 49.937284],
          [10.752291, 49.936411],
          [10.752741, 49.935447],
          [10.753362, 49.933819],
          [10.753713, 49.932945],
          [10.754235, 49.931641],
          [10.754649, 49.930992],
          [10.755225, 49.930299],
          [10.755927, 49.929679],
          [10.756629, 49.929157],
          [10.757736, 49.92849],
          [10.759068, 49.927977],
          [10.760913, 49.927482],
          [10.762497, 49.927321],
          [10.765863, 49.927248],
          [10.768068, 49.927068],
          [10.770498, 49.92661],
          [10.773234, 49.926052],
          [10.77345, 49.926007],
          [10.776015, 49.925475],
          [10.778823, 49.924828],
          [10.782927, 49.92417],
          [10.786932, 49.923496],
          [10.789362, 49.923117],
          [10.791603, 49.922703],
          [10.793448, 49.92247],
          [10.795986, 49.922281],
          [10.798695, 49.92229],
          [10.800378, 49.92256],
          [10.802583, 49.922982],
          [10.803582, 49.923064],
          [10.805085, 49.922937],
          [10.806687, 49.92256],
          [10.808424, 49.921912],
          [10.80954, 49.921273],
          [10.810566, 49.920589],
          [10.813059, 49.91923],
          [10.81377, 49.91896],
          [10.816677, 49.917835],
          [10.822572, 49.915954],
          [10.82718, 49.914792],
          [10.828764, 49.914351],
          [10.830276, 49.913956],
          [10.832094, 49.913371],
          [10.833228, 49.912957],
          [10.833777, 49.912704],
          [10.837575, 49.910779],
          [10.839528, 49.910014],
          [10.843605, 49.908682],
          [10.843983, 49.908573],
          [10.845207, 49.908151],
          [10.846755, 49.907709],
          [10.849248, 49.907503],
          [10.852533, 49.907484],
          [10.854927, 49.907394],
          [10.859517, 49.90725],
          [10.861209, 49.907115],
          [10.862703, 49.906971],
          [10.863423, 49.906873],
          [10.865439, 49.906521],
          [10.86867, 49.905901],
          [10.871109, 49.905226],
          [10.873593, 49.904461],
          [10.875636, 49.903741],
          [10.878435, 49.90248],
          [10.880712, 49.901292],
          [10.880991, 49.901157],
          [10.882764, 49.90015],
          [10.885419, 49.898728],
          [10.887147, 49.89781],
          [10.887462, 49.897684],
          [10.888965, 49.897009],
          [10.891152, 49.896262],
          [10.891377, 49.896162],
          [10.893627, 49.895227],
          [10.893942, 49.895073],
          [10.895787, 49.894228],
          [10.897425, 49.893274],
          [10.898694, 49.892427],
          [10.898901, 49.89232],
          [10.900026, 49.891518],
          [10.901421, 49.890313],
          [10.902861, 49.88898],
          [10.904085, 49.887541],
          [10.904859, 49.886191],
          [10.906218, 49.883725],
          [10.906353, 49.883455],
          [10.90728, 49.881826],
          [10.907793, 49.88088],
          [10.907883, 49.8807],
          [10.908837, 49.878981],
          [10.910511, 49.876003],
          [10.911402, 49.874626],
          [10.912527, 49.873123],
          [10.914228, 49.871269],
          [10.916154, 49.869595],
          [10.918683, 49.86766],
          [10.922562, 49.864743],
          [10.927521, 49.861008],
          [10.933767, 49.856266],
          [10.940265, 49.851324],
          [10.945377, 49.847247],
          [10.950561, 49.843027],
          [10.950732, 49.842901],
          [10.955817, 49.838761],
          [10.955988, 49.838607],
          [10.956195, 49.838454],
          [10.957806, 49.837159],
          [10.959039, 49.836169],
          [10.964574, 49.831669],
          [10.96758, 49.829193],
          [10.974582, 49.823406],
          [10.98315, 49.816198],
          [10.985319, 49.814434],
          [10.985463, 49.814316],
          [10.986507, 49.813506],
          [10.988055, 49.812535],
          [10.989801, 49.811752],
          [10.997667, 49.808628],
          [10.999746, 49.807693],
          [11.001429, 49.806639],
          [11.002563, 49.805614],
          [11.003571, 49.804407],
          [11.004156, 49.803453],
          [11.004588, 49.802167],
          [11.005569, 49.798278],
          [11.006136, 49.796938],
          [11.006865, 49.795876],
          [11.0079, 49.794742],
          [11.008008, 49.794607],
          [11.010726, 49.791754],
          [11.010798, 49.791691],
          [11.017206, 49.784977],
          [11.021895, 49.780054],
          [11.02284, 49.778974],
          [11.023758, 49.777741],
          [11.024334, 49.776751],
          [11.024433, 49.776562],
          [11.024865, 49.7754],
          [11.025207, 49.773925],
          [11.02581, 49.772304],
          [11.02662, 49.771099],
          [11.027601, 49.769839],
          [11.027709, 49.769766],
          [11.030292, 49.767517],
          [11.033217, 49.76479],
          [11.035863, 49.762387],
          [11.037195, 49.760956],
          [11.038923, 49.759012],
          [11.040498, 49.757095],
          [11.041497, 49.755762],
          [11.041974, 49.755016],
          [11.043945, 49.751686],
          [11.044269, 49.750966],
          [11.044998, 49.749598],
          [11.046492, 49.746573],
          [11.047437, 49.744747],
          [11.047482, 49.744684],
          [11.047545, 49.744575],
          [11.048418, 49.742866],
          [11.049966, 49.73977],
          [11.051226, 49.737322],
          [11.051703, 49.736538],
          [11.052369, 49.735144],
          [11.052963, 49.733677],
          [11.053377, 49.732174],
          [11.053557, 49.730841],
          [11.053593, 49.729446],
          [11.053296, 49.727655],
          [11.05272, 49.725396],
          [11.052648, 49.725244],
          [11.05191, 49.723768],
          [11.051226, 49.722579],
          [11.050974, 49.721869],
          [11.050893, 49.721509],
          [11.050875, 49.721427],
          [11.050596, 49.720212],
          [11.050488, 49.71888],
          [11.050569, 49.71771],
          [11.051037, 49.715928],
          [11.051064, 49.71583],
          [11.051307, 49.715236],
          [11.051622, 49.714335],
          [11.052108, 49.713049],
          [11.052639, 49.711582],
          [11.05308, 49.709934],
          [11.053422, 49.708216],
          [11.053593, 49.706506],
          [11.053692, 49.70484],
          [11.053638, 49.703446],
          [11.053467, 49.701556],
          [11.053449, 49.701466],
          [11.053134, 49.699566],
          [11.053098, 49.699414],
          [11.052738, 49.698406],
          [11.052198, 49.697218],
          [11.051199, 49.695705],
          [11.049948, 49.694292],
          [11.048427, 49.692988],
          [11.046942, 49.691917],
          [11.044026, 49.689766],
          [11.039751, 49.686688],
          [11.038986, 49.686156],
          [11.038779, 49.685995],
          [11.036979, 49.684663],
          [11.03589, 49.683871],
          [11.033748, 49.682278],
          [11.031111, 49.679838],
          [11.029887, 49.678489],
          [11.026962, 49.675023],
          [11.023317, 49.670677],
          [11.019573, 49.666222],
          [11.018502, 49.664728],
          [11.017503, 49.663],
          [11.015766, 49.65976],
          [11.015676, 49.659616],
          [11.013462, 49.655502],
          [11.012211, 49.653468],
          [11.011041, 49.651911],
          [11.009637, 49.650417],
          [11.008278, 49.649139],
          [11.006667, 49.647898],
          [11.005128, 49.64671],
          [11.004993, 49.646611],
          [10.999998, 49.643055],
          [10.995102, 49.639554],
          [10.994922, 49.639393],
          [10.992339, 49.637404],
          [10.990251, 49.635568],
          [10.98864, 49.634001],
          [10.987227, 49.632445],
          [10.986174, 49.631221],
          [10.984527, 49.629169],
          [10.98297, 49.627171],
          [10.981098, 49.624776],
          [10.980423, 49.623858],
          [10.98027, 49.623706],
          [10.979046, 49.622122],
          [10.978155, 49.620934],
          [10.975419, 49.617585],
          [10.974042, 49.615236],
          [10.973772, 49.614697],
          [10.973664, 49.614472],
          [10.973079, 49.61278],
          [10.972665, 49.611034],
          [10.972512, 49.609207],
          [10.97244, 49.608154],
          [10.97244, 49.608064],
          [10.972296, 49.604518],
          [10.97217, 49.601575],
          [10.97217, 49.601341],
          [10.971837, 49.593492],
          [10.971819, 49.593376],
          [10.971648, 49.589766],
          [10.971648, 49.589695],
          [10.971513, 49.58676],
          [10.971495, 49.58658],
          [10.971459, 49.585302],
          [10.971378, 49.583727],
          [10.971252, 49.580875],
          [10.971144, 49.578165],
          [10.971018, 49.574728],
          [10.971, 49.574538],
          [10.97082, 49.570786],
          [10.970811, 49.570462],
          [10.970604, 49.565574],
          [10.970595, 49.565439],
          [10.970595, 49.565341],
          [10.970586, 49.565214],
          [10.970559, 49.56489],
          [10.970541, 49.564099],
          [10.970496, 49.563469],
          [10.970523, 49.562884],
          [10.970379, 49.561344],
          [10.970352, 49.560463],
          [10.970334, 49.560058],
          [10.969929, 49.55734],
          [10.969848, 49.556557],
          [10.969839, 49.556385],
          [10.969848, 49.556287],
          [10.969785, 49.554586],
          [10.969785, 49.553425],
          [10.96983, 49.550923],
          [10.969884, 49.549212],
          [10.969803, 49.547656],
          [10.969677, 49.545126],
          [10.969569, 49.54158],
          [10.969569, 49.541445],
          [10.969497, 49.540428],
          [10.969344, 49.539384],
          [10.969281, 49.539196],
          [10.968894, 49.537765],
          [10.968246, 49.536361],
          [10.96749, 49.535056],
          [10.966176, 49.533373],
          [10.964997, 49.532157],
          [10.963872, 49.531158],
          [10.963683, 49.531006],
          [10.962099, 49.529647],
          [10.96092, 49.528396],
          [10.959849, 49.527001],
          [10.959399, 49.526317],
          [10.958769, 49.525093],
          [10.958265, 49.523706],
          [10.958229, 49.523626],
          [10.957914, 49.522582],
          [10.957761, 49.521249],
          [10.957806, 49.519963],
          [10.957914, 49.518919],
          [10.958472, 49.516282],
          [10.959012, 49.513375],
          [10.959102, 49.512933],
          [10.959264, 49.511224],
          [10.959282, 49.51018],
          [10.959138, 49.508956],
          [10.958904, 49.507813],
          [10.958355, 49.506688],
          [10.957599, 49.505482],
          [10.956906, 49.504735],
          [10.956006, 49.503907],
          [10.953765, 49.502233],
          [10.950021, 49.499244],
          [10.948032, 49.497724],
          [10.947348, 49.497076],
          [10.947186, 49.496959],
          [10.946178, 49.495968],
          [10.945179, 49.494817],
          [10.944324, 49.493674],
          [10.943361, 49.491955],
          [10.943289, 49.491828],
          [10.942884, 49.490874],
          [10.94256, 49.48992],
          [10.942362, 49.488895],
          [10.942128, 49.487598],
          [10.941867, 49.484494],
          [10.941876, 49.484187],
          [10.941876, 49.483405],
          [10.941876, 49.483315],
          [10.94211, 49.481452],
          [10.942191, 49.481173],
          [10.942803, 49.479301],
          [10.942848, 49.479121],
          [10.944261, 49.4766],
          [10.944486, 49.47625],
          [10.945836, 49.474405],
          [10.947735, 49.47246],
          [10.948725, 49.471615],
          [10.948806, 49.471543],
          [10.950669, 49.470112],
          [10.952424, 49.468923],
          [10.954611, 49.467645],
          [10.954953, 49.467411],
          [10.962567, 49.462821],
          [10.96272, 49.46274],
          [10.966779, 49.460257],
          [10.966878, 49.460193],
          [10.969398, 49.458681],
          [10.971225, 49.457665],
          [10.97136, 49.457611],
          [10.97361, 49.456576],
          [10.975626, 49.455766],
          [10.975995, 49.455639],
          [10.978236, 49.454884],
          [10.981305, 49.453939],
          [10.984806, 49.452876],
          [10.991277, 49.450879],
          [10.99359, 49.450059],
          [10.995471, 49.449079],
          [10.996749, 49.448097],
          [10.997667, 49.447153],
          [10.998702, 49.445829],
          [10.999323, 49.444489],
          [11.000268, 49.441717],
          [11.000835, 49.440421],
          [11.001978, 49.438593],
          [11.002221, 49.438323],
          [11.003823, 49.43665],
          [11.005542, 49.435354],
          [11.007711, 49.434102],
          [11.009133, 49.433374],
          [11.010672, 49.432843],
          [11.011212, 49.432654],
          [11.011797, 49.432483],
          [11.013264, 49.432041],
          [11.01366, 49.431915],
          [11.017737, 49.430746],
          [11.018097, 49.430646],
          [11.020365, 49.430026],
          [11.022111, 49.429458],
          [11.024055, 49.428729],
          [11.025621, 49.428019],
          [11.025873, 49.427901],
          [11.02617, 49.427758],
          [11.027106, 49.427325],
          [11.027187, 49.42728],
          [11.031678, 49.425193],
          [11.034657, 49.423834],
          [11.037213, 49.42279],
          [11.03769, 49.42261],
          [11.04039, 49.42171],
          [11.043144, 49.420873],
          [11.043279, 49.420837],
          [11.048535, 49.419235],
          [11.050704, 49.418586],
          [11.051649, 49.418254],
          [11.053386, 49.417435],
          [11.055366, 49.416444],
          [11.056734, 49.415724],
          [11.056959, 49.415599],
          [11.059029, 49.414465],
          [11.061432, 49.41316],
          [11.063457, 49.412107],
          [11.063925, 49.411818],
          [11.064996, 49.411179],
          [11.066121, 49.410334],
          [11.067273, 49.409191],
          [11.06811, 49.408147],
          [11.068812, 49.406904],
          [11.068992, 49.406482],
          [11.069235, 49.405851],
          [11.069406, 49.404205],
          [11.069379, 49.403088],
          [11.069073, 49.401675],
          [11.067291, 49.395672],
          [11.066319, 49.392343],
          [11.065986, 49.391164],
          [11.065761, 49.390291],
          [11.06478, 49.387077],
          [11.064483, 49.385998],
          [11.064366, 49.385602],
          [11.063862, 49.383855],
          [11.062413, 49.378851],
          [11.061315, 49.375161],
          [11.060676, 49.372975],
          [11.060253, 49.371174],
          [11.060073, 49.369707],
          [11.059947, 49.367737],
          [11.060019, 49.365514],
          [11.060037, 49.365334],
          [11.060235, 49.363902],
          [11.060559, 49.362309],
          [11.060703, 49.36176],
          [11.061441, 49.359663],
          [11.062215, 49.3578],
          [11.063223, 49.356109],
          [11.064249, 49.354498],
          [11.064402, 49.354336],
          [11.065617, 49.352968],
          [11.06712, 49.351428],
          [11.069091, 49.34979],
          [11.07495, 49.345273],
          [11.07729, 49.343382],
          [11.078172, 49.342563],
          [11.078514, 49.342212],
          [11.079549, 49.341123],
          [11.080458, 49.339918],
          [11.081502, 49.338297],
          [11.082195, 49.336813],
          [11.082834, 49.334923],
          [11.083077, 49.333627],
          [11.083329, 49.332384],
          [11.083491, 49.331529],
          [11.084481, 49.326291],
          [11.084499, 49.326148],
          [11.085093, 49.322754],
          [11.085741, 49.319596],
          [11.086173, 49.318155],
          [11.08692, 49.316148],
          [11.087739, 49.314457],
          [11.08944, 49.311685],
          [11.090133, 49.310748],
          [11.094471, 49.305592],
          [11.094543, 49.30551],
          [11.095794, 49.304286],
          [11.097396, 49.302882],
          [11.098755, 49.301793],
          [11.098944, 49.301658],
          [11.101023, 49.300146],
          [11.101194, 49.300003],
          [11.107233, 49.295485],
          [11.110212, 49.293234],
          [11.111157, 49.292524],
          [11.111553, 49.292218],
          [11.111715, 49.292074],
          [11.113515, 49.290741],
          [11.11428, 49.290139],
          [11.118816, 49.286773],
          [11.124279, 49.282624],
          [11.128482, 49.2795],
          [11.132811, 49.276216],
          [11.134233, 49.275073],
          [11.13597, 49.273408],
          [11.137095, 49.272192],
          [11.138031, 49.271032],
          [11.139246, 49.269366],
          [11.140128, 49.267863],
          [11.141028, 49.265902],
          [11.14164, 49.264219],
          [11.142306, 49.261564],
          [11.143197, 49.257549],
          [11.144313, 49.252537],
          [11.14596, 49.245121],
          [11.146014, 49.244958],
          [11.146788, 49.241422],
          [11.147607, 49.237677],
          [11.14857, 49.233186],
          [11.149083, 49.231477],
          [11.149848, 49.229595],
          [11.150991, 49.227616],
          [11.151945, 49.226302],
          [11.15307, 49.224951],
          [11.153241, 49.22479],
          [11.154978, 49.223079],
          [11.156688, 49.221694],
          [11.158452, 49.22047],
          [11.160441, 49.219237],
          [11.162484, 49.218193],
          [11.165868, 49.216474],
          [11.171034, 49.213837],
          [11.17458, 49.212036],
          [11.176272, 49.211209],
          [11.176497, 49.211101],
          [11.178621, 49.210012],
          [11.180853, 49.208878],
          [11.186424, 49.206042],
          [11.186613, 49.205962],
          [11.190015, 49.204215],
          [11.195568, 49.201372],
          [11.19888, 49.199985],
          [11.201598, 49.199005],
          [11.204775, 49.198105],
          [11.205126, 49.198006],
          [11.208519, 49.197258],
          [11.211876, 49.196709],
          [11.222145, 49.194973],
          [11.222631, 49.1949],
          [11.224629, 49.194559],
          [11.224908, 49.194514],
          [11.227554, 49.194081],
          [11.229084, 49.193821],
          [11.230794, 49.193532],
          [11.232513, 49.193245],
          [11.23524, 49.192822],
          [11.238462, 49.192507],
          [11.241855, 49.192353],
          [11.246481, 49.192201],
          [11.246778, 49.192192],
          [11.247012, 49.192183],
          [11.247669, 49.192111],
          [11.24973, 49.191886],
          [11.252223, 49.191435],
          [11.254365, 49.190904],
          [11.254572, 49.19086],
          [11.258856, 49.189726],
          [11.262213, 49.188961],
          [11.265282, 49.188331],
          [11.267955, 49.187898],
          [11.272122, 49.187386],
          [11.27727, 49.186783],
          [11.282148, 49.186224],
          [11.286684, 49.185666],
          [11.293245, 49.184929],
          [11.296071, 49.184515],
          [11.298897, 49.184073],
          [11.300598, 49.183723],
          [11.300805, 49.183678],
          [11.302821, 49.183263],
          [11.30607, 49.182444],
          [11.309958, 49.181329],
          [11.313279, 49.180195],
          [11.317815, 49.178385],
          [11.321118, 49.176855],
          [11.325942, 49.174299],
          [11.331261, 49.171555],
          [11.334636, 49.169764],
          [11.334789, 49.169683],
          [11.336922, 49.168764],
          [11.33946, 49.167811],
          [11.342178, 49.166991],
          [11.345184, 49.166245],
          [11.34756, 49.165794],
          [11.350161, 49.165434],
          [11.352456, 49.165237],
          [11.352708, 49.165209],
          [11.355408, 49.165074],
          [11.357667, 49.16511],
          [11.361969, 49.165164],
          [11.365317, 49.165173],
          [11.368278, 49.165074],
          [11.371806, 49.164823],
          [11.375379, 49.164373],
          [11.378124, 49.163968],
          [11.380968, 49.16341],
          [11.386782, 49.16215],
          [11.389014, 49.161744],
          [11.391255, 49.161474],
          [11.393595, 49.161268],
          [11.397123, 49.161178],
          [11.397501, 49.161178],
          [11.399544, 49.161249],
          [11.404188, 49.161672],
          [11.408724, 49.16215],
          [11.411145, 49.162276],
          [11.413152, 49.162267],
          [11.414547, 49.162195],
          [11.414727, 49.162186],
          [11.416545, 49.162006],
          [11.418768, 49.161691],
          [11.421351, 49.161142],
          [11.423457, 49.160565],
          [11.42631, 49.159513],
          [11.428281, 49.158531],
          [11.428497, 49.158415],
          [11.430405, 49.157298],
          [11.432286, 49.15593],
          [11.433744, 49.154616],
          [11.435004, 49.153383],
          [11.436552, 49.151917],
          [11.436714, 49.151764],
          [11.437767, 49.150746],
          [11.439027, 49.149522],
          [11.439945, 49.148433],
          [11.440764, 49.147228],
          [11.441763, 49.145472],
          [11.442393, 49.143789],
          [11.442762, 49.142269],
          [11.442879, 49.141566],
          [11.442942, 49.140378],
          [11.442924, 49.140181],
          [11.442843, 49.138732],
          [11.442609, 49.137409],
          [11.442222, 49.136176],
          [11.441034, 49.133584],
          [11.439477, 49.130083],
          [11.439009, 49.12867],
          [11.438487, 49.126887],
          [11.438181, 49.125403],
          [11.437587, 49.121883],
          [11.43729, 49.118976],
          [11.437263, 49.116249],
          [11.4372, 49.113226],
          [11.4372, 49.112883],
          [11.437209, 49.110462],
          [11.4372, 49.110282],
          [11.437272, 49.105692],
          [11.437272, 49.105611],
          [11.437407, 49.101634],
          [11.43747, 49.100437],
          [11.437497, 49.10032],
          [11.437686, 49.099069],
          [11.438118, 49.097899],
          [11.438847, 49.096477],
          [11.439684, 49.095198],
          [11.440629, 49.094145],
          [11.441736, 49.09311],
          [11.442393, 49.092445],
          [11.44395, 49.090978],
          [11.444112, 49.090833],
          [11.447613, 49.087576],
          [11.448657, 49.086433],
          [11.449602, 49.085173],
          [11.450277, 49.08385],
          [11.450763, 49.082544],
          [11.451465, 49.079916],
          [11.451753, 49.079088],
          [11.451807, 49.078945],
          [11.452464, 49.077586],
          [11.45349, 49.075911],
          [11.454327, 49.074957],
          [11.455758, 49.073536],
          [11.457135, 49.072005],
          [11.45808, 49.070701],
          [11.458152, 49.070575],
          [11.458818, 49.069134],
          [11.459358, 49.06746],
          [11.45952, 49.066281],
          [11.459817, 49.062853],
          [11.460078, 49.061502],
          [11.460618, 49.059495],
          [11.461086, 49.058083],
          [11.461995, 49.056084],
          [11.463633, 49.05271],
          [11.464299, 49.051108],
          [11.464344, 49.050945],
          [11.465082, 49.048075],
          [11.465415, 49.047273],
          [11.465928, 49.04622],
          [11.466558, 49.045375],
          [11.467521, 49.044376],
          [11.468556, 49.043521],
          [11.469195, 49.043088],
          [11.469645, 49.042783],
          [11.470194, 49.042477],
          [11.470752, 49.042162],
          [11.472057, 49.04163],
          [11.472336, 49.041523],
          [11.474082, 49.040983],
          [11.476332, 49.040515],
          [11.478735, 49.040281],
          [11.480517, 49.040299],
          [11.483208, 49.040533],
          [11.483442, 49.040569],
          [11.487645, 49.041055],
          [11.492127, 49.041216],
          [11.49516, 49.041244],
          [11.498445, 49.0411],
          [11.501271, 49.040875],
          [11.504754, 49.040451],
          [11.507535, 49.039966],
          [11.510721, 49.039308],
          [11.513088, 49.038732],
          [11.516481, 49.037698],
          [11.519829, 49.036519],
          [11.522709, 49.035511],
          [11.524509, 49.035051],
          [11.527218, 49.034557],
          [11.529639, 49.034304],
          [11.532276, 49.034242],
          [11.534895, 49.034368],
          [11.539863, 49.03462],
          [11.540142, 49.034637],
          [11.545794, 49.034943],
          [11.54853, 49.035007],
          [11.551203, 49.034845],
          [11.553768, 49.034493],
          [11.556243, 49.034017],
          [11.557656, 49.033621],
          [11.55888, 49.033288],
          [11.561427, 49.032613],
          [11.561679, 49.03254],
          [11.564334, 49.031839],
          [11.568492, 49.030713],
          [11.568726, 49.030651],
          [11.574774, 49.029067],
          [11.577501, 49.028284],
          [11.579265, 49.027699],
          [11.58066, 49.027141],
          [11.580876, 49.027069],
          [11.582505, 49.026286],
          [11.583153, 49.025943],
          [11.584188, 49.025349],
          [11.586348, 49.023937],
          [11.589741, 49.021741],
          [11.594655, 49.018321],
          [11.596851, 49.016997],
          [11.599173, 49.015845],
          [11.601612, 49.014846],
          [11.603835, 49.014145],
          [11.607732, 49.0132],
          [11.610189, 49.012651],
          [11.612826, 49.011832],
          [11.615355, 49.010842],
          [11.617686, 49.009735],
          [11.620026, 49.008466],
          [11.622006, 49.007169],
          [11.623644, 49.005919],
          [11.625174, 49.004577],
          [11.627217, 49.002219],
          [11.628702, 49.000221],
          [11.629314, 48.998998],
          [11.630133, 48.997125],
          [11.630637, 48.995335],
          [11.630934, 48.993733],
          [11.630952, 48.993615],
          [11.631429, 48.990258],
          [11.631771, 48.988206],
          [11.632095, 48.986947],
          [11.632581, 48.985741],
          [11.63349, 48.984274],
          [11.634444, 48.982986],
          [11.635929, 48.981502],
          [11.637477, 48.980224],
          [11.639097, 48.979125],
          [11.640969, 48.978145],
          [11.643696, 48.977011],
          [11.646171, 48.976273],
          [11.648241, 48.975849],
          [11.650059, 48.975489],
          [11.651292, 48.975354],
          [11.653218, 48.975246],
          [11.654451, 48.975229],
          [11.654703, 48.975229],
          [11.6568, 48.975399],
          [11.659509, 48.97575],
          [11.662128, 48.976471],
          [11.66391, 48.977236],
          [11.66553, 48.978127],
          [11.667186, 48.979395],
          [11.66823, 48.980718],
          [11.668923, 48.981916],
          [11.669913, 48.984517],
          [11.670534, 48.9862],
          [11.671308, 48.987424],
          [11.672424, 48.988558],
          [11.673657, 48.989413],
          [11.674962, 48.990159],
          [11.67516, 48.990277],
          [11.676699, 48.990888],
          [11.678634, 48.991384],
          [11.680461, 48.991662],
          [11.682639, 48.991752],
          [11.684943, 48.991564],
          [11.687247, 48.991042],
          [11.689074, 48.990358],
          [11.690514, 48.989593],
          [11.691711, 48.988764],
          [11.692917, 48.987657],
          [11.693439, 48.986884],
          [11.693718, 48.986461],
          [11.694267, 48.985453],
          [11.694546, 48.984328],
          [11.694582, 48.982761],
          [11.694087, 48.980971],
          [11.693331, 48.979648],
          [11.692935, 48.97819],
          [11.691441, 48.976929],
          [11.689596, 48.975589],
          [11.68875, 48.975319],
          [11.688498, 48.975175],
          [11.688102, 48.97486],
          [11.687868, 48.97468],
          [11.687814, 48.974635],
          [11.687742, 48.974581],
          [11.687292, 48.974275],
          [11.686977, 48.973716],
          [11.685276, 48.972322],
          [11.683926, 48.971359],
          [11.682252, 48.969936],
          [11.681955, 48.968973],
          [11.681775, 48.967732],
          [11.681982, 48.966643],
          [11.682531, 48.965464],
          [11.68317, 48.964726],
          [11.684502, 48.963519],
          [11.684664, 48.963358],
          [11.686275, 48.961935],
          [11.687355, 48.961225],
          [11.68938, 48.960091],
          [11.689641, 48.959982],
          [11.691828, 48.959109],
          [11.694879, 48.958138],
          [11.6982, 48.95721],
          [11.702205, 48.956365],
          [11.705994, 48.955752],
          [11.709954, 48.955293],
          [11.714751, 48.954663],
          [11.719665, 48.953889],
          [11.725722, 48.952819],
          [11.726073, 48.952746],
          [11.729871, 48.95191],
          [11.732724, 48.951207],
          [11.735577, 48.950218],
          [11.737953, 48.949011],
          [11.739933, 48.947779],
          [11.740923, 48.947013],
          [11.742021, 48.946014],
          [11.742174, 48.945879],
          [11.744181, 48.94407],
          [11.745576, 48.943054],
          [11.747376, 48.942082],
          [11.749455, 48.941227],
          [11.751948, 48.940534],
          [11.754549, 48.940093],
          [11.757915, 48.939904],
          [11.764413, 48.93966],
          [11.770911, 48.939409],
          [11.774322, 48.939237],
          [11.777238, 48.938905],
          [11.779605, 48.938554],
          [11.780703, 48.938257],
          [11.780829, 48.938221],
          [11.78217, 48.937788],
          [11.783862, 48.937033],
          [11.785248, 48.936214],
          [11.786742, 48.935053],
          [11.787939, 48.933648],
          [11.789802, 48.931326],
          [11.79099, 48.930004],
          [11.79243, 48.928798],
          [11.793816, 48.927772],
          [11.794725, 48.927195],
          [11.794896, 48.927079],
          [11.79648, 48.92625],
          [11.798514, 48.925414],
          [11.80107, 48.924792],
          [11.80287, 48.924532],
          [11.804994, 48.924442],
          [11.807253, 48.924595],
          [11.813814, 48.925512],
          [11.817603, 48.926016],
          [11.81934, 48.926151],
          [11.821527, 48.926215],
          [11.824038, 48.92607],
          [11.826099, 48.925819],
          [11.82924, 48.925351],
          [11.832759, 48.925045],
          [11.841642, 48.924802],
          [11.846772, 48.924496],
          [11.848626, 48.924369],
          [11.851398, 48.924189],
          [11.851587, 48.924172],
          [11.851758, 48.924163],
          [11.855232, 48.923947],
          [11.86074, 48.923533],
          [11.864448, 48.92301],
          [11.866806, 48.922569],
          [11.868471, 48.922219],
          [11.86875, 48.922165],
          [11.870451, 48.921696],
          [11.872584, 48.920995],
          [11.872692, 48.92095],
          [11.874168, 48.920455],
          [11.87577, 48.919698],
          [11.877336, 48.918789],
          [11.878299, 48.917953],
          [11.878452, 48.917781],
          [11.880351, 48.915549],
          [11.881638, 48.914235],
          [11.882907, 48.913245],
          [11.885256, 48.911851],
          [11.887947, 48.910807],
          [11.889936, 48.910275],
          [11.891772, 48.909997],
          [11.892105, 48.909943],
          [11.894733, 48.909799],
          [11.897001, 48.909844],
          [11.898783, 48.909943],
          [11.899557, 48.910005],
          [11.902122, 48.910168],
          [11.904948, 48.910312],
          [11.90637, 48.910383],
          [11.909529, 48.910537],
          [11.911185, 48.910653],
          [11.912832, 48.910762],
          [11.914443, 48.910833],
          [11.923722, 48.911229],
          [11.924955, 48.911293],
          [11.92644, 48.911437],
          [11.926728, 48.911463],
          [11.928726, 48.911562],
          [11.930841, 48.911463],
          [11.93292, 48.911193],
          [11.935278, 48.910447],
          [11.938329, 48.909178],
          [11.94534, 48.906406],
          [11.947797, 48.905505],
          [11.95029, 48.904903],
          [11.953071, 48.904461],
          [11.956032, 48.904219],
          [11.95821, 48.904281],
          [11.960064, 48.904605],
          [11.961864, 48.905325],
          [11.965563, 48.906972],
          [11.967354, 48.908071],
          [11.96883, 48.909231],
          [11.96955, 48.910204],
          [11.969973, 48.911148],
          [11.970207, 48.912417],
          [11.970153, 48.913678],
          [11.969874, 48.915046],
          [11.969964, 48.916413],
          [11.970468, 48.918015],
          [11.971107, 48.919312],
          [11.972016, 48.920598],
          [11.973123, 48.921724],
          [11.974644, 48.922822],
          [11.977452, 48.92482],
          [11.979855, 48.926241],
          [11.982204, 48.92733],
          [11.985075, 48.928456],
          [11.990574, 48.930507],
          [11.991726, 48.93085],
          [11.992887, 48.931183],
          [11.995182, 48.931534],
          [11.997117, 48.931524],
          [11.999394, 48.931228],
          [12.001473, 48.930651],
          [12.002265, 48.930301],
          [12.003507, 48.929787],
          [12.005415, 48.929193],
          [12.011094, 48.928123],
          [12.011274, 48.928087],
          [12.012354, 48.927907],
          [12.013497, 48.927907],
          [12.013704, 48.927907],
          [12.01518, 48.927988],
          [12.01761, 48.928419],
          [12.01932, 48.928906],
          [12.020985, 48.929598],
          [12.022218, 48.930327],
          [12.023352, 48.931219],
          [12.024468, 48.932344],
          [12.02517, 48.93337],
          [12.025773, 48.934611],
          [12.026016, 48.935601],
          [12.026061, 48.936249],
          [12.026043, 48.93642],
          [12.025935, 48.937563],
          [12.02553, 48.938832],
          [12.024837, 48.940155],
          [12.023658, 48.941478],
          [12.022281, 48.942513],
          [12.020175, 48.943729],
          [12.018258, 48.944503],
          [12.013731, 48.946248],
          [12.011499, 48.947122],
          [12.010401, 48.947545],
          [12.006792, 48.94893],
          [12.004866, 48.949354],
          [12.003084, 48.949605],
          [12.001842, 48.94974],
          [11.998611, 48.949768],
          [11.994309, 48.949948],
          [11.991942, 48.950469],
          [11.989953, 48.951235],
          [11.988531, 48.952135],
          [11.987694, 48.953179],
          [11.987379, 48.954384],
          [11.987865, 48.95614],
          [11.988909, 48.958687],
          [11.99043, 48.960207],
          [11.992581, 48.96171],
          [11.995254, 48.963133],
          [11.998881, 48.964473],
          [12.002697, 48.965644],
          [12.004164, 48.966139],
          [12.007962, 48.967129],
          [12.01221, 48.967858],
          [12.017295, 48.968551],
          [12.02121, 48.969217],
          [12.024369, 48.970125],
          [12.028419, 48.971808],
          [12.032109, 48.973591],
          [12.033702, 48.97468],
          [12.034233, 48.975075],
          [12.036474, 48.976966],
          [12.038238, 48.978792],
          [12.040587, 48.981294],
          [12.042882, 48.98395],
          [12.044898, 48.986442],
          [12.045582, 48.987621],
          [12.045969, 48.989422],
          [12.045906, 48.990646],
          [12.045348, 48.99223],
          [12.044133, 48.994372],
          [12.042972, 48.996162],
          [12.040488, 48.998872],
          [12.040191, 48.999168],
          [12.038211, 49.000896],
          [12.035367, 49.002903],
          [12.032577, 49.004821],
          [12.032343, 49.005019],
          [12.030732, 49.006423],
          [12.02958, 49.007619],
          [12.028635, 49.009077],
          [12.028266, 49.01031],
          [12.028545, 49.011688],
          [12.029067, 49.012623],
          [12.029643, 49.013326],
          [12.029751, 49.013461],
          [12.031128, 49.014955],
          [12.032586, 49.016296],
          [12.034737, 49.018122],
          [12.038463, 49.020778],
          [12.041883, 49.023306],
          [12.045834, 49.025665],
          [12.04965, 49.027626],
          [12.053862, 49.029382],
          [12.057669, 49.030651],
          [12.060972, 49.031271],
          [12.063528, 49.031496],
          [12.06621, 49.031416],
          [12.068613, 49.031065],
          [12.072357, 49.03003],
        ],
      },
    },
  ],
};
