import moment from 'moment';

type Props = {
  className?: string;
  date?: Date;
  fromNow?: 'bottom' | 'right';
};

export const DateLabel = ({
  date,
  className,
  fromNow: fromNowPosition,
}: Props) => {
  const dateMoment = moment(date || new Date()).local();
  const dateResult = dateMoment.format('LLLL');
  const fromNow = fromNowPosition && dateMoment.fromNow();

  return (
    <p className={`text-md ${className}`}>
      {dateResult} {fromNowPosition === 'right' && ` (${fromNow})`}
      {fromNowPosition === 'bottom' && (
        <>
          <br />({fromNow})
        </>
      )}
    </p>
  );
};
