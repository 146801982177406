export const mmsiToColor = (mmsi: number) => {
  const hash = (str: string): number => {
    let h = 0;
    for (let i = 0; i < str.length; i++) {
      h = (Math.imul(31, h) + str.charCodeAt(i)) | 0;
    }

    return h;
  };

  const intToRGB = (i: number): string => {
    const c = (i & 0x00ffffff).toString(16).toUpperCase();

    return '00000'.substring(0, 6 - c.length) + c;
  };

  const mmsiString = mmsi.toString();
  const hashed = hash(mmsiString);

  return `#${intToRGB(hashed)}`;
};
