import 'react-datepicker/dist/react-datepicker.css';

import { Container } from '@/components';
import { DateTimePicker } from '@/components/date-time-picker';
import { useAppDispatch } from '@/hooks';

type Props = {
  startTime: Date | null | undefined;
  endTime: Date | null | undefined;
};
export const MapDateTimeControl = ({ startTime, endTime }: Props) => {
  const dispatch = useAppDispatch();

  // The custom style is a hack to get the datepicker to display correctly with timeSelect enabled
  // See https://github.com/Hacker0x01/react-datepicker/issues/1116
  return (
    <Container
      className={'absolute bottom-8 left-4 rounded-lg p-2 select-none'}
    >
      <div className={'grid grid-cols-2 grid-rows-2 grid-flow-col gap-4'}>
        <div>
          <label className={'text-sm font-bold text-gray-500'}>
            Start time
          </label>
        </div>
        <div>
          <DateTimePicker
            onChange={(date: Date) => {
              dispatch({
                type: 'timestamps/setStartTime',
                payload: date.toISOString(),
              });
            }}
            timestamp={startTime}
          />
        </div>
        <div>
          <label className={'text-sm font-bold text-gray-500'}>End time</label>
        </div>
        <div>
          <DateTimePicker
            onChange={(date: Date) => {
              dispatch({
                type: 'timestamps/setEndTime',
                payload: date.toISOString(),
              });
            }}
            timestamp={endTime}
          />
        </div>
      </div>
    </Container>
  );
};
