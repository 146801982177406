import { parseISO } from 'date-fns';

import { Container, DateLabel, Paragraph } from '@/components';
import { RefreshButton } from '@/components/button-refresh';
import { useAppDispatch } from '@/hooks';
import { setInitialTime } from '@/store/timestamps';
import {
  fetchVessels,
  LoadingData,
  setHistoricMode,
  setSelectedVessel,
} from '@/store/vessels';

type Props = {
  loadingData?: LoadingData;
  historicalMode?: boolean;
  startTime: Date | null | undefined;
  endTime: Date | null | undefined;
};
export const MapLoadingControl = ({ loadingData, historicalMode }: Props) => {
  const dispatch = useAppDispatch();

  return (
    <Container
      className={'absolute bottom-8 right-4 rounded-lg p-2 select-none'}
    >
      <div className={'flex flex-col w-full justify-center'}>
        <div className="btn-group m-auto">
          <input
            className="btn btn-xs btn-outline"
            data-title="Historical"
            defaultChecked={historicalMode}
            name="options"
            onClick={() => {
              dispatch({
                type: 'vessels/setHistoricMode',
                payload: true,
              });
              dispatch({
                type: 'vessels/setSelectedVessel',
                payload: null,
              });
            }}
            type="radio"
          />
          <input
            className="btn btn-xs btn-outline"
            data-title="Current"
            defaultChecked={!historicalMode}
            name="options"
            onClick={() => {
              dispatch(setInitialTime);
              dispatch(setSelectedVessel(null));
              dispatch(setHistoricMode(false));
            }}
            type="radio"
          />
        </div>
        <div className="divider"></div>
        <div className={'flex'}>
          {loadingData?.updatedAt && (
            <div className={'mr-2'}>
              <Paragraph className={'inline'}>
                <span>Last updated: </span>
              </Paragraph>
              <DateLabel
                className={'inline text-gray-500 font-bold'}
                date={parseISO(loadingData.updatedAt)}
              />
            </div>
          )}
          <RefreshButton
            className={`${loadingData?.isLoading && 'animate-spin'}`}
            onClick={() => dispatch(fetchVessels())}
          />
        </div>
      </div>
    </Container>
  );
};
