import { ViewState } from 'react-map-gl/src/types/external';
import { getJSONVar } from '@/constants/env';

export const INITIAL_MAP_CONFIG: ViewState = (() => {
  const env = getJSONVar('REACT_APP_INITIAL_MAP_CONFIG_JSON');
  const defaultMapConfig = {
    longitude: 4.4188927,
    latitude: 50.7166855,
    zoom: 7,
  };

  return {
    ...defaultMapConfig,
    ...env,
  };
})();
