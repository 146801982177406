import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { AuthData, AuthState } from './types';
import { AuthenticatedState, UnauthenticatedState } from './types';

const LOCAL_STORAGE_AUTH_DATA_KEY = 'user';

function persistAuthData(authData: AuthenticatedState): void {
  localStorage.setItem(LOCAL_STORAGE_AUTH_DATA_KEY, JSON.stringify(authData));
}

function clearPersistedAuthState(): void {
  localStorage.removeItem(LOCAL_STORAGE_AUTH_DATA_KEY);
}

function initAuthState(): AuthState {
  const storedData = localStorage.getItem(LOCAL_STORAGE_AUTH_DATA_KEY);

  try {
    const authData = JSON.parse(storedData as string) as AuthData | null;
    if (authData) {
      return AuthenticatedState(authData);
    }
  } catch (e) {
    console.log('Failed to parse auth data from local storage');
  }
  return UnauthenticatedState();
}

const authSlice = createSlice({
  name: 'auth',
  initialState: initAuthState(),
  reducers: {
    setAuthState: (_, action: PayloadAction<AuthState>) => {
      const authState = action.payload;
      if (authState.authenticated) {
        persistAuthData(action.payload as AuthenticatedState);
      }
      return action.payload;
    },
    clearAuthState: () => {
      clearPersistedAuthState();
      return UnauthenticatedState();
    },
  },
});

export default authSlice.reducer;

export const { setAuthState, clearAuthState } = authSlice.actions;
