import classNames from 'classnames';
import React from 'react';

type Props = {
  className?: string;
  children?: React.ReactNode;
};

export const Container = ({ className, children }: Props) => {
  return (
    <div
      className={classNames(
        'align-middle flex bg-neutral-50 dark:bg-slate-800',
        className,
      )}
    >
      {children}
    </div>
  );
};
