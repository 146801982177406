import { LOGO_URL, USER_DISPLAY_NAME } from '@/constants/env';
import classNames from 'classnames';

type Props = {
  pageTitle?: string;
};

export function NavBar({ pageTitle }: Props) {
  return (
    <div className="navbar bg-base-100 py-0 px-6 mx-0">
      <div className="navbar-start">
        <span className="text-xl font-extrabold select-none text-primary">
          {pageTitle || 'Sytadel'}
        </span>
      </div>
      <div className={'navbar-end py-4'}>
        {LOGO_URL && (
          <img className={classNames('w-32')} src={LOGO_URL} alt={'logo'} />
        )}
        {!LOGO_URL && USER_DISPLAY_NAME && (
          <span className={'text-xl font-extrabold select-none text-primary'}>
            {USER_DISPLAY_NAME}
          </span>
        )}
      </div>
    </div>
  );
}
