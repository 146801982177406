import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { Credentials, login, logout, selectAuthState } from '@/store/auth';

export function useAuth() {
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuthState);

  const dispatchLogin = async (credentials: Credentials) => {
    await dispatch(login(credentials));
  };

  const dispatchLogout = async () => {
    await dispatch(logout());
  };

  return {
    authState,
    login: dispatchLogin,
    logout: dispatchLogout,
  };
}
