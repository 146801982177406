import classNames from 'classnames';
import React, { useState } from 'react';
import { Marker, Popup } from 'react-map-gl';

import { DateLabel } from '@/components';
import { MarkerTooltipItem } from '@/components/map/util/MarkerTooltipItem';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectSelectedVessel,
  setSelectedVessel,
  Vessel,
  VesselProperty,
} from '@/store/vessels';
import { mmsiToColor } from '@/utils';
import { symmetricTooltipOffset } from '@/utils/map';

import { MarkerIcon } from './MarkerIcon';

type Props = {
  vessel: Vessel;
  animate?: boolean;
};

export const MapMarker = ({ vessel, animate = true }: Props) => {
  const dispatch = useAppDispatch();

  const activeVessel = useAppSelector(selectSelectedVessel);

  const [showTooltip, setShowTooltip] = useState(false);

  const [lng, lat] = vessel.location.value.coordinates;
  const markerSize = 20;

  return (
    <>
      <Marker
        key={vessel.id}
        latitude={lat}
        longitude={lng}
        onClick={() => dispatch(setSelectedVessel(vessel))}
      >
        {showTooltip && !activeVessel && (
          <Popup
            closeButton={false}
            closeOnClick={false}
            latitude={lat}
            longitude={lng}
            offset={symmetricTooltipOffset(markerSize * 0.75)}
          >
            <MarkerTooltipItem
              label="Name"
              value={vessel[VesselProperty.Name].value}
            />
            <MarkerTooltipItem
              label="MMSI"
              value={vessel[VesselProperty.MMSI].value}
            />
            <MarkerTooltipItem
              label="Provider"
              value={vessel[VesselProperty.DataProvider].value}
            />
            <MarkerTooltipItem
              className={'flex flex-col !whitespace-normal'}
              label="Latest timestamp"
              value={
                <DateLabel
                  className={'inline'}
                  date={new Date(vessel.location.observedAt)}
                  fromNow={'bottom'}
                />
              }
            />
          </Popup>
        )}
        <div
          className={classNames('group relative cursor-pointer')}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <MarkerIcon
            bgColor={mmsiToColor(
              vessel[VesselProperty.MMSI].value as unknown as number,
            )}
            // animate the first marker
            className={`${animate ? 'animate-ping' : ''}`}
            size={markerSize}
          />
        </div>
      </Marker>
    </>
  );
};
