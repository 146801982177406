type Props = {
  bgColor: string;
  className?: string;
  size?: number;
};
export const MarkerIcon = ({ bgColor, className, size = 6 }: Props) => {
  return (
    <span
      className={`flex`}
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      <div
        style={{
          backgroundColor: bgColor,
          borderRadius: '9999px',
        }}
      >
        <span
          className={`absolute inline-flex h-full 
                  w-full rounded-full bg-inherit opacity-75 ${className ?? ''}`}
        ></span>
        <span
          className={`relative inline-flex rounded-full
                  bg-inherit border-2 border-white shadow-[0_0_4px_2px_rgba(0,0,0,0.15)]`}
          style={{ width: `${size}px`, height: `${size}px` }}
        ></span>
      </div>
    </span>
  );
};
