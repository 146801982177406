import { FeatureCollection, LineString } from 'geojson';

export const CORRIDOR_AWT_DUISBURG: FeatureCollection<LineString> = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        name: 'awt_duisburg',
      },
      geometry: {
        type: 'LineString',
        coordinates: [
          [3.797432, 51.17536],
          [3.801914, 51.184881],
          [3.802122, 51.186484],
          [3.802112, 51.187636],
          [3.802022, 51.189544],
          [3.801941, 51.191154],
          [3.801923, 51.193171],
          [3.801914, 51.195178],
          [3.801707, 51.19777],
          [3.802446, 51.199659],
          [3.802481, 51.200433],
          [3.802481, 51.200668],
          [3.802446, 51.20146],
          [3.801482, 51.204159],
          [3.801644, 51.205501],
          [3.802247, 51.209317],
          [3.802554, 51.211026],
          [3.803003, 51.212754],
          [3.803273, 51.213789],
          [3.804443, 51.218802],
          [3.804875, 51.22152],
          [3.806009, 51.224608],
          [3.808448, 51.22917],
          [3.808529, 51.22944],
          [3.808412, 51.23269],
          [3.809303, 51.234733],
          [3.810788, 51.238729],
          [3.813146, 51.2416],
          [3.816125, 51.244633],
          [3.819473, 51.247989],
          [3.823478, 51.251968],
          [3.826071, 51.254569],
          [3.830984, 51.259366],
          [3.831866, 51.260383],
          [3.833108, 51.261399],
          [3.837527, 51.26698],
          [3.838517, 51.26887],
          [3.839237, 51.270319],
          [3.839786, 51.272496],
          [3.84002, 51.274584],
          [3.840533, 51.276357],
          [3.840614, 51.278715],
          [3.839966, 51.28191],
          [3.839786, 51.282424],
          [3.838697, 51.285349],
          [3.837572, 51.288463],
          [3.8366, 51.290749],
          [3.836447, 51.293718],
          [3.836357, 51.293989],
          [3.83436, 51.297058],
          [3.833522, 51.299505],
          [3.832874, 51.301657],
          [3.831884, 51.304483],
          [3.83084, 51.30784],
          [3.830516, 51.3091],
          [3.830129, 51.310765],
          [3.829202, 51.312763],
          [3.827898, 51.314868],
          [3.827231, 51.316156],
          [3.825701, 51.318684],
          [3.825531, 51.319683],
          [3.826007, 51.320854],
          [3.825953, 51.321771],
          [3.825431, 51.323077],
          [3.823973, 51.32494],
          [3.823298, 51.326415],
          [3.822479, 51.328278],
          [3.820751, 51.331357],
          [3.820346, 51.33177],
          [3.819806, 51.332221],
          [3.818087, 51.333121],
          [3.817583, 51.334039],
          [3.81752, 51.334219],
          [3.81698, 51.335578],
          [3.816953, 51.335649],
          [3.816881, 51.335839],
          [3.816656, 51.336414],
          [3.816179, 51.33745],
          [3.814541, 51.33942],
          [3.812705, 51.340843],
          [3.812048, 51.342364],
          [3.811742, 51.344128],
          [3.812894, 51.345802],
          [3.815819, 51.346657],
          [3.823523, 51.34618],
          [3.833018, 51.345883],
          [3.842513, 51.345586],
          [3.853466, 51.346342],
          [3.863978, 51.348817],
          [3.87449, 51.351301],
          [3.886532, 51.355008],
          [3.898565, 51.358726],
          [3.909662, 51.363972],
          [3.92075, 51.369229],
          [3.929462, 51.373296],
          [3.938525, 51.378418],
          [3.945518, 51.384024],
          [3.951305, 51.391161],
          [3.95513, 51.399477],
          [3.958947, 51.407793],
          [3.962231, 51.415219],
          [3.966461, 51.422481],
          [3.972491, 51.429178],
          [3.982508, 51.436719],
          [3.996072, 51.438213],
          [4.007745, 51.437737],
          [4.011326, 51.439401],
          [4.012514, 51.441553],
          [4.013216, 51.444325],
          [4.012622, 51.448401],
          [4.011911, 51.450931],
          [4.011165, 51.454404],
          [4.011075, 51.454594],
          [4.010219, 51.456951],
          [4.010192, 51.457051],
          [4.007996, 51.46183],
          [4.006646, 51.466167],
          [4.007033, 51.469218],
          [4.007024, 51.469372],
          [4.007024, 51.469525],
          [4.005422, 51.474061],
          [4.004739, 51.48513],
          [4.00427, 51.494437],
          [4.004099, 51.497316],
          [4.004099, 51.497451],
          [4.005117, 51.501951],
          [4.007592, 51.505317],
          [4.012155, 51.510664],
          [4.014818, 51.515361],
          [4.015241, 51.51942],
          [4.015799, 51.520528],
          [4.016762, 51.521176],
          [4.019237, 51.521257],
          [4.022603, 51.520429],
          [4.029911, 51.518691],
          [4.039118, 51.517747],
          [4.04837, 51.517306],
          [4.065066, 51.51781],
          [4.081752, 51.518322],
          [4.091732, 51.518376],
          [4.100103, 51.51826],
          [4.112973, 51.517882],
          [4.125833, 51.517512],
          [4.135536, 51.517486],
          [4.145246, 51.517458],
          [4.156388, 51.516991],
          [4.16096, 51.516225],
          [4.162509, 51.515488],
          [4.164192, 51.513445],
          [4.165191, 51.512437],
          [4.166756, 51.511959],
          [4.167845, 51.511663],
          [4.169915, 51.511005],
          [4.173308, 51.510304],
          [4.174011, 51.510115],
          [4.174334, 51.510016],
          [4.177502, 51.509386],
          [4.181336, 51.508567],
          [4.187483, 51.508612],
          [4.189247, 51.508603],
          [4.191677, 51.508441],
          [4.196196, 51.50736],
          [4.200939, 51.505561],
          [4.205871, 51.504768],
          [4.208436, 51.504858],
          [4.210433, 51.505948],
          [4.211415, 51.507568],
          [4.21209, 51.508801],
          [4.213151, 51.511033],
          [4.214996, 51.513022],
          [4.218812, 51.515488],
          [4.221621, 51.517098],
          [4.222593, 51.518952],
          [4.223565, 51.520807],
          [4.225014, 51.523534],
          [4.229001, 51.530392],
          [4.232645, 51.53679],
          [4.23278, 51.537043],
          [4.235382, 51.542812],
          [4.236993, 51.549481],
          [4.234968, 51.555889],
          [4.232177, 51.55965],
          [4.228064, 51.563755],
          [4.223663, 51.567211],
          [4.217859, 51.571576],
          [4.212054, 51.575931],
          [4.201478, 51.583932],
          [4.2012, 51.58414],
          [4.200083, 51.585085],
          [4.19697, 51.587533],
          [4.193297, 51.592158],
          [4.190984, 51.596398],
          [4.189886, 51.602706],
          [4.190787, 51.607315],
          [4.193405, 51.612652],
          [4.19355, 51.612877],
          [4.198104, 51.618655],
          [4.205871, 51.624091],
          [4.21587, 51.628221],
          [4.229306, 51.631606],
          [4.235984, 51.633514],
          [4.243257, 51.635692],
          [4.248747, 51.637411],
          [4.262435, 51.641965],
          [4.268357, 51.644386],
          [4.283298, 51.648543],
          [4.297374, 51.652297],
          [4.310415, 51.654745],
          [4.318956, 51.656068],
          [4.333292, 51.658525],
          [4.34655, 51.66063],
          [4.35501, 51.662997],
          [4.361616, 51.667947],
          [4.368779, 51.674364],
          [4.370751, 51.676812],
          [4.374513, 51.68188],
          [4.376934, 51.68485],
          [4.382252, 51.688576],
          [4.38822, 51.69097],
          [4.390947, 51.691519],
          [4.392692, 51.691635],
          [4.393458, 51.691537],
          [4.395879, 51.691914],
          [4.396824, 51.692094],
          [4.400262, 51.693597],
          [4.400729, 51.693867],
          [4.402106, 51.694606],
          [4.40325, 51.69529],
          [4.405526, 51.697216],
          [4.406201, 51.698322],
          [4.407128, 51.69961],
          [4.408632, 51.700879],
          [4.412637, 51.702949],
          [4.421313, 51.705144],
          [4.42755, 51.704316],
          [4.434011, 51.702597],
          [4.446242, 51.700033],
          [4.461561, 51.697044],
          [4.473621, 51.695505],
          [4.485897, 51.69448],
          [4.49658, 51.69358],
          [4.51143, 51.694371],
          [4.527099, 51.695614],
          [4.542759, 51.697801],
          [4.555467, 51.699934],
          [4.568166, 51.702057],
          [4.578614, 51.70465],
          [4.589055, 51.707242],
          [4.597937, 51.709509],
          [4.606443, 51.711391],
          [4.61726, 51.712965],
          [4.627449, 51.715126],
          [4.636241, 51.716908],
          [4.637331, 51.717133],
          [4.644009, 51.718393],
          [4.644594, 51.718528],
          [4.64517, 51.718672],
          [4.654215, 51.72004],
          [4.670387, 51.722029],
          [4.68288, 51.72202],
          [4.691096, 51.721615],
          [4.695633, 51.721435],
          [4.704831, 51.72094],
          [4.711634, 51.721056],
          [4.719789, 51.721866],
          [4.728051, 51.723451],
          [4.735476, 51.724945],
          [4.746015, 51.725457],
          [4.751505, 51.724917],
          [4.755599, 51.724315],
          [4.764374, 51.722784],
          [4.770756, 51.720904],
          [4.773897, 51.71995],
          [4.779603, 51.718132],
          [4.784975, 51.716421],
          [4.793534, 51.714298],
          [4.800942, 51.712939],
          [4.807179, 51.71221],
          [4.81311, 51.711552],
          [4.819517, 51.711228],
          [4.823388, 51.711157],
          [4.828707, 51.711544],
          [4.832469, 51.7123],
          [4.839327, 51.713407],
          [4.843268, 51.714154],
          [4.847894, 51.715162],
          [4.851882, 51.716116],
          [4.862096, 51.718284],
          [4.865391, 51.718798],
          [4.868684, 51.719319],
          [4.871259, 51.719733],
          [4.875804, 51.720192],
          [4.881959, 51.720138],
          [4.886972, 51.719373],
          [4.888511, 51.719158],
          [4.890041, 51.718933],
          [4.890627, 51.718861],
          [4.893246, 51.718933],
          [4.895865, 51.718996],
          [4.898025, 51.718888],
          [4.901382, 51.718779],
          [4.906746, 51.718951],
          [4.907907, 51.719023],
          [4.910598, 51.719248],
          [4.914351, 51.719581],
          [4.922307, 51.720687],
          [4.937949, 51.722812],
          [4.945842, 51.723136],
          [4.958631, 51.722659],
          [4.965479, 51.72192],
          [4.9725, 51.720732],
          [4.98069, 51.718906],
          [4.981338, 51.718771],
          [4.982318, 51.718509],
          [4.99266, 51.716179],
          [5.00301, 51.713839],
          [5.008608, 51.712948],
          [5.022513, 51.711517],
          [5.034078, 51.710905],
          [5.048631, 51.711265],
          [5.049657, 51.711346],
          [5.052555, 51.711687],
          [5.056542, 51.712281],
          [5.062491, 51.713839],
          [5.068538, 51.715945],
          [5.070555, 51.716781],
          [5.072381, 51.717735],
          [5.076945, 51.719986],
          [5.082939, 51.722893],
          [5.090535, 51.726763],
          [5.093388, 51.728077],
          [5.096114, 51.729229],
          [5.103531, 51.731919],
          [5.111451, 51.734161],
          [5.118183, 51.735502],
          [5.118624, 51.735583],
          [5.123529, 51.736923],
          [5.128001, 51.737581],
          [5.132519, 51.738372],
          [5.135256, 51.738786],
          [5.140809, 51.739723],
          [5.144966, 51.740227],
          [5.148792, 51.740784],
          [5.154218, 51.741603],
          [5.16293, 51.742729],
          [5.163777, 51.742819],
          [5.167269, 51.743016],
          [5.173614, 51.743116],
          [5.180588, 51.742666],
          [5.185206, 51.741937],
          [5.188806, 51.741262],
          [5.192415, 51.740569],
          [5.196104, 51.740299],
          [5.199498, 51.740407],
          [5.201973, 51.740821],
          [5.204655, 51.741091],
          [5.20695, 51.74155],
          [5.210243, 51.742306],
          [5.214249, 51.743296],
          [5.218119, 51.743601],
          [5.219972, 51.743584],
          [5.222367, 51.743359],
          [5.227173, 51.742441],
          [5.231961, 51.741018],
          [5.236784, 51.738805],
          [5.241932, 51.735529],
          [5.245245, 51.73399],
          [5.247819, 51.733684],
          [5.250114, 51.733594],
          [5.252895, 51.734134],
          [5.254992, 51.734556],
          [5.256144, 51.73507],
          [5.258934, 51.736518],
          [5.262498, 51.738166],
          [5.263866, 51.738642],
          [5.264982, 51.739003],
          [5.266809, 51.739371],
          [5.268267, 51.739633],
          [5.268618, 51.73966],
          [5.270004, 51.739804],
          [5.273865, 51.740019],
          [5.274656, 51.740038],
          [5.27876, 51.740038],
          [5.282703, 51.739839],
          [5.285817, 51.739255],
          [5.288976, 51.73822],
          [5.291676, 51.737337],
          [5.294484, 51.736807],
          [5.296716, 51.736779],
          [5.299424, 51.737167],
          [5.301504, 51.737752],
          [5.303907, 51.73867],
          [5.304618, 51.73893],
          [5.310297, 51.741397],
          [5.314509, 51.743475],
          [5.320773, 51.746058],
          [5.326038, 51.747778],
          [5.32809, 51.748435],
          [5.329809, 51.748858],
          [5.331735, 51.749326],
          [5.336316, 51.750657],
          [5.338422, 51.751279],
          [5.345838, 51.753178],
          [5.353092, 51.755292],
          [5.354964, 51.756076],
          [5.357808, 51.75792],
          [5.360166, 51.760819],
          [5.360723, 51.762888],
          [5.360507, 51.767173],
          [5.360552, 51.76909],
          [5.360643, 51.77044],
          [5.360723, 51.772411],
          [5.361416, 51.776362],
          [5.362533, 51.77935],
          [5.363397, 51.781392],
          [5.363792, 51.782391],
          [5.36508, 51.784597],
          [5.365287, 51.784876],
          [5.3658, 51.78565],
          [5.368347, 51.789159],
          [5.368788, 51.790707],
          [5.368086, 51.791985],
          [5.367537, 51.792517],
          [5.366403, 51.793183],
          [5.365215, 51.793633],
          [5.362776, 51.794596],
          [5.360616, 51.795973],
          [5.359148, 51.796909],
          [5.358456, 51.797439],
          [5.358257, 51.797584],
          [5.35725, 51.798277],
          [5.357124, 51.798358],
          [5.357006, 51.798429],
          [5.356818, 51.798573],
          [5.355585, 51.799375],
          [5.353236, 51.80112],
          [5.351886, 51.802254],
          [5.350662, 51.803028],
          [5.350662, 51.803227],
          [5.350833, 51.803542],
          [5.351499, 51.803677],
          [5.354829, 51.80401],
          [5.359374, 51.804981],
          [5.364008, 51.806799],
          [5.367447, 51.80869],
          [5.372847, 51.814144],
          [5.378688, 51.820921],
          [5.385186, 51.8275],
          [5.390855, 51.83173],
          [5.394825, 51.834376],
          [5.39712, 51.835699],
          [5.403276, 51.839326],
          [5.406606, 51.841899],
          [5.409108, 51.84406],
          [5.411258, 51.846067],
          [5.413491, 51.850324],
          [5.417244, 51.85782],
          [5.419053, 51.86116],
          [5.420952, 51.86449],
          [5.420736, 51.86557],
          [5.420475, 51.865902],
          [5.419854, 51.866245],
          [5.421303, 51.866236],
          [5.423004, 51.866938],
          [5.424066, 51.868252],
          [5.425056, 51.869358],
          [5.429394, 51.873373],
          [5.432994, 51.87636],
          [5.436332, 51.878772],
          [5.439249, 51.881212],
          [5.441904, 51.883039],
          [5.444325, 51.883948],
          [5.446737, 51.884865],
          [5.451416, 51.886765],
          [5.454198, 51.887656],
          [5.458292, 51.888465],
          [5.465555, 51.88914],
          [5.476103, 51.889708],
          [5.484833, 51.889284],
          [5.497154, 51.889078],
          [5.497938, 51.889078],
          [5.505435, 51.889474],
          [5.511546, 51.890104],
          [5.518638, 51.891642],
          [5.522409, 51.892452],
          [5.532966, 51.89518],
          [5.536395, 51.896331],
          [5.540903, 51.897771],
          [5.552325, 51.900301],
          [5.560542, 51.90139],
          [5.562063, 51.901506],
          [5.563593, 51.901624],
          [5.566644, 51.901866],
          [5.574834, 51.90165],
          [5.580603, 51.901056],
          [5.590899, 51.899598],
          [5.596011, 51.899293],
          [5.599997, 51.899103],
          [5.606631, 51.899086],
          [5.610096, 51.899086],
          [5.613561, 51.899086],
          [5.623974, 51.899193],
          [5.629049, 51.899238],
          [5.635062, 51.899068],
          [5.644439, 51.898194],
          [5.65236, 51.897061],
          [5.661288, 51.896187],
          [5.667597, 51.895801],
          [5.671286, 51.895711],
          [5.679845, 51.895612],
          [5.684193, 51.895602],
          [5.692095, 51.895522],
          [5.704434, 51.893974],
          [5.716476, 51.890347],
          [5.725269, 51.887404],
          [5.737086, 51.885414],
          [5.738328, 51.885279],
          [5.747652, 51.884263],
          [5.763249, 51.880618],
          [5.767722, 51.879565],
          [5.778027, 51.878197],
          [5.783238, 51.877638],
          [5.788449, 51.877089],
          [5.799636, 51.875533],
          [5.806368, 51.873903],
          [5.812929, 51.87204],
          [5.821038, 51.868702],
          [5.825799, 51.866398],
          [5.829426, 51.864633],
          [5.833178, 51.862491],
          [5.835789, 51.86107],
          [5.841009, 51.858244],
          [5.841549, 51.857991],
          [5.845238, 51.856111],
          [5.852754, 51.853168],
          [5.856228, 51.852321],
          [5.857218, 51.85216],
          [5.86395, 51.85126],
          [5.868072, 51.851178],
          [5.870691, 51.851206],
          [5.871807, 51.851395],
          [5.873904, 51.852033],
          [5.876316, 51.853465],
          [5.878998, 51.855886],
          [5.880573, 51.857578],
          [5.885199, 51.863634],
          [5.891634, 51.868486],
          [5.898023, 51.87124],
          [5.902776, 51.872212],
          [5.905152, 51.872698],
          [5.907519, 51.873174],
          [5.914422, 51.873895],
          [5.922873, 51.87448],
          [5.929308, 51.873652],
          [5.935194, 51.872014],
          [5.941332, 51.86881],
          [5.94468, 51.865624],
          [5.949315, 51.8617],
          [5.954202, 51.857839],
          [5.959179, 51.855688],
          [5.965443, 51.854257],
          [5.968773, 51.854284],
          [5.971842, 51.854814],
          [5.976738, 51.856218],
          [5.979204, 51.857713],
          [5.981282, 51.859342],
          [5.982399, 51.861439],
          [5.985792, 51.867127],
          [5.989653, 51.872437],
          [5.99283, 51.875074],
          [5.996133, 51.877413],
          [6.002694, 51.880528],
          [6.010038, 51.881707],
          [6.017481, 51.88095],
          [6.025401, 51.877972],
          [6.031755, 51.874506],
          [6.039108, 51.871869],
          [6.046641, 51.870844],
          [6.052734, 51.869412],
          [6.057873, 51.867729],
          [6.059799, 51.867],
          [6.06807, 51.863896],
          [6.071949, 51.862375],
          [6.075189, 51.860467],
          [6.07869, 51.858334],
          [6.081813, 51.856201],
          [6.085098, 51.854248],
          [6.089571, 51.852078],
          [6.096294, 51.849694],
          [6.102135, 51.848442],
          [6.107625, 51.84802],
          [6.120783, 51.848262],
          [6.12981, 51.848262],
          [6.138774, 51.847435],
          [6.144111, 51.846517],
          [6.149961, 51.845175],
          [6.16329, 51.841774],
          [6.171354, 51.838876],
          [6.180993, 51.835141],
          [6.190236, 51.831604],
          [6.195969, 51.83011],
          [6.202422, 51.829408],
          [6.213366, 51.829282],
          [6.219153, 51.829273],
          [6.226083, 51.829218],
          [6.226686, 51.829209],
          [6.236244, 51.828759],
          [6.24366, 51.828004],
          [6.246747, 51.827608],
          [6.251301, 51.826735],
          [6.254028, 51.826015],
          [6.260751, 51.824071],
          [6.263433, 51.8229],
          [6.266736, 51.821551],
          [6.274611, 51.816708],
          [6.281946, 51.813325],
          [6.290874, 51.810075],
          [6.297696, 51.808177],
          [6.301413, 51.806413],
          [6.307137, 51.803281],
          [6.313104, 51.798502],
          [6.319152, 51.792058],
          [6.321249, 51.788394],
          [6.322221, 51.784966],
          [6.324345, 51.776326],
          [6.32727, 51.770539],
          [6.32898, 51.76774],
          [6.33051, 51.765598],
          [6.334407, 51.761584],
          [6.340203, 51.758398],
          [6.347376, 51.755742],
          [6.355503, 51.753978],
          [6.364521, 51.753322],
          [6.37677, 51.754509],
          [6.377139, 51.754537],
          [6.377976, 51.754573],
          [6.382629, 51.754915],
          [6.390612, 51.755437],
          [6.401664, 51.755554],
          [6.408288, 51.754968],
          [6.413913, 51.753069],
          [6.418071, 51.750307],
          [6.420267, 51.747859],
          [6.421419, 51.746292],
          [6.422652, 51.743449],
          [6.422517, 51.740344],
          [6.421356, 51.737418],
          [6.415776, 51.732127],
          [6.411384, 51.728437],
          [6.408549, 51.725196],
          [6.407505, 51.72246],
          [6.408486, 51.717619],
          [6.410718, 51.71518],
          [6.413679, 51.713164],
          [6.41988, 51.711138],
          [6.428979, 51.710013],
          [6.441696, 51.708565],
          [6.448734, 51.707296],
          [6.451389, 51.706486],
          [6.454035, 51.705676],
          [6.458337, 51.703678],
          [6.462072, 51.701139],
          [6.465636, 51.697558],
          [6.467454, 51.694741],
          [6.469515, 51.690394],
          [6.471036, 51.686685],
          [6.471702, 51.684391],
          [6.472395, 51.680817],
          [6.472647, 51.679531],
          [6.473079, 51.677704],
          [6.476166, 51.671296],
          [6.478875, 51.66811],
          [6.480396, 51.666652],
          [6.48153, 51.665932],
          [6.486039, 51.663799],
          [6.491277, 51.662602],
          [6.498828, 51.661764],
          [6.506712, 51.661593],
          [6.51411, 51.662008],
          [6.517107, 51.662107],
          [6.520104, 51.662214],
          [6.523785, 51.662043],
          [6.530139, 51.661234],
          [6.534774, 51.660154],
          [6.542415, 51.658381],
          [6.551766, 51.657823],
          [6.564924, 51.659209],
          [6.57198, 51.659974],
          [6.577209, 51.660028],
          [6.581799, 51.659865],
          [6.585876, 51.659271],
          [6.590943, 51.657931],
          [6.595632, 51.655924],
          [6.599547, 51.65353],
          [6.601392, 51.65182],
          [6.602967, 51.650172],
          [6.603984, 51.648292],
          [6.605091, 51.645627],
          [6.605181, 51.645303],
          [6.605559, 51.642649],
          [6.604623, 51.640003],
          [6.603552, 51.638158],
          [6.59943, 51.634674],
          [6.591978, 51.629923],
          [6.58971, 51.627952],
          [6.587775, 51.625603],
          [6.586785, 51.623127],
          [6.586614, 51.6196],
          [6.587757, 51.61645],
          [6.592626, 51.609249],
          [6.596208, 51.601806],
          [6.598251, 51.595569],
          [6.598647, 51.592321],
          [6.598764, 51.589341],
          [6.59844, 51.587371],
          [6.598116, 51.5854],
          [6.597522, 51.581413],
          [6.59745, 51.577939],
          [6.598116, 51.575103],
          [6.600294, 51.572025],
          [6.604542, 51.569019],
          [6.608619, 51.567426],
          [6.613767, 51.566491],
          [6.618132, 51.566509],
          [6.624558, 51.567778],
          [6.632658, 51.570667],
          [6.643404, 51.575212],
          [6.649155, 51.576958],
          [6.65559, 51.578029],
          [6.660243, 51.578253],
          [6.665607, 51.578109],
          [6.671781, 51.577327],
          [6.677379, 51.575851],
          [6.681771, 51.573843],
          [6.68421, 51.57207],
          [6.68583, 51.57019],
          [6.687225, 51.567921],
          [6.687792, 51.565231],
          [6.687531, 51.56272],
          [6.686721, 51.560299],
          [6.683688, 51.556177],
          [6.681096, 51.552882],
          [6.678369, 51.549139],
          [6.676344, 51.543873],
          [6.675732, 51.540076],
          [6.676353, 51.537214],
          [6.678126, 51.534631],
          [6.680934, 51.53212],
          [6.684345, 51.529833],
          [6.688017, 51.528088],
          [6.694209, 51.525828],
          [6.696792, 51.525289],
          [6.699843, 51.524505],
          [6.709401, 51.521328],
          [6.713856, 51.518862],
          [6.717042, 51.516946],
          [6.719193, 51.515325],
          [6.721686, 51.512977],
          [6.723207, 51.511195],
          [6.724413, 51.5089],
          [6.72489, 51.50646],
          [6.724485, 51.504058],
          [6.722451, 51.50071],
          [6.720102, 51.498675],
          [6.716916, 51.496956],
          [6.712137, 51.495292],
          [6.705882, 51.494284],
          [6.701499, 51.493555],
          [6.695136, 51.492321],
          [6.691284, 51.49099],
          [6.686613, 51.488856],
          [6.683121, 51.48667],
          [6.681411, 51.484959],
          [6.680277, 51.483205],
          [6.679917, 51.481153],
          [6.679935, 51.480729],
          [6.680331, 51.478767],
          [6.680475, 51.478452],
          [6.682527, 51.476014],
          [6.685434, 51.474033],
          [6.688872, 51.472908],
          [6.693804, 51.471892],
          [6.700959, 51.471145],
          [6.707331, 51.470695],
          [6.711732, 51.47002],
          [6.71517, 51.468886],
          [6.718716, 51.467068],
          [6.721191, 51.464827],
          [6.722766, 51.462649],
          [6.723774, 51.460417],
          [6.724242, 51.458347],
          [6.724053, 51.456385],
          [6.723972, 51.456078],
          [6.722937, 51.454243],
          [6.722712, 51.452676],
          [6.722739, 51.451893],
          [6.72309, 51.450823],
          [6.722019, 51.451542],
        ],
      },
    },
  ],
};
