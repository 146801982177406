import { Container } from '@/components';
import { useAppDispatch } from '@/hooks';
import {
  POSITION_ACCURACIES,
  Vessel,
  VESSEL_NAV_STATUSES,
  VESSEL_SUBTYPES,
  VESSEL_TYPES,
  VesselProperty,
} from '@/store/vessels';

import { MarkerTooltipItem } from '../util/MarkerTooltipItem';

type Props = {
  vessel: Vessel;
  startTime: Date | null | undefined;
  endTime: Date | null | undefined;
};

export const MapVesselControl = ({ vessel }: Props) => {
  const dispatch = useAppDispatch();

  let etaValue: Date | string = '';
  if (vessel[VesselProperty.EstimatedTimeOfArrival]?.value) {
    etaValue = new Date(vessel[VesselProperty.EstimatedTimeOfArrival].value);
  }

  return (
    <Container
      className={'absolute top-4 right-4 rounded-lg p-2 select-none grid'}
    >
      <div
        className="px-3 py-2 bg-gray-100 border-b border-gray-200
                rounded-t-lg dark:border-gray-600 dark:bg-gray-700 width-80"
      >
        <div className={'flex justify-between items-center'}>
          <div>
            <h3 className="font-semibold text-gray-900 dark:text-white">
              <MarkerTooltipItem
                label="Name"
                value={vessel[VesselProperty.Name].value}
              />
              <MarkerTooltipItem
                label="MMSI"
                value={vessel[VesselProperty.MMSI].value}
              />
            </h3>
          </div>
          <div>
            <button
              className="btn btn-circle btn-outline btn-xs"
              onClick={() => {
                dispatch({
                  type: 'vessels/setSelectedVessel',
                  payload: null,
                });
              }}
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 18L18 6M6 6l12 12"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className="px-3 py-2 width-80">
        <MarkerTooltipItem
          label="Latest timestamp"
          value={new Date(vessel.location.observedAt)}
        />
        <MarkerTooltipItem
          label="Navigation status"
          possibleValues={VESSEL_NAV_STATUSES}
          value={vessel[VesselProperty.NavigationStatus]?.value}
        />
        <MarkerTooltipItem
          label="Type"
          possibleValues={VESSEL_TYPES}
          value={vessel[VesselProperty.VesselType]?.value}
        />
        <MarkerTooltipItem
          label="Subtype"
          possibleValues={VESSEL_SUBTYPES}
          value={vessel[VesselProperty.VesselSubType]?.value}
        />
        <MarkerTooltipItem
          label="Destination port"
          value={vessel[VesselProperty.DestinationPort]?.value}
        />
        <MarkerTooltipItem
          label="Draught"
          unit={'m'}
          value={vessel[VesselProperty.Draught]?.value}
        />
        <MarkerTooltipItem
          label="Course"
          unit={'°'}
          value={vessel[VesselProperty.CourseOverGround]?.value}
        />
        <MarkerTooltipItem
          label="Heading"
          unit={'°'}
          value={vessel[VesselProperty.Heading]?.value}
        />
        <MarkerTooltipItem
          label="Speed"
          unit={'kn'}
          value={vessel[VesselProperty.SpeedOverGround]?.value}
        />
        <MarkerTooltipItem
          label="Position accuracy"
          possibleValues={POSITION_ACCURACIES}
          value={vessel[VesselProperty.PositionAccuracy]?.value}
        />
        <MarkerTooltipItem label="ETA" value={etaValue} />
        <MarkerTooltipItem
          label="Data provider"
          value={vessel[VesselProperty.DataProvider]?.value}
        />
      </div>
    </Container>
  );
};
