import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

export type TimestampsState = {
  startTime?: string | null;
  endTime?: string | null;
};

const initialState: TimestampsState = {
  startTime: moment().startOf('day').toISOString(),
  endTime: moment().endOf('day').toISOString(),
};

export const timestampsSlice = createSlice({
  name: 'timestamps',
  initialState,
  reducers: {
    setStartTime: (state, action: PayloadAction<string>) => {
      state.startTime = moment(action.payload).toISOString();
    },
    setEndTime: (state, action: PayloadAction<string>) => {
      state.endTime = moment(action.payload).toISOString();
    },
    setInitialTime: (state) => {
      state.startTime = initialState.startTime;
      state.endTime = initialState.endTime;
    },
  },
});

export default timestampsSlice.reducer;

export const { setStartTime, setEndTime, setInitialTime } =
  timestampsSlice.actions;
