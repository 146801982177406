import { Vessel, VesselProperty } from '@/store/vessels';

export function sortVesselsAlpha(vessels: Vessel[]) {
  return vessels.sort((va, vb) => {
    const aName = va[VesselProperty.Name].value;
    const bName = vb[VesselProperty.Name].value;

    return aName.localeCompare(bName);
  });
}

export function filterValidVessels(vessels: Vessel[]) {
  return vessels.filter((v) => {
    const valid =
      v[VesselProperty.Name] &&
      v[VesselProperty.MMSI] &&
      v[VesselProperty.DataProvider];

    if (!valid) {
      console.warn(`Invalid vessel "${v.id}"`);
    }

    return valid;
  });
}
