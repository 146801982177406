import {
  Feature,
  FeatureCollection,
  LineString,
  Point,
  Polygon,
} from 'geojson';
import _cloneDeep from 'lodash.clonedeep';
import _uniqBy from 'lodash.uniqby';

import { VesselFeatureProperties } from '@/components/map/layers/MapTrackLayer';
import { PointOfInterest } from '@/constants';
import { TemporalVessel } from '@/store/vessels';

// Converts degrees to radians
function toRadians(degrees: number): number {
  return (degrees * Math.PI) / 180;
}

// Converts radians to degrees
function toDegrees(radians: number): number {
  return (radians * 180) / Math.PI;
}

// Creates a GeoJSON Polygon that approximates a circle
export function createGeoJSONCircle(
  center: Point,
  radius: number,
  numSides = 64,
): Polygon {
  if (center.type !== 'Point') {
    throw new Error('The input GeoJSON is not a Point');
  }

  const coordinates = [];
  const [centerLng, centerLat] = center.coordinates;
  const angularDistance = radius / 6371000; // Earth's mean radius in meters

  for (let i = 0; i <= numSides; i++) {
    const angle = (i * 360) / numSides;
    const bearing = toRadians(angle);

    const lat = Math.asin(
      Math.sin(toRadians(centerLat)) * Math.cos(angularDistance) +
        Math.cos(toRadians(centerLat)) *
          Math.sin(angularDistance) *
          Math.cos(bearing),
    );
    const lng =
      toRadians(centerLng) +
      Math.atan2(
        Math.sin(bearing) *
          Math.sin(angularDistance) *
          Math.cos(toRadians(centerLat)),
        Math.cos(angularDistance) -
          Math.sin(toRadians(centerLat)) * Math.sin(lat),
      );

    coordinates.push([toDegrees(lng), toDegrees(lat)]);
  }

  return {
    type: 'Polygon',
    coordinates: [coordinates],
  };
}

export function vesselTrackToLineString(vessel: TemporalVessel): LineString {
  if (!vessel.location || !vessel.location.values) {
    return {
      type: 'LineString',
      coordinates: [],
    };
  }

  const coordinates = vessel.location.values.map(
    (value) => value[0].coordinates,
  );

  return {
    type: 'LineString',
    coordinates,
  };
}

export function vesselTrackToPointFeatureCollection(
  vessel: TemporalVessel,
): FeatureCollection<Point, VesselFeatureProperties> {
  if (!vessel.location || !vessel.location.values) {
    return {
      type: 'FeatureCollection',
      features: [],
    };
  }

  const points: Feature<Point, VesselFeatureProperties>[] =
    vessel.location.values.map(
      ([point, timestamp]) =>
        ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: point.coordinates,
          },
          properties: {
            timestamp,
            longitude: point.coordinates[0],
            latitude: point.coordinates[1],
          },
        } as Feature<Point, VesselFeatureProperties>),
    );

  return {
    type: 'FeatureCollection',
    features: points,
  };
}

export function poiAsFeatureCollection(
  poi: PointOfInterest[],
): FeatureCollection<Point> {
  return {
    type: 'FeatureCollection',
    features: poi.map(
      (poi) =>
        ({
          type: 'Feature',
          properties: {
            name: poi.name,
          },
          geometry: poi.location,
        } as Feature<Point>),
    ),
  };
}

/**
 * De-duplicates observations based on timestamp.
 * This should be the case from the source, but it has been proven unreliable.
 * @param vesselTrack
 */
export function dedupObservations(vesselTrack: TemporalVessel) {
  const dedup = _cloneDeep(vesselTrack);
  // TODO: if this is possible, fix related types
  if (dedup.location?.values) {
    dedup.location.values = _uniqBy(
      dedup.location.values,
      ([, timestamp]) => timestamp,
    );
  }

  return dedup;
}
