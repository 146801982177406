import { Point } from 'geojson';

export type PointOfInterest = {
  name: string;
  type: 'origin' | 'destination';
  location: Point;
};

export const PointsOfInterest: PointOfInterest[] = [
  {
    name: 'All Weather Terminal',
    type: 'origin',
    location: {
      type: 'Point',
      coordinates: [3.802813084839221, 51.180482455511026],
    },
  },
  {
    name: 'Regensburg Port',
    type: 'destination',
    location: {
      type: 'Point',
      coordinates: [12.1638054952343, 49.0109486],
    },
  },
  {
    name: 'Bonneuil Port',
    type: 'destination',
    location: {
      type: 'Point',
      coordinates: [2.4949348651754333, 48.7792905],
    },
  },
  {
    name: 'Duisburg Port',
    type: 'destination',
    location: {
      type: 'Point',
      coordinates: [6.726196515611067, 51.42872105],
    },
  },
  {
    name: 'Giurgiu Port',
    type: 'destination',
    location: {
      type: 'Point',
      coordinates: [25.969183, 43.8761919],
    },
  },
];
