import classNames from 'classnames';
import { ReactNode } from 'react';

import { DateLabel } from '@/components';

type MarketToolTipItemProps = {
  label: string;
  className?: string;
  value: Date | ReactNode;
  possibleValues?: string[] | Record<number, string>;
  unit?: string;
};

export const MarkerTooltipItem = ({
  label,
  value,
  className,
  possibleValues,
  unit,
}: MarketToolTipItemProps) => {
  const isDate = value instanceof Date;
  // Check if the value is a number
  const isNumber = !isNaN(value as number);
  const isOther = !isDate && (!isNumber || (isNumber && !possibleValues));

  return (
    <div className={classNames('whitespace-nowrap', className)}>
      {(!!value || value === 0) && (
        <>
          <span className={'font-semibold font-variant-caps'}>{label}: </span>
          {isDate && (
            <DateLabel className={'inline'} date={value} fromNow={'right'} />
          )}
          {isNumber &&
            possibleValues &&
            possibleValues[value as number] &&
            (possibleValues[value as number] as string)}
          {isOther && (value as ReactNode)}
          {unit && <span className={'font-light'}>{unit}</span>}
        </>
      )}
    </div>
  );
};
