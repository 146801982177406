import { FeatureCollection, LineString } from 'geojson';

export const CORRIDOR_REGENSBURG_GIURGIU: FeatureCollection<LineString> = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        name: 'regensburg_giurgiu',
      },
      geometry: {
        type: 'LineString',
        coordinates: [
          [12.070339079211728, 49.030799255472886],
          [12.075935583431789, 49.029633054816358],
          [12.075935583431789, 49.029633054816358],
          [12.076824915563069, 49.029444575376665],
          [12.076824915563069, 49.029444575376665],
          [12.076824915563069, 49.029444575376665],
          [12.076824915563069, 49.029444575376665],
          [12.076824915563069, 49.029444575376665],
          [12.076824915563069, 49.029444575376665],
          [12.077076443842623, 49.029391565405611],
          [12.077076443842623, 49.029391565405611],
          [12.079151552148939, 49.028949813450424],
          [12.079151552148939, 49.028949813450424],
          [12.085835017862788, 49.027565631926372],
          [12.09020981329645, 49.027141534561757],
          [12.09020981329645, 49.027141534561757],
          [12.09081168453681, 49.027094412409234],
          [12.09081168453681, 49.027094412409234],
          [12.09156626937547, 49.027029619376648],
          [12.09156626937547, 49.027029619376648],
          [12.097279554582469, 49.026552504447743],
          [12.097279554582469, 49.026552504447743],
          [12.098977370469456, 49.026411136182844],
          [12.098977370469456, 49.026411136182844],
          [12.099705005849595, 49.026352232620596],
          [12.099705005849595, 49.026352232620596],
          [12.101582484793402, 49.026140179219169],
          [12.103136570234931, 49.025774974020642],
          [12.103136570234931, 49.025774974020642],
          [12.104555908383839, 49.025244832672769],
          [12.107852725476556, 49.023601358601752],
          [12.107852725476556, 49.023601358601752],
          [12.11042190718914, 49.022623495228423],
          [12.113179735111387, 49.02202852076212],
          [12.113179735111387, 49.02202852076212],
          [12.117985721881425, 49.021256810610858],
          [12.122019157507122, 49.021268592535172],
          [12.122019157507122, 49.021268592535172],
          [12.125127328390176, 49.021569030662967],
          [12.130858579902858, 49.022541023944868],
          [12.134155396995576, 49.0228826898013],
          [12.134155396995576, 49.0228826898013],
          [12.134460824192178, 49.022906252877348],
          [12.134460824192178, 49.022906252877348],
          [12.138557141887762, 49.022994614313163],
          [12.141512599172517, 49.022794328166746],
          [12.141512599172517, 49.022794328166746],
          [12.144809416265234, 49.022246482530804],
          [12.147746907244306, 49.021427648240511],
          [12.157466678618478, 49.017174205913463],
          [12.159227376575354, 49.016561491749805],
          [12.161518080549858, 49.01610784275023],
          [12.166225252638643, 49.015642406362971],
          [12.170671913295035, 49.0144758504726],
          [12.172800920518398, 49.013639212748444],
          [12.17431009019572, 49.012814344889215],
          [12.178648953018017, 49.00934385825915],
          [12.180077274319768, 49.00850124181666],
          [12.181361865176058, 49.008029841782474],
          [12.183311209342596, 49.007576115034546],
          [12.185188688286408, 49.007340411196068],
          [12.186832605256345, 49.007328625974857],
          [12.188710084200155, 49.007546652115757],
          [12.19047976530987, 49.008006271663618],
          [12.191854187694574, 49.008595521287944],
          [12.193237593232118, 49.009467597934517],
          [12.194513200935567, 49.010516426642035],
          [12.195716943416288, 49.011877514102082],
          [12.198762232229452, 49.017132965773797],
          [12.200747509007357, 49.01937755798081],
          [12.202436341741501, 49.02072073010369],
          [12.20438568590804, 49.021981393767703],
          [12.212021365823057, 49.025881001612461],
          [12.212021365823057, 49.025881001612461],
          [12.214437833937339, 49.027047290212082],
          [12.21640514440956, 49.02766576549886],
          [12.218803646218159, 49.02801328633425],
          [12.221256046943806, 49.027977945343217],
          [12.22308861012341, 49.027689326310167],
          [12.233185673916912, 49.025386204251106],
          [12.246408874899153, 49.023082975592096],
          [12.251565204629998, 49.021904812306595],
          [12.255598640255695, 49.020520434806087],
          [12.258015108369976, 49.019259734135481],
          [12.259003255182508, 49.01839961161474],
          [12.259757840021168, 49.017433428866134],
          [12.260629205846763, 49.015972336403003],
          [12.261913796703055, 49.013297483436411],
          [12.262749229917286, 49.01226638939908],
          [12.263800258799707, 49.011470959407454],
          [12.264950102363379, 49.010893528192952],
          [12.267609115604374, 49.010227706336565],
          [12.269199133657265, 49.010180568168515],
          [12.270591522347651, 49.010339659306808],
          [12.274831570488693, 49.011341332574915],
          [12.282808610211676, 49.0135449428277],
          [12.286321022972581, 49.014187153128447],
          [12.290354458598278, 49.014658494866481],
          [12.297172671604745, 49.014964864603037],
          [12.304691570532826, 49.015017889942953],
          [12.310153327460274, 49.014852922033292],
          [12.313827436972321, 49.014346231463968],
          [12.321157689690738, 49.012484393918655],
          [12.323259747455577, 49.011736104150074],
          [12.325200108469277, 49.010822821869723],
          [12.327176402094338, 49.009585444958482],
          [12.330167791990457, 49.007122384152119],
          [12.332206967685407, 49.005708131498331],
          [12.335099542900272, 49.0040227612999],
          [12.339420439416887, 49.001818729589125],
          [12.341190120526603, 49.000716677155097],
          [12.342726239662447, 48.999437794297314],
          [12.343894049531803, 48.997982067396116],
          [12.343894049531803, 48.997982067396116],
          [12.344657617523305, 48.996107830033104],
          [12.344639651217623, 48.99422762798762],
          [12.343813201156232, 48.992229467156548],
          [12.342330980937435, 48.990514167826881],
          [12.340687063967497, 48.989317549477718],
          [12.338782635565163, 48.988368486903596],
          [12.331838658418919, 48.986205028248577],
          [12.330176775143299, 48.985550666666121],
          [12.328550824479041, 48.984595637042872],
          [12.327391997762527, 48.983534471550648],
          [12.326861991744897, 48.982355372279635],
          [12.326978772731833, 48.981205723630957],
          [12.327823189098906, 48.980002985891609],
          [12.329206594636448, 48.979118601390056],
          [12.329206594636448, 48.979118601390056],
          [12.329233544094972, 48.979106809490688],
          [12.329233544094972, 48.979106809490688],
          [12.329961179475109, 48.978823803069048],
          [12.329961179475109, 48.978823803069048],
          [12.331218820872875, 48.978540795040509],
          [12.335413953249715, 48.978110383916892],
          [12.335413953249715, 48.978110383916892],
          [12.336707527258847, 48.977980670246716],
          [12.336707527258847, 48.977980670246716],
          [12.344001847365897, 48.977237758178219],
          [12.344001847365897, 48.977237758178219],
          [12.352598724634921, 48.976347428335643],
          [12.356838772775966, 48.975751900823568],
          [12.356838772775966, 48.975751900823568],
          [12.359893044741971, 48.975179951861953],
          [12.362040018271017, 48.974501859539615],
          [12.363701901546637, 48.973741205871164],
          [12.366549560997298, 48.972101928276018],
          [12.368193477967237, 48.971441484659934],
          [12.370035024299682, 48.971046393671571],
          [12.37206521684179, 48.971064084379798],
          [12.374670331165737, 48.971742223462861],
          [12.376970018293083, 48.972998230618359],
          [12.37860495211018, 48.974655168175964],
          [12.380078189176137, 48.977220069660682],
          [12.380707009875021, 48.978010150655933],
          [12.382045499648358, 48.979030162076832],
          [12.383698399771138, 48.979755359812948],
          [12.385782491230296, 48.980197548376061],
          [12.404799825795108, 48.982243356397902],
          [12.407036630852563, 48.982225669656721],
          [12.408357154320219, 48.981948576558729],
          [12.40979445877481, 48.981412072777104],
          [12.413172124243101, 48.979313167326787],
          [12.416064699457966, 48.978063215348612],
          [12.4188854094501, 48.97709035367361],
          [12.426862449173083, 48.974885130246463],
          [12.431758267471533, 48.973293063393044],
          [12.43527068023244, 48.971907334191123],
          [12.440813285535457, 48.969283188262047],
          [12.44406518686397, 48.968204005027019],
          [12.446831997939059, 48.967626072161096],
          [12.451107978691468, 48.967048132596652],
          [12.453838857155191, 48.966977364026391],
          [12.461519452834413, 48.967325308529801],
          [12.463118454040146, 48.967136593168242],
          [12.464744404704403, 48.966611724813362],
          [12.465777467281139, 48.966027875995309],
          [12.466657816259577, 48.965208117127958],
          [12.467951390268709, 48.963244179560142],
          [12.468616143578956, 48.96174610911816],
          [12.4687778403301, 48.960855502606627],
          [12.468741907718734, 48.959776136975194],
          [12.467942407115867, 48.957381395400425],
          [12.467780710364726, 48.956484812324689],
          [12.467870541893138, 48.955588213133687],
          [12.468184952242579, 48.954709294337995],
          [12.46879580663578, 48.953812663234366],
          [12.469667172461376, 48.953039895866816],
          [12.470709218190956, 48.952461787261157],
          [12.471912960671675, 48.9520488484414],
          [12.473673658628551, 48.951789285148159],
          [12.475263676681442, 48.951848276924295],
          [12.476763863205921, 48.952166831310088],
          [12.4782640497304, 48.952833429276666],
          [12.479620505809422, 48.95401322685413],
          [12.481497984753231, 48.956927207303742],
          [12.482647828316903, 48.958401828897728],
          [12.483779705574895, 48.959262987745191],
          [12.485513454073246, 48.960071047936836],
          [12.487462798239784, 48.960448531423275],
          [12.489268411960866, 48.960419040628778],
          [12.490822497402391, 48.960100538937098],
          [12.493544392713273, 48.959080140192107],
          [12.495385939045718, 48.958101009632045],
          [12.496778327736104, 48.956809235692958],
          [12.497622744103175, 48.955257883053648],
          [12.498009019675347, 48.952573870065336],
          [12.498565975151502, 48.95106957986286],
          [12.499617004033921, 48.949883813266389],
          [12.501799910174332, 48.948467935640807],
          [12.504234344594297, 48.947488596777561],
          [12.50802523509328, 48.946745230350629],
          [12.510684248334274, 48.946603635489929],
          [12.516379567235592, 48.946810127860843],
          [12.518409759777702, 48.946550537313584],
          [12.519640451716946, 48.946161148960599],
          [12.520889109961871, 48.945523961465234],
          [12.521877256774403, 48.944709765603214],
          [12.522559976390335, 48.943759853641211],
          [12.522874386739776, 48.942839424803026],
          [12.522874386739776, 48.941806872249543],
          [12.522524043778969, 48.940809701221269],
          [12.521814374704514, 48.939865615615716],
          [12.519191294074885, 48.938012795703351],
          [12.514699717654288, 48.936419551610214],
          [12.51201375495477, 48.934838059035449],
          [12.510890860849621, 48.933817143561591],
          [12.510001528718343, 48.932707685733163],
          [12.509381691172301, 48.931515581226691],
          [12.508995415600131, 48.930099182436443],
          [12.509031348211494, 48.928305019619117],
          [12.509633219451855, 48.926552107501266],
          [12.516451432458322, 48.914988428427122],
          [12.518454675541909, 48.912296345641899],
          [12.520709446905048, 48.910046928390145],
          [12.523907449316514, 48.90751990318676],
          [12.527303081090485, 48.905299794282314],
          [12.531004140061057, 48.903321688632609],
          [12.534112310944112, 48.902075737124065],
          [12.538334392779472, 48.900806133730896],
          [12.541765957164809, 48.900215609536076],
          [12.545314302537083, 48.900014829720483],
          [12.545314302537083, 48.900014829720483],
          [12.545350235148446, 48.900014829720483],
          [12.545350235148446, 48.900014829720483],
          [12.549940626250297, 48.899943966063589],
          [12.549940626250297, 48.899943966063589],
          [12.55267150471402, 48.899902628884],
          [12.55267150471402, 48.899902628884],
          [12.553785415666329, 48.899884912939427],
          [12.553785415666329, 48.899884912939427],
          [12.555537130470363, 48.899861291670234],
          [12.555537130470363, 48.899861291670234],
          [12.556417479448799, 48.89984357571101],
          [12.556417479448799, 48.89984357571101],
          [12.561537876568281, 48.899766806481807],
          [12.561537876568281, 48.899766806481807],
          [12.566307930726955, 48.899678226455443],
          [12.569002876579313, 48.899436106915751],
          [12.569002876579313, 48.899436106915751],
          [12.575524645542021, 48.898119192296463],
          [12.580887587788215, 48.897357373828463],
          [12.580887587788215, 48.897357373828463],
          [12.583546601029209, 48.897103431759014],
          [12.585711540863937, 48.89722745014015],
          [12.587777666017411, 48.897682181571867],
          [12.589727010183951, 48.898503049916172],
          [12.589727010183951, 48.898503049916172],
          [12.591137365180019, 48.899471539116767],
          [12.592763315844275, 48.901036436275781],
          [12.594362317050008, 48.901857249535304],
          [12.594362317050008, 48.901857249535304],
          [12.595853520421645, 48.902258793557657],
          [12.59746150478022, 48.902453659346982],
          [12.599087455444478, 48.902430039291765],
          [12.600794254484304, 48.902158407854522],
          [12.603022076388919, 48.901567899636909],
          [12.605106167848078, 48.900800228523494],
          [12.611636919963626, 48.897558164318923],
          [12.613801859798354, 48.897121148689443],
          [12.615993749091606, 48.897162488169364],
          [12.615993749091606, 48.897162488169364],
          [12.617350205170625, 48.897434146758286],
          [12.618724627555329, 48.89795383733648],
          [12.61985650481332, 48.898638875752461],
          [12.620682954874709, 48.899394769316146],
          [12.621500421783258, 48.900628977205038],
          [12.623180271364561, 48.904833322691545],
          [12.624087569801523, 48.906250438095711],
          [12.624779272570295, 48.906988503119585],
          [12.625731486771461, 48.907632086922952],
          [12.62700709447491, 48.908169384483344],
          [12.629603225646017, 48.908736197625416],
          [12.632046643218821, 48.908736197625416],
          [12.634840403752433, 48.908187097491357],
          [12.637193989796826, 48.90727782163475],
          [12.64014046392874, 48.905630455051195],
          [12.642152690165167, 48.905140367772717],
          [12.643509146244186, 48.905134463077403],
          [12.645027299074348, 48.905423792327042],
          [12.649438027119377, 48.907419528051427],
          [12.650965163102379, 48.90791549734513],
          [12.652959423033124, 48.908169384483344],
          [12.655348941688882, 48.908187097491357],
          [12.659346444703214, 48.907667513313626],
          [12.665490921246592, 48.905896163012144],
          [12.668500277448391, 48.905211224062],
          [12.671428785274623, 48.904774275344273],
          [12.675893412236697, 48.904467228013779],
          [12.679944814168074, 48.903664172230201],
          [12.679944814168074, 48.903664172230201],
          [12.684077064475025, 48.90290834323401],
          [12.687149302746713, 48.901939920627164],
          [12.689619669778041, 48.90074708162539],
          [12.6950185446356, 48.897316034509728],
          [12.699025030802774, 48.895337612858668],
          [12.70321118002677, 48.89377253727838],
          [12.708924465233769, 48.892402317677494],
          [12.71267942312139, 48.890878494880255],
          [12.71267942312139, 48.890878494880255],
          [12.714583851523724, 48.889797615671981],
          [12.717656089795412, 48.887688947994711],
          [12.720234254660836, 48.886607999836123],
          [12.721824272713727, 48.886283120139765],
          [12.731966252271436, 48.885249397972871],
          [12.734212040481735, 48.8847354824291],
          [12.737544790185819, 48.883595398059235],
          [12.741775855174021, 48.881734582768146],
          [12.746653707166789, 48.878910737161284],
          [12.748773731237312, 48.877445582900876],
          [12.75093867107204, 48.875430925719165],
          [12.754882275169324, 48.870710047551611],
          [12.758897744489339, 48.867076026908954],
          [12.760011655441646, 48.86568734770173],
          [12.760739290821785, 48.86447591521982],
          [12.761242347380891, 48.863081254195926],
          [12.761943033302504, 48.85955897113687],
          [12.763461186132666, 48.855870946069189],
          [12.764188821512803, 48.853524416894842],
          [12.764637979154864, 48.850545289418349],
          [12.764637979154864, 48.846348201936955],
          [12.764817642211687, 48.844551031566084],
          [12.765563243897507, 48.841884715916549],
          [12.766560373862879, 48.840294318156083],
          [12.767728183732233, 48.839241908230484],
          [12.771429242702807, 48.836681740080749],
          [12.777636601316072, 48.833175339057235],
          [12.786673653074315, 48.829154206129985],
          [12.791353875704576, 48.827320935492139],
          [12.794237467766601, 48.826433844975597],
          [12.803274519524843, 48.824689187815174],
          [12.806975578495416, 48.824357991799886],
          [12.810353243963705, 48.824476276342324],
          [12.818743508717381, 48.825262861448273],
          [12.821788797530546, 48.825712333108562],
          [12.827277503916518, 48.827113949109545],
          [12.832865024983741, 48.8291246377809],
          [12.835380307779275, 48.83027187693002],
          [12.837123039430468, 48.831342215542946],
          [12.83865017541347, 48.832548536627321],
          [12.84285429094315, 48.837089720213015],
          [12.84541448950289, 48.839194608388752],
          [12.848558592997309, 48.841021532320376],
          [12.852430331871863, 48.842416807982026],
          [12.857002756668033, 48.843321351519926],
          [12.860380422136322, 48.843486887130965],
          [12.863057401682997, 48.84317355140486],
          [12.867333382435406, 48.842150762655898],
          [12.870405620707096, 48.841630492156312],
          [12.876065006997049, 48.841216636763207],
          [12.878652155015313, 48.841204812273148],
          [12.881679477522797, 48.841506335897449],
          [12.885182907130861, 48.842127114114078],
          [12.89336655936919, 48.844255438157781],
          [12.89336655936919, 48.844255438157781],
          [12.898064748305137, 48.844970771208111],
          [12.902816836158129, 48.84521906624169],
          [12.906302299460512, 48.844970771208111],
          [12.909958442666877, 48.844154936001523],
          [12.915240536537501, 48.842263093077086],
          [12.925876589501476, 48.837148847492742],
          [12.933233791678415, 48.834807353875703],
          [12.935991619600662, 48.83377256803908],
          [12.943663232127044, 48.830171346714415],
          [12.943663232127044, 48.830171346714415],
          [12.943860861489549, 48.830088556973635],
          [12.943860861489549, 48.830088556973635],
          [12.94853210096697, 48.828391337141639],
          [12.94853210096697, 48.828391337141639],
          [12.959779008324148, 48.825120922191687],
          [12.959779008324148, 48.825120922191687],
          [12.961881066088985, 48.824292935182541],
          [12.964117871146444, 48.823009528283649],
          [12.96603128270162, 48.821436274991107],
          [12.967351806169274, 48.819862972313807],
          [12.968079441549412, 48.818609026544948],
          [12.968573514955677, 48.817343219284254],
          [12.970406078135282, 48.809836443755685],
          [12.970406078135282, 48.809836443755685],
          [12.970756421096086, 48.808493505661453],
          [12.971286427113718, 48.807440427723279],
          [12.97221169185636, 48.806339996810742],
          [12.97325373758594, 48.805517616007712],
          [12.983467582366378, 48.80028127806348],
          [12.988165771302324, 48.797689532295003],
          [13.003230518617007, 48.788457569097027],
          [13.004569008390346, 48.787445500587545],
          [13.005629020425607, 48.786190744036752],
          [13.00632072319438, 48.784640010991012],
          [13.006527335709727, 48.779472527932292],
          [13.006985476504628, 48.777246735569733],
          [13.008027522234206, 48.774872842937413],
          [13.009491776147321, 48.772676448284145],
          [13.011917227414443, 48.770154324050615],
          [13.016741180490166, 48.766382742297679],
          [13.023182101077303, 48.759235498844902],
          [13.024709237060305, 48.75808071204932],
          [13.029883533096834, 48.754882702372008],
          [13.032470681115097, 48.752803886908112],
          [13.034330193753226, 48.750796060402628],
          [13.036674796644778, 48.747443581284834],
          [13.03714192059252, 48.746217442073075],
          [13.037528196164692, 48.744256741842541],
          [13.037618027693103, 48.74300090573707],
          [13.03743836463628, 48.741750962228046],
          [13.036558015657842, 48.739713072983328],
          [13.035156643814615, 48.737544762892384],
          [13.033845103499802, 48.736004376497704],
          [13.031482534302567, 48.734090676888499],
          [13.028895386284303, 48.732644985539984],
          [13.026865193742191, 48.731821396882275],
          [13.02016376172266, 48.729640895585419],
          [13.018465945835676, 48.728894289171208],
          [13.016669315267436, 48.727738805009793],
          [13.015160145590114, 48.72623367300308],
          [13.01436962814009, 48.724621828147093],
          [13.014288779764518, 48.722974374507068],
          [13.014917600463402, 48.721380204323886],
          [13.016256090236741, 48.719785983612375],
          [13.017441866411779, 48.718802164207041],
          [13.0186456088925, 48.718079103942806],
          [13.021475302037475, 48.717024128519448],
          [13.024826018047241, 48.716561828946418],
          [13.028913352589985, 48.71671000234911],
          [13.034788334548127, 48.717812398762497],
          [13.036629880880572, 48.718381368104403],
          [13.038327696767558, 48.719128130599366],
          [13.040923827938663, 48.720900165485844],
          [13.045361505442212, 48.725421831132124],
          [13.046520332158728, 48.726144785845904],
          [13.048101367058779, 48.726725512432942],
          [13.050526818325901, 48.727033650347728],
          [13.052961252745863, 48.726755141160645],
          [13.055386704012989, 48.725801087359926],
          [13.057551643847715, 48.724432195944132],
          [13.057551643847715, 48.724432195944132],
          [13.06021065708871, 48.722144701163288],
          [13.063597305709839, 48.718239126372247],
          [13.065663430863314, 48.716911517476376],
          [13.067864303309406, 48.716218064971372],
          [13.070262805118006, 48.715986912012148],
          [13.072418761799893, 48.716182503046774],
          [13.076470163731273, 48.717219715521402],
          [13.0783296763694, 48.717480497007784],
          [13.080764110789364, 48.717450862816051],
          [13.083360241960468, 48.716941151985843],
          [13.084995175777566, 48.716301042697665],
          [13.086405530773634, 48.715459405127689],
          [13.088938779874852, 48.71320113897626],
          [13.095083256418228, 48.708204142576513],
          [13.096727173388167, 48.706230103350237],
          [13.09749972453251, 48.704516835370711],
          [13.097616505519445, 48.702720509078262],
          [13.097023617431928, 48.700740325939037],
          [13.095828858104049, 48.698635554861013],
          [13.094122059064222, 48.696696716024903],
          [13.092648821998266, 48.695463409651666],
          [13.088444706468586, 48.692534185922881],
          [13.086692991664552, 48.690826381705982],
          [13.085947389978733, 48.689675953157924],
          [13.085632979629292, 48.688715265881335],
          [13.085641962782132, 48.687683396169049],
          [13.085983322590097, 48.686746392710702],
          [13.086800789498646, 48.685684829753484],
          [13.088193178189032, 48.684706274246238],
          [13.089747263630558, 48.684101339513496],
          [13.091481012128909, 48.683787007813109],
          [13.09891906268142, 48.684113201048689],
          [13.104677263652624, 48.685465397748082],
          [13.106455927915183, 48.685678899171315],
          [13.107803400841362, 48.685625523900356],
          [13.109321553671524, 48.685346785455188],
          [13.110516312999403, 48.684925709559231],
          [13.111720055480124, 48.684249608502554],
          [13.112537522388671, 48.683531982539208],
          [13.113157359934714, 48.682677935009409],
          [13.113624483882457, 48.681568837783232],
          [13.113920927926216, 48.680234330139896],
          [13.11402872576031, 48.677986346215107],
          [13.113471770284155, 48.67306297031088],
          [13.113615500729614, 48.671277386189566],
          [13.113965843690421, 48.669729037770409],
          [13.114729411681923, 48.667510248669679],
          [13.115250434546711, 48.666477944505317],
          [13.116238581359243, 48.6652023670486],
          [13.117397408075758, 48.664205614286026],
          [13.11951743214628, 48.663084243864461],
          [13.122257293762845, 48.66237224969489],
          [13.125599026619769, 48.662063715764525],
          [13.132839447809772, 48.662093382570667],
          [13.137789165025271, 48.661772980140242],
          [13.144553479114691, 48.660888896204092],
          [13.146781301019308, 48.660343011131133],
          [13.1489821734654, 48.65957164170991],
          [13.153410867816108, 48.657500753111876],
          [13.167280855802915, 48.649340980177826],
          [13.16941884617912, 48.647791957786751],
          [13.175374676512831, 48.642699433912959],
          [13.179048786024881, 48.640052068027423],
          [13.179048786024881, 48.640052068027423],
          [13.182174923213617, 48.638300931283993],
          [13.186675482787054, 48.636330087734763],
          [13.186675482787054, 48.636330087734763],
          [13.188436180743929, 48.635647398069011],
          [13.188436180743929, 48.635647398069011],
          [13.20278227583132, 48.63055364793027],
          [13.206977408208157, 48.628784369633301],
          [13.211145591126471, 48.626486590123314],
          [13.21804465250851, 48.621742264741371],
          [13.225222191628625, 48.618351491130397],
          [13.234708401028927, 48.613505436831161],
          [13.238184881178469, 48.61227603291043],
          [13.243799351704217, 48.610868417751028],
          [13.249916878789069, 48.608932140381363],
          [13.2533035274102, 48.608088708006171],
          [13.259474953412102, 48.607203682832591],
          [13.26262804005936, 48.607049247320276],
          [13.26570027833105, 48.60711458547928],
          [13.268529971476026, 48.607702625106178],
          [13.274512751268263, 48.610179444128818],
          [13.277288545496191, 48.611016902214146],
          [13.280998587619605, 48.611551442665949],
          [13.285436265123156, 48.61151580681188],
          [13.288966644189745, 48.611016902214146],
          [13.295335699554153, 48.609484521556148],
          [13.302719851189615, 48.608076828576337],
          [13.305199201373785, 48.607346238270914],
          [13.308774496204581, 48.60584344611992],
          [13.310517227855772, 48.605302905201604],
          [13.322428888523197, 48.60308722097939],
          [13.332265440884306, 48.602190227340564],
          [13.339712474589657, 48.60095460736899],
          [13.342308605760763, 48.600853617862057],
          [13.347716463771162, 48.60099619122493],
          [13.350537173763298, 48.600806093318347],
          [13.364748521558068, 48.599041712988708],
          [13.370551638293481, 48.59809712041357],
          [13.370551638293481, 48.59809712041357],
          [13.377953756234625, 48.597039631041],
          [13.383146018576836, 48.595399884645204],
          [13.384879767075187, 48.59468693482912],
          [13.386649448184903, 48.593682846776076],
          [13.39145543495494, 48.590266421407556],
          [13.396521933157375, 48.587283544645565],
          [13.399477390442128, 48.585316651094246],
          [13.409197161816302, 48.579831525443304],
          [13.409197161816302, 48.579831525443304],
          [13.40926004388619, 48.579795867207892],
          [13.40926004388619, 48.579795867207892],
          [13.411416000568078, 48.578577529052858],
          [13.411416000568078, 48.578577529052858],
          [13.412691608271528, 48.577864341873521],
          [13.412691608271528, 48.577864341873521],
          [13.412817372411304, 48.577793022602201],
          [13.412817372411304, 48.577793022602201],
          [13.414784682883525, 48.576610296683192],
          [13.414784682883525, 48.576610296683192],
          [13.419249309845599, 48.574862902225199],
          [13.420551867007573, 48.574565720287829],
          [13.422114935601941, 48.574446847023765],
          [13.423884616711657, 48.574601382212542],
          [13.428609755106125, 48.575665284730604],
          [13.430469267744252, 48.575908968748237],
          [13.433424725029006, 48.575784155129838],
          [13.437476126960384, 48.574690536914289],
          [13.439703948865002, 48.574488452697977],
          [13.443458906752619, 48.574987718117981],
          [13.44886676476302, 48.576057555987418],
          [13.455478365254139, 48.57636661604603],
          [13.455478365254139, 48.57636661604603],
          [13.460014857438944, 48.576937183498131],
          [13.462296578260606, 48.576996617237405],
          [13.471576175145561, 48.575617736492646],
          [13.471576175145561, 48.575617736492646],
          [13.471576175145561, 48.575617736492646],
          [13.471576175145561, 48.575617736492646],
          [13.475573678159893, 48.574642987759589],
          [13.481430693812353, 48.574405241315304],
          [13.481430693812353, 48.574405241315304],
          [13.488868744364863, 48.574203155958656],
          [13.49058452655753, 48.57443495968198],
          [13.493063876741701, 48.575053097748366],
          [13.495722889982694, 48.576039725541769],
          [13.497815964594693, 48.577180861385074],
          [13.499486831023155, 48.578589415420602],
          [13.500645657739669, 48.580211878389264],
          [13.500645657739669, 48.580211878389264],
          [13.500744472420923, 48.580413939727727],
          [13.500744472420923, 48.580413939727727],
          [13.501040916464682, 48.580978518597909],
          [13.501040916464682, 48.580978518597909],
          [13.502756698657349, 48.584169756381606],
          [13.504382649321606, 48.586309020852823],
          [13.506691319601794, 48.588525441025162],
          [13.509071855104709, 48.590117875933984],
          [13.511111030799661, 48.590747705742459],
          [13.51335681900996, 48.590896249364505],
          [13.516653636102678, 48.590444675399425],
          [13.520058251029491, 48.589642527484038],
          [13.523256253440957, 48.588531383039729],
          [13.526526121075154, 48.586932955933136],
          [13.536650134327179, 48.58096068988791],
          [13.540962047690954, 48.577894058206887],
          [13.546944827483189, 48.572633995115375],
          [13.553295916541915, 48.568912987253519],
          [13.555505772140847, 48.567878664869482],
          [13.560284809452364, 48.5660774667734],
          [13.570741199359516, 48.560810229032569],
          [13.572546813080596, 48.560191916846158],
          [13.574810567596577, 48.559751959342101],
          [13.576831776985847, 48.559621160427312],
          [13.578852986375114, 48.559728177746386],
          [13.580676566401877, 48.560049228345193],
          [13.58205098878658, 48.560578362848368],
          [13.583236764961619, 48.561458259654586],
          [13.584763900944623, 48.563622265063032],
          [13.586039508648073, 48.564888521991143],
          [13.589372258352155, 48.567373384748905],
          [13.591761777007912, 48.568550382389219],
          [13.593747053785815, 48.569109149456985],
          [13.596136572441575, 48.569406363466861],
          [13.59829252912346, 48.569287478072539],
          [13.600340687971254, 48.568811933700204],
          [13.602676307709965, 48.567914331510757],
          [13.608021283650475, 48.565156037004108],
          [13.610976740935229, 48.563301237142397],
          [13.615495266814351, 48.559960047827701],
          [13.620148539986088, 48.556999165385861],
          [13.622250597750929, 48.555869465769177],
          [13.624460453349862, 48.554971633872434],
          [13.628646602573859, 48.55387756552085],
          [13.637180597772996, 48.552902397701224],
          [13.643846097181163, 48.55168935784156],
          [13.650044472641587, 48.550886594885114],
          [13.652281277699045, 48.550190856687649],
          [13.653583834861019, 48.549382123341694],
          [13.654616897437755, 48.548234413392301],
          [13.656368612241788, 48.545296633074202],
          [13.658524568923674, 48.540169976404826],
          [13.659557631500412, 48.538504593366234],
          [13.661381211527175, 48.536619075726392],
          [13.663734797571568, 48.535007110643946],
          [13.667148395651223, 48.533520012471612],
          [13.684674526844395, 48.528070911552874],
          [13.694259550925949, 48.5248404362437],
          [13.700314195940916, 48.522555776827232],
          [13.708156488371278, 48.519241647587265],
          [13.708156488371278, 48.519241647587265],
          [13.708461915567879, 48.519116694128314],
          [13.708461915567879, 48.519116694128314],
          [13.709503961297457, 48.518700180372427],
          [13.709503961297457, 48.518700180372427],
          [13.710662788013972, 48.518247961559936],
          [13.710662788013972, 48.518247961559936],
          [13.716546753124954, 48.516123669171414],
          [13.724676506446237, 48.51383266578533],
          [13.727003143032107, 48.512916235423518],
          [13.727003143032107, 48.512916235423518],
          [13.727003143032107, 48.512916235423518],
          [13.727057041949154, 48.512975744391802],
          [13.730659286238472, 48.510648891672027],
          [13.73425254737495, 48.507220903756618],
          [13.736139009471602, 48.504983064329018],
          [13.739004635227943, 48.499941611900113],
          [13.74175347999735, 48.496173587457626],
          [13.746254039570788, 48.491280095070813],
          [13.749128648479971, 48.488803403116144],
          [13.757698576290471, 48.483706752584936],
          [13.763654406624184, 48.481092734282598],
          [13.766295453559495, 48.479741013664729],
          [13.772188401823318, 48.476066771650565],
          [13.773230447552898, 48.475221122366037],
          [13.774092830225651, 48.474238484013604],
          [13.776401500505838, 48.470593622827352],
          [13.777254900025753, 48.469765750221669],
          [13.77859338979909, 48.46886043522133],
          [13.780399003520172, 48.468139745286905],
          [13.786112288727171, 48.466668553071344],
          [13.788088582352234, 48.46640647386927],
          [13.795445784529173, 48.465906137088979],
          [13.795445784529173, 48.465906137088979],
          [13.798185646145736, 48.465644053949759],
          [13.801716025212327, 48.464726752304536],
          [13.803350959029425, 48.464083439961605],
          [13.810070357354638, 48.46084888490055],
          [13.818182144370237, 48.455642188428556],
          [13.828027679884187, 48.447169740102964],
          [13.837271344157777, 48.440739984028099],
          [13.845922120343849, 48.435239185289184],
          [13.852300158861096, 48.431418670157541],
          [13.855201717228804, 48.429898728570912],
          [13.861768401955718, 48.427371352503705],
          [13.865128101118323, 48.426411631967191],
          [13.867319990411575, 48.426006278719107],
          [13.869359166106527, 48.425898978788744],
          [13.871056981993513, 48.42607781188017],
          [13.872682932657769, 48.426578541188462],
          [13.873814809915761, 48.42720444588619],
          [13.874677192588514, 48.428110503792219],
          [13.874910754562386, 48.429070192246883],
          [13.87465024312999, 48.430310011369166],
          [13.874066338195313, 48.431478274783551],
          [13.872952427243005, 48.43276571764023],
          [13.871281560814543, 48.433886243204718],
          [13.868523732892296, 48.434953103622931],
          [13.86382554395635, 48.43621661882807],
          [13.860519743710791, 48.437354947600163],
          [13.858974641422105, 48.438159510143237],
          [13.857276825535118, 48.439500419399522],
          [13.854096789429336, 48.442533723514998],
          [13.853054743699758, 48.444392948086296],
          [13.853027794241234, 48.44566218730921],
          [13.853378137202039, 48.446883725621319],
          [13.853881193761149, 48.447819225339678],
          [13.854671711211173, 48.448564037634213],
          [13.856135965124286, 48.449404172791887],
          [13.857618185343084, 48.449994046618365],
          [13.859837024094858, 48.450393250269727],
          [13.86275654876825, 48.450405166748389],
          [13.864319617362616, 48.45005362945215],
          [13.866152180542221, 48.449314797371947],
          [13.871425291260001, 48.44642490735913],
          [13.874883805103863, 48.445334452519411],
          [13.877569767803378, 48.444887538034166],
          [13.880085050598915, 48.44470281223159],
          [13.885977998862739, 48.444947126859411],
          [13.888879557230444, 48.445548970075478],
          [13.892409936297033, 48.447056526229922],
          [13.900234262421714, 48.451775543142858],
          [13.903980237156494, 48.454248085244274],
          [13.906504503104868, 48.454790241031304],
          [13.907537565681606, 48.45473066375542],
          [13.908606560869709, 48.454450649622352],
          [13.918416163772294, 48.449314797371947],
          [13.919089900235385, 48.448802375261373],
          [13.919745670392791, 48.448015856846709],
          [13.920365507938833, 48.446675172379635],
          [13.920527204689975, 48.445507258399431],
          [13.920365507938833, 48.444035410184945],
          [13.91943126004335, 48.439941421820272],
          [13.91943126004335, 48.439071332288414],
          [13.919637872558697, 48.438242945529602],
          [13.921254840070111, 48.435757704203461],
          [13.925440989294108, 48.430876264847761],
          [13.926006927923103, 48.429690105589152],
          [13.926168624674245, 48.427293860213744],
          [13.925387090377061, 48.421064285775735],
          [13.925746416490711, 48.418965730894627],
          [13.927201687250982, 48.416723997030118],
          [13.931522583767597, 48.412264083600896],
          [13.934307361148367, 48.408417988514572],
          [13.937927571743369, 48.405114288564782],
          [13.93914029737693, 48.404225711703752],
          [13.940424888233222, 48.403683016186399],
          [13.941664563325308, 48.403390793124771],
          [13.943838486312877, 48.403408684380878],
          [13.945338672837357, 48.403694943622639],
          [13.94694665719593, 48.404237639012727],
          [13.948114467065285, 48.404750510652818],
          [13.949111597030658, 48.405400538207267],
          [13.950566867790933, 48.406956992441607],
          [13.951474166227891, 48.408549177945019],
          [13.952543161415994, 48.413408911535285],
          [13.953207914726244, 48.415269201953123],
          [13.954178095233091, 48.41656301765601],
          [13.955247090421196, 48.41719500782677],
          [13.956405917137708, 48.417546772537271],
          [13.957717457452523, 48.417677938416496],
          [13.964023630747041, 48.417004219169122],
          [13.967203666852825, 48.416920748906264],
          [13.971291001395569, 48.417135386448166],
          [13.97302474989392, 48.417636203855267],
          [13.977094118130982, 48.419812317436552],
          [13.979986693345845, 48.421058324094808],
          [13.982933167477759, 48.421731989615331],
          [13.984559118142014, 48.421845259932262],
          [13.986167102500588, 48.421690258382313],
          [13.987622373260862, 48.421219289234486],
          [13.996578576643532, 48.417111537877147],
          [13.998474021893026, 48.416044302872045],
          [13.99948013501124, 48.415132067483356],
          [14.001510327553349, 48.412615882428312],
          [14.003064412994878, 48.408954670408541],
          [14.004420869073897, 48.406521667128288],
          [14.006522926838736, 48.403844036339649],
          [14.014374202421941, 48.395142253878596],
          [14.015416248151521, 48.393340876500801],
          [14.016350496047004, 48.390805717525097],
          [14.016862535758952, 48.389839341325413],
          [14.019620363681199, 48.386683577654161],
          [14.019620363681199, 48.386683577654161],
          [14.021255297498296, 48.384875934892044],
          [14.021255297498296, 48.384875934892044],
          [14.021255297498296, 48.384875934892044],
          [14.021255297498296, 48.384875934892044],
          [14.021425977402281, 48.384696956868119],
          [14.021425977402281, 48.384696956868119],
          [14.023833462363719, 48.382328421745306],
          [14.026249930478002, 48.380753314702737],
          [14.028684364897966, 48.378885795098306],
          [14.029591663334926, 48.377656655355231],
          [14.030031837824145, 48.376701963233877],
          [14.030516928077569, 48.374971538132698],
          [14.03059777645314, 48.373211217772266],
          [14.029959972601414, 48.370507963277703],
          [14.029223354068437, 48.36876539203903],
          [14.027256043596216, 48.365453148766008],
          [14.025854671752988, 48.362540583906501],
          [14.025854671752988, 48.362540583906501],
          [14.023797529752356, 48.358362433678685],
          [14.022872265009712, 48.355610636153003],
          [14.02202784864264, 48.351975170335201],
          [14.021596657306262, 48.346667760276588],
          [14.021758354057404, 48.344834814227653],
          [14.022225478005145, 48.343007773089134],
          [14.023060911219378, 48.34133591335933],
          [14.024192788477368, 48.33969385489231],
          [14.026429593534825, 48.33748445640385],
          [14.033912559851542, 48.33166193420243],
          [14.037146494874371, 48.33012109399769],
          [14.040784671775054, 48.328986336946045],
          [14.048456284301436, 48.327093028182723],
          [14.055984166382357, 48.324835299986567],
          [14.059451663379059, 48.32335398486331],
          [14.064733757249682, 48.320439013359724],
          [14.066790899250314, 48.319626614571504],
          [14.070465008762364, 48.318497597713552],
          [14.074552343305108, 48.31766724661837],
          [14.083769058120174, 48.316711430064601],
          [14.095276476909746, 48.315020785687253],
          [14.099211097854189, 48.313957383491989],
          [14.108302048529477, 48.310522084515917],
          [14.110898179700584, 48.309823047536931],
          [14.113664990775671, 48.30938689140828],
          [14.116368919780871, 48.309231547229295],
          [14.119162680314483, 48.309315194153662],
          [14.123034419189038, 48.309829022252543],
          [14.126816326535181, 48.310695348611027],
          [14.133095550371177, 48.312619137998816],
          [14.136706777813338, 48.314017125775941],
          [14.141782259168613, 48.316317150519296],
          [14.148160297685861, 48.318563308374216],
          [14.148160297685861, 48.318563308374216],
          [14.148160297685861, 48.318563308374216],
          [14.148160297685861, 48.318563308374216],
          [14.149346073860899, 48.318993412426657],
          [14.149346073860899, 48.318993412426657],
          [14.157610574474798, 48.321783027064569],
          [14.167141699639307, 48.325611778631135],
          [14.178496404830577, 48.329929978788819],
          [14.183616801950059, 48.33143499166966],
          [14.185530213505233, 48.331697767141591],
          [14.186868703278572, 48.331673878518281],
          [14.189617548047979, 48.331208048126868],
          [14.190920105209951, 48.330801936108394],
          [14.191935201481005, 48.330228595987919],
          [14.192995213516266, 48.329416353115882],
          [14.195959653953862, 48.326185170657688],
          [14.197774250827782, 48.324560543173156],
          [14.199759527605687, 48.323270360952542],
          [14.20631722917976, 48.319692323777922],
          [14.207188595005354, 48.318999386068519],
          [14.209892524010554, 48.316125983582346],
          [14.215354280938001, 48.312756548751196],
          [14.217788715357965, 48.311466067974294],
          [14.219522463856316, 48.310755094714324],
          [14.228784094435589, 48.307875252964109],
          [14.231002933187364, 48.307588454846247],
          [14.233778727415293, 48.307731854106642],
          [14.238970989757503, 48.308329346687906],
          [14.248026007821428, 48.30970355307791],
          [14.251933679307347, 48.309799148667516],
          [14.255931182321682, 48.309333118476765],
          [14.260988697371273, 48.30789317779297],
          [14.264689756341847, 48.307020828819773],
          [14.269513709417568, 48.305365714785665],
          [14.272226621575609, 48.304977322850753],
          [14.273636976571677, 48.305060976747995],
          [14.275505472362644, 48.305413516665695],
          [14.284838968164646, 48.308245698147644],
          [14.284838968164646, 48.308245698147644],
          [14.287201537361881, 48.309147900167822],
          [14.289285628821039, 48.310312971893538],
          [14.289285628821039, 48.310312971893538],
          [14.295825364089428, 48.316974281402011],
          [14.295825364089428, 48.316974281402011],
          [14.296984190805942, 48.317924119264234],
          [14.296984190805942, 48.317924119264234],
          [14.298870652902593, 48.319041201541978],
          [14.298870652902593, 48.319041201541978],
          [14.299220995863401, 48.319292093662952],
          [14.299220995863401, 48.319292093662952],
          [14.299517439907159, 48.319507143070268],
          [14.299517439907159, 48.319507143070268],
          [14.301367969392446, 48.320713792374725],
          [14.302984936903862, 48.32147241371019],
          [14.306560231734656, 48.322242970288414],
          [14.309578571089299, 48.322535659271551],
          [14.312120803343356, 48.322320622631288],
          [14.315282873143456, 48.321567987251449],
          [14.317690358104898, 48.320516668448782],
          [14.320681748001014, 48.318724597818822],
          [14.320681748001014, 48.318724597818822],
          [14.324382806971588, 48.316108061645281],
          [14.330293721541093, 48.31106577332379],
          [14.330293721541093, 48.31106577332379],
          [14.332961717934928, 48.30849664335711],
          [14.334255291944061, 48.306477096916417],
          [14.338648053683405, 48.298045523604763],
          [14.342322163195455, 48.28937347370556],
          [14.342322163195455, 48.28937347370556],
          [14.343310310007986, 48.287042347676142],
          [14.343310310007986, 48.287042347676142],
          [14.343507939370493, 48.286576109701478],
          [14.343507939370493, 48.286576109701478],
          [14.344774563921103, 48.284292677641901],
          [14.346741874393322, 48.281913495858291],
          [14.346741874393322, 48.281913495858291],
          [14.349122409896239, 48.279689637813831],
          [14.350972939381526, 48.278344515396569],
          [14.355033324465747, 48.275988971273428],
          [14.35940811989941, 48.273968138751819],
          [14.363522403900676, 48.272455451453176],
          [14.366648541089413, 48.271624351228539],
          [14.370062139169066, 48.271056325313033],
          [14.374805243869217, 48.270560044871338],
          [14.374805243869217, 48.270560044871338],
          [14.377356459276115, 48.270087677082429],
          [14.379808860001761, 48.269423963064689],
          [14.383635683112111, 48.267994855872409],
          [14.386231814283217, 48.266481991744072],
          [14.392843414774335, 48.2618952880782],
          [14.396876850400034, 48.259586830409987],
          [14.399760442462057, 48.258336870708284],
          [14.402751832358174, 48.257332096255212],
          [14.406650520691255, 48.256422997591287],
          [14.413360935863627, 48.255717238798567],
          [14.418337602537649, 48.2549337155072],
          [14.425748703631633, 48.252888124826491],
          [14.4316506350483, 48.250483553954794],
          [14.4316506350483, 48.250483553954794],
          [14.4316506350483, 48.250483553954794],
          [14.4316506350483, 48.250483553954794],
          [14.432584882943782, 48.250070817734162],
          [14.432584882943782, 48.250070817734162],
          [14.441334473811107, 48.246266309619465],
          [14.44308618861514, 48.245279244767175],
          [14.444748071890762, 48.244010988025821],
          [14.445817067078865, 48.24297003682554],
          [14.450137963595479, 48.238393189717932],
          [14.451952560469401, 48.237064929976846],
          [14.455078697658138, 48.235640901501078],
          [14.457962289720159, 48.235048541849174],
          [14.460019431720795, 48.234881004541883],
          [14.462040641110063, 48.235114359926946],
          [14.46661306590623, 48.236269154243871],
          [14.477401832468505, 48.23860259689048],
          [14.480545935962928, 48.239506029437322],
          [14.486528715755162, 48.242030769573184],
          [14.48997824644618, 48.243149512681782],
          [14.493751170639484, 48.244088759391438],
          [14.497874437793593, 48.244627173294923],
          [14.501350917943133, 48.244459667354512],
          [14.505429269333037, 48.243693918926809],
          [14.508294895089378, 48.242694839289555],
          [14.513585972112841, 48.239918850919395],
          [14.515364636375399, 48.239153034513421],
          [14.518032632769234, 48.238387206643267],
          [14.521500129765935, 48.237884625873178],
          [14.521500129765935, 48.237884625873178],
          [14.523539305460886, 48.237782912497565],
          [14.528300376466719, 48.238070102684574],
          [14.531399564196931, 48.237896592139364],
          [14.531399564196931, 48.237896592139364],
          [14.535325201988535, 48.237477771157423],
          [14.54334715747572, 48.235521233437318],
          [14.550875039556642, 48.234055276941284],
          [14.56160092404903, 48.230291465680821],
          [14.580779955364982, 48.224989347757521],
          [14.58310659195085, 48.224115582606622],
          [14.586322560667998, 48.222403917233322],
          [14.588271904834539, 48.221147063557488],
          [14.589915821804476, 48.219704636309785],
          [14.59308687475742, 48.215664426646057],
          [14.594622993893264, 48.213216203738519],
          [14.59646454022571, 48.210827726778511],
          [14.602564101004882, 48.204314221010442],
          [14.606480755643641, 48.200751797153302],
          [14.610711820631844, 48.196440633625933],
          [14.613891856737625, 48.194386715640533],
          [14.620260912102033, 48.191811716724764],
          [14.625525039666973, 48.188829346962706],
          [14.629118300803453, 48.186278025450314],
          [14.634032085407586, 48.181636211074881],
          [14.636960593233816, 48.179216311481191],
          [14.642637945829451, 48.175556295816584],
          [14.646006628144901, 48.17357941927677],
          [14.651109058958699, 48.171069275299828],
          [14.656606748497511, 48.16887055182319],
          [14.662122404342004, 48.16707915156433],
          [14.666191772579065, 48.166210389778108],
          [14.672228451288349, 48.165605243562979],
          [14.676962572835659, 48.165587268813738],
          [14.67907361375334, 48.165838914729875],
          [14.685047410392734, 48.167438634895525],
          [14.692728006071956, 48.169122181630087],
          [14.692728006071956, 48.169122181630087],
          [14.692728006071956, 48.169122181630087],
          [14.692728006071956, 48.169122181630087],
          [14.693859883329946, 48.169373810202345],
          [14.693859883329946, 48.169373810202345],
          [14.697453144466424, 48.170200581104041],
          [14.70064216372505, 48.170464186415195],
          [14.707954450137784, 48.172141643011209],
          [14.707954450137784, 48.172141643011209],
          [14.716227933904523, 48.174004753694767],
          [14.720081706473396, 48.174406122374727],
          [14.724510400824105, 48.174531923851589],
          [14.73197540083514, 48.174232396018304],
          [14.738658866548988, 48.174202443138732],
          [14.747929480281099, 48.174735601778771],
          [14.754289552492667, 48.175466439445536],
          [14.759715376808748, 48.17644287023537],
          [14.763335587403752, 48.177359380376622],
          [14.768330220383454, 48.178844930640992],
          [14.774097404507504, 48.180929420528066],
          [14.776325226412117, 48.18151042702938],
          [14.781014432195224, 48.181989602693896],
          [14.789593343158565, 48.182127364868435],
          [14.792530834137636, 48.182564607578769],
          [14.79872920959806, 48.18449322269678],
          [14.80744286785402, 48.187787273237987],
          [14.811215792047321, 48.188907202194912],
          [14.814072434650823, 48.189548006151526],
          [14.817288403367968, 48.189949253147724],
          [14.820261826958404, 48.190075016484926],
          [14.827780725886484, 48.189667781703207],
          [14.830098379319512, 48.189673770473441],
          [14.83315265128552, 48.190170835963265],
          [14.836188956945843, 48.191087100676867],
          [14.837886772832828, 48.191841659316204],
          [14.842566995463091, 48.194326833414493],
          [14.845234991856927, 48.196051413508592],
          [14.84598957669559, 48.197033440123406],
          [14.847426881150181, 48.200584147573828],
          [14.848603674172375, 48.202470173709102],
          [14.850813529771312, 48.204906936401336],
          [14.854568487658929, 48.208409205518421],
          [14.856131556253297, 48.210396711551596],
          [14.856131556253297, 48.210396711551596],
          [14.856571730742516, 48.211091123186861],
          [14.856571730742516, 48.211091123186861],
          [14.857173601982876, 48.212126736838719],
          [14.85757784386073, 48.213359867973445],
          [14.856643595965247, 48.219249754557431],
          [14.856661562270929, 48.221913149470367],
          [14.857056820995941, 48.224079674130643],
          [14.857452079720952, 48.224869654782928],
          [14.858044967808471, 48.225581823859841],
          [14.859464305957379, 48.226509424184748],
          [14.861359751206873, 48.227030069904046],
          [14.868761869148019, 48.227520788606981],
          [14.871295118249236, 48.227945675390544],
          [14.872579709105526, 48.228406464112304],
          [14.874627867953317, 48.22962723495538],
          [14.877798920906262, 48.231117254019914],
          [14.877798920906262, 48.231117254019914],
          [14.880296237396111, 48.230919784107648],
          [14.881913204907528, 48.230590665893843],
          [14.886782073747455, 48.228508196128146],
          [14.888902097817978, 48.227903785301024],
          [14.891516195294765, 48.227610553714307],
          [14.893860798186317, 48.227879848091611],
          [14.893860798186317, 48.227879848091611],
          [14.895855058117062, 48.227909769601624],
          [14.897651688685302, 48.228370558646674],
          [14.898630852344994, 48.228813390962287],
          [14.90094850577802, 48.230315401762233],
          [14.902799035263307, 48.230979623555477],
          [14.904739396277005, 48.231314723170158],
          [14.90839553948337, 48.231524159315001],
          [14.90839553948337, 48.231524159315001],
          [14.913605768131264, 48.232655099685111],
          [14.915456297616551, 48.232798709372148],
          [14.920882121932632, 48.232188365418672],
          [14.934734143613756, 48.231422433294561],
          [14.937671634592828, 48.23093175200281],
          [14.939360467326972, 48.230327369798744],
          [14.941965581650917, 48.228729612169438],
          [14.943771195371999, 48.227395117192223],
          [14.945945118359568, 48.224983363115442],
          [14.952439937863751, 48.2159098410688],
          [14.956976430048556, 48.207523183996074],
          [14.958332886127574, 48.205810963839099],
          [14.960012735708878, 48.204164544321969],
          [14.9612164781896, 48.203284436546696],
          [14.96309395713341, 48.202386351799845],
          [14.969921153292717, 48.200530260092485],
          [14.976272242351444, 48.198266934688434],
          [14.97987448664076, 48.197272955954624],
          [14.988588144896722, 48.195907700717832],
          [15.001829312184643, 48.194596302880569],
          [15.011171791139486, 48.193338766580794],
          [15.015519637114625, 48.19311719746338],
          [15.020720882609675, 48.193123185830494],
          [15.023191249641005, 48.193362719941504],
          [15.033584757478268, 48.195039427365607],
          [15.041507898284204, 48.196596320844975],
          [15.043349444616648, 48.19681787491794],
          [15.051982254497037, 48.19678194729552],
          [15.060372519250713, 48.195919676799129],
          [15.064873078824149, 48.195171166338341],
          [15.067981249707204, 48.194003468184526],
          [15.071978752721535, 48.191434438573495],
          [15.071978752721535, 48.191434438573495],
          [15.073829282206823, 48.190069027761581],
          [15.073829282206823, 48.190069027761581],
          [15.073829282206823, 48.190069027761581],
          [15.073829282206823, 48.190069027761581],
          [15.074278439848882, 48.189739646899838],
          [15.074278439848882, 48.189739646899838],
          [15.075589980163697, 48.188787424865609],
          [15.075589980163697, 48.188787424865609],
          [15.077072200382492, 48.187751339283345],
          [15.079084426618923, 48.185948620222987],
          [15.085175004245251, 48.181127083272742],
          [15.091750672125007, 48.174741592293792],
          [15.094607314728506, 48.173040257901512],
          [15.097589721471783, 48.172099707265069],
          [15.097589721471783, 48.172099707265069],
          [15.100706875507678, 48.171356839791649],
          [15.10379708008505, 48.1708835557085],
          [15.106743554216964, 48.170781709195431],
          [15.109025275038626, 48.170997383924906],
          [15.111432760000067, 48.171530575890678],
          [15.113669565057522, 48.172411229131818],
          [15.115816538586568, 48.173777110360597],
          [15.11724485988832, 48.175172907536059],
          [15.118286905617898, 48.176892141959634],
          [15.118834877941211, 48.178383695852084],
          [15.119050473609398, 48.179647589080673],
          [15.118816911635529, 48.181246878504631],
          [15.118044360491185, 48.182708357792158],
          [15.113615666140475, 48.18822446766805],
          [15.111998698629062, 48.190787669566049],
          [15.111351911624494, 48.192548299395497],
          [15.110453596340376, 48.196266981938898],
          [15.110229017519345, 48.198117240329445],
          [15.110354781659122, 48.199195029951525],
          [15.110759023536977, 48.200099158200999],
          [15.111540557834159, 48.201039195155907],
          [15.11612196578317, 48.204547715771092],
          [15.119553530168506, 48.206613199990535],
          [15.123407302737379, 48.208499004141522],
          [15.125419528973806, 48.209313171138795],
          [15.12883312705346, 48.210139297949723],
          [15.145451959809671, 48.212342237624981],
          [15.156321574747517, 48.2131084552978],
          [15.166697116279098, 48.214221844918356],
          [15.17955200799485, 48.215867941128685],
          [15.183935786581353, 48.215981669457811],
          [15.192101472513999, 48.215712312479454],
          [15.204623987574623, 48.215035920931278],
          [15.22224893344905, 48.215227466347415],
          [15.226147621782127, 48.215430982566907],
          [15.230163091102144, 48.215897869660843],
          [15.230163091102144, 48.215897869660843],
          [15.252719787886384, 48.221691703939229],
          [15.262996514736711, 48.223481195822316],
          [15.269724896214765, 48.224193384218154],
          [15.292353458221738, 48.225007301679568],
          [15.295641292161614, 48.225462132270771],
          [15.301606105648167, 48.225982788643805],
          [15.301606105648167, 48.225982788643805],
          [15.301606105648167, 48.225982788643805],
          [15.301606105648167, 48.225982788643805],
          [15.303600365578912, 48.226198231110978],
          [15.303600365578912, 48.226198231110978],
          [15.305594625509658, 48.226353827884381],
          [15.305594625509658, 48.226353827884381],
          [15.30974484212229, 48.226934319364616],
          [15.312502670044537, 48.227837957948211],
          [15.327774029874572, 48.234946822835184],
          [15.331214577412748, 48.236287104208877],
          [15.331214577412748, 48.236287104208877],
          [15.335634288610615, 48.238387206643267],
          [15.33965874108347, 48.240020560048407],
          [15.342200973337528, 48.240989778073889],
          [15.345866099696737, 48.242054700163145],
          [15.345866099696737, 48.242054700163145],
          [15.347932224850213, 48.243005932047168],
          [15.356232658075475, 48.248216454480271],
          [15.364443259772328, 48.2527385901342],
          [15.367299902375828, 48.254491109256968],
          [15.368934836192928, 48.255968442197393],
          [15.370884180359464, 48.258940922035599],
          [15.375052363277781, 48.264072080117387],
          [15.377370016710808, 48.26666736485501],
          [15.386595714678716, 48.275337291410764],
          [15.388033019133307, 48.276371605117887],
          [15.399944679800731, 48.283013432868565],
          [15.401507748395099, 48.284220945138031],
          [15.403502008325844, 48.286217462209294],
          [15.404014048037794, 48.286910845088585],
          [15.404822531793503, 48.288596443518664],
          [15.405541184020798, 48.29097531401181],
          [15.407418662964607, 48.300507615266248],
          [15.410104625664124, 48.309279345488576],
          [15.411667694258492, 48.313748284947309],
          [15.412018037219298, 48.315468841773409],
          [15.412089902442029, 48.318204885560526],
          [15.411721593175539, 48.320456933775382],
          [15.410401069707884, 48.325761100092173],
          [15.408352910860092, 48.331381241974491],
          [15.407751039619729, 48.333608820746612],
          [15.407427646117448, 48.334994289822916],
          [15.407095269462324, 48.338195057174275],
          [15.407059336850958, 48.345545312539365],
          [15.40729289882483, 48.348697656170891],
          [15.408209180414634, 48.351616982326121],
          [15.409161394615797, 48.353306413699784],
          [15.410580732764707, 48.355282320667072],
          [15.412422279097152, 48.357156675183901],
          [15.416464697875691, 48.360099392035735],
          [15.421791707510518, 48.363388109714265],
          [15.426166502944181, 48.365650098984183],
          [15.432203181653465, 48.368091024837625],
          [15.441455829079896, 48.370848115775765],
          [15.44517485435615, 48.372125159261543],
          [15.449864060139253, 48.374064533208383],
          [15.452028999973981, 48.375174418601851],
          [15.453861563153584, 48.376481188132111],
          [15.460787573994146, 48.383068227927474],
          [15.465404914554522, 48.387089244436559],
          [15.465404914554522, 48.387089244436559],
          [15.468063927795514, 48.389165253234481],
          [15.470354631770022, 48.391217316629771],
          [15.473992808670703, 48.394909629260425],
          [15.473992808670703, 48.394909629260425],
          [15.477657935029912, 48.399138459436799],
          [15.479508464515197, 48.400999271918174],
          [15.482562736481206, 48.40312838731225],
          [15.486003284019382, 48.405245486517572],
          [15.489731292448477, 48.406837725603083],
          [15.49492355479069, 48.408644588227318],
          [15.497025612555531, 48.409199156949107],
          [15.499469030128335, 48.409401900993664],
          [15.502604150469912, 48.409228972300475],
          [15.504427730496674, 48.408763850828464],
          [15.507526918226887, 48.40717167204614],
          [15.512126292481577, 48.404494075479228],
          [15.512979692001492, 48.403855963738131],
          [15.514515811137336, 48.402376944996476],
          [15.51604294712034, 48.400241834026531],
          [15.516528037373764, 48.398971460116393],
          [15.517399403199359, 48.394605426231813],
          [15.518001274439719, 48.392511744288406],
          [15.518935522335203, 48.390763960908174],
          [15.520651304527872, 48.388413604656954],
          [15.522465901401794, 48.386826754534823],
          [15.524999150503012, 48.385389001739149],
          [15.526867646293979, 48.384643263338219],
          [15.530056665552602, 48.383855751725214],
          [15.532266521151538, 48.383629041577862],
          [15.536398771458487, 48.38384381963737],
          [15.540396274472819, 48.384464284496111],
          [15.543863771469521, 48.38529951370797],
          [15.546603633086084, 48.386337565196285],
          [15.559683103622865, 48.392434198858219],
          [15.574864631924484, 48.398023132151337],
          [15.574864631924484, 48.398023132151337],
          [15.58084741171672, 48.399824343753053],
          [15.587171551316922, 48.40119012063164],
          [15.591752959265932, 48.401726878799352],
          [15.601472730640106, 48.402215920198778],
          [15.601472730640106, 48.402215920198778],
          [15.611129619944391, 48.402865980145968],
          [15.62488282694426, 48.4029196544492],
          [15.62488282694426, 48.4029196544492],
          [15.632707153068941, 48.402997183898329],
          [15.635734475576424, 48.402734776055169],
          [15.638779764389589, 48.402192064629844],
          [15.641735221674343, 48.4014048245784],
          [15.644609830583525, 48.400343224004416],
          [15.647286810130202, 48.399048995583335],
          [15.649002592322869, 48.397927701943189],
          [15.649002592322869, 48.397927701943189],
          [15.650628542987125, 48.396323254763814],
          [15.652937213267315, 48.392959119462574],
          [15.653997225302573, 48.391688563690558],
          [15.660977135060183, 48.385293547833626],
          [15.662360540597726, 48.384291271017148],
          [15.665342947341003, 48.382638664356605],
          [15.667184493673448, 48.381898851930984],
          [15.669052989464417, 48.381421547885481],
          [15.671963530984966, 48.381045667799583],
          [15.674856106199829, 48.381027768678642],
          [15.682851112228491, 48.382215063396401],
          [15.685186731967205, 48.382919074320661],
          [15.69138510742763, 48.385884166000992],
          [15.693864457611799, 48.386868514382478],
          [15.696864830660758, 48.387632116983347],
          [15.700242496129047, 48.388079535058893],
          [15.700242496129047, 48.388079535058893],
          [15.70815665378214, 48.38833605298143],
          [15.710734818647563, 48.388145156045873],
          [15.716663699522753, 48.386683577654161],
          [15.718864571968844, 48.385830473723537],
          [15.721155275943348, 48.384649229288783],
          [15.721155275943348, 48.384649229288783],
          [15.7260510942418, 48.381797425195806],
          [15.731503868016405, 48.379088659969661],
          [15.738914969110393, 48.374022762855581],
          [15.740819397512725, 48.372900917704399],
          [15.743343663461102, 48.372047582767216],
          [15.746478783802679, 48.371570186354447],
          [15.749326443253338, 48.371564218870965],
          [15.752623260346056, 48.371868559636937],
          [15.762325065414549, 48.372996394849764],
          [15.766340534734562, 48.373688598799561],
          [15.773104848823982, 48.375281825581744],
          [15.777677273620149, 48.376552790972717],
          [15.780875276031614, 48.377644721814207],
          [15.785816010094273, 48.379685316670532],
          [15.788097730915934, 48.380449027043177],
          [15.790729794698407, 48.381021802303593],
          [15.793415757397923, 48.381278355799083],
          [15.797494108787827, 48.381379783568555],
          [15.801249066675446, 48.381224658662838],
          [15.804033844056217, 48.380878609092704],
          [15.810923922285413, 48.379673383605052],
          [15.81390632902869, 48.379404888892125],
          [15.81878418102146, 48.379715149322003],
          [15.826392911477951, 48.380663818521093],
          [15.830462279715013, 48.380926340207758],
          [15.835142502345276, 48.380968104896716],
          [15.843999891046693, 48.380723482659668],
          [15.846587039064959, 48.380460959926836],
          [15.849443681668459, 48.379959776404611],
          [15.853135757486191, 48.378957394556977],
          [15.856558338718685, 48.377728256542632],
          [15.856558338718685, 48.377728256542632],
          [15.856558338718685, 48.377728256542632],
          [15.856558338718685, 48.377728256542632],
          [15.857950727409071, 48.377262847024483],
          [15.857950727409071, 48.377262847024483],
          [15.861220595043266, 48.376123172418531],
          [15.863421467489356, 48.37509087968305],
          [15.869026954862264, 48.373097839120561],
          [15.872844794819773, 48.371039077585827],
          [15.874138368828904, 48.370066358399257],
          [15.875431942838036, 48.368789263280746],
          [15.882861010237704, 48.360505270675347],
          [15.885070865836639, 48.358278867178647],
          [15.888556329139021, 48.355574820020983],
          [15.892248404956751, 48.35353325887656],
          [15.894323513263069, 48.352876599016284],
          [15.909927249748225, 48.349330489534701],
          [15.912406599932398, 48.348942432271876],
          [15.91762581173313, 48.348542431692799],
          [15.922431798503169, 48.347628985763535],
          [15.926635914032849, 48.346303564517839],
          [15.939688435111107, 48.341120955987144],
          [15.9423474483521, 48.340255146282018],
          [15.944153062073179, 48.339872991113673],
          [15.946461732353368, 48.339634142678634],
          [15.96595517401876, 48.340046155529279],
          [15.978774133123144, 48.340691038523396],
          [15.995455847949245, 48.342219617477085],
          [16.000890655418168, 48.342237530239977],
          [16.004699512222835, 48.341688202648029],
          [16.008427520651932, 48.340661183009324],
          [16.010179235455965, 48.339974501359684],
          [16.015703874453301, 48.337227682260739],
          [16.020716473738688, 48.335400368452277],
          [16.023079042935922, 48.334928600328965],
          [16.027624518273566, 48.334779305709773],
          [16.027624518273566, 48.334779305709773],
          [16.049534428053239, 48.335406340172696],
          [16.055993314946061, 48.335830330534627],
          [16.058984704842178, 48.335860188877923],
          [16.058984704842178, 48.335860188877923],
          [16.075352009318834, 48.335758670439375],
          [16.082349885382126, 48.335376481563607],
          [16.095519187447319, 48.334188094724546],
          [16.103307580960635, 48.334086572956622],
          [16.109047815626159, 48.334409053167754],
          [16.151672875857631, 48.337974115138628],
          [16.161087220035203, 48.338953418499123],
          [16.169154091286593, 48.340105867260355],
          [16.203775162336562, 48.347891677773319],
          [16.220618573913804, 48.351037906385862],
          [16.224274717120171, 48.35148564609193],
          [16.233994488494343, 48.351587133211716],
          [16.241001347710476, 48.352088596013175],
          [16.241001347710476, 48.352088596013175],
          [16.241001347710476, 48.352088596013175],
          [16.241001347710476, 48.352088596013175],
          [16.242941708724171, 48.352207991190916],
          [16.242941708724171, 48.352207991190916],
          [16.250137214149969, 48.352769144778897],
          [16.256021179260955, 48.353694437730553],
          [16.258895788170136, 48.353897402969565],
          [16.277868206970741, 48.352810932564807],
          [16.282269951862926, 48.352255749183676],
          [16.285467954274392, 48.351473706417494],
          [16.289420541524517, 48.350076745196219],
          [16.314681167313957, 48.339705797326651],
          [16.320870559621543, 48.336970906824348],
          [16.320870559621543, 48.336970906824348],
          [16.32537111919498, 48.334647926083264],
          [16.32707791823481, 48.333501325882452],
          [16.328658953134859, 48.332062067260395],
          [16.333303243153754, 48.325856665597797],
          [16.340795192623311, 48.315086500826112],
          [16.341954019339827, 48.312929805259373],
          [16.342816402012581, 48.310175554560821],
          [16.344074043410348, 48.29854751309508],
          [16.344693880956392, 48.296312426713271],
          [16.345699994074607, 48.293981617442583],
          [16.347415776267272, 48.291214390345161],
          [16.350299368329296, 48.287412944055696],
          [16.368678899042383, 48.26487938326926],
          [16.368678899042383, 48.26487938326926],
          [16.369972473051515, 48.263306625352456],
          [16.369972473051515, 48.263306625352456],
          [16.372541654764095, 48.260184886443128],
          [16.372541654764095, 48.260184886443128],
          [16.37783273178756, 48.25409036786062],
          [16.37783273178756, 48.25409036786062],
          [16.378227990512574, 48.253677660751656],
          [16.378227990512574, 48.253677660751656],
          [16.384471281737202, 48.247163624772995],
          [16.384471281737202, 48.247163624772995],
          [16.388280138541869, 48.24338283035344],
          [16.388280138541869, 48.24338283035344],
          [16.388504717362903, 48.24318540777746],
          [16.388504717362903, 48.24318540777746],
          [16.394182069958539, 48.237908558402751],
          [16.394182069958539, 48.237908558402751],
          [16.397478887051257, 48.23501862451311],
          [16.407450186704981, 48.227059991910878],
          [16.407450186704981, 48.227059991910878],
          [16.417744879860994, 48.219231798615859],
          [16.433618110931384, 48.207684824200712],
          [16.433618110931384, 48.207684824200712],
          [16.435414741499621, 48.206002543763617],
          [16.435414741499621, 48.206002543763617],
          [16.443454663292492, 48.201230792928527],
          [16.443454663292492, 48.201230792928527],
          [16.464385409412476, 48.187056611208142],
          [16.466963574277901, 48.185092156723954],
          [16.47262296056785, 48.180228610110071],
          [16.479081847460673, 48.175741998484355],
          [16.479081847460673, 48.175741998484355],
          [16.479081847460673, 48.175741998484355],
          [16.479081847460673, 48.175741998484355],
          [16.479710668159555, 48.175316678477202],
          [16.479710668159555, 48.175316678477202],
          [16.481255770448239, 48.174310273423281],
          [16.481255770448239, 48.174310273423281],
          [16.483860884772188, 48.172656850807769],
          [16.490571299944559, 48.169349845629611],
          [16.490571299944559, 48.169349845629611],
          [16.499976660969292, 48.164233152925746],
          [16.499976660969292, 48.164233152925746],
          [16.504153827040447, 48.162261831824679],
          [16.510424067723601, 48.159763118400342],
          [16.510424067723601, 48.159763118400342],
          [16.516352948598794, 48.157587880286492],
          [16.516352948598794, 48.157587880286492],
          [16.518652635726138, 48.156784876518422],
          [16.521293682661451, 48.156161641050858],
          [16.521293682661451, 48.156161641050858],
          [16.525075590007592, 48.154921140234265],
          [16.530483448017989, 48.152122412292563],
          [16.530483448017989, 48.152122412292563],
          [16.532486691101575, 48.150875820517903],
          [16.532486691101575, 48.150875820517903],
          [16.535127738036888, 48.148933953774332],
          [16.535127738036888, 48.148933953774332],
          [16.537139964273315, 48.1472617318652],
          [16.539448634553501, 48.145038011881098],
          [16.542305277157006, 48.143107912664547],
          [16.544371402310478, 48.142292693679757],
          [16.54640159485259, 48.141759197776729],
          [16.552546071395966, 48.140404452927243],
          [16.552546071395966, 48.140404452927243],
          [16.561107016053626, 48.139583196387292],
          [16.563550433626428, 48.139217523189714],
          [16.571680186947713, 48.137113353029349],
          [16.577618050975744, 48.135248902281944],
          [16.577618050975744, 48.135248902281944],
          [16.586430523912956, 48.133030657254473],
          [16.586430523912956, 48.133030657254473],
          [16.591119729696061, 48.132095368468988],
          [16.594560277234237, 48.13178360175484],
          [16.602654097944153, 48.131957471886572],
          [16.609517226714825, 48.132664937385861],
          [16.613604561257571, 48.132581001310804],
          [16.621608550439078, 48.131369908385025],
          [16.62855252758532, 48.129781055043637],
          [16.631463069105866, 48.129283404076148],
          [16.634184964416747, 48.12903757471036],
          [16.644569489101169, 48.128917657519544],
          [16.650085144945663, 48.128575891989534],
          [16.655268424135034, 48.12779042223913],
          [16.660478652782928, 48.126345366357292],
          [16.660478652782928, 48.126345366357292],
          [16.672408279756034, 48.121926001271625],
          [16.672408279756034, 48.121926001271625],
          [16.678489874229523, 48.119989036218733],
          [16.685406901917244, 48.118381839497331],
          [16.69223409807655, 48.117776129180456],
          [16.697803652838093, 48.117872083766088],
          [16.70108250362513, 48.118291882971107],
          [16.704567966927513, 48.11929339007807],
          [16.708637335164575, 48.120882567871931],
          [16.714027226869291, 48.123329205874967],
          [16.71762947115861, 48.124516502907987],
          [16.725157353239535, 48.126177473411076],
          [16.732909814141482, 48.127664506123743],
          [16.73762596938311, 48.128384022624992],
          [16.741551607174713, 48.128557904267062],
          [16.745782672162917, 48.128234124185177],
          [16.750319164347722, 48.127424665049929],
          [16.750319164347722, 48.127424665049929],
          [16.760425211294063, 48.124882280807455],
          [16.765743237776054, 48.123323209355654],
          [16.765743237776054, 48.123323209355654],
          [16.772893827437645, 48.120888564676164],
          [16.772893827437645, 48.120888564676164],
          [16.782155458016916, 48.118057995265772],
          [16.789108418316001, 48.116456735454683],
          [16.789108418316001, 48.116456735454683],
          [16.795109164413923, 48.11574304927354],
          [16.798082588004355, 48.115671080368891],
          [16.801100927358998, 48.115833010262577],
          [16.809051017623457, 48.117188403432955],
          [16.821986757714775, 48.120049005272591],
          [16.821986757714775, 48.120049005272591],
          [16.826002227034788, 48.120804609353243],
          [16.836243021273752, 48.122363757248827],
          [16.839836282410232, 48.123161303069544],
          [16.843995482175703, 48.124306629166668],
          [16.843995482175703, 48.124306629166668],
          [16.847795355827529, 48.125296026439358],
          [16.852933719252693, 48.126327377853585],
          [16.85501781071185, 48.126591208609774],
          [16.860668213848964, 48.126932987347082],
          [16.866498280042897, 48.127688490169525],
          [16.866498280042897, 48.127688490169525],
          [16.87125036789589, 48.128467965560176],
          [16.875229904604538, 48.129469274278826],
          [16.879290289688761, 48.130908246631776],
          [16.882506258405908, 48.132491069649561],
          [16.882506258405908, 48.132491069649561],
          [16.900921721730356, 48.142940074520169],
          [16.905000073120259, 48.144888162056127],
          [16.908251974448774, 48.146044991357002],
          [16.908251974448774, 48.146044991357002],
          [16.912132696476167, 48.147015988569919],
          [16.916974615857576, 48.14771725291024],
          [16.921582973265107, 48.148022929239417],
          [16.929766625503436, 48.148160782674545],
          [16.933126324666041, 48.148436488433958],
          [16.936252461854782, 48.149053824219727],
          [16.938426384842348, 48.149773041012367],
          [16.941965747061779, 48.15150511341993],
          [16.943888141769794, 48.15291949596822],
          [16.947508352364796, 48.157300238590366],
          [16.94943074707281, 48.160074715205937],
          [16.951937046715507, 48.164155260216539],
          [16.953320452253049, 48.166042627186258],
          [16.955521324699141, 48.168367288505465],
          [16.95888102386175, 48.170787700172383],
          [16.96129749197603, 48.172123670552786],
          [16.962878526876082, 48.172800628803884],
          [16.965016517252284, 48.173417671459454],
          [16.967298238073948, 48.173741166583859],
          [16.969517076825724, 48.173771119732891],
          [16.972301854206496, 48.173441634131265],
          [16.974026619552006, 48.173028276473225],
          [16.976128677316847, 48.172195560348719],
          [16.976128677316847, 48.172195560348719],
          [16.978913454697615, 48.170733781354585],
          [16.978913454697615, 48.170733781354585],
          [16.983396047965371, 48.168541034732854],
          [16.987402534132546, 48.165802965388878],
          [16.987402534132546, 48.165802965388878],
          [16.992109706221331, 48.162621348920091],
          [16.997445699009003, 48.159709187991865],
          [17.002673893962577, 48.157491999900294],
          [17.00977058470712, 48.154777312112373],
          [17.012995536577108, 48.153063329297744],
          [17.014459790490225, 48.152020528524275],
          [17.017055921661331, 48.149761054148421],
          [17.019984429487561, 48.146692324873385],
          [17.023002768842201, 48.143095924243859],
          [17.024520921672362, 48.141897068035767],
          [17.026488232144587, 48.141015890870101],
          [17.029021481245799, 48.140416441976512],
          [17.033917299544253, 48.139781018506859],
          [17.036504447562518, 48.139703088671958],
          [17.038875999912594, 48.13983496986171],
          [17.045263021582684, 48.140901996118956],
          [17.057228581167156, 48.143731306681865],
          [17.057228581167156, 48.143731306681865],
          [17.060848791762158, 48.14406697724759],
          [17.063669501754294, 48.144037006750608],
          [17.07538353305921, 48.142754253077328],
          [17.07538353305921, 48.142754253077328],
          [17.088714531875542, 48.13919953919477],
          [17.092586270750097, 48.138396247661824],
          [17.097886330926404, 48.137844726794924],
          [17.101928749704943, 48.137808757836936],
          [17.104515897723207, 48.137958628328974],
          [17.104515897723207, 48.137958628328974],
          [17.111334110729675, 48.138180435854281],
          [17.117191126382131, 48.138150461920375],
          [17.117191126382131, 48.138150461920375],
          [17.120667606531676, 48.137988602374882],
          [17.128096673931346, 48.137305189779134],
          [17.128096673931346, 48.137305189779134],
          [17.132660115574669, 48.13686756114614],
          [17.132660115574669, 48.13686756114614],
          [17.136442022920814, 48.136064233125268],
          [17.139702907402171, 48.13469734760514],
          [17.139702907402171, 48.13469734760514],
          [17.141616318957343, 48.133546257890401],
          [17.141616318957343, 48.133546257890401],
          [17.143278202232963, 48.132245255638573],
          [17.144904152897219, 48.13052452461347],
          [17.146107895377941, 48.12864184691805],
          [17.146844513910917, 48.126663162217156],
          [17.146844513910917, 48.126663162217156],
          [17.147114008496153, 48.124624437641039],
          [17.146898412827966, 48.12260961855894],
          [17.146898412827966, 48.12260961855894],
          [17.145955181779641, 48.12009698046527],
          [17.145955181779641, 48.12009698046527],
          [17.144302281656859, 48.117800117843665],
          [17.139047137244759, 48.112588317651664],
          [17.137888310528247, 48.110836938163473],
          [17.137196607759474, 48.108983529796603],
          [17.136989995244125, 48.107070070453076],
          [17.137079826772538, 48.106110314801825],
          [17.13755593387312, 48.104364713271515],
          [17.138463232310084, 48.102673042855521],
          [17.141715133638595, 48.097759684408835],
          [17.141715133638595, 48.097759684408835],
          [17.151497787082658, 48.082998787755571],
          [17.151497787082658, 48.082998787755571],
          [17.157848876141383, 48.073503972637539],
          [17.159259231137447, 48.071985359906144],
          [17.164415560868296, 48.067165118288045],
          [17.16647270286893, 48.063845312036904],
          [17.167128473026338, 48.062986810115106],
          [17.16878137314912, 48.061443872069887],
          [17.16878137314912, 48.061443872069887],
          [17.170856481455431, 48.060123032334651],
          [17.17325498326403, 48.059096356223293],
          [17.183944935145053, 48.055752011953814],
          [17.18729565115482, 48.054449039853765],
          [17.190331956815143, 48.052851803266087],
          [17.195865578965318, 48.049224811399085],
          [17.198093400869936, 48.048023764416371],
          [17.200500885831378, 48.046960814473792],
          [17.204354658400248, 48.045675636659894],
          [17.220874676475209, 48.041339426305264],
          [17.220874676475209, 48.041339426305264],
          [17.221000440614986, 48.041309395874983],
          [17.221000440614986, 48.041309395874983],
          [17.225734562162295, 48.039982033369341],
          [17.230010542914702, 48.038510482925325],
          [17.234331439431319, 48.036726547045852],
          [17.239505735467848, 48.034101586323189],
          [17.242631872656581, 48.032215376372456],
          [17.281609772834528, 48.006600541143108],
          [17.284080139865857, 48.005266274268372],
          [17.288149508102919, 48.003661502172534],
          [17.292578202453626, 48.002633700558007],
          [17.296952997887288, 48.002200935856827],
          [17.313032841473028, 48.002381254923534],
          [17.325178064114326, 48.002261042282427],
          [17.33137643957475, 48.001948488104489],
          [17.337511932965288, 48.001281298811989],
          [17.345929147177486, 47.999760555833745],
          [17.352944989546462, 47.99790915604919],
          [17.359691337330197, 47.995558744381235],
          [17.365943611707667, 47.992799428842297],
          [17.371863509430018, 47.989546974234763],
          [17.425142588931148, 47.956914898722211],
          [17.442192613023735, 47.945513471011743],
          [17.4819161148875, 47.921264292115019],
          [17.518953654051749, 47.899334548454718],
          [17.523588960917806, 47.896232805111993],
          [17.528017655268513, 47.892956198681787],
          [17.54136662039053, 47.881962392321618],
          [17.54136662039053, 47.881962392321618],
          [17.541447468766098, 47.881896121208172],
          [17.541447468766098, 47.881896121208172],
          [17.541447468766098, 47.881896121208172],
          [17.541447468766098, 47.881896121208172],
          [17.547897372506078, 47.87655800583078],
          [17.549334676960669, 47.875136022287933],
          [17.554248461564804, 47.867639836903741],
          [17.555595934490981, 47.86600668161924],
          [17.557194935696714, 47.864463875117728],
          [17.603637835885696, 47.825866725322335],
          [17.608039580777881, 47.8224771006238],
          [17.608039580777881, 47.8224771006238],
          [17.608039580777881, 47.8224771006238],
          [17.608039580777881, 47.8224771006238],
          [17.612540140351321, 47.81908725458198],
          [17.627784550722829, 47.805549791032092],
          [17.633183425580384, 47.801670090155682],
          [17.636534141590154, 47.799835729449455],
          [17.651607872057678, 47.793783086550881],
          [17.651607872057678, 47.793783086550881],
          [17.651796518267343, 47.79369859801151],
          [17.651796518267343, 47.79369859801151],
          [17.655578425613484, 47.791857920736604],
          [17.674155585689078, 47.781150525030824],
          [17.678530381122741, 47.779061903307614],
          [17.686579286068447, 47.775729593199038],
          [17.688717276444656, 47.774642924161604],
          [17.691636801118044, 47.772892131833984],
          [17.694286831206195, 47.770954117107827],
          [17.696613467792066, 47.768877161341869],
          [17.704842035794599, 47.759529833942423],
          [17.706557817987267, 47.758074446465216],
          [17.708731740974837, 47.75678207657117],
          [17.712558564085189, 47.755387005751587],
          [17.717966422095586, 47.754269714269462],
          [17.720697300559308, 47.754034174084417],
          [17.72835992993285, 47.753810711897657],
          [17.72835992993285, 47.753810711897657],
          [17.732087938361943, 47.75340002213305],
          [17.732087938361943, 47.75340002213305],
          [17.748563040672696, 47.750144587124403],
          [17.752560543687032, 47.749933188199485],
          [17.756665844535458, 47.750168746946869],
          [17.759504520833275, 47.750132507208974],
          [17.762253365602678, 47.749691588376692],
          [17.763555922764652, 47.749329186539583],
          [17.765990357184616, 47.748338608642648],
          [17.769260224818812, 47.746484244460866],
          [17.771092787998413, 47.745064735380112],
          [17.774227908339991, 47.742056456280018],
          [17.775162156235478, 47.741361748647172],
          [17.777407944445777, 47.740195827176272],
          [17.780040008228244, 47.739410476753086],
          [17.782788852997651, 47.739072168304617],
          [17.785582613531261, 47.739168828085639],
          [17.792239129786587, 47.740654949628585],
          [17.792239129786587, 47.740654949628585],
          [17.7948801767219, 47.741440281279125],
          [17.799722096103302, 47.743633106163564],
          [17.802515856636916, 47.744285499044054],
          [17.805318600323368, 47.744509002131963],
          [17.809352035949065, 47.744273417768717],
          [17.812011049190062, 47.743910978204859],
          [17.814490399374229, 47.743198173034187],
          [17.820589960153399, 47.741005329828653],
          [17.823320838617121, 47.740377060206924],
          [17.826042733928009, 47.740147498261543],
          [17.832887896392997, 47.740395183475279],
          [17.837047096158472, 47.740763688563725],
          [17.842589701461488, 47.741536936663508],
          [17.85523798066189, 47.743917018884943],
          [17.861580086567773, 47.745493612417548],
          [17.865739286333248, 47.745837919594635],
          [17.868622878395271, 47.74571106984699],
          [17.872422752047097, 47.745070775926308],
          [17.892571963869898, 47.739815235758329],
          [17.895401657014872, 47.739398394346352],
          [17.898321181688264, 47.73928965255957],
          [17.901114942221874, 47.739470888744705],
          [17.903926669061171, 47.739954182154001],
          [17.914625604095033, 47.743457925201305],
          [17.91714987004341, 47.74398346631952],
          [17.919781933825877, 47.74429153968066],
          [17.919781933825877, 47.74429153968066],
          [17.937415862853147, 47.744261336490581],
          [17.94107200605951, 47.744527123961895],
          [17.944494587292006, 47.745143262425884],
          [17.947827336996088, 47.746115779866393],
          [17.951330766604158, 47.74763794459998],
          [17.953414858063311, 47.748858060378694],
          [17.959972559637386, 47.753363784645266],
          [17.962083600555065, 47.754444858844131],
          [17.965604996468812, 47.755652736410482],
          [17.968057397194464, 47.756160036627307],
          [17.970635562059886, 47.756437841793037],
          [17.973168811161102, 47.756474077140091],
          [17.975720026568002, 47.756274782419034],
          [17.979672613818124, 47.755459477884052],
          [17.983176043426191, 47.754118727094024],
          [17.990281717323576, 47.75022914645394],
          [17.993587517569139, 47.74883389994779],
          [17.995905171002168, 47.748169483702846],
          [17.998456386409067, 47.74769834704415],
          [18.002373041047825, 47.747420495220588],
          [18.006047150559876, 47.747631904351707],
          [18.009649394849195, 47.748308407800849],
          [18.011931115670858, 47.749009062817734],
          [18.026735351553146, 47.754867619181724],
          [18.030481326287926, 47.755839954923481],
          [18.034388997773846, 47.756419724110046],
          [18.038386500788178, 47.756594861448008],
          [18.084613805308969, 47.75633517483935],
          [18.084613805308969, 47.75633517483935],
          [18.086796711449381, 47.75628082166422],
          [18.086796711449381, 47.75628082166422],
          [18.086877559824948, 47.75628082166422],
          [18.086877559824948, 47.75628082166422],
          [18.089590471982991, 47.756099644003704],
          [18.092141687389891, 47.755731247481826],
          [18.102409431087377, 47.753484576173079],
          [18.107619659735271, 47.752548434498685],
          [18.120887776481716, 47.751147210331141],
          [18.120887776481716, 47.751147210331141],
          [18.120887776481716, 47.751147210331141],
          [18.120887776481716, 47.751147210331141],
          [18.130445851104746, 47.750271426067194],
          [18.130445851104746, 47.750271426067194],
          [18.139213408277755, 47.749389587021014],
          [18.147388077363242, 47.747958076755275],
          [18.147388077363242, 47.747958076755275],
          [18.153478654989573, 47.74638759826221],
          [18.169962740453165, 47.741150314049243],
          [18.174023125537385, 47.740534128323162],
          [18.185027487767851, 47.739682330163312],
          [18.190731789822006, 47.739464847548696],
          [18.195151501019875, 47.739676288991838],
          [18.199202902951257, 47.740159580494435],
          [18.209237084674871, 47.741899392756679],
          [18.213010008868171, 47.742261846326286],
          [18.215660038956326, 47.742346418462752],
          [18.220960099132629, 47.742080619856928],
          [18.228613745353329, 47.740950960641825],
          [18.235090598551832, 47.740334772556203],
          [18.253757590155832, 47.739911894159803],
          [18.2577191605588, 47.739664206646793],
          [18.26170768042029, 47.739029879593986],
          [18.265552469836326, 47.738027022962001],
          [18.267968937950606, 47.737163100857735],
          [18.274418841690583, 47.734329577408374],
          [18.277949220757172, 47.733151414599483],
          [18.280428570941339, 47.732510966197047],
          [18.284273360357375, 47.731791962851005],
          [18.286932373598368, 47.731471731274773],
          [18.292394130525814, 47.731248172214734],
          [18.29649943137424, 47.731459647025794],
          [18.299239292990805, 47.731779878676335],
          [18.312309780374743, 47.734251034050409],
          [18.322631422989279, 47.735266046780055],
          [18.326601976545085, 47.735797712218471],
          [18.37044874556296, 47.744182808114331],
          [18.374275568673308, 47.745173465104259],
          [18.38819047242432, 47.750368065054197],
          [18.391990346076145, 47.751328405235363],
          [18.394685291928504, 47.751672673815328],
          [18.407917476063584, 47.752705465890557],
          [18.426036495344277, 47.755229982451631],
          [18.430213661415429, 47.756286860908709],
          [18.434031501372939, 47.757657751267594],
          [18.436501868404271, 47.758799125816637],
          [18.442808041698786, 47.76213252082664],
          [18.445979094651726, 47.763430796233301],
          [18.450371856391072, 47.764638465196967],
          [18.455555135580443, 47.7653389003491],
          [18.458339912961215, 47.76542947317148],
          [18.462427247503957, 47.765200021715131],
          [18.466209154850102, 47.764602235536003],
          [18.47005394426613, 47.763557602791906],
          [18.47260515967303, 47.762579372762964],
          [18.474850947883329, 47.761498467573119],
          [18.484696483397279, 47.755399084447333],
          [18.486969221066101, 47.754179122016772],
          [18.488936531538325, 47.753448338744178],
          [18.491119437678737, 47.752922893189329],
          [18.494640833592481, 47.752578632880073],
          [18.498135280047709, 47.752808140005961],
          [18.516362097162496, 47.755791640533005],
          [18.520260785495573, 47.756619018275948],
          [18.522704203068376, 47.757404109078585],
          [18.524923041820152, 47.75833412439323],
          [18.532370075525503, 47.762603526812335],
          [18.534741627875579, 47.763563641191745],
          [18.536151982871647, 47.763938020613011],
          [18.539170322226287, 47.764360703818049],
          [18.550156718151072, 47.764584120696057],
          [18.5628768625742, 47.766238583387313],
          [18.566488090016364, 47.766250659563866],
          [18.572075611083587, 47.765568351192819],
          [18.592224822906385, 47.761776244235371],
          [18.60473835481417, 47.758901787908492],
          [18.611053511261531, 47.758056329352108],
          [18.618545460731088, 47.75782684537333],
          [18.637517879531696, 47.758442826396887],
          [18.644398974608048, 47.758865554252154],
          [18.65305873394696, 47.759849892947258],
          [18.662625791722832, 47.761703780901257],
          [18.670809443961161, 47.763980289088082],
          [18.674887795351065, 47.765387205873992],
          [18.679693782121106, 47.767295238225145],
          [18.703526086608797, 47.777480289992248],
          [18.708754281562371, 47.780027750372035],
          [18.724205304449228, 47.788025481824306],
          [18.726397193742478, 47.789389500349351],
          [18.728265689533448, 47.791055244330302],
          [18.729649095070993, 47.792938194975434],
          [18.730511477743747, 47.795092641345065],
          [18.730511477743747, 47.795092641345065],
          [18.730511477743747, 47.795098676083384],
          [18.730511477743747, 47.795098676083384],
          [18.730736056564776, 47.79639009396162],
          [18.730574359813634, 47.801645954251107],
          [18.730807921787505, 47.803516453614165],
          [18.731436742486391, 47.805344650807683],
          [18.733053709997805, 47.80792694512899],
          [18.735272548749581, 47.810104901978811],
          [18.73798546090762, 47.811902703361],
          [18.741803300865129, 47.813543595261095],
          [18.745118084263531, 47.814400216756042],
          [18.745118084263531, 47.814400216756042],
          [18.754092253951882, 47.816095320458039],
          [18.763803042173215, 47.818405639593934],
          [18.77549910717245, 47.819943784622204],
          [18.788371965193885, 47.822658047036363],
          [18.79135437193716, 47.823001843482601],
          [18.794363728138961, 47.823110410308367],
          [18.799070900227747, 47.822845024333404],
          [18.802008391206819, 47.822416785012784],
          [18.80936559338376, 47.820058389682458],
          [18.814512939961762, 47.81892439163741],
          [18.818447560906208, 47.818550408127464],
          [18.828445810018454, 47.818447863791249],
          [18.84823569572761, 47.816089288158643],
          [18.847301447832123, 47.81619786944065],
          [18.851900822086819, 47.815401601432995],
          [18.851900822086819, 47.815401601432995],
          [18.859599384071721, 47.814297664223098],
          [18.882200996620167, 47.810098868983488],
          [18.888102928036833, 47.808397536338553],
          [18.891300930448299, 47.806997816631423],
          [18.894301303497262, 47.80539895271064],
          [18.898604233708191, 47.802297619738567],
          [18.901299179560549, 47.799401266111239],
          [18.902898180766282, 47.796999583773861],
          [18.904101923247005, 47.794597790417349],
          [18.904703794487364, 47.792799390045687],
          [18.906302795693094, 47.784802393174559],
          [18.908413836610777, 47.779864764938381],
          [18.908413836610777, 47.779864764938381],
          [18.910102669344923, 47.776598912078114],
          [18.911575906410874, 47.774238435997965],
          [18.911575906410874, 47.774238435997965],
          [18.913300671756385, 47.771902002492865],
          [18.915600358883733, 47.769800933886323],
          [18.918304287888933, 47.767802424922571],
          [18.921502290300396, 47.766099707155455],
          [18.921502290300396, 47.766099707155455],
          [18.923900792108995, 47.765302671175988],
          [18.92639810859885, 47.764698847909166],
          [18.931204095368891, 47.764197669274431],
          [18.933701411858738, 47.764197669274431],
          [18.937402470829312, 47.764602235536003],
          [18.940825052061808, 47.765507969489974],
          [18.940825052061808, 47.765507969489974],
          [18.942998975049377, 47.766298964242026],
          [18.947795978666576, 47.768901312466944],
          [18.949403963025151, 47.770102816781559],
          [18.950796351715535, 47.771400893263497],
          [18.95661743475663, 47.777118081698895],
          [18.95661743475663, 47.777118081698895],
          [18.960192729587426, 47.780299391625995],
          [18.960192729587426, 47.780299391625995],
          [18.962797843911371, 47.782701845816298],
          [18.963399715151734, 47.783601228495826],
          [18.964531592409724, 47.786250997070255],
          [18.964531592409724, 47.786250997070255],
          [18.964989733204625, 47.787089961698129],
          [18.964989733204625, 47.787089961698129],
          [18.96540295823532, 47.788001339581108],
          [18.967199588803556, 47.790397402542951],
          [18.971403704333238, 47.795098676083384],
          [18.973595593626488, 47.798296988773892],
          [18.973595593626488, 47.798296988773892],
          [18.975697651391329, 47.799799524310039],
          [18.979003451636888, 47.80130201639254],
          [18.984195713979098, 47.804101725064378],
          [18.98539945645982, 47.804602519111221],
          [18.98779795826842, 47.805199845455604],
          [18.993098018444723, 47.806002303368324],
          [18.995801947449923, 47.806201407547363],
          [18.999997079826763, 47.806201407547363],
          [19.00420119535644, 47.805899734250964],
          [19.007003939042892, 47.805598059202453],
          [19.010803812694721, 47.80489816634222],
          [19.014397073831198, 47.803999152194002],
          [19.018798818723383, 47.802400195968801],
          [19.024098878899686, 47.800101233034233],
          [19.027602308507753, 47.799202135866992],
          [19.031303367478326, 47.798598706223736],
          [19.035696129217669, 47.796896996880143],
          [19.041598060634335, 47.79380119122002],
          [19.043996562442935, 47.792998544829238],
          [19.047796436094764, 47.792298482216069],
          [19.053096496271067, 47.792201921115513],
          [19.057004167756986, 47.792401078188817],
          [19.060804041408812, 47.791701007526825],
          [19.062995930702066, 47.79069916586451],
          [19.065699859707266, 47.788701460081057],
          [19.069104474634077, 47.786902855652244],
          [19.074395551657538, 47.785200763270375],
          [19.076901851300235, 47.784198796302903],
          [19.084798042647645, 47.780402011285894],
          [19.085696357931766, 47.779701778993783],
          [19.086298229172122, 47.778898914846067],
          [19.086495858534629, 47.777999450812437],
          [19.086495858534629, 47.776997345040378],
          [19.086001785128364, 47.775602816314844],
          [19.084995672010152, 47.774202213023841],
          [19.082399540839045, 47.771799366055973],
          [19.080800539633312, 47.770700539744453],
          [19.07620116537862, 47.768297530975715],
          [19.075204035413247, 47.76750052867466],
          [19.074395551657538, 47.766697476125223],
          [19.073802663570021, 47.765701190692354],
          [19.07349723637342, 47.764801498359034],
          [19.073398421692168, 47.763799138238852],
          [19.073703848888769, 47.761897016234592],
          [19.073901478251276, 47.760997258103941],
          [19.074700978854139, 47.759403017565688],
          [19.07589573818202, 47.75780268910615],
          [19.077503722540595, 47.756401606420752],
          [19.079497982471338, 47.755199785608852],
          [19.081600040236179, 47.753502694878073],
          [19.081600040236179, 47.753502694878073],
          [19.082597170201552, 47.752602791572571],
          [19.085696357931766, 47.748598335158718],
          [19.090098102823951, 47.744502961520588],
          [19.09130184530467, 47.743101520734164],
          [19.092802031829152, 47.74079993482497],
          [19.094401033034881, 47.739501094714221],
          [19.09500290427524, 47.73880031170836],
          [19.095901219559362, 47.736099792435475],
          [19.096000034240614, 47.734202699617391],
          [19.095901219559362, 47.733302462603092],
          [19.095200533637751, 47.731501941884957],
          [19.095200533637751, 47.73060165818103],
          [19.094796291759895, 47.728698322610072],
          [19.09370034711327, 47.726100006210984],
          [19.09309847587291, 47.724202549094358],
          [19.0929008465104, 47.722401713764881],
          [19.092802031829152, 47.718600417362104],
          [19.092397789951296, 47.714798843596796],
          [19.091795918710936, 47.712900974841219],
          [19.090897603426814, 47.711099748845648],
          [19.090403530020552, 47.710501341197713],
          [19.089604029417682, 47.708500554624855],
          [19.089702844098937, 47.707098144836714],
          [19.091203030623415, 47.702401006201342],
          [19.091598289348429, 47.699402354026276],
          [19.09099641810807, 47.695297064620561],
          [19.089999288142696, 47.692600336339211],
          [19.089604029417682, 47.690798408779244],
          [19.089298602221081, 47.687297170178233],
          [19.088696730980722, 47.685501106840597],
          [19.086900100412482, 47.68230190482074],
          [19.086100599809619, 47.679701274684568],
          [19.085804155765857, 47.677100514908346],
          [19.085103469844242, 47.675400878595127],
          [19.083998542044778, 47.673797968002418],
          [19.083702098001019, 47.672902736129664],
          [19.083100226760656, 47.672098224114926],
          [19.082004282114035, 47.671499368773709],
          [19.081195798358326, 47.670797670943635],
          [19.080602910270805, 47.669999175729444],
          [19.080297483074208, 47.669200668298259],
          [19.080297483074208, 47.666399746410683],
          [19.080701724952057, 47.665498337851595],
          [19.082300726157793, 47.663997972309929],
          [19.082695984882807, 47.66310257232665],
          [19.082597170201552, 47.661299625764457],
          [19.082201911476538, 47.659502667404176],
          [19.08130359619242, 47.656798037720996],
          [19.077898981265609, 47.651001066412704],
          [19.077602537221846, 47.650099391960772],
          [19.077395924706497, 47.648302048121835],
          [19.077800166584353, 47.646498590454577],
          [19.078698481868472, 47.644900844395103],
          [19.080001039030446, 47.643296997117297],
          [19.084699227966393, 47.638600164878667],
          [19.085301099206752, 47.637698276344622],
          [19.085804155765857, 47.636802425342076],
          [19.086603656368723, 47.634199527854399],
          [19.086998915093737, 47.633400472847853],
          [19.08859791629947, 47.63129986845437],
          [19.090502344701804, 47.628297128825388],
          [19.09099641810807, 47.624700878946541],
          [19.090897603426814, 47.622902661220998],
          [19.090098102823951, 47.621098326719689],
          [19.089397416902337, 47.620299071401],
          [19.088499101618215, 47.619602740519625],
          [19.085597543250511, 47.618101056270604],
          [19.083998542044778, 47.616998986356627],
          [19.08169885491743, 47.614401158221497],
          [19.080198668392953, 47.612099943920519],
          [19.080198668392953, 47.612099943920519],
          [19.078797296549727, 47.608302718930425],
          [19.077503722540595, 47.606497880572441],
          [19.073596051054672, 47.602597278915624],
          [19.07250010640805, 47.600998190453666],
          [19.071799420486435, 47.599302134127392],
          [19.071601791123928, 47.598399567453612],
          [19.071601791123928, 47.59569783187191],
          [19.070901105202314, 47.592802094913068],
          [19.070999919883569, 47.589900139180706],
          [19.071799420486435, 47.585901356187534],
          [19.071799420486435, 47.585901356187534],
          [19.070901105202314, 47.583901850126075],
          [19.070299233961954, 47.581799256826436],
          [19.069203289315329, 47.572297148227662],
          [19.06799954683461, 47.56619986179345],
          [19.067200046231743, 47.563399478487113],
          [19.067200046231743, 47.563399478487113],
          [19.066499360310129, 47.561599153020445],
          [19.066499360310129, 47.561599153020445],
          [19.064100858501529, 47.556500926073923],
          [19.064100858501529, 47.556500926073923],
          [19.062600671977052, 47.554100162281692],
          [19.061298114815077, 47.552602660479096],
          [19.059797928290596, 47.550201718085887],
          [19.057300611800748, 47.545102382139483],
          [19.056096869320026, 47.543398450636019],
          [19.051497495065334, 47.537898194173735],
          [19.051497495065334, 47.537898194173735],
          [19.048802549212976, 47.534799118591891],
          [19.048802549212976, 47.534799118591891],
          [19.043798933080428, 47.528697467811725],
          [19.042199931874695, 47.526101321599079],
          [19.041301616590577, 47.524002473955804],
          [19.040897374712724, 47.521800413471134],
          [19.040798560031469, 47.519598260552122],
          [19.041696875315591, 47.514599091029623],
          [19.041696875315591, 47.514599091029623],
          [19.042002302512191, 47.512002247063577],
          [19.042101117193443, 47.508598245293555],
          [19.04229874655595, 47.507700183065509],
          [19.04229874655595, 47.507700183065509],
          [19.042496375918457, 47.504101712036743],
          [19.043304859674166, 47.500496925444772],
          [19.043304859674166, 47.500496925444772],
          [19.043978596137254, 47.49874906102724],
          [19.043978596137254, 47.49874906102724],
          [19.043996562442935, 47.498700508407254],
          [19.043996562442935, 47.498700508407254],
          [19.04469724836455, 47.497007207697777],
          [19.04469724836455, 47.497007207697777],
          [19.046197434889027, 47.494500536864592],
          [19.048003048610109, 47.492103003654904],
          [19.049404420453335, 47.490597661045406],
          [19.049404420453335, 47.490597661045406],
          [19.053204294105161, 47.487101214948552],
          [19.054902109992149, 47.485802128797133],
          [19.054902109992149, 47.485802128797133],
          [19.06349898726117, 47.478802293979761],
          [19.06349898726117, 47.478802293979761],
          [19.064900359104396, 47.477102280073545],
          [19.066400545628877, 47.474600731027891],
          [19.06739767559425, 47.471297532376582],
          [19.067541406039709, 47.468947520180002],
          [19.067541406039709, 47.468947520180002],
          [19.067496490275502, 47.468601385474557],
          [19.067496490275502, 47.468601385474557],
          [19.067101231550492, 47.466700640237669],
          [19.066301730947622, 47.464799826265171],
          [19.065699859707266, 47.463797772062208],
          [19.06349898726117, 47.461101240441188],
          [19.06349898726117, 47.461101240441188],
          [19.056501111197878, 47.453502836526667],
          [19.055396183398411, 47.452099653834793],
          [19.054003794708027, 47.449900651536979],
          [19.052997681589812, 47.447397807767558],
          [19.051802922261935, 47.439499704397988],
          [19.051802922261935, 47.439499704397988],
          [19.050895623824971, 47.437002441929707],
          [19.050895623824971, 47.437002441929707],
          [19.049799679178349, 47.434498984437624],
          [19.047796436094764, 47.430986651569022],
          [19.047796436094764, 47.430986651569022],
          [19.045397934286164, 47.426598944534547],
          [19.044400804320791, 47.423602696197705],
          [19.044104360277029, 47.421402502621156],
          [19.044104360277029, 47.418199294006726],
          [19.04380791623327, 47.416017110507021],
          [19.04380791623327, 47.416017110507021],
          [19.043502489036669, 47.413597759582096],
          [19.04290061779631, 47.410801386367929],
          [19.041598060634335, 47.407202357111821],
          [19.039100744144484, 47.402897789727326],
          [19.036495629820539, 47.399699535932697],
          [19.034797813933551, 47.397996964233613],
          [19.031797440884592, 47.395601109451611],
          [19.0295965684385, 47.394099084022358],
          [19.025796694786674, 47.392299029541086],
          [19.021098505850727, 47.390401608257001],
          [19.014603686346547, 47.388899434626424],
          [19.014603686346547, 47.388899434626424],
          [19.011899757341343, 47.388498036853107],
          [19.005198325321814, 47.388102717876457],
          [18.999997079826763, 47.387397218181718],
          [18.988597458871283, 47.385402305875715],
          [18.98089889688638, 47.383601954350276],
          [18.973802206141837, 47.381102039967729],
          [18.969400461249652, 47.37899739726744],
          [18.966400088200693, 47.377300239798899],
          [18.962600214548864, 47.374800026598123],
          [18.959096784940797, 47.372001593860396],
          [18.957497783735068, 47.370602321803617],
          [18.956096411891838, 47.369099583947488],
          [18.952197723558761, 47.364402491606512],
          [18.950598722353028, 47.361901666907414],
          [18.948999721147295, 47.358402747272024],
          [18.948397849906936, 47.356601473823211],
          [18.947697163985325, 47.35290136791788],
          [18.947795978666576, 47.351099906668743],
          [18.948200220544429, 47.349201002567341],
          [18.949502777706403, 47.346602071154884],
          [18.950499907671777, 47.343899533693389],
          [18.950904149549633, 47.340101139065894],
          [18.95069753703428, 47.338201839268606],
          [18.950095665793924, 47.336302471148471],
          [18.949296165191054, 47.334597851843711],
          [18.948209203697271, 47.333112353008524],
          [18.948209203697271, 47.333112353008524],
          [18.947104275897804, 47.331602458913096],
          [18.942702531005619, 47.327498738326284],
          [18.940007585153261, 47.32431417757472],
          [18.940007585153261, 47.32431417757472],
          [18.933701411858738, 47.315301378287266],
          [18.930997482853542, 47.310197503449558],
          [18.929596111010312, 47.306701247451166],
          [18.927799480442076, 47.303198668539686],
          [18.927799480442076, 47.303198668539686],
          [18.92579623735849, 47.299902986741962],
          [18.92460147803061, 47.298300760378737],
          [18.922301790903266, 47.295997855698303],
          [18.922301790903266, 47.295997855698303],
          [18.921196863103795, 47.294401603655317],
          [18.919301417854307, 47.291001791763627],
          [18.918097675373584, 47.288399995974501],
          [18.917495804133225, 47.286681643802851],
          [18.917495804133225, 47.286681643802851],
          [18.916597488849106, 47.284097917032277],
          [18.916301044805348, 47.281398275244548],
          [18.916597488849106, 47.278698495693433],
          [18.917486820980383, 47.275236725772203],
          [18.917486820980383, 47.275236725772203],
          [18.918196490054839, 47.271817396197626],
          [18.918196490054839, 47.271817396197626],
          [18.918798361295195, 47.269299994550217],
          [18.920082952151489, 47.265331643741163],
          [18.920082952151489, 47.265331643741163],
          [18.920397362500928, 47.264398946686427],
          [18.920702789697533, 47.262362805825589],
          [18.920702789697533, 47.262362805825589],
          [18.920496177182184, 47.260399745581708],
          [18.919400232535555, 47.257802541608555],
          [18.917900046011077, 47.255400319363865],
          [18.914297801721762, 47.250997994752694],
          [18.912599985834774, 47.247900294946731],
          [18.911998114594411, 47.246302591554198],
          [18.911504041188149, 47.243899847633351],
          [18.910902169947789, 47.235898046644181],
          [18.910497928069933, 47.233897407568975],
          [18.909401983423308, 47.230499807804463],
          [18.907802982217575, 47.22710199022],
          [18.905000238531123, 47.223099972400803],
          [18.902898180766282, 47.220702277369817],
          [18.88830055739934, 47.206698304874152],
          [18.886899185556114, 47.205099359102064],
          [18.884500683747515, 47.201797566657291],
          [18.882497440663926, 47.198397909450534],
          [18.880898439458196, 47.194900368707415],
          [18.879595882296222, 47.190297668884845],
          [18.878401122968341, 47.179802753340972],
          [18.877799251727982, 47.176401686990729],
          [18.876900936443864, 47.173800365345066],
          [18.875697193963141, 47.171101206778616],
          [18.874098192757408, 47.168597339762002],
          [18.872301562189168, 47.165000114457222],
          [18.871699690948809, 47.162202782784703],
          [18.871798505630064, 47.157499513265435],
          [18.872499191551679, 47.150297206767561],
          [18.872795635595438, 47.148397199008897],
          [18.874098192757408, 47.142898401589001],
          [18.879802494811567, 47.127902123942803],
          [18.884599498428766, 47.109899785474994],
          [18.888695816124354, 47.098398330036197],
          [18.888695816124354, 47.098398330036197],
          [18.89519961878138, 47.080202491381421],
          [18.896097934065498, 47.078397859376182],
          [18.898801863070695, 47.074402981981251],
          [18.902197494844668, 47.071802786028279],
          [18.906401610374349, 47.069600167858034],
          [18.912096929275666, 47.067097638425011],
          [18.915303914839974, 47.065402704568903],
          [18.91632799426387, 47.064686777689531],
          [18.91632799426387, 47.064686777689531],
          [18.921717885968587, 47.061492525237973],
          [18.921717885968587, 47.061492525237973],
          [18.930871718713764, 47.057514740276183],
          [18.930871718713764, 47.057514740276183],
          [18.935480076121298, 47.055807261549617],
          [18.935480076121298, 47.055807261549617],
          [18.938902657353793, 47.054601589515975],
          [18.943196604411884, 47.05260023616556],
          [18.947104275897804, 47.050200961297598],
          [18.950796351715535, 47.047201715928381],
          [18.96540295823532, 47.033598939891299],
          [18.967900274725171, 47.030200765504013],
          [18.968897404690541, 47.028400563836911],
          [18.969903517808756, 47.025602170634514],
          [18.969903517808756, 47.025602170634514],
          [18.97029877653377, 47.023697709751289],
          [18.970604203730371, 47.020899070003516],
          [18.970496405896277, 47.018100283547035],
          [18.970002332490012, 47.015497341725087],
          [18.96910401720589, 47.01280239768775],
          [18.967702645362664, 47.009997061480981],
          [18.965501772916571, 47.006701522004462],
          [18.962097157989756, 47.002701272480458],
          [18.959797470862412, 47.00050192652499],
          [18.959797470862412, 47.00050192652499],
          [18.95659946845095, 46.997597916018371],
          [18.95340146603948, 46.99359698481905],
          [18.951398222955895, 46.989797967398331],
          [18.950203463628018, 46.986997551027102],
          [18.949403963025151, 46.984098934927708],
          [18.94890090646604, 46.979900871880417],
          [18.949098535828551, 46.977302197611543],
          [18.949601592387655, 46.974795337897625],
          [18.949601592387655, 46.974795337897625],
          [18.950095665793924, 46.972098340480919],
          [18.951002964230881, 46.969499287013512],
          [18.955296911288976, 46.962197968232424],
          [18.955296911288976, 46.962197968232424],
          [18.962195972671012, 46.950499068100129],
          [18.963803957029583, 46.946801242291819],
          [18.965501772916571, 46.941201892109639],
          [18.966202458838183, 46.935700098187404],
          [18.966202458838183, 46.932197546937516],
          [18.965699402279078, 46.926799120952509],
          [18.964899901676212, 46.921798957031555],
          [18.963902771710838, 46.917399653915254],
          [18.962699029230119, 46.9130981729204],
          [18.961198842705638, 46.908900674620078],
          [18.958997970259549, 46.904598511434564],
          [18.95790202561292, 46.90280021537059],
          [18.95790202561292, 46.90280021537059],
          [18.955395725970227, 46.899301650886912],
          [18.951802464833751, 46.895600289428103],
          [18.944858487687505, 46.889860525547853],
          [18.944858487687505, 46.889860525547853],
          [18.94280134568687, 46.888098571061981],
          [18.939567410664043, 46.884967428990919],
          [18.939567410664043, 46.884967428990919],
          [18.936899414270208, 46.882302744538663],
          [18.93360259717749, 46.878600209803167],
          [18.930701038809779, 46.875001813497512],
          [18.927125743978987, 46.870144209848803],
          [18.927125743978987, 46.870144209848803],
          [18.926101664555091, 46.868498297515416],
          [18.924502663349354, 46.864899223816558],
          [18.923900792108995, 46.863001321572625],
          [18.923397735549891, 46.860151271143359],
          [18.923397735549891, 46.860151271143359],
          [18.923200106187384, 46.856502494389574],
          [18.923397735549891, 46.854702585582615],
          [18.925499793314728, 46.848497659362629],
          [18.925499793314728, 46.847502347057798],
          [18.924897922074368, 46.842697555432117],
          [18.925095551436875, 46.821998861219505],
          [18.92590403519258, 46.819097457208443],
          [18.927296423882968, 46.816398765062381],
          [18.927898295123327, 46.815501220121533],
          [18.930000352888168, 46.813097447216521],
          [18.930808836643877, 46.812384286762203],
          [18.930808836643877, 46.812384286762203],
          [18.932560551447907, 46.810927196780078],
          [18.932560551447907, 46.810927196780078],
          [18.933297169980886, 46.810398453945098],
          [18.933297169980886, 46.810398453945098],
          [18.937097043632711, 46.808400250943798],
          [18.939396730760055, 46.807600948957635],
          [18.943061857119265, 46.806709405806878],
          [18.943061857119265, 46.806709405806878],
          [18.946610202491538, 46.805393584090623],
          [18.946610202491538, 46.805393584090623],
          [18.948703277103537, 46.80429909104042],
          [18.953697910083239, 46.800898641925031],
          [18.956401839088439, 46.798401972839628],
          [18.957515750040749, 46.797073648477649],
          [18.957515750040749, 46.797073648477649],
          [18.958297284337934, 46.796101983039676],
          [18.960102898059013, 46.794398451288757],
          [18.961701899264746, 46.792498058013599],
          [18.963193102636385, 46.78940439403695],
          [18.963193102636385, 46.78940439403695],
          [18.965699402279078, 46.783899323601418],
          [18.969696905293411, 46.779298006030828],
          [18.971331839110508, 46.777883083342715],
          [18.971331839110508, 46.777883083342715],
          [18.977997338518676, 46.773502739141705],
          [18.980503638161366, 46.771398570724671],
          [18.981905010004596, 46.769792702675574],
          [18.981905010004596, 46.769792702675574],
          [18.984501141175699, 46.766599282562524],
          [18.985597085822324, 46.764900970476674],
          [18.98730388486215, 46.761301110236296],
          [18.98779795826842, 46.758501052635978],
          [18.98779795826842, 46.755700849520238],
          [18.986396586425194, 46.745101840221594],
          [18.98600132770018, 46.740601862863862],
          [18.986504384259284, 46.736200016854177],
          [18.986998457665553, 46.734402236537647],
          [18.988498644190035, 46.731102045454186],
          [18.990304257911109, 46.727801652421988],
          [18.994697019650456, 46.722197910724702],
          [18.998002819896019, 46.719401980183761],
          [19.002701008831963, 46.716297958074506],
          [19.005800196562173, 46.713199916709542],
          [19.009501255532747, 46.705697362089332],
          [19.009896514257758, 46.702099708211875],
          [19.009698884895251, 46.697602303712493],
          [19.009600070213999, 46.696702777858661],
          [19.009195828336143, 46.695698494989593],
          [19.008602940248625, 46.69479893742151],
          [19.000697765748377, 46.686702244990485],
          [18.997499763336908, 46.683799710407854],
          [18.977602079793662, 46.667102486626654],
          [18.973002705538971, 46.662700808861679],
          [18.968897404690541, 46.659198938092878],
          [18.967496032847315, 46.657700709317048],
          [18.964001586392094, 46.652897465606273],
          [18.961899528627253, 46.650597430215036],
          [18.95850389685328, 46.647797791349191],
          [18.951802464833751, 46.643400709182579],
          [18.948496664588191, 46.641698516905699],
          [18.942603716324363, 46.639398005289394],
          [18.941399973843648, 46.639101953795368],
          [18.935695671789485, 46.636998708009578],
          [18.930701038809779, 46.635802103540335],
          [18.920702789697533, 46.635197623181966],
          [18.913902542996748, 46.635401173444436],
          [18.905997368496493, 46.636098173089053],
          [18.901299179560549, 46.636801331775146],
          [18.89440011817851, 46.637300938973326],
          [18.888201742718088, 46.636801331775146],
          [18.883395755948047, 46.635697245186719],
          [18.880799624776941, 46.63409967219966],
          [18.875796008644397, 46.629497904981548],
          [18.874897693360278, 46.628899523125895],
          [18.862797386483184, 46.618201560220385],
          [18.860201255312081, 46.615097891682701],
          [18.859096327512614, 46.61340097979344],
          [18.858395641590999, 46.611599109692612],
          [18.857596140988136, 46.60890236333492],
          [18.857497326306881, 46.606100567016419],
          [18.857901568184737, 46.603199876304558],
          [18.858503439425096, 46.60129891389473],
          [18.860003625949577, 46.598601654722067],
          [18.86530368612588, 46.591101704466809],
          [18.866696074816264, 46.589601589859114],
          [18.86949881850272, 46.587397642475814],
          [18.878796381693355, 46.5807976114422],
          [18.886198499634499, 46.576401260951634],
          [18.889899558605073, 46.573301372610764],
          [18.892298060413669, 46.570602719967468],
          [18.892899931654032, 46.569701080691935],
          [18.89340298821314, 46.568799426427894],
          [18.895298433462631, 46.563803002061633],
          [18.896897434668364, 46.558799940583349],
          [18.898002362467832, 46.554401806776667],
          [18.898199991830339, 46.550874394143868],
          [18.898199991830339, 46.550874394143868],
          [18.89829880651159, 46.545400581967371],
          [18.897894564633738, 46.539950933992337],
          [18.897894564633738, 46.539950933992337],
          [18.897598120589979, 46.538202234878483],
          [18.89699624934962, 46.53629899304331],
          [18.89379824693815, 46.529099439230109],
          [18.893304173531888, 46.527202058861619],
          [18.893196375697791, 46.52519953994382],
          [18.89340298821314, 46.522300701234968],
          [18.893897061619406, 46.520403083390008],
          [18.89459774754102, 46.518598121529671],
          [18.895397248143883, 46.516799281393418],
          [18.897598120589979, 46.513300335865843],
          [18.901299179560549, 46.508502828830679],
          [18.910003854663668, 46.499401239583804],
          [18.910596742751185, 46.498597357521795],
          [18.915600358883733, 46.494398426522629],
          [18.919202603173051, 46.490700130407447],
          [18.920199733138425, 46.488999324532173],
          [18.92189754902541, 46.484502756124819],
          [18.924098421471506, 46.478100535351572],
          [18.924403848668103, 46.476102393372415],
          [18.924197236152754, 46.475100201655458],
          [18.923496550231143, 46.473299922031778],
          [18.92220297622201, 46.468102904507603],
          [18.921798734344154, 46.46539902177507],
          [18.921601104981651, 46.459997041530322],
          [18.920900419060036, 46.456599645407948],
          [18.920900419060036, 46.455702301391483],
          [18.920603975016277, 46.454798753855258],
          [18.919804474413411, 46.449500940654971],
          [18.918403102570185, 46.444097383078251],
          [18.917495804133225, 46.442302268257365],
          [18.914603228918359, 46.438098992179953],
          [18.90989605682957, 46.432700494856668],
          [18.906796869099363, 46.42959859336878],
          [18.89650217594335, 46.420997787592881],
          [18.89009718796758, 46.414798669375628],
          [18.888695816124354, 46.413200780417867],
          [18.885497813712885, 46.408902350638478],
          [18.884204239703752, 46.405501767782845],
          [18.883000497223037, 46.39749810240626],
          [18.883000497223037, 46.396500659356718],
          [18.883296941266796, 46.39469778125104],
          [18.885201369669126, 46.389202043053508],
          [18.887599871477725, 46.384399789487922],
          [18.890501429845433, 46.379900779024389],
          [18.894103674134751, 46.375097707216952],
          [18.895100804100124, 46.372500773733698],
          [18.895801490021739, 46.369798344299532],
          [18.896699805305857, 46.363500421082762],
          [18.896295563428005, 46.352402903988512],
          [18.896295563428005, 46.352402903988512],
          [18.895900304702991, 46.348198898375358],
          [18.895397248143883, 46.346102975384603],
          [18.895298433462631, 46.344000770717784],
          [18.896097934065498, 46.340199233719673],
          [18.897202861864965, 46.336502869268635],
          [18.898496435874097, 46.333302459303304],
          [18.899098307114457, 46.332297640798345],
          [18.902098680163416, 46.328402243946933],
          [18.906096183177748, 46.324698878565592],
          [18.909698427467067, 46.32180176638596],
          [18.912896429878533, 46.319797892407401],
          [18.916202230124092, 46.317998686059951],
          [18.920199733138425, 46.315200492435054],
          [18.923101291506132, 46.312699989937741],
          [18.923900792108995, 46.311800277467327],
          [18.924296050834009, 46.310801269050749],
          [18.924502663349354, 46.309802242399336],
          [18.924403848668103, 46.308697707237094],
          [18.923101291506132, 46.303900787538581],
          [18.922598234947024, 46.301201171826882],
          [18.92189754902541, 46.298600726462091],
          [18.920496177182184, 46.296099465592278],
          [18.917199360089466, 46.29210217631082],
          [18.914198987040507, 46.287999065102881],
          [18.913004227712626, 46.285298665366511],
          [18.911998114594411, 46.283597655589773],
          [18.910902169947789, 46.280102349305643],
          [18.910497928069933, 46.27460129137917],
          [18.910497928069933, 46.269900740052435],
          [18.9112974286728, 46.264702963658834],
          [18.913695930481399, 46.259299734611638],
          [18.914603228918359, 46.256200400866895],
          [18.914899672962118, 46.253902184585257],
          [18.914899672962118, 46.251299493639181],
          [18.914198987040507, 46.246702553990858],
          [18.912402356472267, 46.239501978334118],
          [18.909698427467067, 46.230498376209681],
          [18.908297055623841, 46.227198549674533],
          [18.907102296295964, 46.223799085902733],
          [18.906599239736853, 46.221201181924961],
          [18.907299925658467, 46.218702603284598],
          [18.90850366813919, 46.216197695148956],
          [18.915600358883733, 46.208197384656692],
          [18.917495804133225, 46.20579768595347],
          [18.924996736755624, 46.199300566239785],
          [18.925697422677235, 46.198498476173796],
          [18.927601851079569, 46.195899375212363],
          [18.92830253700118, 46.194102317260047],
          [18.92830253700118, 46.193101162601366],
          [18.92830253700118, 46.193101162601366],
          [18.927503036398317, 46.189102580446573],
          [18.924897922074368, 46.182000182400458],
          [18.923801977427743, 46.17949982041776],
          [18.922795864309528, 46.178100314452756],
          [18.922795864309528, 46.178100314452756],
          [18.920397362500928, 46.175400722327218],
          [18.918897175976451, 46.174200169891989],
          [18.915600358883733, 46.172097584868133],
          [18.910596742751185, 46.16970253062518],
          [18.906500425055604, 46.16850185379743],
          [18.902404107360017, 46.167599773653336],
          [18.896699805305857, 46.16669767871543],
          [18.888498186761847, 46.166399050925044],
          [18.884500683747515, 46.16669767871543],
          [18.881895569423566, 46.16710206793082],
          [18.878203493605838, 46.167997934989025],
          [18.867495575419134, 46.171400852572674],
          [18.861297199958706, 46.172999591247496],
          [18.857497326306881, 46.173702523893297],
          [18.853598637973803, 46.17400111203289],
          [18.849996393684481, 46.173602994153185],
          [18.847795521238393, 46.172800529042824],
          [18.845702446626394, 46.171798986393704],
          [18.843896832905312, 46.170697890508301],
          [18.841695960459219, 46.168601392769126],
          [18.839701700528476, 46.165397391706541],
          [18.839099829288113, 46.163798432054264],
          [18.83869558741026, 46.1605008198771],
          [18.839198643969368, 46.156798548066533],
          [18.840698830493846, 46.150600574217165],
          [18.840698830493846, 46.149698200539866],
          [18.840096959253486, 46.144302319584106],
          [18.839701700528476, 46.142497350927954],
          [18.839099829288113, 46.140698547430055],
          [18.838497958047753, 46.139901826755619],
          [18.83689895684202, 46.138401719823008],
          [18.832101953224822, 46.13510258570517],
          [18.828499708935503, 46.132898903005213],
          [18.823100834077945, 46.1301970925917],
          [18.82179827691597, 46.129400219971338],
          [18.819597404469878, 46.127600988607654],
          [18.816597031420919, 46.124699682069128],
          [18.814297344293575, 46.123099539912204],
          [18.811503583759961, 46.120602727213715],
          [18.807703710108136, 46.115801804324505],
          [18.805502837662043, 46.111697960200672],
          [18.801801778691473, 46.106696960145108],
          [18.799403276882874, 46.104498369470889],
          [18.795998661956059, 46.102399349817112],
          [18.791902344260475, 46.100599236667776],
          [18.786799913446675, 46.099901599160674],
          [18.778795924265168, 46.098500068561307],
          [18.776604034971918, 46.097802404493905],
          [18.771995677564384, 46.096002141258843],
          [18.769300731712026, 46.094002472022488],
          [18.767198673947188, 46.091697467368753],
          [18.766695617388081, 46.090800358433853],
          [18.766102729300563, 46.088999866611175],
          [18.766102729300563, 46.088999866611175],
          [18.761700984408375, 46.077597464015739],
          [18.759302482599775, 46.069302760776736],
          [18.757703481394046, 46.065700315077656],
          [18.756400924232072, 46.063200902332149],
          [18.754496495829738, 46.061000577509148],
          [18.747202175722688, 46.0548978013802],
          [18.741704486183874, 46.051101147850709],
          [18.739198186541181, 46.049000096036011],
          [18.735595942251862, 46.046499927281687],
          [18.730502494590905, 46.043600587404761],
          [18.713596200943776, 46.03879919535963],
          [18.708898012007833, 46.037302572284759],
          [18.705098138356004, 46.036398342866867],
          [18.700301134738805, 46.034801874117122],
          [18.696600075768234, 46.033797821318487],
          [18.695504131121609, 46.033398689673106],
          [18.693402073356769, 46.03230106278496],
          [18.689395587189594, 46.02999971746511],
          [18.686997085380995, 46.02789786308724],
          [18.685802326053118, 46.026500740587679],
          [18.68290076768541, 46.021997275048818],
          [18.682298896445051, 46.020200776467121],
          [18.681697025204691, 46.017000618774667],
          [18.682298896445051, 46.011697805564197],
          [18.683197211729173, 46.009801146568329],
          [18.687697771302609, 46.002401059042775],
          [18.695504131121609, 45.994001446777276],
          [18.705996453640125, 45.988702670040908],
          [18.714602314061988, 45.984701726305282],
          [18.721896634169038, 45.980800371679742],
          [18.723001561968506, 45.980001340334852],
          [18.725804305654961, 45.977298281632024],
          [18.728696880869823, 45.974801112458685],
          [18.734203553561478, 45.96950049772915],
          [18.738596315300821, 45.96629740690819],
          [18.739997687144047, 45.965697977525728],
          [18.745297747320354, 45.964199375691749],
          [18.757496868878697, 45.960102990502371],
          [18.760101983202645, 45.958498074098237],
          [18.762500485011245, 45.956699513320025],
          [18.766003914619308, 45.953202144691282],
          [18.767198673947188, 45.951696959187238],
          [18.767602915825041, 45.950897507941136],
          [18.77000141763364, 45.943202200058067],
          [18.77239991944224, 45.937198868407769],
          [18.77370247660421, 45.935399616412404],
          [18.77610097841281, 45.932700628958287],
          [18.77789760898105, 45.93110116700241],
          [18.784697855681834, 45.926502456937044],
          [18.788102470608649, 45.924002998074741],
          [18.798702590961259, 45.917597620710175],
          [18.80616759097229, 45.913297791679426],
          [18.80616759097229, 45.913297791679426],
          [18.811997657166227, 45.909797684884836],
          [18.81419852961232, 45.908697605733302],
          [18.814207512765162, 45.908728858282821],
          [18.819309943578961, 45.90569727904964],
          [18.822220485099507, 45.903403169875297],
          [18.825113060314372, 45.900246270666663],
          [18.826604263686011, 45.897976943119247],
          [18.82741274744172, 45.896351472442618],
          [18.828293096420154, 45.893788133545222],
          [18.828670388839488, 45.891806153827538],
          [18.828993782341769, 45.885765993962075],
          [18.829101580175863, 45.879249888457188],
          [18.829272260079847, 45.877461265176507],
          [18.82984718186168, 45.874822002596481],
          [18.82984718186168, 45.874822002596481],
          [18.830790412910009, 45.872245160853304],
          [18.831652795582762, 45.870631446691661],
          [18.832802639146436, 45.869211589670314],
          [18.83417706153114, 45.868004369929658],
          [18.836090473086312, 45.866784613511676],
          [18.838587789576167, 45.865714958933623],
          [18.841210870205792, 45.865033121589832],
          [18.847867386461118, 45.863694444128889],
          [18.850068258907214, 45.862993815037349],
          [18.852152350366371, 45.862005412554844],
          [18.854056778768705, 45.860691685854249],
          [18.855664763127276, 45.859046355775774],
          [18.856841556149476, 45.857063136561194],
          [18.857416477931313, 45.854754505230432],
          [18.857416477931313, 45.854754505230432],
          [18.857676989363704, 45.850124440830022],
          [18.857676989363704, 45.850124440830022],
          [18.857245798027328, 45.844104780849399],
          [18.857245798027328, 45.844104780849399],
          [18.85672477516254, 45.83840990833346],
          [18.855997139782403, 45.835211762815923],
          [18.848864516426492, 45.818254140418297],
          [18.848065015823629, 45.815925115564688],
          [18.84748111088895, 45.81335180548389],
          [18.847193649998029, 45.810377637192865],
          [18.847445178277585, 45.806407657730482],
          [18.848082982129309, 45.803508266831201],
          [18.849331640374238, 45.799813359976532],
          [18.851164203553836, 45.795297725855981],
          [18.852233198741942, 45.793506402076346],
          [18.854371189118144, 45.791226452171102],
          [18.856931387677886, 45.789434997528069],
          [18.860165322700716, 45.787875257611546],
          [18.864441303453127, 45.786403173881695],
          [18.86885203149815, 45.785438468139304],
          [18.874547350399471, 45.784605299744726],
          [18.876954835360909, 45.78399764276228],
          [18.879299438252463, 45.783176982097928],
          [18.882641171109388, 45.781548154157832],
          [18.884707296262864, 45.780176142931779],
          [18.887375292656699, 45.777739016913657],
          [18.888839546569812, 45.775821819792426],
          [18.889953457522122, 45.773685257762892],
          [18.890645160290894, 45.77137316734003],
          [18.891489576657964, 45.76115872129953],
          [18.891929751147185, 45.757780649422479],
          [18.892414841400608, 45.755436560992116],
          [18.893717398562579, 45.751544153889363],
          [18.896861502057, 45.744993516256919],
          [18.897804733105325, 45.742241392284427],
          [18.898271857053068, 45.740297897272569],
          [18.898774913612172, 45.736335465628798],
          [18.898783896765014, 45.731620307205361],
          [18.899026441891728, 45.728014698076592],
          [18.899556447909358, 45.7244276698842],
          [18.900391881123586, 45.720878041139088],
          [18.90182918557818, 45.716550450159382],
          [18.903859378120291, 45.711990437340219],
          [18.905880587509557, 45.70854665975591],
          [18.908243156706796, 45.70534105862631],
          [18.908243156706796, 45.70534105862631],
          [18.9116028558694, 45.701677289380868],
          [18.917396989451969, 45.696501178788274],
          [18.922885695837941, 45.692316021499281],
          [18.928482200058006, 45.688601196749133],
          [18.933818192845674, 45.685488586053481],
          [18.938848758436748, 45.682978289978536],
          [18.944867470840347, 45.680530642852062],
          [18.952341454004223, 45.678026400745168],
          [18.952341454004223, 45.678026400745168],
          [18.960974263884612, 45.67518310279403],
          [18.963318866776159, 45.674065829183533],
          [18.964396845117104, 45.673337706286361],
          [18.966247374602393, 45.671517357597757],
          [18.966948060524004, 45.670418842675303],
          [18.967451117083112, 45.66921986749513],
          [18.967702645362664, 45.66795181344358],
          [18.967693662209825, 45.666652342113167],
          [18.967693662209825, 45.666652342113167],
          [18.967424167624589, 45.665377952041908],
          [18.966957043676846, 45.664285594603811],
          [18.965618553903507, 45.662333109535297],
          [18.963247001553434, 45.65967736942487],
          [18.96058798831244, 45.657304048276053],
          [18.957012693481644, 45.654767371604159],
          [18.941328108620915, 45.645454773880722],
          [18.9387948595197, 45.643294381626028],
          [18.937204841466809, 45.641366286789577],
          [18.935974149527564, 45.6391994560801],
          [18.935273463605949, 45.637151881073507],
          [18.934725491282634, 45.634319069593474],
          [18.934159552653643, 45.632993691769983],
          [18.933054624854176, 45.631423298328095],
          [18.9312939268973, 45.629808888001762],
          [18.929245768049508, 45.628571351325824],
          [18.92701794614489, 45.627679302867556],
          [18.918537849862801, 45.625449119612753],
          [18.912474221694996, 45.623199999883525],
          [18.910506911222775, 45.622301583263265],
          [18.909024691003978, 45.621378021101378],
          [18.907147212060167, 45.619555983108725],
          [18.906266863081729, 45.618054327980339],
          [18.905808722286832, 45.616370415820477],
          [18.905673974994212, 45.609885625075847],
          [18.905943469579448, 45.607686155613699],
          [18.906293812540255, 45.606630379636989],
          [18.907362807728358, 45.604688454079024],
          [18.908827061641471, 45.603016710273707],
          [18.91120759714439, 45.600684984403834],
          [18.91244727223647, 45.599000550713384],
          [18.913165924463769, 45.59760519794461],
          [18.913255755992179, 45.597089788596932],
          [18.913058126629675, 45.596071526946218],
          [18.912537103764883, 45.595065818290365],
          [18.911036917240406, 45.593387501836155],
          [18.899691195201978, 45.585705577746133],
          [18.896205731899592, 45.58314679843491],
          [18.894004859453499, 45.581279507493562],
          [18.892289077260831, 45.579493891738601],
          [18.890420581469861, 45.577029145719855],
          [18.888929378098226, 45.574312769691844],
          [18.888183776412404, 45.572382302230302],
          [18.887932248132852, 45.571382457493762],
          [18.887779534534552, 45.569552506876661],
          [18.888040045966942, 45.567735074231187],
          [18.888668866665828, 45.566018206682323],
          [18.889630064019837, 45.564445937623191],
          [18.890824823347717, 45.563056015130847],
          [18.893744348021105, 45.560760366486058],
          [18.896978283043932, 45.55893635959697],
          [18.90160460675715, 45.557150033393263],
          [18.904883457544187, 45.556332329916565],
          [18.916588505696264, 45.554470437609453],
          [18.919238535784416, 45.553753341009738],
          [18.921169913645272, 45.552891554920222],
          [18.922948577907828, 45.551740389382168],
          [18.924475713890832, 45.55028095436829],
          [18.926371159140327, 45.547739433975146],
          [18.926371159140327, 45.547739433975146],
          [18.928464233752322, 45.545468324718769],
          [18.930790870338193, 45.54344249333252],
          [18.930790870338193, 45.54344249333252],
          [18.934994985867871, 45.540384733804238],
          [18.939567410664043, 45.537490404923311],
          [18.942451002726067, 45.535999138193716],
          [18.946475455198918, 45.534444906730954],
          [18.950625671811554, 45.533356289883855],
          [18.955350810206021, 45.532651509358239],
          [18.958458981089077, 45.532557118438632],
          [18.96309428795513, 45.533035364132587],
          [18.98283027474724, 45.536408139333908],
          [18.987187103875215, 45.537547034526014],
          [18.99140918571058, 45.539113764251525],
          [18.994535322899313, 45.540661574846773],
          [18.996547549135741, 45.541894759294252],
          [19.000248608106315, 45.544826609632914],
          [19.002871688735944, 45.547494083102478],
          [19.004632386692816, 45.549620421754121],
          [19.007695641811665, 45.554212535500056],
          [19.008638872859994, 45.555225266250147],
          [19.009743800659457, 45.556049275940772],
          [19.011657212214633, 45.556999073646999],
          [19.013112482974908, 45.557439371773349],
          [19.015358271185207, 45.557772738059775],
          [19.01763999200687, 45.557722418747055],
          [19.019598319326249, 45.557370182296253],
          [19.02192495591212, 45.556539901925994],
          [19.024062946288321, 45.555313328931128],
          [19.025877543162245, 45.553640114394383],
          [19.027135184560013, 45.551532799644647],
          [19.033225762186344, 45.537490404923311],
          [19.033917464955117, 45.536527692951474],
          [19.035130190588674, 45.535401361945425],
          [19.036953770615437, 45.534306471075965],
          [19.038957013699026, 45.533576531986832],
          [19.041050088311025, 45.533186388243664],
          [19.056905353075734, 45.532997608041448],
          [19.060902856090067, 45.532701851117267],
          [19.064864426493031, 45.532129210949428],
          [19.068781081131792, 45.53127338620385],
          [19.072437224338159, 45.530184707965482],
          [19.084878891023216, 45.525464760915277],
          [19.084878891023216, 45.525464760915277],
          [19.084878891023216, 45.525464760915277],
          [19.084878891023216, 45.525464760915277],
          [19.086819252036914, 45.524722119854573],
          [19.086819252036914, 45.524722119854573],
          [19.092478638326863, 45.522550782552841],
          [19.095541893445713, 45.520977297358257],
          [19.097302591402585, 45.519705889177558],
          [19.098874643149799, 45.518214150871941],
          [19.100743138940768, 45.515551582952611],
          [19.101470774320902, 45.51371981414492],
          [19.101857049893074, 45.511995001385181],
          [19.102054679255581, 45.508475967626275],
          [19.101461791168063, 45.504944122205522],
          [19.100078385630518, 45.501493905951435],
          [19.100078385630518, 45.501493905951435],
          [19.09804819308841, 45.498377196735824],
          [19.095991051087772, 45.496097797140884],
          [19.09356559982065, 45.494000919903172],
          [19.089999288142696, 45.491652072810354],
          [19.085786189460176, 45.489643195570416],
          [19.082390557686203, 45.488459246900682],
          [19.078905094383821, 45.48757756683127],
          [19.074979456592217, 45.486954084737896],
          [19.050590196628374, 45.484699415124936],
          [19.046799306129387, 45.484598645661222],
          [19.043008415630403, 45.48477499210442],
          [19.037995816345017, 45.485442584353081],
          [19.03305508228236, 45.486626596429559],
          [19.027081285642968, 45.488761534033237],
          [19.020667314514352, 45.491696153952667],
          [19.020667314514352, 45.491696153952667],
          [19.015645732076123, 45.494548760229677],
          [19.013247230267524, 45.495612940575164],
          [19.00983363218787, 45.496318185108876],
          [19.008081917383837, 45.496381152941311],
          [19.005952910160474, 45.496211139632159],
          [19.003509492587668, 45.495644424894486],
          [19.002323716412629, 45.495209939733222],
          [19.000122843966537, 45.493988325810001],
          [18.99828129763409, 45.492288097378463],
          [18.993637007615195, 45.48679034082172],
          [18.992073939020827, 45.484460087354293],
          [18.991238505806596, 45.482791062790668],
          [18.990582735649188, 45.480977123421297],
          [18.990160527465651, 45.479093839854755],
          [18.989971881255986, 45.47524519507963],
          [18.99050188727362, 45.468328311921745],
          [18.990412055745207, 45.46498925762787],
          [18.990043746478715, 45.462922724948776],
          [18.989450858391198, 45.460912823247291],
          [18.987779991962736, 45.457069238038628],
          [18.987151171263854, 45.455065429067638],
          [18.986702013621795, 45.45184532274417],
          [18.986809811455888, 45.449412791763116],
          [18.98730388486215, 45.447024271962547],
          [18.988444745272982, 45.444383547922541],
          [18.990169510618493, 45.442083055112178],
          [18.991561899308877, 45.440765744390369],
          [18.993098018444723, 45.439618587511958],
          [18.996745178498252, 45.437633068566271],
          [19.000554035302915, 45.435981567838994],
          [19.016283535927847, 45.430635921114614],
          [19.018852717640428, 45.429602034778483],
          [19.022823271196238, 45.427408115676684],
          [19.025230756157676, 45.425579784618876],
          [19.027377729686727, 45.423455063446212],
          [19.02832994388789, 45.42227602936525],
          [19.029174360254963, 45.42102130829943],
          [19.030449967958411, 45.418480255098757],
          [19.030997940281726, 45.416550743779339],
          [19.031141670727184, 45.415226531194143],
          [19.031096754962981, 45.413889675562906],
          [19.030737428849331, 45.412035681887573],
          [19.030324203818637, 45.410881634683562],
          [19.029138427643598, 45.408743747233387],
          [19.028392825957781, 45.407772526794496],
          [19.02666806061227, 45.406031856172099],
          [19.023667687563311, 45.403912706495419],
          [19.019715100313185, 45.40212145846629],
          [19.015502001630665, 45.401042897929003],
          [19.011639245908952, 45.400645528330259],
          [18.990043746478715, 45.40050676368503],
          [18.986935575595666, 45.400216618325516],
          [18.983360280764867, 45.399238943645315],
          [18.981132458860252, 45.398172943586857],
          [18.977979372212992, 45.396223814554965],
          [18.974305262700941, 45.39344823970994],
          [18.972778126717941, 45.391965774569982],
          [18.971421670638918, 45.390319246432156],
          [18.969831652586027, 45.387549690528871],
          [18.969220798192829, 45.385776851167627],
          [18.968960286760431, 45.38418061727274],
          [18.968960286760431, 45.382552790836506],
          [18.969229781345668, 45.380956465906813],
          [18.970101147171263, 45.3786849377178],
          [18.971951676656552, 45.376015775484817],
          [18.975185611679382, 45.37311930797447],
          [18.979829901698277, 45.369654710821358],
          [18.984105882450688, 45.366909387510283],
          [18.988534576801396, 45.364429015330515],
          [19.023443108742281, 45.346009008064101],
          [19.025428385520186, 45.345099846294673],
          [19.027494510673659, 45.344411651634843],
          [19.029928945093626, 45.343862352645786],
          [19.033656953522719, 45.343521405764918],
          [19.067730052249374, 45.343193084605637],
          [19.071341279691531, 45.342997353777854],
          [19.076120317003049, 45.342372273377173],
          [19.079650696069638, 45.341608276848369],
          [19.084169221948759, 45.340238108489402],
          [19.087457055888638, 45.338905793174007],
          [19.090574209924533, 45.337232461754809],
          [19.094122555296806, 45.33455502862801],
          [19.096152747838914, 45.332395308728472],
          [19.097042079970194, 45.331208059199163],
          [19.098488367577627, 45.32862507398788],
          [19.099512447001523, 45.32583986504541],
          [19.100060419324834, 45.323844030197144],
          [19.100608391648148, 45.320780665881379],
          [19.100779071552129, 45.31871517006185],
          [19.100662290565193, 45.31477346451608],
          [19.099638211141301, 45.308133850258045],
          [19.099656177446981, 45.305935214750903],
          [19.100051436171995, 45.303237347197495],
          [19.100806021010655, 45.301335494556817],
          [19.102090611866945, 45.299477809456597],
          [19.103195539666412, 45.298353058964558],
          [19.105773704531835, 45.296533190690674],
          [19.108648313441016, 45.295231443525978],
          [19.141203259337509, 45.282408269211132],
          [19.14856046151445, 45.279955799232688],
          [19.160256526513685, 45.276592961883544],
          [19.168287465153714, 45.273994843936755],
          [19.19397029912669, 45.264872042618315],
          [19.224495052481071, 45.254590538593973],
          [19.231286316029014, 45.252060977975042],
          [19.240188620494639, 45.248468808306598],
          [19.244500533858414, 45.246925623407677],
          [19.259439517033318, 45.242302142292793],
          [19.27048879502799, 45.239651851449786],
          [19.283056225852825, 45.237475863927344],
          [19.299082170521515, 45.235565480609147],
          [19.327756394390612, 45.23336403210844],
          [19.349136298152654, 45.232174708357988],
          [19.36450647266394, 45.231807784304884],
          [19.397115317477478, 45.231504121228106],
          [19.401876388483309, 45.231643300339691],
          [19.401876388483309, 45.231643300339691],
          [19.410042074415959, 45.232256949631108],
          [19.421756105720878, 45.233749924360346],
          [19.421756105720878, 45.233749924360346],
          [19.453089342830964, 45.237906007603847],
          [19.459260768832866, 45.238563868104933],
          [19.469851906032638, 45.239082560437573],
          [19.482634932525659, 45.23886116794796],
          [19.487917026396278, 45.239367206656667],
          [19.491177910877635, 45.240044026384496],
          [19.494411845900466, 45.241024451878559],
          [19.51475868708577, 45.249208763243288],
          [19.518540594431915, 45.250530539097909],
          [19.522520131140563, 45.251681534164419],
          [19.5280447701379, 45.252914717280071],
          [19.533371779772725, 45.253705205193555],
          [19.537836406734801, 45.253850653771103],
          [19.54227408423835, 45.253483869686875],
          [19.545831412763466, 45.252693378692577],
          [19.54915517931471, 45.251232522380349],
          [19.5518141925557, 45.249322601608732],
          [19.553889300862018, 45.246912974177889],
          [19.556224920600727, 45.243156028283742],
          [19.557877820723508, 45.239329253909851],
          [19.55917139473264, 45.234363550905677],
          [19.559593602916177, 45.230200882100512],
          [19.560249373073585, 45.228378827789911],
          [19.561659728069653, 45.226132812417795],
          [19.565486551180001, 45.221520294160896],
          [19.568513873687483, 45.21707192827364],
          [19.569798464543773, 45.215610155977338],
          [19.57119085323416, 45.214439445439169],
          [19.572861719662622, 45.213369964477579],
          [19.574658350230859, 45.212515630916158],
          [19.576814306912745, 45.211743555472843],
          [19.579365522319645, 45.211060070104779],
          [19.581898771420864, 45.210585422655534],
          [19.587091033763073, 45.210205701844146],
          [19.591959902603001, 45.210534793360488],
          [19.596640125233264, 45.211515727929459],
          [19.621478542839167, 45.220210498535195],
          [19.62629351276205, 45.221438037352584],
          [19.629266936352487, 45.221849320204058],
          [19.632402056694062, 45.22202015989852],
          [19.642229625902331, 45.221646842864374],
          [19.649739541677572, 45.221722771951264],
          [19.655955883443674, 45.222102415864619],
          [19.662827995367191, 45.222836386908703],
          [19.673571846165263, 45.224614329210809],
          [19.701428603125809, 45.231010665267931],
          [19.704994914803763, 45.231662279283057],
          [19.708489361258987, 45.232079814433426],
          [19.712603645260256, 45.232307559586189],
          [19.716870642859824, 45.232244297135296],
          [19.726590414233993, 45.231599016113883],
          [19.73693900630705, 45.23061210155705],
          [19.740990408238432, 45.229922516472051],
          [19.749919662162579, 45.227701869119564],
          [19.754896328836601, 45.226835097182992],
          [19.759962827039033, 45.226303639240314],
          [19.769583783731953, 45.225930350333748],
          [19.777381160398111, 45.22521540033037],
          [19.792122514210515, 45.223007223637111],
          [19.804645029271139, 45.220495239281483],
          [19.809855257919033, 45.220153550214825],
          [19.813915643003256, 45.220425636119629],
          [19.818739596078977, 45.22129250578481],
          [19.824866106316669, 45.222792095821248],
          [19.830462610536735, 45.224513095554826],
          [19.835448260363599, 45.226335273780741],
          [19.840595606941605, 45.228562301181057],
          [19.844817688776967, 45.230681692247657],
          [19.849758422839621, 45.233522184987578],
          [19.849758422839621, 45.233522184987578],
          [19.853172020919278, 45.235622413490809],
          [19.855417809129573, 45.23748851525842],
          [19.857322237531911, 45.239784685198565],
          [19.85858886208252, 45.242295817163487],
          [19.859145817558673, 45.244566493330055],
          [19.859181750170038, 45.246868701851483],
          [19.858615811541043, 45.25007519395119],
          [19.857376136448956, 45.254641128657127],
          [19.857376136448956, 45.254641128657127],
          [19.85688206304269, 45.25691763485338],
          [19.857106641863719, 45.258985382217624],
          [19.858058856064886, 45.260907624153994],
          [19.85972073934051, 45.262602178202457],
          [19.85972073934051, 45.262602178202457],
          [19.861643134048524, 45.263778219563697],
          [19.863915871717346, 45.264625460070313],
          [19.866242508303216, 45.265074365960302],
          [19.869108134059559, 45.265194495103444],
          [19.869108134059559, 45.265194495103444],
          [19.873375131659124, 45.264966881774654],
          [19.876833645502984, 45.264296681673542],
          [19.889904132886922, 45.259554473782721],
          [19.895204193063229, 45.257436159614599],
          [19.89987543254065, 45.25522923483399],
          [19.90393581762487, 45.252971632777431],
          [19.907403314621572, 45.250587456984569],
          [19.911194205120559, 45.247184931997396],
          [19.912829138937653, 45.245331798273597],
          [19.914275426545085, 45.243390054055347],
          [19.916054090807645, 45.240360294521402],
          [19.917796822458836, 45.236204390787286],
          [19.91903649755092, 45.233692989602716],
          [19.92187517384874, 45.229346801414962],
          [19.930939175065504, 45.218837395362769],
          [19.933993447031508, 45.215546874942802],
          [19.945033741873338, 45.204781746218615],
          [19.950567364023517, 45.199895313058995],
          [19.956361497606085, 45.195160389416429],
          [19.962191563800022, 45.190735285121484],
          [19.965578212421153, 45.188418140477594],
          [19.969935041549135, 45.185853977033332],
          [19.974561365262346, 45.183555628675357],
          [19.978738531333505, 45.18178906645673],
          [19.984460799693345, 45.179883143955529],
          [19.990371714262853, 45.178268442081894],
          [19.99621974676247, 45.177001977198699],
          [20.005508326800264, 45.175501179845419],
          [20.025073633688386, 45.1731327525073],
          [20.047073374996476, 45.169795255889333],
          [20.053622093417705, 45.169104933318316],
          [20.061482352153753, 45.168781935480744],
          [20.070582285981882, 45.16883893523223],
          [20.079825950255472, 45.169256931666915],
          [20.079825950255472, 45.169256931666915],
          [20.089518772171122, 45.170092915333761],
          [20.101232803476041, 45.171682517094169],
          [20.106137604927333, 45.172081493837688],
          [20.113593621785526, 45.172043496173011],
          [20.127688188593364, 45.171353200851087],
          [20.131308399188363, 45.171543191223712],
          [20.133536221092982, 45.171923170067615],
          [20.135764042997593, 45.172531130944797],
          [20.159039392009134, 45.18111155314228],
          [20.162381124866059, 45.18213731782695],
          [20.165983369155374, 45.182985775884376],
          [20.171687671209536, 45.18382155802577],
          [20.177356040652331, 45.184093817740461],
          [20.183168140540584, 45.183802563113368],
          [20.188863459441901, 45.182941453761558],
          [20.193804193504558, 45.181694088440601],
          [20.198484416134821, 45.180016117363166],
          [20.202302256092327, 45.178224116286671],
          [20.209569626740855, 45.174057337466969],
          [20.212875426986415, 45.172423471678989],
          [20.216378856594481, 45.171036548821647],
          [20.230078164677302, 45.166387894410761],
          [20.234300246512667, 45.164532126685259],
          [20.238396564208252, 45.162340596165301],
          [20.243094753144195, 45.15920516748492],
          [20.266388068461413, 45.141288742850868],
          [20.273969849459384, 45.134882317806621],
          [20.280437719505045, 45.12871600845309],
          [20.280437719505045, 45.12871600845309],
          [20.284938279078485, 45.123873752548235],
          [20.289250192442257, 45.118695126180477],
          [20.292807520967369, 45.113921753181387],
          [20.302635090175638, 45.099694353297842],
          [20.306201401853592, 45.095268173418148],
          [20.309767713531546, 45.091406082916492],
          [20.313828098615769, 45.087600812226647],
          [20.31745729236361, 45.084664238382196],
          [20.33399527674425, 45.073442979591704],
          [20.338909061348382, 45.069744365701524],
          [20.341963333314389, 45.067130449621978],
          [20.344810992765051, 45.064414895649122],
          [20.347470006006045, 45.061597689315583],
          [20.349805625744754, 45.05881841731356],
          [20.352150228636308, 45.055683642486294],
          [20.354243303248303, 45.052472541116735],
          [20.356093832733592, 45.049178753783458],
          [20.357755716009212, 45.045675326722957],
          [20.365014103504897, 45.025615345973598],
          [20.36555309267537, 45.023539117362077],
          [20.365804620954922, 45.021532659759295],
          [20.365741738885035, 45.019075288356198],
          [20.36526563178445, 45.016547959178283],
          [20.364367316500331, 45.014084023352062],
          [20.363199506630977, 45.012045480844634],
          [20.361753219023541, 45.010337113639288],
          [20.359947605302466, 45.0088128768367],
          [20.357495204576814, 45.007263194504958],
          [20.354638561973317, 45.005815092794407],
          [20.351332761727758, 45.004506688716468],
          [20.347973062565149, 45.003496783581006],
          [20.328749115484992, 44.999196547966626],
          [20.320772075762012, 44.997608491618969],
          [20.309758730378707, 44.99591875133622],
          [20.306659542648493, 44.995245382301654],
          [20.303901714726248, 44.99436236752004],
          [20.301233718332412, 44.993206169321518],
          [20.29879030075961, 44.991814888919151],
          [20.296679259841927, 44.990277453028369],
          [20.294092111823662, 44.987691672387207],
          [20.292178700268487, 44.984807150448198],
          [20.290166474032059, 44.980353011003267],
          [20.288854933717246, 44.975777785704977],
          [20.288288995088251, 44.971488180434953],
          [20.288351877158139, 44.967185542938395],
          [20.288998664162705, 44.963028774218685],
          [20.290265288713314, 44.958814494810134],
          [20.292017003517344, 44.95494954416057],
          [20.294199909657756, 44.951332273942825],
          [20.29685892289875, 44.94788007938746],
          [20.299958110628964, 44.944612064881511],
          [20.305608513766074, 44.93951257334691],
          [20.311636209322515, 44.934590689415337],
          [20.317376443988042, 44.930329803237349],
          [20.324949241833167, 44.925114557114675],
          [20.32849758720544, 44.922392264965559],
          [20.332683736429438, 44.918690249507492],
          [20.336456660622737, 44.914771705262318],
          [20.338792280361449, 44.911959857883637],
          [20.340921287584813, 44.909065165404861],
          [20.3606752406826, 44.879600993893938],
          [20.362489837556524, 44.877233022993494],
          [20.36451104694579, 44.87494771136199],
          [20.367924645025447, 44.871713744837642],
          [20.371751468135795, 44.868734260516995],
          [20.376108297263777, 44.865945629056455],
          [20.380635806295739, 44.863583463395088],
          [20.380635806295739, 44.863583463395088],
          [20.384067370681073, 44.862074428125418],
          [20.388316401974958, 44.860469840905395],
          [20.404135734128303, 44.855292832031346],
          [20.415885698044587, 44.850962381039345],
          [20.427285319000063, 44.846192139775205],
          [20.441254121668123, 44.839561548883943],
          [20.448440643941076, 44.83660588112761],
          [20.457100403279991, 44.833834804911469],
          [20.466523730610405, 44.831566882467015],
          [20.466523730610405, 44.831566882467015],
          [20.475965024246499, 44.830490225122588],
          [20.486843622337187, 44.829795802319836],
          [20.502636005032009, 44.829579191660272],
          [20.520350782434843, 44.829662013479172],
          [20.520350782434843, 44.829662013479172],
          [20.526405427449809, 44.830528449988051],
          [20.532046847434081, 44.831687925526147],
          [20.537535553820053, 44.83320412757223],
          [20.542970361288976, 44.835115249792771],
          [20.547677533377762, 44.837147340227652],
          [20.551998429894375, 44.839376824090934],
          [20.55578033724052, 44.841657178340277],
          [20.560280896813957, 44.844759079588719],
          [20.562562617635621, 44.846083865361706],
          [20.56533841186355, 44.847395882781257],
          [20.568159121855686, 44.848459486620627],
          [20.572219506939906, 44.84958038917167],
          [20.593446697103651, 44.854031940198709],
          [20.597587930563442, 44.854751543464936],
          [20.601953742844263, 44.855210047050761],
          [20.606364470889289, 44.855362880768119],
          [20.610775198934316, 44.855210047050761],
          [20.615060162839566, 44.854770647853897],
          [20.619030716395375, 44.854070149447743],
          [20.622543129156284, 44.853089437364034],
          [20.625929777777415, 44.851764812713093],
          [20.625929777777415, 44.851764812713093],
          [20.629999146014473, 44.849529439528936],
          [20.633448676705495, 44.846829043970317],
          [20.633448676705495, 44.846829043970317],
          [20.635883111125459, 44.844147629725448],
          [20.642431829546688, 44.835089768580055],
          [20.642431829546688, 44.835089768580055],
          [20.645665764569518, 44.830407404493798],
          [20.646537130395114, 44.828502497184679],
          [20.647103069024109, 44.826540185701084],
          [20.647363580456506, 44.824386663101578],
          [20.647246799469571, 44.822220316598546],
          [20.646779675521827, 44.82008574851362],
          [20.64596220861328, 44.818053056491898],
          [20.64596220861328, 44.818053056491898],
          [20.645001011259271, 44.816377146792583],
          [20.643788285625707, 44.814745796273435],
          [20.637419230261301, 44.808462116603941],
          [20.635281239885096, 44.805855399500039],
          [20.633421727246969, 44.803146583331369],
          [20.631732894512822, 44.800106184045383],
          [20.630062028084364, 44.796319802447961],
          [20.628552858407041, 44.792278171531933],
          [20.627259284397908, 44.788013115263674],
          [20.62620825551549, 44.783530960179206],
          [20.625507569593879, 44.779188739830445],
          [20.625327906537052, 44.77624273040356],
          [20.625489603288191, 44.77329019353877],
          [20.626073508222873, 44.770025009403504],
          [20.627124537105292, 44.766702239957816],
          [20.628606757324086, 44.763462196254743],
          [20.63043932050369, 44.760445220532382],
          [20.632999519063432, 44.757166552434107],
          [20.636116673099327, 44.75401528364381],
          [20.639584170096029, 44.751195581692109],
          [20.643437942664903, 44.748650076487877],
          [20.648612238701428, 44.745881154063838],
          [20.65438840597832, 44.743182284063501],
          [20.659966943892702, 44.740898035595052],
          [20.667045668331561, 44.738332933933378],
          [20.670001125616317, 44.737043959294532],
          [20.673908797102236, 44.734931765467131],
          [20.677439176168829, 44.732500413615305],
          [20.680538363899039, 44.729788155113773],
          [20.683089579305936, 44.726922595609331],
          [20.685002990861111, 44.724152632562337],
          [20.688012347062912, 44.718388897268554],
          [20.690653393998225, 44.714303510161479],
          [20.694192756217657, 44.709930549708005],
          [20.698432804358699, 44.705723258391252],
          [20.727187876603363, 44.68150144647273],
          [20.730942834490985, 44.678556844768408],
          [20.735254747854757, 44.675573765944733],
          [20.739845138956611, 44.672801345163869],
          [20.744489428975506, 44.670335440124418],
          [20.74953796087226, 44.668003594028995],
          [20.754406829712185, 44.666054993151661],
          [20.75962604151292, 44.664259666826219],
          [20.764997966911956, 44.662707080012275],
          [20.770289043935421, 44.661441978531826],
          [20.776298773186181, 44.66029825191869],
          [20.783413430236408, 44.659212010223861],
          [20.790321474771282, 44.65839412069441],
          [20.79698697417945, 44.657819035211148],
          [20.803688406198987, 44.657448421544117],
          [20.810398821371358, 44.65728228361408],
          [20.828742419473077, 44.65751232062123],
          [20.847364495312874, 44.658406900307028],
          [20.863650951413963, 44.659812640499815],
          [20.878554001977502, 44.661729504019654],
          [20.893277389484222, 44.664234110178221],
          [20.90874637867676, 44.667549991149691],
          [20.912842696372348, 44.668655242656826],
          [20.916983929832139, 44.670009626171279],
          [20.921143129597613, 44.671625900925626],
          [20.925122666306262, 44.673459327997975],
          [20.928913556805245, 44.675484335086587],
          [20.932488851636037, 44.677707289780138],
          [20.935606005671932, 44.679923771762283],
          [20.938489597733959, 44.682274299782783],
          [20.945658153701231, 44.689172037932771],
          [20.945658153701231, 44.689172037932771],
          [20.949565825187154, 44.692620599002616],
          [20.953680109188422, 44.695928470180846],
          [20.953680109188422, 44.695928470180846],
          [20.963103436518832, 44.702837352181291],
          [20.966373304153027, 44.704835793702522],
          [20.971008611019087, 44.707319383662735],
          [20.976039176610154, 44.70966879998889],
          [20.98127635471657, 44.711775532442026],
          [20.986693195879813, 44.713633223796997],
          [20.992271733794194, 44.715235514489422],
          [20.99777840648585, 44.716524975178928],
          [21.003599489526941, 44.717597376044687],
          [21.009510404096446, 44.718395280460221],
          [21.015484200735845, 44.718912316646509],
          [21.021269351165571, 44.719142109024077],
          [21.027072467900986, 44.719116576582749],
          [21.032444393300018, 44.718848485268559],
          [21.039585999808768, 44.718235700457342],
          [21.039585999808768, 44.718235700457342],
          [21.050132221244333, 44.717782490849309],
          [21.060696408985578, 44.718025054179634],
          [21.071179748351252, 44.718963381698174],
          [21.081734952939655, 44.720622971343502],
          [21.089541312758659, 44.722352723730729],
          [21.097150043215148, 44.724465376967899],
          [21.120856583563061, 44.732640809416445],
          [21.124683406673412, 44.733827778477064],
          [21.131627383819655, 44.73542312862137],
          [21.139640356154004, 44.736546228724485],
          [21.142407167229091, 44.737082246084007],
          [21.146171108269552, 44.738173408927175],
          [21.149656571571935, 44.739628260687518],
          [21.149656571571935, 44.739628260687518],
          [21.154759002385735, 44.742646323252892],
          [21.159996180492151, 44.746506406204936],
          [21.1644338579957, 44.750500204182053],
          [21.168997299639031, 44.755501632238193],
          [21.171018509028297, 44.757479118310322],
          [21.174063797841463, 44.759960448643064],
          [21.177351631781338, 44.762129133386011],
          [21.180926926612138, 44.764048989298402],
          [21.184888497015105, 44.765751931012701],
          [21.188391926623169, 44.766944598126393],
          [21.196530663097292, 44.769285215473367],
          [21.202926667920224, 44.771836189027454],
          [21.20854113844597, 44.774820685055857],
          [21.216554110780315, 44.780062311134344],
          [21.221944002485035, 44.782746706660902],
          [21.225186920660704, 44.784021910211465],
          [21.323570410577474, 44.819059853346531],
          [21.327963172316817, 44.820334254534423],
          [21.332508647654464, 44.821296408769619],
          [21.337152937673363, 44.821946331683243],
          [21.341779261386577, 44.822271290392628],
          [21.341779261386577, 44.822271290392628],
          [21.346297787265698, 44.822296777272761],
          [21.350870212061867, 44.822016420971572],
          [21.355379754788146, 44.821436588841273],
          [21.359781499680331, 44.820557271846276],
          [21.359781499680331, 44.820563643756806],
          [21.366159538197579, 44.818709388081643],
          [21.372106385378451, 44.816243326807921],
          [21.374909129064903, 44.814784031579372],
          [21.377253731956458, 44.813337444840762],
          [21.379517486472441, 44.811661398090088],
          [21.381332083346358, 44.810042660314394],
          [21.383182612831643, 44.808054227325364],
          [21.384592967827711, 44.806180448892725],
          [21.385760777697069, 44.804242872490988],
          [21.386712991898236, 44.802190500197412],
          [21.387341812597121, 44.800329279586713],
          [21.387755037627816, 44.79844250152118],
          [21.388060464824417, 44.793508540248943],
          [21.388500639313634, 44.791003150367892],
          [21.389551668196052, 44.788115123277656],
          [21.391294399847247, 44.785144066035585],
          [21.392713737996154, 44.783371559920241],
          [21.394160025603586, 44.781892304523559],
          [21.397717354128702, 44.779093092534531],
          [21.402002318033951, 44.77672736566003],
          [21.406637624900007, 44.774999239757392],
          [21.409530200114872, 44.774253132516762],
          [21.412602438386561, 44.773711082749443],
          [21.4157734913395, 44.773392227568479],
          [21.41892657798676, 44.773315702063101],
          [21.421962883647087, 44.773462375859346],
          [21.434215904122475, 44.774635752818682],
          [21.443306854797765, 44.775216055438456],
          [21.453933924608897, 44.775560408082285],
          [21.464192685153542, 44.77555403120013],
          [21.473355501051564, 44.775273447688363],
          [21.517040573318297, 44.773143519305208],
          [21.517040573318297, 44.773143519305208],
          [21.534791283332495, 44.772269843326555],
          [21.540037444591754, 44.771868075483887],
          [21.547771939188024, 44.770879587148272],
          [21.555201006587694, 44.769476542398195],
          [21.563546355577163, 44.767333644639116],
          [21.571253900714908, 44.764756959928192],
          [21.578377540917977, 44.7618038358641],
          [21.585519147426727, 44.758193548249814],
          [21.589893942860389, 44.755578181345236],
          [21.593181776800268, 44.75314131833936],
          [21.596173166696385, 44.750353472078856],
          [21.598751331561807, 44.747272011860829],
          [21.600718642034028, 44.744139344581129],
          [21.613456752762847, 44.719831480681066],
          [21.614615579479359, 44.718101652914804],
          [21.61607085023963, 44.716486674780427],
          [21.617544087305586, 44.715216363866602],
          [21.619277835803938, 44.714022629201381],
          [21.621218196817637, 44.712956545878008],
          [21.623293305123951, 44.712062808198674],
          [21.627290808138284, 44.710913696616309],
          [21.633632914044171, 44.709917781456483],
          [21.635681072891959, 44.709285749485801],
          [21.637594484447135, 44.708443029455644],
          [21.639543828613672, 44.707255539496892],
          [21.641169779277931, 44.705882872898904],
          [21.642364538605808, 44.704465480672745],
          [21.643819809366086, 44.702192472942635],
          [21.643819809366086, 44.702192472942635],
          [21.646415940537189, 44.698144255038372],
          [21.648230537411113, 44.695756056852687],
          [21.650799719123693, 44.693042075717983],
          [21.653467715517529, 44.690755847031937],
          [21.656153678217045, 44.688827170532264],
          [21.659064219737591, 44.687083642798541],
          [21.662468834664406, 44.685391157255701],
          [21.667714995923664, 44.683219593946447],
          [21.669107384614048, 44.682389268815761],
          [21.670365026011815, 44.681386475677932],
          [21.671380122282873, 44.680294241748186],
          [21.672161656580052, 44.679099786920979],
          [21.673392348519297, 44.67564403295075],
          [21.674515242624448, 44.673670136452039],
          [21.676204075358594, 44.671760056092786],
          [21.678234267900702, 44.670156562102378],
          [21.680695651779189, 44.668802182021444],
          [21.685654352147527, 44.666757774790753],
          [21.689993214969828, 44.665307479693638],
          [21.696047859984795, 44.66362074724622],
          [21.699704003191158, 44.662278993207607],
          [21.703979983943569, 44.660164070222763],
          [21.708597324503941, 44.657077805508266],
          [21.710672432810259, 44.655914822568569],
          [21.713520092260918, 44.654739035885967],
          [21.716754027283748, 44.653869960841448],
          [21.720113726446353, 44.653397076301758],
          [21.723572240290217, 44.653320391958999],
          [21.74098159049645, 44.65474542609541],
          [21.74809624754668, 44.655889262242013],
          [21.75916349184703, 44.658924472251201],
          [21.766754255997839, 44.660349368673558],
          [21.770877523151949, 44.660809417439317],
          [21.775494863712325, 44.661071388021952],
          [21.779959490674397, 44.661077777533571],
          [21.784388185025104, 44.660841365134722],
          [21.789921807175283, 44.660202407881862],
          [21.795572210312393, 44.659135333570589],
          [21.800782438960287, 44.657748746594258],
          [21.809558979286138, 44.654822108553823],
          [21.813026476282836, 44.654029718260482],
          [21.816664653183523, 44.653480150891994],
          [21.820383678459773, 44.653205365254742],
          [21.824102703736028, 44.653211755633187],
          [21.827201891466242, 44.653429028081312],
          [21.833633828900538, 44.654291719474962],
          [21.838224220002388, 44.654681523969273],
          [21.843317667663346, 44.654796547745619],
          [21.848402132171465, 44.654592060874378],
          [21.854501692950635, 44.65390191236046],
          [21.860026331947971, 44.652847502938023],
          [21.865568937250988, 44.651339344626358],
          [21.872557830161437, 44.648866135728156],
          [21.876151091297913, 44.647792461828054],
          [21.880867246539541, 44.64670598586526],
          [21.885448654488552, 44.645977396467707],
          [21.890425321162574, 44.645530012478282],
          [21.895455886753641, 44.645434144031654],
          [21.899713901200368, 44.645619489552203],
          [21.90615482178751, 44.646213869468234],
          [21.910727246583676, 44.646335301174972],
          [21.91497627787756, 44.646169131406936],
          [21.919171410254396, 44.645728140099266],
          [21.92480384708583, 44.644692756027446],
          [21.930472216528621, 44.643088513344416],
          [21.93540396743844, 44.641151858494737],
          [21.941925736401146, 44.637834467806179],
          [21.945303401869438, 44.636377057018741],
          [21.949803961442875, 44.634881255253298],
          [21.954529099837345, 44.633775359927093],
          [21.960062721987519, 44.633014645127652],
          [21.966063468085437, 44.632797296210583],
          [21.971417427178793, 44.633129711871852],
          [21.978621915757429, 44.634171694779148],
          [21.982053480142767, 44.63424201197018],
          [21.984586729243983, 44.634011882664481],
          [21.986931332135534, 44.633577191486033],
          [21.989248985568562, 44.632905970770871],
          [21.991198329735102, 44.632132459175949],
          [21.993524966320972, 44.630892262045435],
          [21.995618040932971, 44.629377139896512],
          [21.997217042138704, 44.627804439630282],
          [21.998573498217723, 44.625963175415798],
          [21.999265200986496, 44.624626943633267],
          [21.999768257545604, 44.623226744314806],
          [22.000603690759831, 44.618335372077304],
          [22.001771500629189, 44.61469694692277],
          [22.003631013267317, 44.61101352862535],
          [22.00613731291001, 44.607502553110415],
          [22.008715477775436, 44.604726877637347],
          [22.011760766588598, 44.602110969913973],
          [22.01469825756767, 44.600045023114582],
          [22.019297631822361, 44.59732016360531],
          [22.021507487421296, 44.595663437731801],
          [22.023834124007163, 44.593290207569126],
          [22.025630754575403, 44.590609812220798],
          [22.026744665527712, 44.587820534503415],
          [22.027193823169771, 44.585127091149303],
          [22.026502120400998, 44.57165800234332],
          [22.026618901387934, 44.568618227803626],
          [22.027050092724313, 44.565488693945952],
          [22.027903492244228, 44.562038971773937],
          [22.029224015711883, 44.558409822473983],
          [22.0309936968216, 44.554844458917628],
          [22.033023889363708, 44.551618160720622],
          [22.034946284071726, 44.549127899535449],
          [22.037057324989402, 44.546880811494042],
          [22.039419894186636, 44.544780890129275],
          [22.041962126440698, 44.542860164015792],
          [22.04445045977771, 44.541259510512639],
          [22.047396933909617, 44.539652410123317],
          [22.055400923091124, 44.536213962703812],
          [22.059847583747516, 44.533889534066653],
          [22.064267294945385, 44.530892613119406],
          [22.068112084361413, 44.527479265316067],
          [22.071346019384247, 44.523636608196284],
          [22.073735538040005, 44.51968481143453],
          [22.075109960424708, 44.516366888529284],
          [22.076852692075899, 44.51026859158852],
          [22.078658305796981, 44.506399173163047],
          [22.081056807605581, 44.502811403417482],
          [22.083877517597713, 44.499633480102418],
          [22.087946885834775, 44.496122185993514],
          [22.092698973687767, 44.49302720218693],
          [22.115785676489637, 44.480940454380459],
          [22.12150794484948, 44.478344617197891],
          [22.128910062790627, 44.475761484328821],
          [22.13632116388461, 44.473928223887903],
          [22.144217355232023, 44.472697471282359],
          [22.151933883522609, 44.472159008855563],
          [22.151933883522609, 44.472159008855563],
          [22.158446669332474, 44.472210291205492],
          [22.163144858268421, 44.4726205483827],
          [22.167905929274255, 44.473505155607377],
          [22.172631067668721, 44.474889731300934],
          [22.176996879949545, 44.476703732147215],
          [22.181093197645129, 44.479011213569017],
          [22.184740357698651, 44.481735206128519],
          [22.18763293291352, 44.484600068138363],
          [22.189151085743681, 44.486516306706001],
          [22.190444659752814, 44.488509384381786],
          [22.236357553924162, 44.568567030240459],
          [22.239699286781086, 44.573366605861345],
          [22.24377763817099, 44.577890643148208],
          [22.24377763817099, 44.577890643148208],
          [22.246391735647776, 44.580309290966987],
          [22.249230411945593, 44.582619069694566],
          [22.252257734453075, 44.58479440065031],
          [22.256264220620253, 44.587385497472141],
          [22.258581874053277, 44.589125626061524],
          [22.260881561180625, 44.591217549926505],
          [22.262875821111368, 44.593437337863506],
          [22.264582620151195, 44.595816958717357],
          [22.266100772981357, 44.598580267101816],
          [22.26889453351497, 44.604528610028403],
          [22.27144574892187, 44.61062343071535],
          [22.272128468537797, 44.612752949711521],
          [22.27257762617986, 44.614812049980188],
          [22.273035766974761, 44.620662821499742],
          [22.273538823533865, 44.622133414517648],
          [22.274329340983893, 44.623508065610331],
          [22.275703763368597, 44.625080882281765],
          [22.277428528714108, 44.62642349694493],
          [22.27909939514257, 44.627350522279642],
          [22.282638757362001, 44.6287442290595],
          [22.28499234340639, 44.629958900336042],
          [22.286501513083714, 44.63098176169467],
          [22.289995959538938, 44.63379453748226],
          [22.295242120798196, 44.636843688140601],
          [22.297451976397131, 44.638639863326688],
          [22.298547921043756, 44.639988556186836],
          [22.29941030371651, 44.641586492932127],
          [22.300551164127342, 44.646252216350462],
          [22.301467445717144, 44.649230413428825],
          [22.302877800713212, 44.652694132697718],
          [22.30430612201496, 44.655397223759842],
          [22.306021904207629, 44.657774306101174],
          [22.308168877736673, 44.660068225964118],
          [22.310432632252656, 44.661991470444903],
          [22.313181477022063, 44.663869926720018],
          [22.313181477022063, 44.663869926720018],
          [22.315849473415899, 44.665333035868436],
          [22.318760014936444, 44.666623608044979],
          [22.321841236360974, 44.667696933316186],
          [22.325317716510515, 44.668597744543185],
          [22.383384816476003, 44.678116099750355],
          [22.387732662451139, 44.679106174445437],
          [22.391712199159791, 44.680453926356357],
          [22.394550875457607, 44.681718612907112],
          [22.397398534908266, 44.683296238743168],
          [22.399850935633914, 44.684969624862788],
          [22.402078757538529, 44.686834563123831],
          [22.403821489189724, 44.688616417221105],
          [22.405303709408518, 44.690462079260939],
          [22.408726290641013, 44.696247752766332],
          [22.411547000633149, 44.699874679346323],
          [22.413469395341167, 44.701828528023178],
          [22.415634335175895, 44.703692923450269],
          [22.420197776819222, 44.706827781769292],
          [22.420197776819222, 44.706827781769292],
          [22.425452921231319, 44.709490043402923],
          [22.430950610770132, 44.711488255259418],
          [22.436654912824292, 44.712879940332016],
          [22.442709557839258, 44.713703444637339],
          [22.448845051229792, 44.713894955587008],
          [22.455178173982837, 44.713441711981183],
          [22.461304684220529, 44.71233093095168],
          [22.466829323217866, 44.71070940772421],
          [22.466829323217866, 44.71070940772421],
          [22.471042421900389, 44.70930490207116],
          [22.475237554277225, 44.707395996568799],
          [22.520045520649106, 44.681450348369872],
          [22.529208336547125, 44.676576659687385],
          [22.531993113927896, 44.674915807117877],
          [22.531993113927896, 44.674915807117877],
          [22.532819563989285, 44.674423930105277],
          [22.532819563989285, 44.674423930105277],
          [22.536394858820081, 44.672309450204118],
          [22.536394858820081, 44.672309450204118],
          [22.542036278804353, 44.669070505117396],
          [22.544488679529998, 44.667473325523638],
          [22.546869215032913, 44.66585054598405],
          [22.551064347409753, 44.662489842327332],
          [22.583403697638058, 44.632516019933114],
          [22.587230520748406, 44.629958900336042],
          [22.594066700060555, 44.626110222967725],
          [22.594066700060555, 44.626110222967725],
          [22.603418162168239, 44.622779184898718],
          [22.603418162168239, 44.622779184898718],
          [22.617333065919251, 44.617414597100172],
          [22.617333065919251, 44.617414597100172],
          [22.621600063518819, 44.61620605778409],
          [22.625974858952482, 44.615336405465797],
          [22.630466435373076, 44.614799260762851],
          [22.63505682647493, 44.614601027533993],
          [22.638784834904026, 44.614703341543056],
          [22.642243348747883, 44.61501028248869],
          [22.652430244069798, 44.616724006285224],
          [22.658565737460336, 44.617369836833305],
          [22.66574327658045, 44.617568060610338],
          [22.672857933630677, 44.617165218031097],
          [22.680278017877505, 44.616103746422624],
          [22.68745555699762, 44.614396398975074],
          [22.694264786851246, 44.612087883094731],
          [22.700615875909971, 44.609203708005445],
          [22.706239329588559, 44.605916469080555],
          [22.711207013109739, 44.602232493908531],
          [22.734392530592867, 44.580763576026783],
          [22.741606002324346, 44.574819199334641],
          [22.750553222554174, 44.568131849134097],
          [22.754478860345777, 44.564419877964802],
          [22.756428204512318, 44.562083774671677],
          [22.758233818233396, 44.559408341150721],
          [22.759707055299351, 44.556630368508557],
          [22.760820966251661, 44.553762644732117],
          [22.761602500548847, 44.550741140015717],
          [22.761970809815335, 44.547745086391522],
          [22.761952843509651, 44.54443516410565],
          [22.761566567937479, 44.542399180318917],
          [22.760452656985173, 44.540004567390305],
          [22.758575178041362, 44.53773791789844],
          [22.755997013175939, 44.535765732997298],
          [22.7529786738213, 44.53429935742291],
          [22.709554112986961, 44.518896981980667],
          [22.706140514907307, 44.518045089364826],
          [22.702735899980492, 44.517507045505944],
          [22.699169588302539, 44.51723802171341],
          [22.695621242930269, 44.517263643080497],
          [22.691587807304568, 44.517654367532664],
          [22.687967596709569, 44.518346135546821],
          [22.684239588280469, 44.519435013004944],
          [22.681014636410485, 44.520735234135707],
          [22.634230376413537, 44.546035730297284],
          [22.630565250054332, 44.5477642923546],
          [22.62636113452465, 44.549351963387188],
          [22.622327698898953, 44.550523483195335],
          [22.618464943177241, 44.551374900827668],
          [22.614485406468592, 44.551976647130658],
          [22.610074678423565, 44.552354335781054],
          [22.605780731365471, 44.552424752038],
          [22.601450851696018, 44.552213503011551],
          [22.597201820402134, 44.551714184082691],
          [22.593051603789501, 44.550945992750165],
          [22.588604943133106, 44.549780883211653],
          [22.584382861297744, 44.548308458675059],
          [22.580430274047622, 44.546560706423577],
          [22.576648366701477, 44.544486382905376],
          [22.573225785468981, 44.542175089703356],
          [22.570306260795594, 44.539774064692722],
          [22.567701146471649, 44.537155233857078],
          [22.565284678357365, 44.534120060059422],
          [22.563757542374361, 44.531718702879019],
          [22.562598715657849, 44.529451730845942],
          [22.561673450915205, 44.527120628043349],
          [22.560918866076545, 44.524469205416366],
          [22.560496657893008, 44.521964973234191],
          [22.558044257167364, 44.499973064851545],
          [22.557496284844049, 44.49732040683687],
          [22.556580003254247, 44.494622773357662],
          [22.555564906983189, 44.492463295107811],
          [22.554316248738267, 44.490374228186759],
          [22.552887927436515, 44.488413256796541],
          [22.551252993619418, 44.486529124143352],
          [22.549420430439813, 44.484741064425862],
          [22.547264473757927, 44.482952949900486],
          [22.542656116350393, 44.479927771144602],
          [22.537877079038878, 44.477620325969745],
          [22.532379389500068, 44.475723024911005],
          [22.526513390694767, 44.474434619766733],
          [22.520656375042307, 44.473800021702544],
          [22.515365298018843, 44.473755150871121],
          [22.510280833510727, 44.47418462741372],
          [22.475875358128949, 44.479825219963395],
          [22.472857018774306, 44.48009441642963],
          [22.468850532607135, 44.479850857775602],
          [22.465212355706448, 44.478998394479852],
          [22.461771808168272, 44.477492131895573],
          [22.460172806962539, 44.476460159339418],
          [22.458915165564772, 44.475428168532368],
          [22.457073619232329, 44.47331926099907],
          [22.455860893598768, 44.470953860659954],
          [22.455348853886818, 44.468402454181152],
          [22.455591399013532, 44.46587658012308],
          [22.456319034393669, 44.463908374066314],
          [22.46429607411665, 44.448583588639565],
          [22.466622710702516, 44.44472931729932],
          [22.468473240187802, 44.442253727708177],
          [22.47032376967309, 44.440117966389458],
          [22.472623456800438, 44.43780894740717],
          [22.475111790137447, 44.435647366391876],
          [22.478309792548913, 44.433241952276617],
          [22.48145389604333, 44.431214913112477],
          [22.485119022402539, 44.42919421865642],
          [22.489871110255532, 44.426910429082668],
          [22.492494190885161, 44.425274513724283],
          [22.494156074160781, 44.423952917297385],
          [22.495782024825036, 44.422336166474672],
          [22.497012716764281, 44.420764282531401],
          [22.498000863576813, 44.419128195175347],
          [22.498899178860931, 44.416998004992095],
          [22.499420201725723, 44.414745821940258],
          [22.49970766261664, 44.408065760374193],
          [22.500129870800176, 44.402938087027174],
          [22.500830556721791, 44.397630247120063],
          [22.501800737228638, 44.392321925686723],
          [22.502932614486628, 44.38741755876147],
          [22.504217205342918, 44.382814526463591],
          [22.505726375020242, 44.378159767342325],
          [22.507388258295862, 44.373703692632112],
          [22.509400484532289, 44.368900511949917],
          [22.511709154812475, 44.364026293610571],
          [22.51407172400971, 44.359511339592011],
          [22.516883450849008, 44.354662032769859],
          [22.525785755314629, 44.340683507331256],
          [22.528857993586318, 44.336674344554702],
          [22.53208294545631, 44.333088995668838],
          [22.53208294545631, 44.333088995668838],
          [22.53208294545631, 44.333088995668838],
          [22.53208294545631, 44.333088995668838],
          [22.533565165675103, 44.332009492855008],
          [22.535361796243343, 44.331013505373562],
          [22.54298849300552, 44.327813379509678],
          [22.549698908177891, 44.325339266955247],
          [22.561044630216323, 44.321643969480306],
          [22.561044630216323, 44.321643969480306],
          [22.566802831187527, 44.319844436004225],
          [22.566802831187527, 44.319844436004225],
          [22.569372012900111, 44.319041055024854],
          [22.569372012900111, 44.319041055024854],
          [22.584580490660255, 44.313841307347495],
          [22.586996958774535, 44.312645750152193],
          [22.587967139281385, 44.311951544474802],
          [22.589197831220627, 44.310755948781342],
          [22.590024281282016, 44.30960532562645],
          [22.591587349876384, 44.306693312618918],
          [22.592737193440058, 44.305144037111646],
          [22.594084666366239, 44.30368472361598],
          [22.596483168174835, 44.301691769935807],
          [22.600265075520976, 44.299486584280757],
          [22.603472061085284, 44.298200723513943],
          [22.617207301779473, 44.29371284880196],
          [22.625193324655296, 44.291951039193208],
          [22.630385586997509, 44.291140846230881],
          [22.630385586997509, 44.291140846230881],
          [22.646249834915057, 44.290857919071399],
          [22.649744281370282, 44.290600711380051],
          [22.653077031074368, 44.290150595209816],
          [22.653077031074368, 44.290150595209816],
          [22.658790316281365, 44.288896681974606],
          [22.664126309069037, 44.287102574949174],
          [22.668779582240774, 44.284935426366168],
          [22.673271158661372, 44.282150812459392],
          [22.677151880688768, 44.278960884681197],
          [22.680269034724663, 44.275552101090639],
          [22.682739401755992, 44.271808643002892],
          [22.68377246433273, 44.269698822728856],
          [22.684571964935596, 44.267576061265338],
          [22.685362482385621, 44.264327444538836],
          [22.685649943276537, 44.261123682325739],
          [22.685434347608354, 44.257694562675013],
          [22.684724678533897, 44.254458268210705],
          [22.683287374079306, 44.250018528802087],
          [22.681526676122431, 44.245617065020468],
          [22.678894612339963, 44.240192000859643],
          [22.674591682129027, 44.232719652275115],
          [22.673711333150592, 44.230415325816679],
          [22.673199293438646, 44.228091598000368],
          [22.673100478757391, 44.224795738064756],
          [22.673316074425578, 44.223154176481671],
          [22.67377421522048, 44.221300122473551],
          [22.675130671299499, 44.218151959537558],
          [22.677169846994452, 44.215164589985868],
          [22.677169846994452, 44.215164589985868],
          [22.67933478682918, 44.212743679353736],
          [22.68075412497809, 44.211449481519345],
          [22.687617253748762, 44.206201571986568],
          [22.690321182753962, 44.204527293882002],
          [22.69173153775003, 44.203941285303088],
          [22.69641176038029, 44.203535583641312],
          [22.706266279047085, 44.203535583641312],
          [22.727250924084117, 44.20162295246022],
          [22.72916433563929, 44.201017594974999],
          [22.737716297144107, 44.197333796193995],
          [22.743932638910213, 44.194358252117034],
          [22.745720286325614, 44.193739938389662],
          [22.750768818222362, 44.192419559232839],
          [22.759257897657292, 44.189186115599028],
          [22.762581664208536, 44.187427614613753],
          [22.76525864375521, 44.185443601800678],
          [22.773307548700924, 44.177049458125758],
          [22.776801995156148, 44.173937587821044],
          [22.779308294798838, 44.171953121009622],
          [22.782021206956884, 44.169987917616147],
          [22.788273481334354, 44.166044426728661],
          [22.792315900112889, 44.163151077019641],
          [22.797957320097161, 44.159722692790034],
          [22.801586513845006, 44.157950423586065],
          [22.804389257531458, 44.156835477863446],
          [22.808755069812278, 44.155436932344529],
          [22.824825930245176, 44.150770577560799],
          [22.836342332187591, 44.146477720962487],
          [22.84695143569304, 44.142210337873912],
          [22.866570641498214, 44.13505436581719],
          [22.870496279289814, 44.13327490840296],
          [22.874987855710412, 44.130902215108925],
          [22.882003698079384, 44.126730428111948],
          [22.887851730579005, 44.122609935050647],
          [22.895128084380371, 44.116418982694952],
          [22.895128084380371, 44.116418982694952],
          [22.900751538058962, 44.111814041229074],
          [22.904416664418164, 44.109246985324788],
          [22.910866568158145, 44.105338138338666],
          [22.917693764317452, 44.10160320643314],
          [22.920209047112991, 44.100551706354089],
          [22.923820274555148, 44.099551796294065],
          [22.926946411743884, 44.099061511634481],
          [22.93254291596395, 44.098719600189391],
          [22.93254291596395, 44.098719600189391],
          [22.952207037533324, 44.098326077022229],
          [22.973290497251611, 44.098248662320394],
          [22.981896357673474, 44.09800996635169],
          [22.987313198836716, 44.097597084563361],
          [22.992011387772664, 44.096971305106784],
          [22.996287368525071, 44.096119718300876],
          [23.001785058063884, 44.094777799051414],
          [23.01214263328978, 44.091990638692891],
          [23.020685611641756, 44.089216251408025],
          [23.024647182044724, 44.087506389776323],
          [23.02868960082326, 44.085299627366958],
          [23.03731342755081, 44.080169554531004],
          [23.040969570757177, 44.077672112809033],
          [23.042496706740181, 44.076381403936466],
          [23.045380298802201, 44.073425574561057],
          [23.046718788575539, 44.071553903202506],
          [23.047671002776706, 44.069578903035101],
          [23.048039312043198, 44.067907201068152],
          [23.048165076182975, 44.06641618380425],
          [23.048201008794337, 44.063659962105504],
          [23.048012362584675, 44.062220479153765],
          [23.046943367396569, 44.059257487039929],
          [23.043916044889087, 44.054138068479965],
          [23.039918541874755, 44.048178843588481],
          [23.024790912490182, 44.027442784224135],
          [23.01852965495987, 44.019853032398004],
          [23.014217741596095, 44.015040303412079],
          [23.011441947368166, 44.012559495910587],
          [23.008064281899877, 44.010886184330253],
          [23.006653926903809, 44.010492077050401],
          [22.995460918463682, 44.009051301921311],
          [22.98713353577989, 44.007558802887274],
          [22.971089624805515, 44.005555823089196],
          [22.958881520094334, 44.003656160405484],
          [22.958881520094334, 44.003656160405484],
          [22.948443096492866, 44.002480148258812],
          [22.948443096492866, 44.002480148258812],
          [22.934150900322525, 44.001394577893599],
          [22.934150900322525, 44.001394577893599],
          [22.930728319090029, 44.000677315147378],
          [22.924161634363116, 43.99882919280158],
          [22.914405930377576, 43.996612662460187],
          [22.895810803996302, 43.992922569699751],
          [22.894373499541711, 43.992502492053788],
          [22.891525840091049, 43.991436127752138],
          [22.888974624684153, 43.990124150232901],
          [22.884680677626061, 43.986297922101095],
          [22.8807640229873, 43.982122398495989],
          [22.878994341877583, 43.979898780019923],
          [22.877763649938338, 43.977888402100838],
          [22.876883300959904, 43.975780987543764],
          [22.871313746198361, 43.952128858064548],
          [22.868906261236923, 43.93969769424158],
          [22.867738451367568, 43.935777651926273],
          [22.867738451367568, 43.935777651926273],
          [22.866373012135703, 43.932200226076276],
          [22.864495533191896, 43.928635524435805],
          [22.863408571698109, 43.927225111623983],
          [22.859366152919574, 43.922760740533754],
          [22.856159167355266, 43.918321917723887],
          [22.856159167355266, 43.918321917723887],
          [22.851928102367062, 43.914038075018382],
          [22.850338084314171, 43.911850737308349],
          [22.846205834007222, 43.902077921323766],
          [22.844615815954331, 43.897715232552173],
          [22.84317851149974, 43.893047966790803],
          [22.841714257586624, 43.886081991108512],
          [22.841372897778658, 43.881737554798079],
          [22.841372897778658, 43.875644450950489],
          [22.841678324975259, 43.872471387152366],
          [22.842522741342332, 43.867607853302303],
          [22.844238523535001, 43.861921354457323],
          [22.845810575282208, 43.858488467320342],
          [22.849116375527771, 43.853364677568628],
          [22.851425045807957, 43.850579120418232],
          [22.855009323791592, 43.846730950727874],
          [22.860417181801992, 43.842487310804501],
          [22.86360620106062, 43.840556516695514],
          [22.871322729351203, 43.836681781620619],
          [22.873945809980835, 43.835437666159322],
          [22.87753008796447, 43.834180564816563],
          [22.890241249234759, 43.831057136538419],
          [22.901613920731716, 43.828672334361549],
          [22.912375737835465, 43.826702208518157],
          [22.923784341943783, 43.823889481967143],
          [22.933441231248068, 43.822243262552107],
          [22.940358258935788, 43.820843292215727],
          [22.94352032873589, 43.819851626698714],
          [22.953662308293598, 43.815709786787707],
          [22.956366237298798, 43.814867123779642],
          [22.963283264986519, 43.813116939537103],
          [22.981042958153559, 43.810621217734095],
          [22.999862663355866, 43.807457659442029],
          [23.015250804172833, 43.806024927305032],
          [23.015250804172833, 43.806024927305032],
          [23.035804257873487, 43.803853073245051],
          [23.037331393856491, 43.803762307475878],
          [23.042927898076556, 43.803924389109795],
          [23.042927898076556, 43.803924389109795],
          [23.048892711563109, 43.803937355621507],
          [23.055549227818435, 43.803360343126187],
          [23.055549227818435, 43.803360343126187],
          [23.065843920974444, 43.802562889717528],
          [23.076003866837837, 43.801473667863029],
          [23.081411724848238, 43.80120784284825],
          [23.081411724848238, 43.80120784284825],
          [23.094796622581615, 43.801227293499188],
          [23.106106412008682, 43.801525536020684],
          [23.128222934303704, 43.802776841676888],
          [23.135139961991424, 43.80345759281365],
          [23.138472711695506, 43.804248551058357],
          [23.144976514352532, 43.80699089075523],
          [23.155702398844923, 43.811982533464551],
          [23.163257230384367, 43.816267234242495],
          [23.168072200307247, 43.818691421951257],
          [23.173309378413663, 43.820901624968499],
          [23.176740942799, 43.82206826805534],
          [23.186505629937379, 43.824868118450865],
          [23.214712729858732, 43.832541109360726],
          [23.226866935652868, 43.835269190196513],
          [23.229588830963749, 43.835632060909987],
          [23.238751646861772, 43.835826455027366],
          [23.240943536155019, 43.836143964057207],
          [23.240943536155019, 43.836143964057207],
          [23.241868800897667, 43.836416113309589],
          [23.247564119798984, 43.839137537566664],
          [23.254472164333862, 43.843089861273477],
          [23.256825750378255, 43.84410057742631],
          [23.259754258204485, 43.844878039737729],
          [23.263679895996088, 43.845674928091363],
          [23.267246207674042, 43.846024778152199],
          [23.271926430304305, 43.846245053063768],
          [23.271926430304305, 43.846245053063768],
          [23.291527669803791, 43.846867001364544],
          [23.300753367771698, 43.847851739578502],
          [23.306511568742906, 43.848920680421827],
          [23.306511568742906, 43.848920680421827],
          [23.322169204145109, 43.852185690189579],
          [23.324199396687217, 43.852522546104787],
          [23.32825978177144, 43.852794620647302],
          [23.340845178901954, 43.852976002986061],
          [23.35351142440804, 43.852684495386754],
          [23.356835190959281, 43.852101475913486],
          [23.365908175328887, 43.85006734112013],
          [23.370273987609711, 43.849302903103997],
          [23.372411977985912, 43.849115031244267],
          [23.377730004467903, 43.849011377551108],
          [23.389372170550093, 43.850047906376695],
          [23.422097796350563, 43.853844039398439],
          [23.427074463024585, 43.853960640341981],
          [23.429688560501376, 43.853643226127907],
          [23.438465100827223, 43.851699836960748],
          [23.445525858960401, 43.84993129778308],
          [23.469807321090155, 43.842202231042499],
          [23.484261214011635, 43.838269287120077],
          [23.491160275393671, 43.83560614164648],
          [23.502425149056535, 43.830383180365217],
          [23.511210672535224, 43.825982837152019],
          [23.549829246599518, 43.80437821534526],
          [23.553350642513269, 43.80282222532734],
          [23.565495865154563, 43.799885183728421],
          [23.57869211667828, 43.798218850902359],
          [23.587836966270618, 43.796338497355251],
          [23.602407640179035, 43.793628090544317],
          [23.622026845984205, 43.790742474507603],
          [23.627515552370173, 43.790534964164387],
          [23.62950981230092, 43.790735989820277],
          [23.632070010860659, 43.791241793318342],
          [23.638457032530752, 43.792733240250534],
          [23.667310919456671, 43.800047275881127],
          [23.675620335834775, 43.801758942172903],
          [23.685843163768059, 43.804715341174081],
          [23.685843163768059, 43.804715341174081],
          [23.691816960407451, 43.806161070750363],
          [23.696021075937129, 43.80659543109698],
          [23.707753073547732, 43.806939027342665],
          [23.715649264895141, 43.806776953888132],
          [23.719296424948666, 43.806459288641612],
          [23.728522122916573, 43.805058948234134],
          [23.758858230061289, 43.801946963132387],
          [23.771973633209438, 43.800138047294567],
          [23.777426406984041, 43.799029329871445],
          [23.781037634426202, 43.797927075782233],
          [23.785349547789977, 43.795897578304206],
          [23.790371130228202, 43.792992618526313],
          [23.795599325181779, 43.789413098895061],
          [23.79905783902564, 43.786559705058643],
          [23.806864198844639, 43.779542370885139],
          [23.8080769244782, 43.778251662445449],
          [23.810601190426574, 43.774405315795946],
          [23.811751033990248, 43.773179362425417],
          [23.813314102584616, 43.772070144862894],
          [23.817590083337024, 43.769579195364415],
          [23.821219277084868, 43.767691453598694],
          [23.845895997939632, 43.758991502596672],
          [23.857789692301374, 43.755500786328199],
          [23.879762484150937, 43.74972573427133],
          [23.884676268755072, 43.748629060305653],
          [23.888790552756337, 43.748006088630511],
          [23.91344930730542, 43.746974277531017],
          [23.918300209839664, 43.746565441798154],
          [23.928819481816703, 43.745299980165527],
          [23.935673627434536, 43.745209125480571],
          [23.954583164165253, 43.74572180368218],
          [23.954583164165253, 43.74572180368218],
          [23.965264132893434, 43.746299372599033],
          [23.965264132893434, 43.746299372599033],
          [23.978388519194421, 43.746909383156691],
          [23.986176912707737, 43.746747146913044],
          [23.993920390456847, 43.744508241847434],
          [23.993920390456847, 43.744508241847434],
          [23.996615336309205, 43.743638615562112],
          [24.000738603463311, 43.742022634663932],
          [24.01026074547498, 43.736973224528384],
          [24.0221274903782, 43.733403333184377],
          [24.03288032432911, 43.728684259329427],
          [24.043956551782305, 43.724179046881574],
          [24.072442129441736, 43.710836697232779],
          [24.075415553032169, 43.708986063792359],
          [24.078595589137951, 43.706252216511956],
          [24.080985107793712, 43.704453393878893],
          [24.082332580719889, 43.703661115524639],
          [24.084443621637568, 43.702803884528301],
          [24.087147550642769, 43.702005090067381],
          [24.088521973027472, 43.701790777547565],
          [24.098672935738026, 43.700836103379373],
          [24.113351407480536, 43.699809974057516],
          [24.118498754058539, 43.699108559099678],
          [24.124328820252476, 43.697997968635079],
          [24.149275035692476, 43.692113444359769],
          [24.154161870838088, 43.690528551044011],
          [24.165624373863452, 43.685884052809598],
          [24.166998796248155, 43.685403342945285],
          [24.169127803471518, 43.684909636912678],
          [24.183339151266289, 43.683558420677272],
          [24.188558363067024, 43.68342849443534],
          [24.194810637444494, 43.683525939143159],
          [24.200389175358875, 43.684078122831131],
          [24.286250150215022, 43.696309312138006],
          [24.299608098489877, 43.698374662443015],
          [24.305752575033253, 43.698829289749256],
          [24.307692936046955, 43.698744859224455],
          [24.314493182747739, 43.69812136859143],
          [24.340984500476424, 43.696588593225407],
          [24.343338086520816, 43.696809419210858],
          [24.345925234539081, 43.697354985806086],
          [24.349033405422134, 43.698342188932095],
          [24.354387364515485, 43.700374996806197],
          [24.354387364515485, 43.700374996806197],
          [24.359408946953714, 43.702401241496183],
          [24.369416179218806, 43.706843153684282],
          [24.380977496925421, 43.711096430686737],
          [24.400821281551622, 43.716667442255144],
          [24.408951034872906, 43.719108654746144],
          [24.412373616105402, 43.721069343818648],
          [24.421033375444313, 43.727106824357001],
          [24.437616275589161, 43.737791024219838],
          [24.442916335765464, 43.741445024483895],
          [24.445772978368964, 43.743132409370233],
          [24.451908471759499, 43.746299372599033],
          [24.457738537953439, 43.748927565268879],
          [24.469290872507216, 43.753593134141866],
          [24.479603531968909, 43.757233193275482],
          [24.479603531968909, 43.757233193275482],
          [24.486367846058325, 43.758699535991752],
          [24.493284873746045, 43.759912810097759],
          [24.499213754621238, 43.760632977209092],
          [24.503938893015704, 43.760918446457701],
          [24.508134025392543, 43.760840591343161],
          [24.513218489900659, 43.760516193942074],
          [24.549609242060342, 43.757765233282065],
          [24.562913291418152, 43.757278611509506],
          [24.562913291418152, 43.757278611509506],
          [24.566937743891007, 43.756850381076035],
          [24.585712533329104, 43.753794284009125],
          [24.591605481592929, 43.752321332233251],
          [24.604783766810961, 43.74735066351478],
          [24.620369536990435, 43.740698665998806],
          [24.635416317999436, 43.733948566722724],
          [24.654864843900626, 43.724477673753839],
          [24.658305391438802, 43.72254306455703],
          [24.662590355344051, 43.719667003201671],
          [24.663749182060567, 43.718732091222002],
          [24.672498772927892, 43.710960070763754],
          [24.67767306896442, 43.706739253065102],
          [24.694453598471771, 43.696939316469475],
          [24.694453598471771, 43.696939316469475],
          [24.70113706418562, 43.693126714858955],
          [24.708170872860279, 43.689684123569279],
          [24.710066318109771, 43.688898145775525],
          [24.712096510651879, 43.688281048554487],
          [24.719804055789627, 43.686215350608897],
          [24.72434953112727, 43.685643698358966],
          [24.727484651468849, 43.685552753180559],
          [24.731077912605326, 43.68574113946854],
          [24.73470710635317, 43.686358262820171],
          [24.744310096740406, 43.688495409361444],
          [24.747561998068917, 43.68906053872584],
          [24.747561998068917, 43.68906053872584],
          [24.757901606989133, 43.691048192811543],
          [24.768124434922413, 43.692490175134402],
          [24.772616011343011, 43.693315077359543],
          [24.773738905448162, 43.693568391513708],
          [24.776317070313585, 43.695049283617792],
          [24.776317070313585, 43.695049283617792],
          [24.779173712917082, 43.697101687647042],
          [24.782713075136513, 43.699192989112348],
          [24.793205397655029, 43.704453393878893],
          [24.798361727385878, 43.706628858459872],
          [24.803006017404776, 43.708128908942449],
          [24.809006763502694, 43.709719830554477],
          [24.817010752684197, 43.711297723339364],
          [24.822158099262204, 43.712102887189189],
          [24.829973442234042, 43.712752204995304],
          [24.837366577022344, 43.712687273531273],
          [24.839872876665041, 43.712297683269703],
          [24.845190903147028, 43.710830203882004],
          [24.85302421242455, 43.709200350589569],
          [24.859833442278177, 43.708291249817073],
          [24.867289459136366, 43.707830200584326],
          [24.873308171539971, 43.707901630979293],
          [24.878141107768531, 43.708252288047262],
          [24.895568424280452, 43.71042761476027],
          [24.895568424280452, 43.71042761476027],
          [24.906060746798968, 43.711830171612775],
          [24.921233291947747, 43.715089690847151],
          [24.933019188475395, 43.716790803782921],
          [24.939415193298323, 43.717875075758826],
          [24.946188490540589, 43.719517677961633],
          [24.957830656622775, 43.722887143914633],
          [24.957830656622775, 43.722887143914633],
          [24.961756294414375, 43.723893402393401],
          [24.966283803446338, 43.724743839928742],
          [24.969284176495297, 43.724971054020429],
          [24.970541817893064, 43.724886660315548],
          [24.974530337754555, 43.723679168133451],
          [24.980162774585985, 43.720783684503203],
          [24.983612305277003, 43.719368352349434],
          [24.983612305277003, 43.719368352349434],
          [24.98935253994253, 43.717232306117168],
          [25.007040367886841, 43.711122403970236],
          [25.007040367886841, 43.711122403970236],
          [25.009825145267612, 43.710323720354374],
          [25.013993328185926, 43.709466584634022],
          [25.018637618204824, 43.708862686197961],
          [25.023883779464082, 43.708414628587725],
          [25.027575855281814, 43.70758344038309],
          [25.027575855281814, 43.70758344038309],
          [25.03101640281999, 43.706336636464684],
          [25.032956763833688, 43.705466455864183],
          [25.039550398019127, 43.701972618522717],
          [25.053177840879222, 43.695770231011714],
          [25.056528556888985, 43.694386783776025],
          [25.067308340298421, 43.690625984218364],
          [25.080208147778375, 43.688079678613725],
          [25.092964224812874, 43.6867285337837],
          [25.098785307853969, 43.686553142559568],
          [25.105046565384281, 43.686663574130861],
          [25.11802722123981, 43.685832084361337],
          [25.11802722123981, 43.685832084361337],
          [25.129840067225981, 43.685007079214643],
          [25.131349236903301, 43.685111017495601],
          [25.135679116572756, 43.685819092242227],
          [25.140691715858146, 43.686825973131072],
          [25.142281733911037, 43.687241712568124],
          [25.14599177603445, 43.688956607288283],
          [25.153214230918771, 43.693126714858955],
          [25.153214230918771, 43.693126714858955],
          [25.158172931287112, 43.69663405746536],
          [25.162556709873616, 43.698030442332417],
          [25.17048883383239, 43.699680083017725],
          [25.178807233363337, 43.700439941608536],
          [25.183038298351537, 43.70055684207545],
          [25.185490699077185, 43.700329535402716],
          [25.187637672606229, 43.699874919471888],
          [25.202127498139081, 43.695361045716673],
          [25.205020073353943, 43.694536171634361],
          [25.210356066141614, 43.69334105834767],
          [25.225393863997777, 43.691223570891914],
          [25.233981758113956, 43.69030120635496],
          [25.233981758113956, 43.69030120635496],
          [25.24158150541761, 43.689424297339073],
          [25.243144574011978, 43.688872162862673],
          [25.244384249104058, 43.688144636732993],
          [25.246073081838205, 43.686722037821582],
          [25.247339706388814, 43.68390922012496],
          [25.247339706388814, 43.68390922012496],
          [25.24836378581271, 43.682376081505097],
          [25.249846006031508, 43.67877694961836],
          [25.251543821918492, 43.677594516215279],
          [25.251543821918492, 43.677594516215279],
          [25.255155049360653, 43.675879296677785],
          [25.259718491003984, 43.673228406410914],
          [25.259718491003984, 43.673228406410914],
          [25.264165151660372, 43.670590394463133],
          [25.268665711233812, 43.667386938352493],
          [25.268665711233812, 43.667386938352493],
          [25.277190723280107, 43.66032963473647],
          [25.285868448924699, 43.655084857999626],
          [25.289246114392991, 43.653726470627483],
          [25.296818912238116, 43.651328094462457],
          [25.301633882160999, 43.650080121012742],
          [25.301633882160999, 43.650080121012742],
          [25.313581475439786, 43.647174582363384],
          [25.314982847283012, 43.646524554478177],
          [25.32021104223659, 43.643365318789371],
          [25.32021104223659, 43.643365318789371],
          [25.324451090377636, 43.640882016916557],
          [25.330002678833491, 43.638314096825198],
          [25.330002678833491, 43.638314096825198],
          [25.334853581367739, 43.636604256254024],
          [25.342085019404898, 43.634653808366217],
          [25.342085019404898, 43.634653808366217],
          [25.347582708943712, 43.633164923872329],
          [25.348840350341479, 43.632651282672605],
          [25.352433611477956, 43.630733216604092],
          [25.352433611477956, 43.630733216604092],
          [25.358488256492922, 43.627677188276721],
          [25.365980205962479, 43.62446493995229],
          [25.370390934007506, 43.622904271523097],
          [25.370390934007506, 43.622904271523097],
          [25.373481138584875, 43.621954845074903],
          [25.378853063983911, 43.620777795579912],
          [25.386129417785281, 43.619737290649766],
          [25.396145633203211, 43.618891867134984],
          [25.400062287841976, 43.618989416608756],
          [25.407365591101865, 43.620179507445307],
          [25.430919417851477, 43.62544033714601],
          [25.438267636875576, 43.62745610786029],
          [25.440791902823953, 43.62838594060036],
          [25.440791902823953, 43.62838594060036],
          [25.447035194048585, 43.630947782967858],
          [25.448795892005457, 43.631845052189504],
          [25.450691337254952, 43.633288457176882],
          [25.454185783710173, 43.637117863667314],
          [25.456018346889778, 43.638411614782775],
          [25.458416848698377, 43.639724841194727],
          [25.462872492507611, 43.641434592966171],
          [25.462872492507611, 43.641434592966171],
          [25.468999002745306, 43.643488831124287],
          [25.475233310817096, 43.644983960299179],
          [25.475233310817096, 43.644983960299179],
          [25.482177287963342, 43.646492052899262],
          [25.485177661012298, 43.646771565903187],
          [25.486614965466892, 43.646680561812154],
          [25.486614965466892, 43.646680561812154],
          [25.488016337310118, 43.646505053532941],
          [25.494484207355775, 43.644476920661958],
          [25.497529496168941, 43.6438073617635],
          [25.500484953453697, 43.643514833688755],
          [25.502802606886725, 43.643618843834084],
          [25.502802606886725, 43.643618843834084],
          [25.51298950220864, 43.643878868409558],
          [25.524937095487427, 43.643118293357716],
          [25.524937095487427, 43.643118293357716],
          [25.536345699595746, 43.642273198673294],
          [25.545769026926159, 43.642019667949839],
          [25.549622799495033, 43.642078175134834],
          [25.549622799495033, 43.642078175134834],
          [25.555812191802616, 43.642312203305018],
          [25.560609195419815, 43.642702248229483],
          [25.565145687604616, 43.643261308205098],
          [25.566843503491604, 43.643651346967566],
          [25.572107631056546, 43.645497496082264],
          [25.575727841651545, 43.647025076572675],
          [25.58731610881669, 43.652894524433584],
          [25.58731610881669, 43.652894524433584],
          [25.606791584176399, 43.662545691431895],
          [25.606791584176399, 43.662545691431895],
          [25.615747787559069, 43.667062034674231],
          [25.624596193107649, 43.670902283693188],
          [25.631558136559576, 43.674177022754918],
          [25.635708353172209, 43.676548498011918],
          [25.635708353172209, 43.676548498011918],
          [25.638798557749578, 43.678406629575129],
          [25.641295874239429, 43.679465608884655],
          [25.651653449465329, 43.683363531208855],
          [25.659504725048535, 43.686085430121366],
          [25.666421752736252, 43.687637961537064],
          [25.669134664894294, 43.688099166056894],
          [25.672512330362583, 43.688423955844236],
          [25.672512330362583, 43.688423955844236],
          [25.676590681752486, 43.68852788820476],
          [25.684253311126024, 43.688001728777742],
          [25.697233966981553, 43.687969249649512],
          [25.697233966981553, 43.687969249649512],
          [25.709343257011483, 43.687566506998415],
          [25.719287607206688, 43.686442710784981],
          [25.719287607206688, 43.686442710784981],
          [25.72118305245618, 43.686345270815032],
          [25.724300206492074, 43.686579126476985],
          [25.725647679418255, 43.686793493366203],
          [25.731917920101406, 43.688410964286511],
          [25.731917920101406, 43.688410964286511],
          [25.743003130707443, 43.691411939369971],
          [25.750279484508809, 43.693945113148708],
          [25.752309677050921, 43.694834946234607],
          [25.754878858763504, 43.696088484310827],
          [25.760511295594934, 43.699192989112348],
          [25.776941482141478, 43.706752240652349],
          [25.780579659042161, 43.708700346871026],
          [25.782870363016666, 43.710180865252219],
          [25.786113281192339, 43.71256390355579],
          [25.787029562782141, 43.713498911775268],
          [25.788682462904919, 43.715738976292037],
          [25.792033178914686, 43.720614885176609],
          [25.797342222243831, 43.729093217170671],
          [25.800225814305858, 43.733234569416467],
          [25.803971789040634, 43.737784533790069],
          [25.805984015277062, 43.739861435408535],
          [25.813996987611411, 43.747246833134959],
          [25.821066728897428, 43.754177115759532],
          [25.823052005675333, 43.756649241481313],
          [25.82708544130103, 43.760808151682198],
          [25.829061734926093, 43.762462551965235],
          [25.832304653101765, 43.764532109867282],
          [25.83413721628137, 43.765622004996857],
          [25.835727234334261, 43.766322641380007],
          [25.838583876937761, 43.767308708313173],
          [25.840641018938392, 43.767730376710873],
          [25.846677697647678, 43.768327194592644],
          [25.859388858917971, 43.769079695607957],
          [25.865632150142599, 43.769916517515512],
          [25.868183365549498, 43.770552234859849],
          [25.870806446179127, 43.771544718836878],
          [25.871686795157562, 43.771933923743845],
          [25.875019544861647, 43.773990180960944],
          [25.876627529220222, 43.775222604080611],
          [25.879816548478846, 43.778160857787604],
          [25.882161151370397, 43.781118424311273],
          [25.887083919127374, 43.791682746721492],
          [25.89029988784452, 43.796092101816008],
          [25.891674310229224, 43.797667718923783],
          [25.894351289775898, 43.800403877069179],
          [25.908544671264988, 43.813661446798051],
          [25.914734063572574, 43.821705314871295],
          [25.921390579827897, 43.829087089411104],
          [25.925415032300752, 43.833869525346095],
          [25.929870676109985, 43.838476631658644],
          [25.93899755939664, 43.846588421156625],
          [25.93899755939664, 43.846588421156625],
          [25.946507475171881, 43.853895862068157],
          [25.950864304299856, 43.857516859602647],
          [25.955409779637503, 43.860865600780805],
        ],
      },
    },
  ],
};
