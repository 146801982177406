import { useCallback, useEffect, useMemo, useState } from 'react';
import { Layer, MapLayerMouseEvent, Popup, Source, useMap } from 'react-map-gl';

import { DateLabel } from '@/components';
import { TemporalVessel, Vessel, VesselProperty } from '@/store/vessels';
import {
  mmsiToColor,
  vesselTrackToLineString,
  vesselTrackToPointFeatureCollection,
} from '@/utils';
import { symmetricTooltipOffset } from '@/utils/map';

export type VesselFeatureProperties = {
  timestamp: string;
  longitude: number;
  latitude: number;
};

type Props = {
  vessel: Vessel;
  vesselTrack: TemporalVessel;
};
export const MapTrackLayer = ({ vessel, vesselTrack }: Props) => {
  const [vesselTrackLineString, vesselTrackPoints] = useMemo(
    () => [
      vesselTrackToLineString(vesselTrack),
      vesselTrackToPointFeatureCollection(vesselTrack),
    ],
    [vesselTrack],
  );

  const [popupData, setPopupData] = useState<VesselFeatureProperties>();

  const { current: map } = useMap();

  // using 'mousemove' instead of something like 'mouseenter'
  // because mapbox events trigger per layer, not per feature
  // https://github.com/mapbox/mapbox-gl-js/issues/5539
  const onMouseMove = useCallback((event: MapLayerMouseEvent) => {
    if (map) {
      const feature = event.features?.[0];
      if ((feature?.properties as VesselFeatureProperties)?.timestamp) {
        map.getCanvas().style.cursor = 'pointer';
        setPopupData(feature?.properties as VesselFeatureProperties);
      }
    }
  }, []);

  const onMouseLeave = useCallback(() => {
    if (map) {
      // cleanup state
      map.getCanvas().style.cursor = '';
      setPopupData(undefined);
    }
  }, []);

  useEffect(() => {
    if (map) {
      map.on('mousemove', 'vessel-track-pts-ly', onMouseMove);
      map.on('mouseleave', 'vessel-track-pts-ly', onMouseLeave);

      return () => {
        map.off('mousemove', 'vessel-track-pts-ly', onMouseMove);
        map.off('mouseleave', 'vessel-track-pts-ly', onMouseLeave);
      };
    }
  }, []);

  return (
    <>
      {popupData && (
        <Popup
          closeButton={false}
          closeOnClick={false}
          latitude={popupData.latitude}
          longitude={popupData.longitude}
          offset={symmetricTooltipOffset(10)}
        >
          <DateLabel
            className={'text-center'}
            date={new Date(popupData.timestamp)}
            fromNow={'bottom'}
          />
        </Popup>
      )}
      <Source
        data={vesselTrackLineString}
        id={'vessel-track-line-src'}
        type={'geojson'}
      >
        <Layer
          id={'vessel-track-line-ly'}
          layout={{
            'line-join': 'round',
            'line-cap': 'round',
          }}
          paint={{
            'line-color': mmsiToColor(
              vessel[VesselProperty.MMSI].value as unknown as number,
            ),
            'line-width': 3,
            'line-dasharray': [1, 3, 1],
            'line-opacity': 0.6,
          }}
          type={'line'}
        />
      </Source>
      <Source
        data={vesselTrackPoints}
        id={'vessel-track-pts-src'}
        type={'geojson'}
      >
        <Layer
          filter={['!', ['has', 'cluster']]}
          id={'vessel-track-pts-ly'}
          paint={{
            'circle-radius': 5,
            'circle-opacity': 0.75,
            'circle-stroke-width': 1.5,
            'circle-stroke-color': '#fff',
            'circle-color': mmsiToColor(
              vessel[VesselProperty.MMSI].value as unknown as number,
            ),
          }}
          type={'circle'}
        />
      </Source>
    </>
  );
};
