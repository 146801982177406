import moment from 'moment';
import React, { useEffect } from 'react';

import { Container, Map } from '@/components';
import { NavBar } from '@/components/navigation';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { selectEndTime, selectStartTime } from '@/store/timestamps';
import {
  fetchHistoricalVessels,
  fetchVessels,
  fetchVesselTrack,
  selectHistoricMode,
  selectLoadingData,
  selectSelectedVessel,
  selectSelectedVesselTrack,
  selectVesselCollection,
} from '@/store/vessels';
import { Page } from '@/pages/Page';

export function VesselMapPage() {
  const dispatch = useAppDispatch();
  const vessels = useAppSelector(selectVesselCollection);
  const activeVessel = useAppSelector(selectSelectedVessel);
  const activeVesselTrack = useAppSelector(selectSelectedVesselTrack);
  const loadingData = useAppSelector(selectLoadingData);
  const startTime = useAppSelector(selectStartTime);
  const endTime = useAppSelector(selectEndTime);
  const historicMode = useAppSelector(selectHistoricMode);

  useEffect(() => {
    if (historicMode) {
      dispatch(fetchHistoricalVessels());
    } else {
      dispatch(fetchVessels());
    }
  }, [startTime, endTime, historicMode]);

  useEffect(() => {
    dispatch(fetchVesselTrack());
  }, [activeVessel, startTime, endTime]);

  return (
    <Page>
      <NavBar />
      <Container className="flex-1">
        <Map
          activeVessel={activeVessel}
          activeVesselTrack={activeVesselTrack}
          endTime={moment(endTime)?.toDate()}
          historicalMode={historicMode}
          loadingData={loadingData}
          startTime={moment(startTime)?.toDate()}
          vessels={vessels}
        />
      </Container>
    </Page>
  );
}
