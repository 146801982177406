import 'mapbox-gl/dist/mapbox-gl.css';
import 'tailwindcss/tailwind.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';

import { USER_DISPLAY_NAME } from '@/constants/env';
import { store } from '@/store';

import { App } from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Helmet>
        <title>
          Sytadel{USER_DISPLAY_NAME ? ` - ${USER_DISPLAY_NAME}` : ''}
        </title>
      </Helmet>
      <App />
    </Provider>
  </React.StrictMode>,
);
