import classNames from 'classnames';
import React from 'react';

const TH_STYLE = classNames('border-2', 'p-2');

export function TableHeader({ headers }: { headers: string[] }) {
  return (
    <tr>
      {headers.map((header, index) => (
        <th className={TH_STYLE} key={index}>
          {header}
        </th>
      ))}
    </tr>
  );
}
