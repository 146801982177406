import type { AppDispatch, RootState } from '@/store';
import { selectEndTime, selectStartTime } from '@/store/timestamps';
import {
  convertTemporalVessels,
  selectSelectedVessel,
  setSelectedVesselTrack,
  TemporalVessel,
  Vessel,
  VesselProperty,
} from '@/store/vessels';
import { dedupObservations } from '@/utils/geo';
import { ENTITIES_PROXY_ENDPOINT } from '@/constants/env';

const temporalPath = 'temporal';

async function fetchVessels() {
  return fetch(`${ENTITIES_PROXY_ENDPOINT}?limit=1000`);
}

function fetchHistoricalVessels(startTime: string, endTime: string) {
  const attrs = [
    'location',
    VesselProperty.Name,
    VesselProperty.MMSI,
    VesselProperty.VesselType,
    VesselProperty.DestinationPort,
    VesselProperty.EstimatedTimeOfArrival,
    VesselProperty.DataProvider,
  ].join(',');

  return fetch(
    `${ENTITIES_PROXY_ENDPOINT}/${temporalPath}` +
      `?timerel=between` +
      `&timeAt=${startTime}` +
      `&endTimeAt=${endTime}` +
      `&limit=100` +
      `&attrs=${attrs}` +
      `&lastN=1` +
      `&options=temporalValues`,
  );
}

export async function retrieveVessels(): Promise<Vessel[]> {
  try {
    const response = await fetchVessels();

    const response_json = await response.json();
    if (response_json.type && response_json.type.includes('error')) {
      // Added to handle errors from the broker that still yield a 200 response, such as when the tenant is not found
      console.warn('Error retrieving vessels', response_json);

      return [];
    }

    return (await response_json) as Vessel[];
  } catch (error) {
    console.warn('Error retrieving vessels', error);

    return [];
  }
}

export async function retrieveHistoricalVessels(
  startTime: string,
  endTime: string,
): Promise<Vessel[]> {
  try {
    const response = await fetchHistoricalVessels(startTime, endTime);
    const temporalVessels = (await response.json()) as TemporalVessel[];

    return convertTemporalVessels(temporalVessels);
  } catch (error) {
    console.warn('Error retrieving historical vessels', error);

    return [];
  }
}

export async function retrieveVesselTrack(
  dispatch: AppDispatch,
  getState: () => RootState,
) {
  const state = getState();
  const startTime = selectStartTime(state);
  const endTime = selectEndTime(state);
  const vessel = selectSelectedVessel(state);

  if (!endTime || !vessel) {
    return;
  }

  let url: string | null =
    `${ENTITIES_PROXY_ENDPOINT}/${temporalPath}/${vessel.id}` +
    `?attrs=location,type` +
    `&options=temporalValues` +
    `&timerel=between` +
    `&timeAt=${startTime}` +
    `&endTimeAt=${endTime}`;

  let combinedData: TemporalVessel | null = null;

  while (url) {
    const response = await fetch(url);

    if (response.status === 200 || response.status === 206) {
      const data = await response.json();
      if (combinedData === null) {
        combinedData = data as TemporalVessel;
      } else {
        combinedData.location.values = [
          ...combinedData.location.values,
          ...data.location.values,
        ];
      }

      // Check if a "Content-Range" header exists, indicating further content
      const contentRange = response.headers.get('Content-Range');
      if (contentRange) {
        // Modify the URL to fetch the next chunk of data
        url = modifyUrlForNextChunk(url, contentRange);
      } else {
        // If there's no "Content-Range" header, we've fetched all the data
        url = null;
      }
    } else {
      console.warn('Error retrieving vessel track', response);
      dispatch(setSelectedVesselTrack(null));
      url = null;
    }
  }

  if (combinedData) {
    combinedData = dedupObservations(combinedData);
  }
  console.log('combinedData', combinedData);
  console.log('vessel', vessel);
  dispatch(setSelectedVesselTrack(combinedData));
}

export function modifyUrlForNextChunk(
  url: string,
  contentRange: string,
): string | null {
  // Extract the end time from the "Content-Range" header and use it as the new start time
  const match = contentRange.match(/-(\d{4}-\d{2}-\d{2}T.*)\/\*/);
  if (match) {
    const newStartTime = match[1];
    // Replace the old startTime in the URL with the new one
    return url.replace(/(timeAt=)[^&]*/, `$1${newStartTime}Z`);
  } else {
    // If we couldn't extract the end time, return null
    return null;
  }
}
